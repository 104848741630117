import * as React from "react";
import { useLocation } from "react-router-dom";
import { AdminRoutes, AuthRoutes, VendorRoutes } from "./routes";
import { getDataFromCache, removeFromCache } from "./utils/cache";
import { UserTokens } from "./utils/globalTypes";

const App: React.FC = () => {
	// const { toggleColorMode } = useColorMode();

	// const navigationItems: NavigationPropsType["items"] = [
	// 	{
	// 		Icon: <BiGridAlt size='18' />,
	// 		href: "/dashboard",
	// 		label: "Dashboard",
	// 	},
	// 	"SEPERATOR",
	// 	{
	// 		Icon: <BiBriefcaseAlt size='18' />,
	// 		href: "/products",
	// 		label: "Products",
	// 	},
	// 	{
	// 		Icon: <BiArchiveIn size='18' />,
	// 		href: "/orders",
	// 		label: "Orders",
	// 	},
	// 	{
	// 		Icon: <BiCube size='18' />,
	// 		href: "/inventory",
	// 		label: "Inventory",
	// 	},
	// 	"SEPERATOR",
	// 	{
	// 		Icon: <BiStore size='18' />,
	// 		href: "/store-settings",
	// 		label: "Store settings",
	// 	},
	// 	{
	// 		Icon: <BiPhotoAlbum size='18' />,
	// 		href: "/media-library",
	// 		label: "Media library",
	// 	},
	// ];

	// const UserData = {
	// 	data: {
	// 		__typename: "Query",
	// 		GetAllUsers: {
	// 			count: 103,
	// 			users: [
	// 				{
	// 					_id: "62335e5be6ac8da8ab3f9190",
	// 					email: "mohamed.amine.fouzai@gmail.com",
	// 					firstName: "ala",
	// 				},
	// 				{
	// 					_id: "62335e5be6ac8da8ab3f9191",
	// 					email: "amine.fouzai@gmail.com",
	// 					firstName: "fouzai",
	// 				},
	// 				{
	// 					_id: "6242d22aeda752f9221c45ea",
	// 					email: "thehanterx3@gmail.com",
	// 					firstName: "ala",
	// 				},
	// 				{
	// 					_id: "6242d24ceda752f9221c45ec",
	// 					email: "thehanterx3@gmail.com",
	// 					firstName: "ala",
	// 				},
	// 				{
	// 					_id: "6242d24ceda752f9221c45ed",
	// 					email: "thehanterx3@gmail.com",
	// 					firstName: "ala",
	// 				},
	// 				{
	// 					_id: "6242d24ceda752f9221c45ee",
	// 					email: "thehanterx3@gmail.com",
	// 					firstName: "ala",
	// 				},
	// 				{
	// 					_id: "6242d24deda752f9221c45ef",
	// 					email: "thehanterx3@gmail.com",
	// 					firstName: "ala",
	// 				},
	// 				{
	// 					_id: "6242d24deda752f9221c45f0",
	// 					email: "thehanterx3@gmail.com",
	// 					firstName: "ala",
	// 				},
	// 				{
	// 					_id: "6242d24deda752f9221c45f1",
	// 					email: "thehanterx3@gmail.com",
	// 					firstName: "ala",
	// 				},
	// 				{
	// 					_id: "6242d24deda752f9221c45f2",
	// 					email: "thehanterx3@gmail.com",
	// 					firstName: "ala",
	// 				},
	// 			],
	// 		},
	// 	},
	// };
	// const tableHead: TableHeadType<Data>[] = [
	// 	{ accessor: "id", label: "ID" },
	// 	{ accessor: "email", label: "EMAIL" },
	// 	{ accessor: "firstName", label: "FIRSTNAME" },
	// 	{ accessor: "action", label: "" },
	// ];

	// type Data = typeof tableData[number];

	// const tableData = UserData.data.GetAllUsers.users.map((item, index) => ({
	// 	id: item._id,
	// 	email: item.email,
	// 	firstName: item.firstName,
	// 	action: <Button>Action</Button>,
	// }));

	const tokens: UserTokens = getDataFromCache("user_tokens");

	const role: string = getDataFromCache("user_role", true);

	if (tokens && tokens.access && tokens.refresh) {
		switch (role) {
			case "ADMIN":
				return <AdminRoutes />;
			case "VENDOR":
				return <VendorRoutes />;
			default: {
				removeFromCache("user_tokens");
				removeFromCache("user_role");
			}
		}
	}
	return <AuthRoutes />;

	// return (
	// 	<Routes>
	// 		{/* <Route
	// 			path='/'
	// 			element={
	// 				<PrivateRoute>
	// 					<AppRoutes />
	// 				</PrivateRoute>
	// 			}
	// 		/>
	// 		<Route path='/authentication' element={<Authentication />} />
	// 		<Route
	// 			path='/register'
	// 			element={<Link to='/authentication'>auth</Link>}
	// 		/> */}
	// 		<Route path='/' >
	// 			<Route index element={<Navigate to="/nav" />} />
	// 			<Route path='nav' element='hello'>
	// 				<Route path=':id' element='hello id' />
	// 				<Route path='*' element={<Navigate to='/404' replace />} />
	// 			</Route>
	// 			<Route path='*' element={<Navigate to='/404' replace />} />
	// 		</Route>

	// 		{/* <Route
	// 			path='*'
	// 			element={<Navigate to='/404' state={{ from: location }} replace />}
	// 		/> */}

	// 		<Route path='/404' element={<div>404</div>} />
	// 	</Routes>
	// );
};

export default App;
