import {
	Drawer,
	DrawerOverlay,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerFooter,
	Button,
	Accordion,
	Box,
	AccordionButton,
	AccordionIcon,
	Heading,
	AccordionPanel,
	Stack,
	AccordionItem,
	CheckboxGroup,
	Checkbox,
	FormLabel,
	DrawerCloseButton,
	Text,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { FC, ReactNode, useEffect, useState } from "react";
import { BiFilter, BiFilterAlt, BiReset } from "react-icons/bi";
import {
	GetAllUsersQueryVariables,
	ProductType,
	useGetFilterUsersQuery,
} from "../../../api/generated/graphql";
import {
	CategorySelectInput,
	DropDownInput,
	Input,
	SelectInput,
} from "../../../components";

type ProductsFilterDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	handleSubmitFilter: (values: { user: string; status: string }) => void;
	defaultValues: {
		user: string;
		status: string;
	};
	handleResetFilter: () => void;
};

const OrderListFilter: FC<ProductsFilterDrawerProps> = (props) => {
	const {
		isOpen,
		onClose,
		handleSubmitFilter,
		handleResetFilter,
		defaultValues,
	} = props;

	const { data: filterUsersData, loading: filterUsersLoading } =
		useGetFilterUsersQuery();

	const [usersList, setUsersList] = useState<
		{
			render: ReactNode;
			value: string;
		}[]
	>([]);

	useEffect(() => {
		setUsersList(
			filterUsersData?.GetAllUsers?.users?.map((user) => ({
				value: user?.firstName + " " + user?.lastName + "_" + user?._id,
				render: <Text>{user?.firstName + " " + user?.lastName}</Text>,
			})) || []
		);
	}, [filterUsersData]);

	return (
		<Drawer isOpen={isOpen} onClose={onClose} placement='right'>
			<DrawerOverlay />
			<Formik
				validateOnMount={false}
				validateOnBlur={false}
				validateOnChange={false}
				initialValues={defaultValues}
				onSubmit={(values, { setSubmitting, setFieldError }) => {
					handleSubmitFilter(values);
					setSubmitting(false);
					onClose();
				}}>
				{({
					values,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader borderBottomWidth='1px'>Products filter</DrawerHeader>
						<DrawerBody>
							<form onSubmit={handleSubmit} id='product-filter-form'>
								<Stack spacing='12px'>
									<SelectInput
										options={[
											{ value: "PENDING", label: "Awaiting processing" },
											{ value: "PROCESSING", label: "Processing" },
											{ value: "CONFIRMED", label: "Confirmed" },
											{ value: "READY", label: "Ready for delivery" },
											{ value: "TRANSPORTING", label: "In Transportation" },
											{ value: "COMPLETED", label: "Order completed" },
											{ value: "CANCELED", label: "Order canceled" },
											{ value: "REFUNDED", label: "Order refunded" },
											{ value: "FAILED", label: "Order failed" },
										]}
										label='Order status'
										selectProps={{
											name: "status",
											placeholder: "Choose a status...",
											value: values.status,
											onChange: handleChange,
										}}
									/>
									<DropDownInput
										label='User'
										placeholder='Select user...'
										name='user'
										selected={values.user?.split("_")[0]}
										list={usersList}
										isLoading={filterUsersLoading}
										onSelect={(value) => setFieldValue("user", value)}
									/>
								</Stack>
							</form>
						</DrawerBody>
						<DrawerFooter gap='2' borderTopWidth='1px'>
							<Button
								onClick={() => {
									handleResetFilter();
									onClose();
								}}
								isFullWidth
								leftIcon={<BiReset />}>
								Reset
							</Button>
							<Button
								isLoading={isSubmitting}
								type='submit'
								colorScheme='blue'
								isFullWidth
								form='product-filter-form'
								leftIcon={<BiFilterAlt />}>
								Filter
							</Button>
						</DrawerFooter>
					</DrawerContent>
				)}
			</Formik>
		</Drawer>
	);
};

export default OrderListFilter;
