import {
	Box,
	useColorMode,
	Divider,
	useColorModeValue,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import { logoImage, logoImageDARKUI } from "../../../assets";
import { NavItem } from "../../commons";
import { NavigationWrapper } from "./Navigation.styles";
import { NavigationPropsType } from "./Navigation.types";

const Navigation: React.FC<NavigationPropsType> = (props) => {
	const { items } = props;

	const location = useLocation();
	const { colorMode } = useColorMode();

	const bgColor = useColorModeValue("gray.50", "gray.700");
	const borderColor = useColorModeValue("gray.200", "gray.700");

	return (
		<NavigationWrapper
			bg={bgColor}
			paddingY='5'
			borderRight='1px'
			borderRightColor={borderColor}>
			<Box className='logo_container'>
				{colorMode === "dark" ? (
					<img src={logoImageDARKUI} alt='logo' />
				) : (
					<img src={logoImage} alt='logo' />
				)}
			</Box>
			{items?.map((nav, index) =>
				nav === "SEPERATOR" ? (
					<Box key={index} marginY='2' paddingX='16px'>
						<Divider borderColor={borderColor} />
					</Box>
				) : (
					<NavItem
						key={index}
						label={nav.label}
						Icon={nav.Icon}
						selected={location.pathname.includes(nav.href)}
						href={nav.href}
					/>
				)
			)}
		</NavigationWrapper>
	);
};

export default Navigation;
