import { Box, Stack } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const CategoriesWrapper = styled(Stack)`
	.categories_list {
		/* flex: 0.2; */
		padding: 16px;
		height: 100%;
		overflow-y: scroll;
		width: 360px;
		height: inherit;
	}
	.categories_action {
		/* flex: 0.8; */
		padding: 16px;
		height: 100%;
		width: 100%;
	}
`;

export const CategoryFormWrapper = styled(Box)`
	.category_form_header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 12px;
	}
`;
