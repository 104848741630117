import {
	Stack,
	Heading,
	Text,
	Box,
	Button,
	useToast,
	useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiCheckShield, BiMessageCheck, BiMessageError } from "react-icons/bi";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	useConfirmUserEmailMutation,
	useResendVerificationEmailMutation,
} from "../../api/generated/graphql";
import { FullPageLoad } from "../../components";
import { ConfirmEmailWrapper } from "./ConfirmEmail.styles";

const ConfirmEmail = () => {
	const [params, setSearchParams] = useSearchParams();

	const [status, setStatus] = useState<"SUCCESS" | "ERROR" | "LOADING">(
		"LOADING"
	);

	const bgColor = useColorModeValue("gray.100", "gray.800");

	const [confirmEmailMutation, confirmEmailParams] =
		useConfirmUserEmailMutation();

	useEffect(() => {
		confirmEmailMutation({
			variables: { token: params.get("confirmtoken") || "" },
			onCompleted: (data) => {
				if (data.ConfirmUserEmail?.token?.accessToken) {
					setStatus("SUCCESS");
				} else {
					setStatus("ERROR");
				}
			},
			onError: (error) => {
				if (error.message) {
					setStatus("ERROR");
				}
			},
		});
	}, []);

	const [resendVerificationEmailMutation, resendVerificationEmailParam] =
		useResendVerificationEmailMutation();

	const toast = useToast();

	// const handleResendVerificationEmail = () => {
	// 	resendVerificationEmailMutation({
	// 		variables: {
	// 			email: emailNotConfirmed || "",
	// 		},
	// 		onCompleted: (data) => {
	// 			if (data.ResendConfrimEmail?.code) {
	// 				toast({
	// 					title: "Verification email has been send!",
	// 					status: "success",

	// 					position: "bottom-right",
	// 					duration: 5000,
	// 					isClosable: true,
	// 				});
	// 			}
	// 		},
	// 		onError: (error) => {
	// 			toast({
	// 				title: "An error occured while resending verification email.",
	// 				description: error.message,
	// 				status: "error",

	// 				position: "bottom-right",
	// 				duration: 5000,
	// 				isClosable: true,
	// 			});
	// 		},
	// 	});
	// };

	const navigate = useNavigate();

	if (confirmEmailParams.loading) {
		return <FullPageLoad message='Verifying your email...' />;
	}

	if (confirmEmailParams.data?.ConfirmUserEmail?.token?.accessToken) {
		return (
			<ConfirmEmailWrapper bgColor={bgColor}>
				<Stack
					alignItems='center'
					justifyContent='center'
					height='100vh'
					width='100vw'
					spacing='18px'>
					<Box color='main.200'>
						<BiMessageCheck size={80} />
					</Box>
					<Box textAlign='center'>
						<Heading size='lg'>Your email has been verified</Heading>
						<Text>
							Welcome to {process.env.REACT_APP_DASHBOARD_TITLE}. you may now
							login.
						</Text>
					</Box>
					<Button
						colorScheme='main'
						onClick={() => navigate("/authentication")}>
						Login now!
					</Button>
				</Stack>
			</ConfirmEmailWrapper>
		);
	}

	return (
		<ConfirmEmailWrapper bgColor={bgColor}>
			<Stack
				alignItems='center'
				justifyContent='center'
				height='100vh'
				width='100vw'
				spacing='18px'>
				<Box color='main.200'>
					<BiMessageError size={80} />
				</Box>
				<Box textAlign='center'>
					<Heading size='lg'>Your verification link has expired</Heading>
					<Text>It seems your verification has expired or has been used.</Text>
				</Box>
				<Stack spacing='14px'>
					{/* <Button colorScheme='main'>Resend verification email</Button> */}
					<Button variant='ghost'>Contact support</Button>
				</Stack>
			</Stack>
		</ConfirmEmailWrapper>
	);
};

export default ConfirmEmail;
