export const countryOptions = [
	{ label: "Afghanistan" },
	{
		label: "Albania",
	},
	{
		label: "Algeria",
	},
	{
		label: "Andorra",
	},
	{
		label: "Angola",
	},
	{
		label: "Antigua and Barbuda",
	},
	{
		label: "Argentina",
	},
	{
		label: "Armenia",
	},
	{
		label: "Australia",
	},
	{
		label: "Austria",
	},
	{
		label: "Azerbaijan",
	},
	{
		label: "Bahamas",
	},
	{
		label: "Bahrain",
	},
	{
		label: "Bangladesh",
	},
	{
		label: "Barbados",
	},
	{
		label: "Belarus",
	},
	{
		label: "Belgium",
	},
	{
		label: "Belize",
	},
	{
		label: "Benin",
	},
	{
		label: "Bhutan",
	},
	{
		label: "Bolivia",
	},
	{
		label: "Bosnia and Herzegovina",
	},
	{
		label: "Botswana",
	},
	{
		label: "Brazil",
	},
	{
		label: "Brunei",
	},
	{
		label: "Bulgaria",
	},
	{
		label: "Burkina Faso",
	},
	{
		label: "Burundi",
	},
	{
		label: "Côte d'Ivoire",
	},
	{
		label: "Cabo Verde",
	},
	{
		label: "Cambodia",
	},
	{
		label: "Cameroon",
	},
	{
		label: "Canada",
	},
	{
		label: "Central African Republic",
	},
	{
		label: "Chad",
	},
	{
		label: "Chile",
	},
	{
		label: "China",
	},
	{
		label: "Colombia",
	},
	{
		label: "Comoros",
	},
	{
		label: "Congo (Congo-Brazzaville)",
	},
	{
		label: "Costa Rica",
	},
	{
		label: "Croatia",
	},
	{
		label: "Cuba",
	},
	{
		label: "Cyprus",
	},
	{
		label: "Czechia (Czech Republic)",
	},
	{
		label: "Democratic Republic of the Congo",
	},
	{
		label: "Denmark",
	},
	{
		label: "Djibouti",
	},
	{
		label: "Dominica",
	},
	{
		label: "Dominican Republic",
	},
	{
		label: "Ecuador",
	},
	{
		label: "Egypt",
	},
	{
		label: "El Salvador",
	},
	{
		label: "Equatorial Guinea",
	},
	{
		label: "Eritrea",
	},
	{
		label: "Estonia",
	},
	{
		label: "Eswatini (fmr. Swaziland)",
	},
	{
		label: "Ethiopia",
	},
	{
		label: "Fiji",
	},
	{
		label: "Finland",
	},
	{
		label: "France",
	},
	{
		label: "Gabon",
	},
	{
		label: "Gambia",
	},
	{
		label: "Georgia",
	},
	{
		label: "Germany",
	},
	{
		label: "Ghana",
	},
	{
		label: "Greece",
	},
	{
		label: "Grenada",
	},
	{
		label: "Guatemala",
	},
	{
		label: "Guinea",
	},
	{
		label: "Guinea-Bissau",
	},
	{
		label: "Guyana",
	},
	{
		label: "Haiti",
	},
	{
		label: "Holy See",
	},
	{
		label: "Honduras",
	},
	{
		label: "Hungary",
	},
	{
		label: "Iceland",
	},
	{
		label: "India",
	},
	{
		label: "Indonesia",
	},
	{
		label: "Iran",
	},
	{
		label: "Iraq",
	},
	{
		label: "Ireland",
	},
	{
		label: "Israel",
	},
	{
		label: "Italy",
	},
	{
		label: "Jamaica",
	},
	{
		label: "Japan",
	},
	{
		label: "Jordan",
	},
	{
		label: "Kazakhstan",
	},
	{
		label: "Kenya",
	},
	{
		label: "Kiribati",
	},
	{
		label: "Kuwait",
	},
	{
		label: "Kyrgyzstan",
	},
	{
		label: "Laos",
	},
	{
		label: "Latvia",
	},
	{
		label: "Lebanon",
	},
	{
		label: "Lesotho",
	},
	{
		label: "Liberia",
	},
	{
		label: "Libya",
	},
	{
		label: "Liechtenstein",
	},
	{
		label: "Lithuania",
	},
	{
		label: "Luxembourg",
	},
	{
		label: "Madagascar",
	},
	{
		label: "Malawi",
	},
	{
		label: "Malaysia",
	},
	{
		label: "Maldives",
	},
	{
		label: "Mali",
	},
	{
		label: "Malta",
	},
	{
		label: "Marshall Islands",
	},
	{
		label: "Mauritania",
	},
	{
		label: "Mauritius",
	},
	{
		label: "Mexico",
	},
	{
		label: "Micronesia",
	},
	{
		label: "Moldova",
	},
	{
		label: "Monaco",
	},
	{
		label: "Mongolia",
	},
	{
		label: "Montenegro",
	},
	{
		label: "Morocco",
	},
	{
		label: "Mozambique",
	},
	{
		label: "Myanmar (formerly Burma)",
	},
	{
		label: "Namibia",
	},
	{
		label: "Nauru",
	},
	{
		label: "Nepal",
	},
	{
		label: "Netherlands",
	},
	{
		label: "New Zealand",
	},
	{
		label: "Nicaragua",
	},
	{
		label: "Niger",
	},
	{
		label: "Nigeria",
	},
	{
		label: "North Korea",
	},
	{
		label: "North Macedonia",
	},
	{
		label: "Norway",
	},
	{
		label: "Oman",
	},
	{
		label: "Pakistan",
	},
	{
		label: "Palau",
	},
	{
		label: "Palestine State",
	},
	{
		label: "Panama",
	},
	{
		label: "Papua New Guinea",
	},
	{
		label: "Paraguay",
	},
	{
		label: "Peru",
	},
	{
		label: "Philippines",
	},
	{
		label: "Poland",
	},
	{
		label: "Portugal",
	},
	{
		label: "Qatar",
	},
	{
		label: "Romania",
	},
	{
		label: "Russia",
	},
	{
		label: "Rwanda",
	},
	{
		label: "Saint Kitts and Nevis",
	},
	{
		label: "Saint Lucia",
	},
	{
		label: "Saint Vincent and the Grenadines",
	},
	{
		label: "Samoa",
	},
	{
		label: "San Marino",
	},
	{
		label: "Sao Tome and Principe",
	},
	{
		label: "Saudi Arabia",
	},
	{
		label: "Senegal",
	},
	{
		label: "Serbia",
	},
	{
		label: "Seychelles",
	},
	{
		label: "Sierra Leone",
	},
	{
		label: "Singapore",
	},
	{
		label: "Slovakia",
	},
	{
		label: "Slovenia",
	},
	{
		label: "Solomon Islands",
	},
	{
		label: "Somalia",
	},
	{
		label: "South Africa",
	},
	{
		label: "South Korea",
	},
	{
		label: "South Sudan",
	},
	{
		label: "Spain",
	},
	{
		label: "Sri Lanka",
	},
	{
		label: "Sudan",
	},
	{
		label: "Suriname",
	},
	{
		label: "Sweden",
	},
	{
		label: "Switzerland",
	},
	{
		label: "Syria",
	},
	{
		label: "Tajikistan",
	},
	{
		label: "Tanzania",
	},
	{
		label: "Thailand",
	},
	{
		label: "Timor-Leste",
	},
	{
		label: "Togo",
	},
	{
		label: "Tonga",
	},
	{
		label: "Trinidad and Tobago",
	},
	{
		label: "Tunisia",
	},
	{
		label: "Turkey",
	},
	{
		label: "Turkmenistan",
	},
	{
		label: "Tuvalu",
	},
	{
		label: "Uganda",
	},
	{
		label: "Ukraine",
	},
	{
		label: "United Arab Emirates",
	},
	{
		label: "United Kingdom",
	},
	{
		label: "United States of America",
	},
	{
		label: "Uruguay",
	},
	{
		label: "Uzbekistan",
	},
	{
		label: "Vanuatu",
	},
	{
		label: "Venezuela",
	},
	{
		label: "Vietnam",
	},
	{
		label: "Yemen",
	},
	{
		label: "Zambia",
	},
	{
		label: "Zimbabwe",
	},
];
