import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormLabel,
	Heading,
	IconButton,
	Popover,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverTrigger,
	Skeleton,
	Stack,
	Switch,
	Text,
	toast,
	useColorModeValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { BiSlider } from "react-icons/bi";
import { apolloClient } from "../../..";
import {
	InventoryActivity,
	UpdateProductsInventoryByIdMutationVariables,
	useGetProductInventoryByIdQuery,
	useUpdateProductInventoryQuantityMutation,
	useUpdateProductsInventoryByIdMutation,
} from "../../../api/generated/graphql";
import {
	CategorySelectInput,
	Input,
	NumberInput,
	SelectInput,
	TagsSelectInput,
} from "../../../components";

type ProductInventoryFormProps = {
	productID?: string;
	actionButtons: (node: ReactNode) => void;
};

const ProductInventoryForm = (props: ProductInventoryFormProps) => {
	const { productID, actionButtons } = props;

	const borderColor = useColorModeValue("gray.100", "gray.700");

	const toast = useToast();

	const { data: inventoryProductData, loading: inventoryProductLoading } =
		useGetProductInventoryByIdQuery({
			variables: {
				product_id: productID,
			},
			skip: !productID,
		});

	useEffect(() => {
		inventoryProductFormik.setValues({
			brand: inventoryProductData?.GetProductById?.brand || "",
			isAvilable: inventoryProductData?.GetProductById?.isAvilable,
			category: {
				id: inventoryProductData?.GetProductById?.category?._id || "",
				label: inventoryProductData?.GetProductById?.category?.name || "",
			},
			tags: inventoryProductData?.GetProductById.tags || [],
		});
	}, [inventoryProductData]);

	const inventoryProductInitialValues: Omit<
		UpdateProductsInventoryByIdMutationVariables,
		"tags" | "product_id" | "category"
	> & {
		category: {
			id: string;
			label: string;
		};
		tags: string[];
	} = {
		brand: "",
		isAvilable: true,
		category: {
			id: "",
			label: "",
		},
		tags: [],
	};

	const [updateInventoryMutation] = useUpdateProductsInventoryByIdMutation();

	const inventoryProductFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: inventoryProductInitialValues,
		// validate: (values) => {
		// 	const errors: FormikErrors<typeof values> = {};
		// 	if (!values.name) {
		// 		errors.name = "Product name is required!";
		// 	}
		// 	return errors;
		// },
		onSubmit: (values, { setSubmitting }) => {
			updateInventoryMutation({
				variables: {
					product_id: productID,
					brand: values.brand || undefined,
					category: values.category.id || undefined,
					isAvilable: values.isAvilable,
					tags: values.tags || undefined,
				},
				onCompleted: (data) => {
					if (data.UpdateProduct?._id) {
						setSubmitting(false);
						toast({
							title: "Product inventory has been updated with success.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while updating product.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	// useEffect(() => {
	// 	actionButtons(
	// 		<>
	// 			<Button variant='outline'>Discard</Button>
	// 			<Button
	// 				colorScheme='main'
	// 				type='submit'
	// 				form='update-product-inventory'
	// 				isLoading={inventoryProductFormik.isSubmitting}>
	// 				Save
	// 			</Button>
	// 		</>
	// 	);
	// }, [inventoryProductFormik.isSubmitting]);

	return (
		<Stack direction='row' spacing='14px' alignItems='flex-start'>
			<Stack
				// flex={0.75}
				spacing='12px'>
				<Stack
					padding='18px 18px'
					border='1px'
					borderColor={borderColor}
					rounded='base'>
					<Box>
						<Heading size='md' mb='2'>
							Inventory
						</Heading>
					</Box>
					<Skeleton isLoaded={!inventoryProductLoading}>
						<InventoryDisplayTab
							data={{
								_id: inventoryProductData?.GetProductById?._id,
								name: inventoryProductData?.GetProductById?.name,
								isVariation: false,
								inventory: inventoryProductData?.GetProductById?.inventory,
							}}
						/>
					</Skeleton>
					{inventoryProductData?.GetProductById?.variations?.map(
						(variant, index) => (
							<InventoryDisplayTab
								data={{
									_id: variant?._id,
									name: inventoryProductData?.GetProductById?.name,
									index: index + 1,
									isVariation: true,
									inventory: variant?.inventory,
									selectedOption: variant.selectedOption,
								}}
							/>
						)
					)}
				</Stack>
			</Stack>
			{/* <Stack flex={0.25} spacing='12px'>
				<form
					id='update-product-inventory'
					onSubmit={inventoryProductFormik.handleSubmit}>
					<Skeleton
						isLoaded={!inventoryProductLoading}
						opacity={inventoryProductLoading ? 0.4 : 1}>
						<Stack
							padding='18px 24px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<Input
								label='Brand'
								inputProps={{
									placeholder: "Brand",
									name: "brand",
									onChange: inventoryProductFormik.handleChange,
									value: inventoryProductFormik.values.brand || "",
								}}
								errorMessage={inventoryProductFormik.errors.brand}
								isError={inventoryProductFormik.errors.brand !== undefined}
							/>
							<CategorySelectInput
								selected={inventoryProductFormik.values.category}
								handleSelectCategory={(id, label) =>
									inventoryProductFormik.setFieldValue("category", {
										id,
										label,
									})
								}
							/>
							<Box paddingTop='12px' paddingBottom='6px'>
								<Divider color={borderColor} />
							</Box>
							<TagsSelectInput
								tags={inventoryProductFormik.values.tags}
								onCreateTag={(value) =>
									inventoryProductFormik.setFieldValue("tags", [
										value,
										...inventoryProductFormik.values.tags,
									])
								}
								onDeleteTag={(index) => {
									let tempValues = [...inventoryProductFormik.values.tags];
									tempValues.splice(index, 1);
									inventoryProductFormik.setFieldValue("tags", tempValues);
								}}
							/>
						</Stack>
					</Skeleton>
					<Skeleton
						isLoaded={!inventoryProductLoading}
						opacity={inventoryProductLoading ? 0.4 : 1}>
						<Stack
							padding='18px 24px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<FormLabel>Product availability</FormLabel>
							<Stack direction='row' alignItems='center'>
								<Switch
									size='md'
									colorScheme='green'
									name='isAvilable'
									onChange={inventoryProductFormik.handleChange}
									isChecked={
										inventoryProductFormik.values.isAvilable !== null &&
										inventoryProductFormik.values.isAvilable
									}
								/>
								<Text
									color={
										inventoryProductFormik.values.isAvilable
											? "green.400"
											: undefined
									}>
									{inventoryProductFormik.values.isAvilable
										? "Available"
										: "Not available"}
								</Text>
							</Stack>
						</Stack>
					</Skeleton>
				</form>
			</Stack> */}
		</Stack>
	);
};

export default ProductInventoryForm;

type InventoryDisplayTab = {
	data: {
		_id: string | undefined | null;
		name: string | undefined | null;
		isVariation: boolean;
		index?: number;
		selectedOption?:
			| {
					KEY?: string | undefined | null;
					VALUE?: string | undefined | null;
			  }[]
			| null
			| undefined;
		inventory?: {
			_id: any;
			barCode?: string | null;
			informOn?: number | null;
			qunatity?: number | null;
			sellOutOfStock?: boolean | null;
			sku?: string | null;
			trackQunatity?: boolean | null;
			weightPerUnit?: number | null;
			updatedAt?: any | null;
			createdAt?: any | null;
		} | null;
	};
};

const InventoryDisplayTab = (props: InventoryDisplayTab) => {
	const { data } = props;

	const [updateQuantityMutation] = useUpdateProductInventoryQuantityMutation();

	const toast = useToast();

	const quantityUpdateFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			update: 0,
			reason: "STOCK_ENTRY",
			barCode: data?.inventory?.barCode,
			informOn: data?.inventory?.informOn,
			sellOutOfStock: data?.inventory?.sellOutOfStock,
			weightPerUnit: data?.inventory?.weightPerUnit,
			sku: data?.inventory?.sku,
			trackQunatity: data?.inventory?.trackQunatity,
		},
		onSubmit: (values, { setSubmitting }) => {
			updateQuantityMutation({
				variables: {
					activity: values.reason as InventoryActivity,
					_id: data?._id,
					quantity:
						(data?.inventory?.qunatity || 0) +
						quantityUpdateFormik.values.update,
					barCode: quantityUpdateFormik.values.barCode,
					informOn: quantityUpdateFormik.values.informOn,
					sellOutOfStock: quantityUpdateFormik.values.sellOutOfStock,
					sku: quantityUpdateFormik.values.sku,
					trackQunatity: quantityUpdateFormik.values.trackQunatity,
					weightPerUnit: parseFloat(
						quantityUpdateFormik.values.weightPerUnit
							? quantityUpdateFormik.values.weightPerUnit.toString()
							: "0"
					),
				},
				onCompleted: (data) => {
					if (data.UpdateProductInventoryQuantity?._id) {
						setSubmitting(false);
						toast({
							title: "Inventory has been updated with success.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
						quantityUpdateFormik.setFieldValue("update", 0);
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while updating inventory.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<form id='update_inventory' onSubmit={quantityUpdateFormik?.handleSubmit}>
			<Stack spacing='12px'>
				{data.isVariation && <Divider mt='8px' />}
				<Box>
					{data?.isVariation && (
						<Text fontSize='md' opacity={0.8}>
							Variation {data?.index}
						</Text>
					)}
					<Heading size='md' fontWeight='medium'>
						{data.name}
						{data?.selectedOption && (
							<Text display='inline-block' fontSize='md' opacity={0.8}>
								(
								{data?.selectedOption?.map(
									(option, index) =>
										`${option.VALUE}${
											data?.selectedOption?.length !== index + 1 ? " / " : ""
										}`
								)}
								)
							</Text>
						)}
					</Heading>
				</Box>
				<Stack direction='row' spacing='24px'>
					<Input
						label='SKU ( Stock Keeping Unit )'
						inputProps={{
							name: "sku",
							placeholder: "SKU",
							value: quantityUpdateFormik.values.sku || "",
							onChange: quantityUpdateFormik.handleChange,
						}}
					/>
					<Input
						label='Barcode'
						inputProps={{
							name: "barCode",
							placeholder: "Barcode",
							value: quantityUpdateFormik.values.barCode || "",
							onChange: quantityUpdateFormik.handleChange,
						}}
					/>
					<NumberInput
						label='Weight per unit'
						numberInputProps={{
							allowMouseWheel: true,
							placeholder: "Weight",
							name: "weightPerUnit",
							value: quantityUpdateFormik.values.weightPerUnit || 0,
							onChange: (vString, vNumber) =>
								quantityUpdateFormik.setFieldValue("weightPerUnit", vString),
							width: "100%",
							precision: 3,
						}}
						inputRightAddon={{ children: "kg" }}
					/>
					<Box>
						<FormLabel whiteSpace='nowrap' mb='1'>
							Available quantity
						</FormLabel>
						<QuantityPopover
							quantity={
								data.inventory?.qunatity
									? data.inventory?.qunatity +
									  quantityUpdateFormik?.values?.update
									: 0
							}
							isOpen={isOpen}
							onClose={onClose}
							onOpen={onOpen}
							reason={quantityUpdateFormik?.values?.reason}
							setFieldValue={quantityUpdateFormik?.setFieldValue}
							update={quantityUpdateFormik?.values?.update}
						/>
					</Box>
					<Box display='flex' alignItems='flex-end'>
						<Button
							colorScheme='main'
							type='submit'
							disabled={
								quantityUpdateFormik.values ===
									quantityUpdateFormik.initialValues ||
								quantityUpdateFormik.isSubmitting
							}
							isLoading={quantityUpdateFormik.isSubmitting}>
							Save changes
						</Button>
					</Box>
				</Stack>
				<Checkbox
					width='fit-content'
					name='trackQunatity'
					isChecked={quantityUpdateFormik?.values?.trackQunatity ? true : false}
					onChange={quantityUpdateFormik.handleChange}>
					Track quantity
				</Checkbox>
				<Checkbox
					width='fit-content'
					name='sellOutOfStock'
					isChecked={
						quantityUpdateFormik?.values?.sellOutOfStock ? true : false
					}
					onChange={quantityUpdateFormik.handleChange}>
					Continue selling when out of stock
				</Checkbox>
				<NumberInput
					label='Send me a notification when quantity in stock reaches'
					numberInputProps={{
						allowMouseWheel: true,
						name: "informOn",
						value: quantityUpdateFormik.values.informOn || 0,
						onChange: (vString, vNumber) =>
							quantityUpdateFormik.setFieldValue("informOn", vNumber),
						width: "120px",
						precision: 0,
					}}
				/>
			</Stack>
		</form>
	);
};

type QuantityPopoverProps = {
	quantity: number;

	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;

	update: number;
	reason: string;

	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) =>
		| Promise<void>
		| Promise<
				FormikErrors<{
					update: string;
					reason: string;
					barCode: string;
					informOn: string;
					sellOutOfStock: string;
					weightPerUnit: string;
					sku: string;
					trackQunatity: string;
				}>
		  >;
};

const QuantityPopover = (props: QuantityPopoverProps) => {
	const { quantity, isOpen, onClose, onOpen, setFieldValue, reason, update } =
		props;

	const [updateValue, setUpdateValue] = useState<number>(update);

	const [reasonValue, setReasonValue] = useState<string>(reason);

	const handleSavingQuantity = () => {
		setFieldValue("update", updateValue);
		setFieldValue("reason", reasonValue);
		onClose();
	};

	return (
		<Popover
			placement='bottom-start'
			isOpen={isOpen}
			onClose={onClose}
			onOpen={onOpen}
			isLazy>
			<PopoverTrigger>
				<Stack alignItems='center' direction='row' w='fit-content'>
					<Text>{quantity}</Text>{" "}
					<IconButton aria-label='edit-quantity-button' variant='ghost'>
						<BiSlider size={18} />
					</IconButton>
				</Stack>
			</PopoverTrigger>
			<PopoverContent width='220px' p='1.5' shadow='lg'>
				<PopoverBody>
					<Stack spacing='12px'>
						<NumberInput
							label='Update by'
							formLabelProps={{
								fontSize: "sm",
							}}
							numberInputProps={{
								allowMouseWheel: true,
								size: "sm",
								placeholder: "Update by",
								name: "update",
								onChange: (vString, vNumber) => setUpdateValue(vNumber || 0),
								precision: 0,
								value: updateValue,
							}}
							helperText={`New quantity: ${quantity + updateValue || 0}`}
						/>
						<SelectInput
							label='Reason'
							formLabelProps={{
								fontSize: "sm",
							}}
							options={[
								{
									label: "Stock entry",
									value: "STOCK_ENTRY",
								},
								{
									label: "Correction",
									value: "CORRECTION",
								},
								{
									label: "Count",
									value: "COUNT",
								},
								{
									label: "Received",
									value: "RECEIVED",
								},
								{
									label: "Return restock",
									value: "RETURN_RESTOCK",
								},
								{
									label: "Damaged",
									value: "DAMAGED",
								},
								{
									label: "Theft or loss",
									value: "THEFT_LOSS",
								},
								{
									label: "Promotion or donation",
									value: "PROMOTION_DONATION",
								},
							]}
							selectProps={{
								name: "reason",
								value: reasonValue,
								onChange: (e) => setReasonValue(e.target.value),
								size: "sm",
							}}
						/>
						<Stack>
							<Button
								colorScheme='main'
								size='sm'
								onClick={handleSavingQuantity}>
								Save
							</Button>
							<Button variant='ghost' size='sm' onClick={onClose}>
								Discard
							</Button>
						</Stack>
					</Stack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
