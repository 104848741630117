import {
	Box,
	Link,
	Text,
	Heading,
	Stack,
	Button,
	useColorModeValue,
	FormLabel,
	useToast,
	Skeleton,
	Flex,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import ReactQuill from "react-quill";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { apolloClient } from "../../..";
import {
	useCreateStoreMutation,
	useGetAllCountriesQuery,
	useGetStoreByIdQuery,
	useUpdateStoreMutation,
} from "../../../api/generated/graphql";
import {
	DropDownInput,
	ImageUpload,
	Input,
	SelectInput,
} from "../../../components";
import { PageWrapper } from "../../../utils/globalStyle";

const modules = {
	toolbar: [
		[{ header: "1" }, { header: "2" }, { font: [] }],
		[{ size: [] }],
		["bold", "italic", "underline", "strike", "blockquote"],
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" },
		],
		["link", "image"],
	],
	clipboard: {
		matchVisual: false,
	},
};

const AdminCreateStore = () => {
	const location = useLocation();
	const { storeID } = useParams();
	const { from } = (location?.state as { from?: string }) || "";
	const navigate = useNavigate();
	const borderColor = useColorModeValue("gray.100", "gray.700");
	const toast = useToast();

	const { data: countriesData, loading: countriesLoading } =
		useGetAllCountriesQuery();

	const [countriesList, setCountriesList] = useState<
		{
			render: ReactNode;
			value: string;
		}[]
	>([]);

	const [prefixList, setPrefixList] = useState<
		{
			render: ReactNode;
			value: string;
		}[]
	>([]);

	const [coverFiles, setCoverFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const handleCoverFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append(
			"public_id",
			storeID
				? `${storeID}/cover/${file.name}`
				: `stores/misc/cover/${file.name}`
		);
		setCoverFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setCoverFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleCoverDelete = (id: string) => {
		console.log(
			id,
			coverFiles.filter(({ id: imageId }) => id !== imageId)
		);
		setCoverFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	const [logoFiles, setLogoFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const handleLogoFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append(
			"public_id",
			storeID ? `${storeID}/logo/${file.name}` : `stores/misc/logo/${file.name}`
		);
		setLogoFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setLogoFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleLogoDelete = (id: string) => {
		console.log(
			id,
			logoFiles.filter(({ id: imageId }) => id !== imageId)
		);
		setLogoFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	useEffect(() => {
		setCountriesList(
			countriesData?.GetAllCountries.map((country) => ({
				value: country.name || "",
				render: (
					<Stack direction='row' spacing='10px' alignItems='center'>
						<img
							className='select_country_image'
							src={country.flag || ""}
							alt={country.name || "countryflag"}
						/>{" "}
						<Text>{country.name}</Text>
					</Stack>
				),
			})) || []
		);
		setPrefixList(
			countriesData?.GetAllCountries.map((country) => ({
				value: country.dialCode || "",
				render: (
					<Stack direction='row' spacing='10px' alignItems='center'>
						<img
							className='select_country_image'
							src={country.flag || ""}
							alt={country.dialCode || "countryflag"}
						/>{" "}
						<Text>{country.dialCode}</Text>
					</Stack>
				),
			})) || []
		);
	}, [countriesData]);

	const { data: storeData, loading: storeLoading } = useGetStoreByIdQuery({
		variables: {
			store_id: storeID,
		},
		skip: !storeID,
		onError: (error) => {
			toast({
				title: "Store not found.",
				description: error.message,
				status: "error",

				position: "bottom-right",
				duration: 5000,
				isClosable: true,
			});
			navigate(`/admin/stores`, {
				state: {
					pageName: "Stores",
				},
			});
		},
	});

	const [createStoreMutation] = useCreateStoreMutation();
	const [updateStoreMutation] = useUpdateStoreMutation();

	const storeFormInitialValues = {
		vendor: "",
		name: "",
		description: "",
		status: "",
		country: "",
		streetAddress: "",
		apartment: "",
		city: "",
		phonePrefix: "",
		phoneNumber: "",
		zipCode: "",
	};

	const storeFormFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: storeFormInitialValues,
		validate: (values) => {
			const errors: FormikErrors<typeof values> = {};
			if (!values.name) {
				errors.name = "Store name is required";
			}
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			if (storeID) {
				updateStoreMutation({
					variables: {
						store_id: storeID,
						apartment: values.apartment,
						city: values.city,
						country: values.country,
						coverPicture: coverFiles?.[0]?.src
							? (coverFiles?.[0]?.src as string)
							: "",
						description: values?.description,
						logo: logoFiles?.[0]?.src ? (logoFiles?.[0]?.src as string) : "",
						name: values?.name,
						phoneNumber: values?.phoneNumber.toString(),
						phonePrefix: values?.phonePrefix.toString(),
						status: values?.status,
						streetAddress: values?.streetAddress,
						zipCode: values?.zipCode,
					},
					onCompleted: (data) => {
						if (data.UpdateStore?._id) {
							setSubmitting(false);
							toast({
								title: "Store has been updated with success.",
								status: "success",

								position: "bottom-right",
								duration: 5000,
								isClosable: true,
							});
							apolloClient.refetchQueries({
								include: "active",
							});
						}
					},
					onError: (error) => {
						toast({
							title: "An error occurred while updating store.",
							description: error.message,
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						setSubmitting(false);
					},
				});
			} else {
				createStoreMutation({
					variables: {
						apartment: values.apartment,
						city: values.city,
						country: values.country,
						coverPicture: coverFiles?.[0]?.src
							? (coverFiles?.[0]?.src as string)
							: "",
						description: values?.description,
						logo: logoFiles?.[0]?.src ? (logoFiles?.[0]?.src as string) : "",
						name: values?.name,
						phoneNumber: values?.phoneNumber.toString(),
						phonePrefix: values?.phonePrefix.toString(),
						status: values?.status,
						streetAddress: values?.streetAddress,
						zipCode: values?.zipCode,
					},
					onCompleted: (data) => {
						if (data.CreateStore?._id) {
							setSubmitting(false);
							toast({
								title: "Store has been created.",
								status: "success",

								position: "bottom-right",
								duration: 5000,
								isClosable: true,
							});
							apolloClient.refetchQueries({
								include: "active",
							});
							navigate(`/admin/stores`, {
								state: {
									pageName: "Stores",
								},
							});
						}
					},
					onError: (error) => {
						toast({
							title: "An error occured while creating store.",
							description: error.message,
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						setSubmitting(false);
					},
				});
			}
		},
	});

	useEffect(() => {
		storeFormFormik.setValues({
			vendor:
				storeData?.GetStoreById?.vendor?.firstName +
				" " +
				storeData?.GetStoreById?.vendor?.lastName,
			name: storeData?.GetStoreById?.name || "",
			description: storeData?.GetStoreById?.description || "",
			status: storeData?.GetStoreById?.status || "DRAFT",
			streetAddress: storeData?.GetStoreById?.storeAddress?.streetAddress || "",
			country: storeData?.GetStoreById?.storeAddress?.country || "",
			apartment: storeData?.GetStoreById?.storeAddress?.apartment || "",
			city: storeData?.GetStoreById?.storeAddress?.city || "",
			phonePrefix:
				storeData?.GetStoreById?.storeAddress?.phoneNumber?.prefix || "",
			phoneNumber:
				storeData?.GetStoreById?.storeAddress?.phoneNumber?.number || "",
			zipCode: storeData?.GetStoreById?.storeAddress?.zipCode || "",
		});
		setLogoFiles(
			storeData?.GetStoreById?.storeMedia?.logo
				? [
						{
							progress: false,
							id: storeData?.GetStoreById?._id,
							src: storeData?.GetStoreById?.storeMedia?.logo,
						},
				  ]
				: []
		);
		setCoverFiles(
			storeData?.GetStoreById?.storeMedia?.coverPicture
				? [
						{
							progress: false,
							id: storeData?.GetStoreById?._id,
							src: storeData?.GetStoreById?.storeMedia?.coverPicture,
						},
				  ]
				: []
		);
	}, [storeData]);

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								state={{
									pageName: "Stores",
								}}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Skeleton isLoaded={!storeLoading}>
							<Heading fontSize='2xl'>
								{storeData?.GetStoreById?.name || "Create Store"}
							</Heading>
						</Skeleton>
					</Box>
				</Box>
				<Stack direction='row'>
					<Button
						variant='outline'
						onClick={() =>
							navigate(`/admin/stores`, {
								state: {
									pageName: "Stores",
								},
							})
						}>
						Discard
					</Button>
					<Button
						colorScheme='main'
						type='submit'
						form='store-form'
						isLoading={storeFormFormik.isSubmitting}>
						Save
					</Button>
				</Stack>
			</Box>
			<form id='store-form' onSubmit={storeFormFormik.handleSubmit}>
				<Stack direction='row' spacing='14px' alignItems='flex-start'>
					<Stack flex={0.75} spacing='12px'>
						<Stack
							padding='18px 18px'
							border='1px'
							borderColor={borderColor}
							rounded='base'
							spacing='18px'>
							<Skeleton isLoaded={!storeLoading}>
								<Stack direction='row' spacing='18px'>
									<Input
										label='Store title'
										inputProps={{
											placeholder: "Title",
											name: "name",
											onChange: storeFormFormik.handleChange,
											value: storeFormFormik.values.name,
										}}
										errorMessage={storeFormFormik.errors.name}
										isError={storeFormFormik.errors.name !== undefined}
									/>
									<SelectInput
										formControlProps={{
											w: "420px",
										}}
										options={[
											{ label: "Review request", value: "DRAFT" },
											{ label: "Published", value: "PUBLISHED" },
											{ label: "Declined", value: "DECLINED" },
										]}
										label='Store status'
										selectProps={{
											name: "status",
											value: storeFormFormik.values.status || "",
											onChange: storeFormFormik.handleChange,
										}}
										errorMessage={storeFormFormik.errors.status}
										isError={storeFormFormik.errors.status !== undefined}
									/>
								</Stack>
							</Skeleton>
							<Box>
								<Skeleton isLoaded={!storeLoading}>
									<FormLabel marginBottom='1'>Description</FormLabel>
									<ReactQuill
										value={storeFormFormik.values.description || ""}
										onChange={(content) =>
											storeFormFormik.setFieldValue("description", content)
										}
										modules={modules}
										placeholder='Describe this store'
									/>
								</Skeleton>
							</Box>
							<Heading size='md' mb='2'>
								Address settings
							</Heading>
							<Skeleton isLoaded={!storeLoading}>
								<Stack direction='row' spacing='18px'>
									<Input
										label='Address'
										inputProps={{
											placeholder: "Address",
											name: "streetAddress",
											onChange: storeFormFormik.handleChange,
											value: storeFormFormik.values.streetAddress,
										}}
										errorMessage={storeFormFormik.errors.streetAddress}
										isError={storeFormFormik.errors.streetAddress !== undefined}
									/>
									<Input
										label='Apartment, suite, etc.'
										inputProps={{
											placeholder: "Apartment, suite, etc.",
											name: "apartment",
											onChange: storeFormFormik.handleChange,
											value: storeFormFormik.values.apartment,
										}}
										errorMessage={storeFormFormik.errors.apartment}
										isError={storeFormFormik.errors.apartment !== undefined}
									/>
								</Stack>
							</Skeleton>

							<Skeleton isLoaded={!storeLoading}>
								<Stack direction='row' spacing='18px'>
									<DropDownInput
										label='Country'
										placeholder='Country'
										name='country'
										selected={storeFormFormik.values.country}
										errorMessage={storeFormFormik.errors.country}
										list={countriesList}
										isLoading={countriesLoading}
										onSelect={(value) =>
											storeFormFormik.setFieldValue("country", value)
										}
									/>
									<Input
										label='City'
										inputProps={{
											placeholder: "City",
											name: "city",
											onChange: storeFormFormik.handleChange,
											value: storeFormFormik.values.city,
										}}
										errorMessage={storeFormFormik.errors.city}
										isError={storeFormFormik.errors.city !== undefined}
									/>
								</Stack>
							</Skeleton>
							<Skeleton isLoaded={!storeLoading}>
								<Stack direction='row' spacing='18px'>
									<Input
										label='Phone number'
										inputProps={{
											placeholder: "Phone number",
											type: "number",
											name: "phoneNumber",
											onChange: storeFormFormik.handleChange,
											value: storeFormFormik.values.phoneNumber,
										}}
										errorMessage={
											storeFormFormik.errors.phoneNumber ||
											storeFormFormik.errors.phonePrefix
										}
										isError={
											storeFormFormik.errors.phoneNumber !== undefined ||
											storeFormFormik.errors.phonePrefix !== undefined
										}
										inputLeftAddon={{
											background: "transparent",
											p: 0,
											border: "none",
											children: (
												<Box w='100px'>
													<DropDownInput
														name='phonePrefix'
														placeholder='Prefix'
														selected={storeFormFormik.values.phonePrefix}
														list={prefixList}
														isLoading={countriesLoading}
														onSelect={(value) =>
															storeFormFormik.setFieldValue(
																"phonePrefix",
																value
															)
														}
													/>
												</Box>
											),
										}}
									/>
									<Input
										label='Zip/Postal code'
										inputProps={{
											placeholder: "Zip/Postal code",
											name: "zipCode",
											value: storeFormFormik.values.zipCode,
											onChange: storeFormFormik.handleChange,
										}}
										errorMessage={storeFormFormik.errors.zipCode}
										isError={storeFormFormik.errors.zipCode !== undefined}
									/>
								</Stack>
							</Skeleton>
						</Stack>
					</Stack>
					<Stack flex={0.25} spacing='12px'>
						<Stack
							padding='18px 18px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<Skeleton isLoaded={!storeLoading}>
								<ImageUpload
									height='220px'
									width='100%'
									images={logoFiles}
									handleUpload={handleLogoFile}
									handleDelete={handleLogoDelete}
									label='Store logo'
								/>
							</Skeleton>
							<Skeleton isLoaded={!storeLoading}>
								<ImageUpload
									height='120px'
									width='100%'
									images={coverFiles}
									handleUpload={handleCoverFile}
									handleDelete={handleCoverDelete}
									label='Store Cover'
								/>
							</Skeleton>
						</Stack>
						{storeData && (
							<>
								<Stack
									padding='18px 18px'
									border='1px'
									borderColor={borderColor}
									rounded='base'>
									<Text>
										<Text display='inline' opacity='0.6'>
											Owner:{" "}
										</Text>
										{storeData?.GetStoreById?.vendor?.firstName +
											" " +
											storeData?.GetStoreById?.vendor?.lastName}
									</Text>
									<Text>
										<Text display='inline' opacity='0.6'>
											Creation date:{" "}
										</Text>
										{moment(storeData?.GetStoreById?.createdAt).format("LLL")}
									</Text>
									<Text>
										<Text display='inline' opacity='0.6'>
											Last updated:{" "}
										</Text>
										{moment(storeData?.GetStoreById?.updatedAt).format("LLL")}
									</Text>
								</Stack>
								<Stack direction='column' spacing='8px'>
									<Flex justifyContent='space-between'>
										<Link
											w='fit-content'
											as={RouterLink}
											to={"/admin/products"}
											state={{
												pageName: "Products",
												from: location.pathname,
												storeFilter: storeID,
											}}
											display='flex'
											alignItems='center'>
											<Text fontSize='sm' lineHeight='1'>
												Products list
											</Text>
										</Link>
									</Flex>
									<Link
										w='fit-content'
										as={RouterLink}
										to={"/admin/products"}
										state={{
											pageName: "Products",
											from: location.pathname,
											storeFilter: storeID,
										}}
										display='flex'
										alignItems='center'>
										<Text fontSize='sm' lineHeight='1'>
											Orders list
										</Text>
									</Link>
									<Link
										w='fit-content'
										as={RouterLink}
										to={"/admin/products"}
										state={{
											pageName: "Products",
											from: location.pathname,
											storeFilter: storeID,
										}}
										display='flex'
										alignItems='center'>
										<Text fontSize='sm' lineHeight='1'>
											Transfers and credit
										</Text>
									</Link>
								</Stack>
							</>
						)}
					</Stack>
				</Stack>
			</form>
		</PageWrapper>
	);
};

export default AdminCreateStore;
