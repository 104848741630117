import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const SettingsContainer = styled(Box)`
	width: 100vw;
	height: 100vh;
	.app_container {
		height: 100%;
		width: 100%;
		overflow-y: auto;
		overflow-x: none;
	}
	.store_logo_settings {
		height: 52px;
		width: 52px;
		object-fit: cover;
		border-radius: 5px;
	}
`;
