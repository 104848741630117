import {
	FormLabel as ChakraFormLabel,
	InputGroup as ChakraInputGroup,
	NumberInput as ChakraNumberInput,
	InputProps,
	InputGroupProps,
	FormLabelProps,
	FormHelperText,
	FormErrorMessage,
	FormControl as ChakraFormControl,
	FormControlProps,
	InputLeftElement,
	InputElementProps,
	InputRightElement,
	InputAddonProps,
	InputLeftAddon,
	InputRightAddon,
	NumberInputProps,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	NumberInputFieldProps,
	NumberInputStepperProps,
	NumberIncrementStepperProps,
	NumberDecrementStepperProps,
} from "@chakra-ui/react";
import { FC } from "react";

export type InputPropsType = {
	numberInputProps?: Omit<NumberInputProps, "isInvalid"> & {
		ref?: React.LegacyRef<HTMLInputElement> | undefined;
	};
	numberInputFieldProps?: NumberInputFieldProps;
	numberInputStepperProps?: NumberInputStepperProps;
	numberIncrementStepper?: NumberIncrementStepperProps;
	numberDecrementStepper?: NumberDecrementStepperProps;
	inputGroupProps?: InputGroupProps;
	formLabelProps?: FormLabelProps;
	formControlProps?: Omit<FormControlProps, "isInvalid">;
	label?: string;
	helperText?: string;
	errorMessage?: string;
	isError?: boolean;
	inputLeftElementProps?: InputElementProps;
	inputRightElementProps?: InputElementProps;
	inputRightAddon?: InputAddonProps;
	inputLeftAddon?: InputAddonProps;
};

const NumberInput: FC<InputPropsType> = (props) => {
	const {
		numberInputProps,
		numberInputFieldProps,
		numberInputStepperProps,
		numberIncrementStepper,
		numberDecrementStepper,
		inputGroupProps,
		formLabelProps,
		formControlProps,
		label,
		helperText,
		errorMessage,
		isError,
		inputLeftElementProps,
		inputRightElementProps,
		inputRightAddon,
		inputLeftAddon,
	} = props;

	return (
		<ChakraFormControl {...formControlProps} isInvalid={isError}>
			{label && (
				<ChakraFormLabel {...formLabelProps} marginBottom='1'>
					{label}
				</ChakraFormLabel>
			)}
			<ChakraInputGroup {...inputGroupProps}>
				{inputLeftElementProps?.children && (
					<InputLeftElement {...inputLeftElementProps} />
				)}
				{inputLeftAddon?.children && <InputLeftAddon {...inputLeftAddon} />}
				<ChakraNumberInput {...numberInputProps} outlineColor='transparent'>
					<NumberInputField {...numberInputFieldProps} />
					<NumberInputStepper {...numberInputStepperProps}>
						<NumberIncrementStepper {...numberIncrementStepper} />
						<NumberDecrementStepper {...numberDecrementStepper} />
					</NumberInputStepper>
				</ChakraNumberInput>
				{inputRightAddon?.children && <InputRightAddon {...inputRightAddon} />}
				{inputRightElementProps?.children && (
					<InputRightElement {...inputRightElementProps} />
				)}
			</ChakraInputGroup>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			{errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
		</ChakraFormControl>
	);
};

export default NumberInput;
