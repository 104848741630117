import {
	Box,
	Text,
	Link,
	Skeleton,
	Heading,
	Stack,
	Button,
	useDisclosure,
	Modal,
	ModalContent,
	ModalOverlay,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Divider,
	Checkbox,
	useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import {
	BiArrowBack,
	BiListCheck,
	BiSearch,
	BiShow,
	BiStore,
	BiTransferAlt,
} from "react-icons/bi";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { apolloClient } from "../..";
import {
	useCreatePayoutMutation,
	useGetAllPayOutsQuery,
	useGetAllStoresPayoutQuery,
} from "../../api/generated/graphql";
import { Table, TableHeadType, Input, DateTimeInput } from "../../components";
import { PageWrapper } from "../../utils/globalStyle";

type storeType = {
	__typename?: "Store";
	_id?: any | null;
	name?: string | null;
	slug?: string | null;
	vendor?: {
		__typename?: "User";
		_id?: any | null;
		firstName?: string | null;
		lastName?: string | null;
	} | null;
};

const Payout = () => {
	const location = useLocation();

	const { from } = (location?.state as { from?: string }) || "";

	const [pagination, setPagination] = useState({
		limit: 10,
		skip: 0,
	});

	const { data: payoutData, loading: payoutLoading } = useGetAllPayOutsQuery({
		variables: {
			pagination: pagination,
		},
	});

	const handlePageChange = (dir: "next" | "prev") => {
		if (dir === "next") {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip + temp.limit;
				return temp;
			});
		} else {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip - temp.limit;
				return temp;
			});
		}
	};

	const handleLimitChange = (value: number) => {
		setPagination((prevState) => {
			let temp = { ...prevState };
			temp.limit = +value;
			return temp;
		});
	};

	const [selectedPayoutID, setSelectedPayoutID] = useState<string>();

	const [selectedPayoutVendors, setSelectedPayout] = useState<
		storeType[] | null
	>();

	const {
		isOpen: isOpenVendors,
		onOpen: onOpenVendors,
		onClose: onCloseVendors,
	} = useDisclosure();

	const {
		isOpen: isOpenCreatePayout,
		onOpen: onOpenCreatePayout,
		onClose: onCloseCreatePayout,
	} = useDisclosure();

	const payoutTableData =
		payoutData?.GetAllPayOuts?.payouts?.map((payout, index) => ({
			id: payout._id,
			transactionID: payout.transactionId,
			issueDate: moment(payout.issueDate).format("lll"),
			creationDate: moment(payout.createdAt).format("lll"),
			totalAmount: `${payout.totalAmount} TND`,
			action: (
				<Stack
					direction='row'
					width='fit-content'
					display='inline-flex'
					alignItems='center'
					justifyContent='flex-end'
					spacing='18px'>
					<Button
						variant='link'
						onClick={() => {
							setSelectedPayout(payout.stores);
							setSelectedPayoutID(payout.transactionId);
							onOpenVendors();
						}}
						leftIcon={<BiListCheck size={14} />}
						fontSize='sm'
						fontWeight='medium'>
						Vendors list
					</Button>
				</Stack>
			),
		})) || [];

	type PayoutTableData = typeof payoutTableData[number];

	const payoutTableHead: TableHeadType<PayoutTableData>[] = [
		{ accessor: "transactionID", label: "Transaction ID" },
		{ accessor: "issueDate", label: "Issue date" },
		{ accessor: "creationDate", label: "Creation date" },
		{ accessor: "totalAmount", label: "Total amount" },
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Heading fontSize='2xl'>Pay-out history</Heading>
					</Box>
				</Box>
				<Stack direction='row'>
					{/* <Button variant='outline'>Export</Button> */}
					<Button colorScheme='main' onClick={onOpenCreatePayout}>
						Create new payout
					</Button>
				</Stack>
			</Box>
			<Box display='flex' alignItems='baseline' mb='4'>
				<Text mr='2'>Total cumulated credit: </Text>
				<Heading fontSize='lg'>
					{payoutData?.GetAvailableBlance.totalBlance} TND
				</Heading>
			</Box>
			<Table
				head={payoutTableHead}
				data={payoutTableData}
				isLoading={payoutLoading}
				emptyState={
					<Box className='empty_table_container'>
						<BiTransferAlt size={42} />
						<Text fontSize='md' fontWeight='medium'>
							There ain't no payouts made.
						</Text>
					</Box>
				}
				pagination={{
					count: payoutData?.GetAllPayOuts.queryCount || 0,
					limit: pagination.limit,
					skip: pagination.skip,
					onPageChange: handlePageChange,
					onChangeLimit: handleLimitChange,
				}}
			/>
			<Modal
				isOpen={isOpenVendors}
				onClose={() => {
					setSelectedPayout([]);
					setSelectedPayoutID("");
					onCloseVendors();
				}}
				isCentered
				scrollBehavior='inside'
				size='xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader borderBottomWidth='1px'>
						Vendors list of "{selectedPayoutID}"
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing='14px' py='14px'>
							{selectedPayoutVendors?.map((store, index) => (
								<>
									<Box
										display='flex'
										// py='2'
										alignItems='center'
										justifyContent='space-between'>
										<Box>
											<Text>{store.name}</Text>
											<Text fontSize='sm' opacity={0.6}>
												{store.vendor?.firstName + " " + store.vendor?.lastName}
											</Text>
										</Box>
										<Link
											as={RouterLink}
											to={`/admin/stores/${store._id}`}
											state={{
												from: location.pathname,
											}}
											color='blue.400'>
											See store
										</Link>
									</Box>
									<Divider />
								</>
							))}
						</Stack>
					</ModalBody>
				</ModalContent>
			</Modal>
			<CreatePayoutModal
				isOpen={isOpenCreatePayout}
				onClose={onCloseCreatePayout}
			/>
		</PageWrapper>
	);
};

type CreatePayoutModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

const CreatePayoutModal = (props: CreatePayoutModalProps) => {
	const { isOpen, onClose } = props;

	const [selectedStores, setSelectedStores] = useState<
		{
			id: string;
			balance: number;
		}[]
	>([]);

	const [search, setSearch] = useState<string>();

	const handleSearchSubmit: React.KeyboardEventHandler<HTMLInputElement> = (
		event
	) => {
		if (event.code === "Enter") {
			let target = event.target as typeof event.target & {
				value: string;
			};
			setSearch(target.value);
		}
	};

	const { data: storesData, loading: storesLoading } =
		useGetAllStoresPayoutQuery({
			variables: {
				contains: search,
			},
		});

	const handleSelectStore = (id: string, balance: number) => {
		setSelectedStores((prevState) => [...prevState, { id, balance }]);
	};

	const handleRemoveSelectedStore = (id: string) => {
		setSelectedStores((prevState) => {
			let temp = [...prevState];
			const index = temp.map((item) => item.id).indexOf(id, 0);
			if (index > -1) {
				temp.splice(index, 1);
			}
			return temp;
		});
	};

	const storesTableData =
		storesData?.GetAllStores?.stores?.map((store, index) => ({
			id: store?._id,
			check: (
				<Checkbox
					value={store._id}
					onChange={(e) => {
						if (e.target.checked) {
							handleSelectStore(e.target.value, store.storeBalance);
						} else {
							handleRemoveSelectedStore(e.target.value);
						}
					}}
				/>
			),
			storeName: store?.name,
			vendorName: store?.vendor?.firstName + " " + store?.vendor?.lastName,
			storeBalance: store?.storeBalance + " TND",
		})) || [];

	type StoresTableType = typeof storesTableData[number];

	const storesTableHead: TableHeadType<StoresTableType>[] = [
		{ accessor: "check", label: "" },
		{ accessor: "storeName", label: "Store name" },
		{ accessor: "vendorName", label: "Owner" },
		{
			accessor: "storeBalance",
			label: "Balance",
			headCellProps: { isNumeric: true },
		},
	];

	const [createPayoutMutation] = useCreatePayoutMutation();

	const [totalAmount, setTotalAmount] = useState<number>(0);

	useEffect(() => {
		let tempTotal = 0;
		selectedStores.forEach((store) => {
			tempTotal = tempTotal + store.balance;
		});
		setTotalAmount(tempTotal);
	}, [selectedStores]);

	const toast = useToast();

	const payoutFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			issueDate: new Date(),
			totalAmount: 0,
		},
		onSubmit: (values, { setSubmitting }) => {
			createPayoutMutation({
				variables: {
					issueDate: moment(values.issueDate).format(),
					totalAmount: totalAmount,
					stores: selectedStores.map((item) => item.id),
				},
				onCompleted: (data) => {
					if (data.CreatePayout.code) {
						setSubmitting(false);
						toast({
							title: "Payout has been created.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
						onClose();
					}
				},
				onError: (error) => {
					setSubmitting(false);
					if (error.networkError) {
						toast({
							title: "Network error.",
							description:
								"Please check your internet connection, or refresh the page",
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
					} else {
						toast({
							title: "An error occurred while creating payout.",
							description: error.message,
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
					}
				},
			});
		},
	});

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			scrollBehavior='inside'
			size='3xl'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Create payout</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<form onSubmit={payoutFormik.handleSubmit}>
						<Stack spacing='18px'>
							<Heading fontWeight={"medium"} size='md'>
								Select vendors you want to payout
							</Heading>
							<Input
								inputProps={{
									placeholder: "Search for stores",
									onKeyUp: handleSearchSubmit,
								}}
								inputLeftElementProps={{ children: <BiSearch /> }}
							/>
							{selectedStores.length !== 0 && (
								<Text fontSize='sm' opacity={0.6}>
									{selectedStores.length} Selected
								</Text>
							)}
							<Table
								head={storesTableHead}
								data={storesTableData}
								isLoading={storesLoading}
								emptyState={
									<Box className='empty_table_container'>
										<BiStore size={42} />
										<Text fontSize='md' fontWeight='medium'>
											There is no stores!
										</Text>
									</Box>
								}
							/>
							<DateTimeInput
								name='issueDate'
								label='Date of issue'
								onChange={(date) =>
									payoutFormik.setFieldValue("issueDate", date)
								}
								selected={payoutFormik.values?.issueDate}
							/>
							<Box
								display='flex'
								justifyContent='space-between'
								alignItems='center'>
								<Box display='flex' alignItems='baseline'>
									<Text opacity={0.6} mr='2'>
										Total pay-out credit:{" "}
									</Text>
									<Text fontSize='lg' fontWeight='semibold'>
										{totalAmount} TND
									</Text>
								</Box>
								<Button
									isLoading={payoutFormik.isSubmitting}
									colorScheme='main'
									type='submit'>
									Create payout
								</Button>
							</Box>
						</Stack>
					</form>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default Payout;
