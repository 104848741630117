import {
	Drawer,
	DrawerOverlay,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerFooter,
	Button,
	Accordion,
	Box,
	AccordionButton,
	AccordionIcon,
	Heading,
	AccordionPanel,
	Stack,
	AccordionItem,
	CheckboxGroup,
	Checkbox,
	FormLabel,
	DrawerCloseButton,
	Skeleton,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { FC } from "react";
import { BiFilter, BiFilterAlt, BiReset } from "react-icons/bi";
import {
	GetAllUsersQueryVariables,
	ProductType,
	useGetAllVendorsQuery,
} from "../../../api/generated/graphql";
import { CategorySelectInput, Input, SelectInput } from "../../../components";

type StoreFilterDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	handleSubmitFilter: (values: { name: string; vendor: string }) => void;
	defaultValues: {
		name: string;
		vendor: string;
	};
	handleResetFilter: () => void;
};

const StoreFilterDrawer: FC<StoreFilterDrawerProps> = (props) => {
	const {
		isOpen,
		onClose,
		handleSubmitFilter,
		handleResetFilter,
		defaultValues,
	} = props;

	const { data: vendorsData, loading: vendorsLoading } =
		useGetAllVendorsQuery();

	return (
		<Drawer isOpen={isOpen} onClose={onClose} placement='right'>
			<DrawerOverlay />
			<Formik
				validateOnMount={false}
				validateOnBlur={false}
				validateOnChange={false}
				initialValues={defaultValues}
				onSubmit={(values, { setSubmitting, setFieldError }) => {
					handleSubmitFilter(values);
					setSubmitting(false);
					onClose();
				}}>
				{({
					values,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader borderBottomWidth='1px'>Stores filter</DrawerHeader>
						<DrawerBody>
							<form onSubmit={handleSubmit} id='product-filter-form'>
								<Stack spacing='12px'>
									<Skeleton isLoaded={!vendorsLoading}>
										<Input
											label='Exact store name'
											inputGroupProps={{ size: "sm" }}
											inputProps={{
												placeholder: "Name",
												name: "name",
												onChange: handleChange,
												value: values.name,
											}}
										/>
									</Skeleton>
									<Skeleton isLoaded={!vendorsLoading}>
										<SelectInput
											options={
												vendorsData?.GetAllUsers?.users?.map((vendor) => ({
													label: vendor?.firstName + " " + vendor?.lastName,
													value: vendor?._id,
												})) || []
											}
											label='Vendor'
											selectProps={{
												name: "vendor",
												placeholder: "Select a vendor...",
												value: values.vendor,
												onChange: handleChange,
											}}
										/>
									</Skeleton>
								</Stack>
							</form>
						</DrawerBody>
						<DrawerFooter gap='2' borderTopWidth='1px'>
							<Skeleton isLoaded={!vendorsLoading}>
								<Button
									onClick={() => {
										handleResetFilter();
										onClose();
									}}
									isFullWidth
									leftIcon={<BiReset />}>
									Reset
								</Button>
							</Skeleton>
							<Skeleton isLoaded={!vendorsLoading}>
								<Button
									isLoading={isSubmitting}
									type='submit'
									colorScheme='blue'
									isFullWidth
									form='product-filter-form'
									leftIcon={<BiFilterAlt />}>
									Filter
								</Button>
							</Skeleton>
						</DrawerFooter>
					</DrawerContent>
				)}
			</Formik>
		</Drawer>
	);
};

export default StoreFilterDrawer;
