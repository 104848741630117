import {
	Box,
	Button,
	Divider,
	Heading,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useColorModeValue,
	Text,
	Link,
	LinkBox,
	LinkOverlay,
	MenuDivider,
	useColorMode,
	Stack,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
	BiBell,
	BiChevronDown,
	BiHelpCircle,
	BiLinkExternal,
	BiLogOut,
	BiMessage,
	BiMoon,
	BiRadioCircleMarked,
	BiRefresh,
	BiSearch,
	BiShield,
	BiShieldAlt,
	BiShieldAlt2,
	BiStore,
	BiSun,
} from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { profilePicturePlaceholder } from "../../../assets";
import { getDataFromCache, removeFromCache } from "../../../utils/cache";
import { CacheUserDataType } from "../../../utils/globalTypes";
import { Input } from "../../commons";
import { AppBarWrapper } from "./AppBar.styles";
import { apolloClient } from "../../..";
import { useGetStoreStatusQuery } from "../../../api/generated/graphql";

const AppBar = () => {
	const location = useLocation();
	const { colorMode, toggleColorMode } = useColorMode();
	const { pageName } = (location?.state as { pageName?: string }) || "";
	const userData: CacheUserDataType = getDataFromCache("user_data");

	const storeID = getDataFromCache("store_id", true);

	const userRole = getDataFromCache("user_role", true);

	const navigate = useNavigate();

	const borderColor = useColorModeValue("gray.200", "gray.700");

	const handleLogout = () => {
		removeFromCache("user_tokens");
		removeFromCache("user_role");
		removeFromCache("user_data");
		removeFromCache("store_id");
		window.location.reload();
	};

	const { data: storeStatus, loading: storeStatusLoading } =
		useGetStoreStatusQuery();

	const renderStoreStatus = (status: string | null | undefined) => {
		switch (status) {
			case "DRAFT":
				return (
					<Text fontSize='sm' lineHeight='none' color='yellow.400'>
						Review request
					</Text>
				);
			case "DECLINED":
				return (
					<Text fontSize='sm' lineHeight='none' color='red.300'>
						Declined
					</Text>
				);
			case "PUBLISHED":
				return (
					<Link
						target='_blank'
						href={`${process.env.REACT_APP_MARKETPLACE_URL}/store/${storeStatus?.GetCurrentSessionUserStores?.stores?.[0]?.slug}`}>
						<Stack
							direction='row'
							alignItems='center'
							spacing='2px'
							color='green.400'>
							<Text fontSize='sm' lineHeight='normal'>
								Online
							</Text>{" "}
							<BiLinkExternal size={14} />
						</Stack>
					</Link>
				);
		}
	};

	return (
		<AppBarWrapper>
			<Box className='appbar_content'>
				<Heading size='md' fontWeight='semibold'>
					{pageName}
				</Heading>
				<Box className='appbar_elements'>
					{userRole === "VENDOR" && (
						<Stack direction='row' spacing='24px'>
							<Stack spacing='2px'>
								<Text fontSize='xs' lineHeight='none' opacity={0.6}>
									Current balance
								</Text>
								<Text fontSize='sm' lineHeight='normal' fontWeight='medium'>
									{
										storeStatus?.GetCurrentSessionUserStores?.stores?.[0]
											?.storeBalance
									}{" "}
									TND
								</Text>
							</Stack>
							<Stack spacing='2px'>
								<Text fontSize='xs' lineHeight='none' opacity={0.6}>
									Online store
								</Text>
								{renderStoreStatus(
									storeStatus?.GetCurrentSessionUserStores?.stores?.[0]?.status
								)}
							</Stack>
						</Stack>
					)}

					<Button
						size='sm'
						variant='ghost'
						color='blue.400'
						fontSize='sm'
						fontWeight='medium'
						onClick={() =>
							apolloClient.refetchQueries({
								include: "active",
							})
						}>
						Refresh
					</Button>
					{/* <Input
						inputProps={{ placeholder: "Search for..." }}
						inputGroupProps={{ size: "sm" }}
						inputLeftElementProps={{ children: <BiSearch /> }}
					/>
					<IconButton aria-label='help' variant='ghost' size='sm'>
						<BiHelpCircle size='24' />
					</IconButton>
					<IconButton aria-label='help' variant='ghost' size='sm'>
						<BiBell size='24' />
					</IconButton> */}
					<Menu>
						<MenuButton
							className='profile_button'
							as={Button}
							rightIcon={<BiChevronDown />}
							size='sm'
							variant='ghost'
							px='1'>
							<img
								className='profile_picture'
								src={userData?.avatar || profilePicturePlaceholder}
								alt={"profile_picture"}
							/>
						</MenuButton>

						<MenuList px='2' shadow='lg'>
							<MenuItem
								as={LinkBox}
								className='menu_profile_item'
								rounded='base'>
								<img
									className='profile_picture_menu'
									src={userData?.avatar || profilePicturePlaceholder}
									alt={"profile_picture"}
								/>
								<Box ml='3'>
									<Text fontWeight='medium'>
										{userData?.firstName + " " + userData?.lastName}
									</Text>
									<LinkOverlay
										as={RouterLink}
										to={
											userRole === "VENDOR"
												? `/${storeID}/settings/profile`
												: `/admin/settings/profile`
										}
										color='blue.500'>
										<Text fontSize='sm'>Edit profile</Text>
									</LinkOverlay>
								</Box>
							</MenuItem>
							<MenuDivider />
							<MenuItem
								onClick={() =>
									navigate(
										userRole === "VENDOR"
											? `/${storeID}/settings/security`
											: `/admin/settings/security`
									)
								}
								rounded='base'
								icon={<BiShieldAlt2 size={16} />}>
								Security settings
							</MenuItem>
							<MenuItem
								onClick={() =>
									navigate(
										userRole === "VENDOR"
											? `/${storeID}/settings/store`
											: `/admin/settings/home`
									)
								}
								rounded='base'
								icon={<BiStore size={16} />}>
								{userRole === "VENDOR"
									? "Store settings"
									: "Marketplace settings"}
							</MenuItem>

							<MenuDivider />
							<MenuItem
								rounded='base'
								onClick={toggleColorMode}
								icon={
									colorMode === "dark" ? (
										<BiSun size={18} />
									) : (
										<BiMoon size={18} />
									)
								}>
								{colorMode === "dark" ? "Light mode" : "Dark mode"}
							</MenuItem>
							<MenuDivider />
							<MenuItem
								rounded='base'
								icon={<BiLogOut size={16} />}
								color='status.error'
								onClick={handleLogout}>
								Log out
							</MenuItem>
						</MenuList>
					</Menu>
				</Box>
			</Box>
			<Divider borderColor={borderColor} />
		</AppBarWrapper>
	);
};

export default AppBar;
