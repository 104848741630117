import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Stack,
  Textarea,
  Text,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Checkbox,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useToast,
  Skeleton,
  Spinner,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { BiAddToQueue, BiBriefcaseAlt, BiPlus, BiTrash } from "react-icons/bi";
import { apolloClient } from "../..";
import {
  useGetHomePageSettingsQuery,
  useGetProductsToAssignQuery,
  useUpdateHomeSettingsMutation,
} from "../../api/generated/graphql";
import {
  DateTimeInput,
  ColorPicker,
  ImageUpload,
  Input,
  Table,
  TableHeadType,
} from "../../components";
import moment from "moment";
import SettingsWrapper from "../../components/SettingsWrapper/SettingsWrapper";

const HomePageSettings = () => {
  const sectionBgColor = useColorModeValue("white", "gray.900");

  const { data: getHomePageSettings, loading: getHomePageLoading } =
    useGetHomePageSettingsQuery();

  const toast = useToast();

  const handleImage = async (file: File, index: number, form: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "pn8kkadk");
    formData.append("public_id", `homepage/${file.name}`);
    // setFiles((prevFiles) => [
    // 	...prevFiles,
    // 	{
    // 		src: file,
    // 		id: file.name,
    // 		progress: true,
    // 		name: file.name,
    // 		type: file.type,
    // 	},
    // ]);
    homePageFormik.setFieldValue(`${form}[${index}].image`, [
      {
        src: "",
        id: "",
        progress: true,
      },
    ]);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    homePageFormik.setFieldValue(`${form}[${index}].image`, [
      {
        src: data.secure_url,
        id: data.asset_id,
        progress: false,
      },
    ]);
  };

  const handleDeleteImage = (index: number, form: string) => {
    homePageFormik.setFieldValue(`${form}[${index}].image`, []);
  };

  const homePageInitalValues: {
    banners: {
      image: {
        progress: boolean;
        id: string;
        src: string | File | undefined;
      }[];
      url: string;
    }[];
    collections: {
      description: string;
      title: string;
      color: string;
      products: {
        _id: string;
        name: string;
        category: {
          _id: string;
          name: string;
        };
        slug: string;
      }[];
    }[];
    slides: {
      description: string;
      image: {
        progress: boolean;
        id: string;
        src: string | File | undefined;
      }[];
      showOverlay: boolean;
      title: string;
      to: string;
    }[];
    seo: {
      metadescription: string;
      metatags: {
        key: string;
        value: string;
      }[];
      seotitle: string;
    };
    flashSales: {
      title: string;
      color: string;
      description: string;
      endDate: Date | null;
      products: {
        _id: string;
        name: string;
        category: {
          _id: string;
          name: string;
        };
        price: {
          price: number;
        };
        slug: string;
      }[];
    }[];
  } = {
    banners: [],
    collections: [],
    slides: [],
    seo: {
      metadescription: "",
      metatags: [],
      seotitle: "",
    },
    flashSales: [],
  };

  useEffect(() => {
    homePageFormik.setValues({
      banners:
        getHomePageSettings?.GetHomePage?.banners?.map((banner) => ({
          image: banner.image
            ? [
                {
                  id: banner._id || "",
                  progress: false,
                  src: banner.image || "",
                },
              ]
            : [],
          url: banner.url || "",
        })) || [],
      collections:
        getHomePageSettings?.GetHomePage?.collections?.map((collection) => ({
          description: collection.description || "",
          title: collection.title || "",
          color: collection.color?.hexColor || "",
          products:
            collection?.products?.map((prod) => ({
              _id: prod._id || "",
              name: prod.name || "",
              category: {
                _id: prod.category?._id || "",
                name: prod.category?.name || "",
              },
              slug: prod.slug || "",
            })) || [],
        })) || [],
      seo: {
        metadescription:
          getHomePageSettings?.GetHomePage?.seo?.metadescription || "",
        metatags:
          getHomePageSettings?.GetHomePage?.seo?.metatags?.map((metatag) => ({
            key: metatag.key || "",
            value: metatag.value || "",
          })) || [],
        seotitle: getHomePageSettings?.GetHomePage?.seo?.seotitle || "",
      },
      slides:
        getHomePageSettings?.GetHomePage?.slides?.map((slide) => ({
          description: slide.description || "",
          image: slide.image
            ? [
                {
                  id: slide._id || "",
                  progress: false,
                  src: slide.image,
                },
              ]
            : [],
          showOverlay: slide.showOverlay ? true : false,
          title: slide.title || "",
          to: slide.action?.to || "",
        })) || [],
      flashSales:
        getHomePageSettings?.GetHomePage?.flashSales?.map((sale, index) => ({
          color: sale.color?.hexColor || "",
          description: sale.description || "",
          products:
            sale?.products?.map((product, index) => ({
              _id: product?._id || "",
              name: product?.name || "",
              category: {
                _id: product.category?._id || "",
                name: product.category?.name || "",
              },
              price: {
                price: product?.price?.price || 0,
              },
              slug: product?.slug || "",
            })) || [],
          endDate: sale.endDate ? moment(sale.endDate).toDate() : null,

          title: sale.title || "",
        })) || [],
    });
  }, [getHomePageSettings]);

  const [updateHomeSettingsMutation] = useUpdateHomeSettingsMutation();

  const homePageFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: homePageInitalValues,
    onSubmit: (values, { setSubmitting }) => {
      updateHomeSettingsMutation({
        variables: {
          homePageID: getHomePageSettings?.GetHomePage._id || "",
          banners: values.banners.map((banner) => ({
            image: banner.image?.[0]?.src
              ? (banner.image?.[0]?.src as string)
              : "",
            url: banner.url || "",
          })),
          collections:
            values.collections.map((collection) => ({
              description: collection.description,
              products: collection.products.map((prod) => prod._id),
              title: collection.title,
              color: {
                hexColor: collection.color || "#ffffff",
              },
            })) || [],
          seo: values.seo,
          slides: values.slides.map((slide) => ({
            action: { to: slide.to || "" },
            description: slide.description,
            image: slide.image?.[0]?.src
              ? (slide.image?.[0]?.src as string)
              : "",
            showOverlay: slide.showOverlay,
            title: slide.title,
          })),
          flashSales: values.flashSales.map((sales) => ({
            color: {
              hexColor: sales.color || "#ffffff",
            },
            title: sales.title,
            products: sales.products.map((prod) => prod._id),
            description: sales.description,
            endDate: sales.endDate ? moment(sales.endDate).format() : null,
          })),
        },
        onCompleted: (data) => {
          if (data.UpdateHomePageById?.code) {
            setSubmitting(false);
            toast({
              title: "Home page has been updated with success!",
              status: "success",

              position: "bottom-right",
              duration: 5000,
              isClosable: true,
            });
            apolloClient.refetchQueries({
              include: "active",
            });
          }
        },
        onError: (error) => {
          toast({
            title: "An error occurred while updating home page.",
            description: error.message,
            status: "error",
            position: "bottom-right",
            duration: 5000,
            isClosable: true,
          });
          setSubmitting(false);
        },
      });
    },
  });

  const {
    isOpen: isOpenProductModal,
    onOpen: onOpenProductModal,
    onClose: onCloseProductModal,
  } = useDisclosure();

  const {
    isOpen: isOpenFlashSalesModal,
    onOpen: onOpenFlashSalesModal,
    onClose: onCloseFlashSalesModal,
  } = useDisclosure();

  const [selectedCollection, setSelectedCollection] = useState<number | null>(
    null
  );

  const [selectedFlashSales, setSelectedFlashSales] = useState<number | null>(
    null
  );

  const handleAssignProducts = (
    products: {
      _id: string;
      name: string;
      slug: string;
    }[]
  ) => {
    homePageFormik.setFieldValue(
      `collections[${selectedCollection}].products`,
      products
    );
  };

  const handleAssignFlashSales = (
    products: {
      _id: string;
      name: string;
      slug: string;
    }[]
  ) => {
    homePageFormik.setFieldValue(
      `flashSales[${selectedFlashSales}].products`,
      products
    );
  };

  return (
    <SettingsWrapper>
      <form onSubmit={homePageFormik.handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Heading size="lg" fontSize="2xl" fontWeight="medium">
            Home page config
          </Heading>
          <Button
            colorScheme="main"
            type="submit"
            size="sm"
            isLoading={homePageFormik.isSubmitting}
          >
            Save changes
          </Button>
        </Box>
        <Box marginTop="24px">
          {getHomePageLoading ? (
            <Box
              p="4"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size="sm" mr="8px" />
              <Text fontWeight="medium">Loading...</Text>
            </Box>
          ) : (
            <Tabs colorScheme="blue" isLazy>
              <TabList>
                <Tab>Banners</Tab>
                <Tab>Collections</Tab>
                <Tab>Slides</Tab>
                <Tab>SEO</Tab>
                <Tab>Flash sale</Tab>
              </TabList>
              <TabPanels>
                <TabPanel p="12px 0px">
                  <Box
                    p="16px"
                    bgColor={sectionBgColor}
                    width="100%"
                    shadow="md"
                    rounded="base"
                  >
                    <Heading size="md" fontSize="xl" fontWeight="medium">
                      Banners
                    </Heading>
                    <Stack mt="4" spacing="18px">
                      {homePageFormik.values.banners.map((banner, index) => (
                        <Stack key={index}>
                          <Box
                            mb="2"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Heading
                              opacity={0.6}
                              size="sm"
                              fontSize="md"
                              fontWeight="medium"
                            >
                              Banner {index + 1}
                            </Heading>
                            <IconButton
                              aria-label="remove_banner_button"
                              size="sm"
                              onClick={() =>
                                homePageFormik.setFieldValue(
                                  "banners",
                                  homePageFormik.values.banners.filter(
                                    (item, filterIndex) => index !== filterIndex
                                  )
                                )
                              }
                            >
                              <BiTrash size={18} />
                            </IconButton>
                          </Box>
                          <ImageUpload
                            height="140px"
                            width="100%"
                            images={banner.image}
                            handleUpload={(file) =>
                              handleImage(file, index, "banners")
                            }
                            handleDelete={() =>
                              handleDeleteImage(index, "banners")
                            }
                            label="Profile picture"
                          />
                          <Input
                            label="Banner action link"
                            inputProps={{
                              placeholder: "Action URL",
                              name: `banners[${index}].url`,
                              value: banner.url,
                              onChange: homePageFormik.handleChange,
                            }}
                          />
                          <Divider mt="4" />
                        </Stack>
                      ))}
                      <Button
                        onClick={() =>
                          homePageFormik.setFieldValue(
                            `banners[${homePageFormik.values.banners.length}]`,
                            {
                              image: [],
                              url: "",
                            }
                          )
                        }
                        variant="outline"
                        leftIcon={<BiPlus />}
                        isFullWidth
                      >
                        Add a banner
                      </Button>
                    </Stack>
                  </Box>
                </TabPanel>
                <TabPanel p="12px 0px">
                  <Box
                    p="16px"
                    bgColor={sectionBgColor}
                    width="100%"
                    shadow="md"
                    rounded="base"
                  >
                    <Heading size="md" fontSize="xl" fontWeight="medium">
                      Collections
                    </Heading>
                    <Stack mt="4" spacing="18px">
                      {homePageFormik.values.collections.map(
                        (collection, index) => (
                          <Stack key={index}>
                            <Box
                              mb="2"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Heading
                                opacity={0.6}
                                size="sm"
                                fontSize="md"
                                fontWeight="medium"
                              >
                                Collection {index + 1}
                              </Heading>
                              <IconButton
                                aria-label="remove_banner_button"
                                size="sm"
                                onClick={() =>
                                  homePageFormik.setFieldValue(
                                    "collections",
                                    homePageFormik.values.collections.filter(
                                      (item, filterIndex) =>
                                        index !== filterIndex
                                    )
                                  )
                                }
                              >
                                <BiTrash size={18} />
                              </IconButton>
                            </Box>
                            <Stack direction="row" spacing="16px">
                              <Input
                                label="Collection title"
                                inputProps={{
                                  placeholder: "Title",
                                  name: `collections[${index}].title`,
                                  value: collection.title,
                                  onChange: homePageFormik.handleChange,
                                }}
                              />
                              <Box w="80">
                                <ColorPicker
                                  label="Collection color"
                                  color={collection.color || "#ffffff"}
                                  setColor={(color) =>
                                    homePageFormik.setFieldValue(
                                      `collections[${index}].color`,
                                      color
                                    )
                                  }
                                />
                              </Box>
                            </Stack>
                            <FormControl>
                              <FormLabel marginBottom="1">
                                Description
                              </FormLabel>
                              <Textarea
                                placeholder="Describe this collection"
                                name={`collections[${index}].description`}
                                onChange={homePageFormik.handleChange}
                                value={collection.description}
                              />
                            </FormControl>
                            <Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <FormLabel marginBottom="1">
                                  Products list
                                </FormLabel>
                                <Button
                                  leftIcon={<BiAddToQueue size={18} />}
                                  size="sm"
                                  onClick={() => {
                                    setSelectedCollection(index);
                                    onOpenProductModal();
                                  }}
                                >
                                  Assign products
                                </Button>
                              </Box>
                              <Divider my="2" />
                              {collection.products.map((product, prodIndex) => (
                                <Fragment key={prodIndex}>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Text fontWeight="medium" fontSize="lg">
                                      {product.name}
                                    </Text>
                                    <Button
                                      variant="link"
                                      colorScheme="red"
                                      size="sm"
                                      onClick={() =>
                                        homePageFormik.setFieldValue(
                                          `collections[${index}].products`,
                                          homePageFormik.values.collections[
                                            index
                                          ].products.filter(
                                            (item, filterIndex) =>
                                              prodIndex !== filterIndex
                                          )
                                        )
                                      }
                                    >
                                      Unassign product
                                    </Button>
                                  </Stack>
                                  <Divider mt="2" />
                                </Fragment>
                              ))}
                            </Box>
                          </Stack>
                        )
                      )}
                      <Button
                        onClick={() =>
                          homePageFormik.setFieldValue(
                            `collections[${homePageFormik.values.collections.length}]`,
                            {
                              title: "",
                              description: "",
                              products: [],
                            }
                          )
                        }
                        variant="outline"
                        leftIcon={<BiPlus />}
                        isFullWidth
                      >
                        Add a collection
                      </Button>
                    </Stack>
                  </Box>
                </TabPanel>
                <TabPanel p="12px 0px">
                  <Box
                    p="16px"
                    bgColor={sectionBgColor}
                    width="100%"
                    shadow="md"
                    rounded="base"
                  >
                    <Heading size="md" fontSize="xl" fontWeight="medium">
                      Slides
                    </Heading>
                    <Stack mt="4" spacing="18px">
                      {homePageFormik.values.slides.map((slide, index) => (
                        <Stack key={index}>
                          <Box
                            mb="2"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Heading
                              opacity={0.6}
                              size="sm"
                              fontSize="md"
                              fontWeight="medium"
                            >
                              Slide {index + 1}
                            </Heading>
                            <IconButton
                              aria-label="remove_slides_button"
                              size="sm"
                              onClick={() =>
                                homePageFormik.setFieldValue(
                                  "slides",
                                  homePageFormik.values.slides.filter(
                                    (item, filterIndex) => index !== filterIndex
                                  )
                                )
                              }
                            >
                              <BiTrash size={18} />
                            </IconButton>
                          </Box>
                          <Input
                            label="Title"
                            inputProps={{
                              placeholder: "Title",
                              name: `slides[${index}].title`,
                              value: slide.title,
                              onChange: homePageFormik.handleChange,
                            }}
                          />
                          <FormControl>
                            <FormLabel marginBottom="1">Description</FormLabel>
                            <Textarea
                              placeholder="Description"
                              name={`slides[${index}].description`}
                              onChange={homePageFormik.handleChange}
                              value={slide.description}
                            />
                          </FormControl>
                          <ImageUpload
                            height="240px"
                            width="100%"
                            images={slide.image}
                            handleUpload={(file) =>
                              handleImage(file, index, "slides")
                            }
                            handleDelete={() =>
                              handleDeleteImage(index, "slides")
                            }
                            label="Profile picture"
                          />
                          <Checkbox
                            name={`slides[${index}].showOverlay`}
                            onChange={homePageFormik.handleChange}
                            isChecked={slide.showOverlay}
                          >
                            Show overlay
                          </Checkbox>
                          <Input
                            label="Action url"
                            inputProps={{
                              placeholder: "Action url",
                              name: `slides[${index}].to`,
                              value: slide.to,
                              onChange: homePageFormik.handleChange,
                            }}
                          />
                        </Stack>
                      ))}
                      <Button
                        onClick={() =>
                          homePageFormik.setFieldValue(
                            `slides[${homePageFormik.values.slides.length}]`,
                            {
                              description: "",
                              image: [],
                              showOverlay: false,
                              title: "",
                            }
                          )
                        }
                        variant="outline"
                        leftIcon={<BiPlus />}
                        isFullWidth
                      >
                        Add another slide
                      </Button>
                    </Stack>
                  </Box>
                </TabPanel>
                <TabPanel p="12px 0px">
                  <Box
                    p="16px"
                    bgColor={sectionBgColor}
                    width="100%"
                    shadow="md"
                    rounded="base"
                  >
                    <Heading size="md" fontSize="xl" fontWeight="medium">
                      SEO
                    </Heading>
                    <Stack mt="4" spacing="18px">
                      <Input
                        label="Page title"
                        inputProps={{
                          placeholder: "Page title",
                          name: "seo.seotitle",
                          onChange: homePageFormik.handleChange,
                          value: homePageFormik.values.seo.seotitle,
                        }}
                      />
                      <FormControl>
                        <FormLabel marginBottom="1">Meta description</FormLabel>
                        <Textarea
                          placeholder="Meta Description"
                          name="seo.metadescription"
                          onChange={homePageFormik.handleChange}
                          value={homePageFormik.values.seo.metadescription}
                        />
                      </FormControl>
                      <Box>
                        <FormLabel>Meta tags</FormLabel>
                        {homePageFormik.values.seo.metatags.map(
                          (tag, index) => (
                            <Box>
                              <Text>Tag {index + 1}</Text>
                              <Stack
                                mb="12px"
                                direction="row"
                                alignItems="flex-end"
                                spacing="14px"
                              >
                                <Input
                                  label="Key"
                                  inputProps={{
                                    placeholder: "Key",
                                    name: `seo.metatags[${index}].key`,
                                    onChange: homePageFormik.handleChange,
                                    value: tag.key || "",
                                  }}
                                />
                                <Input
                                  label="Value"
                                  inputProps={{
                                    placeholder: "Value",
                                    name: `seo.metatags[${index}].value`,
                                    onChange: homePageFormik.handleChange,
                                    value: tag.value || "",
                                  }}
                                />
                                <IconButton
                                  variant="ghost"
                                  colorScheme="red"
                                  aria-label="delete_meta-tags"
                                  onClick={() =>
                                    homePageFormik.setFieldValue(
                                      `seo.metatags`,
                                      homePageFormik.values.seo.metatags.filter(
                                        (item, filterIndex) =>
                                          index !== filterIndex
                                      )
                                    )
                                  }
                                >
                                  <BiTrash size="18" />
                                </IconButton>
                              </Stack>
                            </Box>
                          )
                        )}
                        <Button
                          onClick={() =>
                            homePageFormik.setFieldValue(
                              `seo.metatags[${homePageFormik.values.seo.metatags.length}]`,
                              {
                                key: "",
                                value: "",
                              }
                            )
                          }
                          variant="outline"
                          leftIcon={<BiPlus />}
                          isFullWidth
                        >
                          Add a meta tag
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                </TabPanel>
                <TabPanel p="12px 0px">
                  <Box
                    p="16px"
                    bgColor={sectionBgColor}
                    width="100%"
                    shadow="md"
                    rounded="base"
                  >
                    <Heading size="md" fontSize="xl" fontWeight="medium">
                      Flash sales
                    </Heading>
                    <Stack mt="4" spacing="18px">
                      <Box>
                        {homePageFormik.values.flashSales.map((sale, index) => (
                          <Stack key={index}>
                            <Box
                              mb="2"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Heading
                                opacity={0.6}
                                size="sm"
                                fontSize="md"
                                fontWeight="medium"
                              >
                                Flash sale {index + 1}
                              </Heading>
                              <IconButton
                                aria-label="remove_banner_button"
                                size="sm"
                                onClick={() =>
                                  homePageFormik.setFieldValue(
                                    "flashSales",
                                    homePageFormik.values.flashSales.filter(
                                      (item, filterIndex) =>
                                        index !== filterIndex
                                    )
                                  )
                                }
                              >
                                <BiTrash size={18} />
                              </IconButton>
                            </Box>
                            <Stack direction="row" spacing="16px">
                              <Input
                                label="Flash sale title"
                                inputProps={{
                                  placeholder: "Title",
                                  name: `flashSales[${index}].title`,
                                  value: sale.title,
                                  onChange: homePageFormik.handleChange,
                                }}
                              />
                              <Box w="80">
                                <ColorPicker
                                  label="Flash sale color"
                                  color={sale.color || "#ffffff"}
                                  setColor={(color) =>
                                    homePageFormik.setFieldValue(
                                      `flashSales[${index}].color`,
                                      color
                                    )
                                  }
                                />
                              </Box>
                            </Stack>

                            <FormControl>
                              <FormLabel marginBottom="1">
                                Description
                              </FormLabel>
                              <Textarea
                                placeholder="Describe this collection"
                                name={`flashSales[${index}].description`}
                                onChange={homePageFormik.handleChange}
                                value={sale.description}
                              />
                            </FormControl>
                            <Stack direction="row" spacing="14px">
                              <DateTimeInput
                                name="endDate"
                                label="End date"
                                showTime
                                onChange={(date) =>
                                  homePageFormik.setFieldValue(
                                    `flashSales[${index}].endDate`,
                                    date
                                  )
                                }
                                selected={sale?.endDate}
                                // errorMessage={homePageFormik.errors.flashSales || ""}
                                // isError={
                                //   homePageFormik.errors.endDate!== undefined
                                // }
                              />
                            </Stack>

                            <Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <FormLabel marginBottom="1">
                                  Products list
                                </FormLabel>
                                <Button
                                  leftIcon={<BiAddToQueue size={18} />}
                                  size="sm"
                                  onClick={() => {
                                    setSelectedFlashSales(index);
                                    onOpenFlashSalesModal();
                                  }}
                                >
                                  Assign products
                                </Button>
                              </Box>
                              <Divider my="2" />
                              {sale.products.map((product, prodIndex) => (
                                <Fragment key={prodIndex}>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Text fontWeight="medium" fontSize="lg">
                                      {product.name}
                                    </Text>
                                    <Button
                                      variant="link"
                                      colorScheme="red"
                                      size="sm"
                                      onClick={() =>
                                        homePageFormik.setFieldValue(
                                          `flashSales[${index}].products`,
                                          homePageFormik.values.flashSales[
                                            index
                                          ].products.filter(
                                            (item, filterIndex) =>
                                              prodIndex !== filterIndex
                                          )
                                        )
                                      }
                                    >
                                      Unassign product
                                    </Button>
                                  </Stack>
                                  <Divider mt="2" />
                                </Fragment>
                              ))}
                            </Box>
                          </Stack>
                        ))}
                      </Box>
                      <Button
                        onClick={() =>
                          homePageFormik.setFieldValue(
                            `flashSales[${homePageFormik.values.flashSales.length}]`,
                            {
                              color: "",
                              description: "",
                              products: [],
                              endDate: null,
                              title: "",
                            }
                          )
                        }
                        variant="outline"
                        leftIcon={<BiPlus />}
                        isFullWidth
                      >
                        Add a flash sale
                      </Button>
                    </Stack>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </Box>
        <ProductAssignModal
          isOpen={isOpenProductModal}
          onClose={onCloseProductModal}
          assignProducts={handleAssignProducts}
          products={
            selectedCollection !== null
              ? homePageFormik.values.collections?.[selectedCollection]
                  ?.products
              : []
          }
        />
        <ProductAssignModal
          isOpen={isOpenFlashSalesModal}
          onClose={onCloseFlashSalesModal}
          assignProducts={handleAssignFlashSales}
          products={
            selectedFlashSales !== null
              ? homePageFormik.values.flashSales?.[selectedFlashSales]?.products
              : []
          }
        />
      </form>
    </SettingsWrapper>
  );
};
export default HomePageSettings;

type ProductAssignModalProps = {
  isOpen: boolean;
  onClose: () => void;
  assignProducts: (
    products: {
      _id: string;
      name: string;
      slug: string;
    }[]
  ) => void;
  products: {
    _id: string;
    name: string;
    slug: string;
  }[];
};

const ProductAssignModal = (props: ProductAssignModalProps) => {
  const { isOpen, onClose, assignProducts, products } = props;

  const handleAssignProduct = () => {
    assignProducts(selectedProducts);
    onClose();
  };

  const [pagination, setPagination] = useState({
    limit: 10,
    skip: 0,
  });

  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = +value;
      return temp;
    });
  };

  const [selectedProducts, setSelectedProducts] = useState<
    {
      _id: string;
      name: string;
      slug: string;
    }[]
  >([]);

  useEffect(() => {
    setSelectedProducts(products);
  }, [products]);

  const handleSelectProduct = (prod: {
    _id: string;
    name: string;
    slug: string;
  }) => {
    setSelectedProducts((prevState) => [...prevState, prod]);
  };

  const handleRemoveSelectedProduct = (id: string) => {
    setSelectedProducts((prevState) => {
      let temp = [...prevState];
      const index = temp.findIndex((object) => {
        return object._id === id;
      });
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };

  const { data: productToAssignListData, loading: productToAssignListLoading } =
    useGetProductsToAssignQuery({
      variables: {
        pagination: pagination,
      },
      skip: !isOpen,
    });

  const productsToAssignTableData =
    productToAssignListData?.GetAllProducts?.products?.map(
      (product, index) => ({
        id: product._id,
        check: (
          <Checkbox
            value={product._id}
            onChange={(e) => {
              if (e.target.checked) {
                handleSelectProduct({
                  _id: product._id,
                  name: product.name || "",
                  slug: product.slug || "",
                });
              } else {
                handleRemoveSelectedProduct(e.target.value);
              }
            }}
          />
        ),
        name: product?.name,
        sku: product?.inventory?.sku,
        category: product?.category?.name,
        store: product?.store?.name,
      })
    ) || [];

  type ProductsToAssignTableType = typeof productsToAssignTableData[number];

  const productsToAssignTableHead: TableHeadType<ProductsToAssignTableType>[] =
    [
      { accessor: "check", label: "" },
      { accessor: "name", label: "Name" },
      { accessor: "sku", label: "SKU" },
      { accessor: "category", label: "Category" },
      { accessor: "store", label: "store" },
    ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      size="5xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth="1px">
          Assign product to collection
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="14px" py="14px">
            <Heading size="md">
              Select products wish you want to assign to category
            </Heading>
            <Box>
              {selectedProducts.length !== 0 && (
                <Text fontSize="sm" opacity={0.6}>
                  {selectedProducts.length} Selected
                </Text>
              )}
              <Table
                head={productsToAssignTableHead}
                data={productsToAssignTableData}
                isLoading={productToAssignListLoading}
                emptyState={
                  <Box className="empty_table_container">
                    <BiBriefcaseAlt size={42} />
                    <Text fontSize="md" fontWeight="medium">
                      There is no assigned products!
                    </Text>
                  </Box>
                }
                pagination={{
                  count:
                    productToAssignListData?.GetAllProducts.queryCount || 0,
                  limit: pagination.limit,
                  skip: pagination.skip,
                  onPageChange: handlePageChange,
                  onChangeLimit: handleLimitChange,
                }}
              />
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter borderTopWidth="1px">
          <Button variant="Outline" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="main" onClick={handleAssignProduct}>
            Assign products
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

// {
//     __typename
//     GetHomePage(_id: "624eea32faa74a79a806f8e4") {
//       banners {
//         _id
//         image
//         url
//       }
//       _id
//       collections {
//         _id
//         description
//         title
//         products {
//           _id
//           name
//           images
//         }
//       }
//       name
//       seo {
//         seotitle
//         metatags {
//           _id
//           key
//           value
//         }
//         metadescription
//         keywords
//         _id
//       }
//       slides {
//         title
//         showOverlay
//         image
//         description
//         action {
//           type
//         }
//         _id
//       }
//     }
//   }
