import {
	Box,
	Button,
	Divider,
	Heading,
	IconButton,
	Link,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Spinner,
	Stack,
	Text,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import {
	BiArrowBack,
	BiBriefcase,
	BiFilterAlt,
	BiRadioCircleMarked,
	BiSearch,
	BiSlider,
	BiTime,
} from "react-icons/bi";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { apolloClient } from "../..";
import {
	GetCurrentSessionProductsQueryVariables,
	InventoryActivity,
	ProductType,
	useGetAllProductsQuery,
	useGetCurrentSessionProductsQuery,
	useUpdateInventoryQuantityMutation,
} from "../../api/generated/graphql";
import { picturePlaceholder } from "../../assets";
import {
	Input,
	NumberInput,
	SelectInput,
	TableHeadType,
	Table,
} from "../../components";
import { getDataFromCache } from "../../utils/cache";
import { PageWrapper } from "../../utils/globalStyle";
import ProductsFilterDrawer from "../Products/Filters/ProductsFilterDrawer";

const Inventory = () => {
	const location = useLocation();

	const toast = useToast();

	const navigate = useNavigate();

	const storeID = getDataFromCache("store_id", true);

	const userRole: string = getDataFromCache("user_role", true);

	const { from } = (location?.state as { from?: string }) || "";

	const [pagination, setPagination] = useState({
		limit: 10,
		skip: 0,
	});

	const handlePageChange = (dir: "next" | "prev") => {
		if (dir === "next") {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip + temp.limit;
				return temp;
			});
		} else {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip - temp.limit;
				return temp;
			});
		}
	};

	const handleLimitChange = (value: number) => {
		setPagination((prevState) => {
			let temp = { ...prevState };
			temp.limit = +value;
			return temp;
		});
	};

	const [search, setSearch] =
		useState<Pick<GetCurrentSessionProductsQueryVariables, "search">>();

	const [filters, setFilters] = useState<
		Pick<GetCurrentSessionProductsQueryVariables, "name" | "brand" | "type"> & {
			category: {
				label: string;
				id: string;
			};
		}
	>();

	const [defaultFilterValues, setDefaultFilterValues] = useState({
		name: "",
		brand: "",
		type: "",
		category: {
			label: "",
			id: "",
		},
	});

	const handleFilter = (values: {
		name: string;
		type: string;
		category: {
			label: string;
			id: string;
		};
		brand: string;
	}) => {
		setDefaultFilterValues(values);
		setFilters({
			brand: values.brand || undefined,
			category: values.category || undefined,
			name: values.name || undefined,
			type: (values.type as ProductType) || undefined,
		});
	};

	const getFiltersCount = () => {
		let count = 0;
		if (filters) {
			Object.values(filters).forEach((value, index) => {
				let testValue: string | undefined = undefined;
				if (typeof value !== "string") {
					testValue = value?.id !== "" ? value?.id : undefined;
				} else {
					testValue = value;
				}
				if (testValue) {
					count = count + 1;
				}
			});
		}
		return count;
	};

	const handleResetFilter = () => {
		setFilters(undefined);
		setDefaultFilterValues({
			brand: "",
			category: {
				label: "",
				id: "",
			},
			name: "",
			type: "",
		});
	};

	const handleSearchSubmit: React.KeyboardEventHandler<HTMLInputElement> = (
		event
	) => {
		if (event.code === "Enter") {
			let target = event.target as typeof event.target & {
				value: string;
			};
			setSearch({
				search: target.value,
			});
		}
	};

	const {
		isOpen: openFilter,
		onOpen: onOpenFilter,
		onClose: onCloseFilter,
	} = useDisclosure();

	const {
		loading: productsVendorLoading,
		error: productsVendorError,
		data: productsVendorData,
	} = useGetCurrentSessionProductsQuery({
		variables: {
			pagination: pagination,
			search: search?.search || undefined,
			brand: filters?.brand || undefined,
			category: filters?.category?.id || undefined,
			name: filters?.name || undefined,
			type: filters?.type || undefined,
		},
		skip: userRole !== "VENDOR",
	});

	const {
		loading: productsAdminLoading,
		error: productsAdminError,
		data: productsAdminData,
	} = useGetAllProductsQuery({
		variables: {
			pagination: pagination,
			search: search?.search || undefined,
			brand: filters?.brand || undefined,
			category: filters?.category?.id || undefined,
			name: filters?.name || undefined,
			type: filters?.type || undefined,
		},
		skip: userRole !== "ADMIN",
	});

	const getAdminProductsList = () => {
		let list: {
			id: any;
			image: JSX.Element;
			name: JSX.Element | string | null | undefined;
			store: JSX.Element;
			sku: string | null | undefined;
			barcode: string | null | undefined;
			lastModified: string;
			quantity: JSX.Element;
			action: JSX.Element;
		}[] = [];
		productsAdminData?.GetAllProducts?.products?.forEach((product, index) => {
			list.push({
				id: product._id,
				image: (
					<Box className='table_image'>
						<img
							src={product.images?.[0] || picturePlaceholder}
							alt='product_profile_picture'
						/>
					</Box>
				),
				name: product?.name,
				store: (
					<Link
						as={RouterLink}
						to={`/admin/stores/${product?.store?._id}`}
						color='blue.300'>
						{product?.store?.name}
					</Link>
				),
				sku: product?.inventory?.sku,
				barcode: product?.inventory?.barCode,
				lastModified: moment(product?.updatedAt).format("LLL"),

				quantity: (
					<QuantityPopover
						quantity={product.inventory?.qunatity || 0}
						productID={product?._id}
					/>
				),

				action: (
					<Button
						variant='link'
						colorScheme='blue'
						leftIcon={<BiTime size={18} />}
						fontWeight='semibold'
						size='sm'
						onClick={() =>
							navigate(`/admin/inventory/${product._id}`, {
								state: {
									from: `/admin/inventory`,
									pageName: "Inventory",
								},
							})
						}>
						History
					</Button>
				),
			});
			product.variations.forEach((variant, variantIndex) => {
				list.push({
					id: variant._id,
					image: (
						<Box className='table_image'>
							<img
								src={variant.images?.[0] || picturePlaceholder}
								alt='variant_profile_picture'
							/>
						</Box>
					),
					name: (
						<Stack>
							<Stack direction='row'>
								<Text whiteSpace='nowrap'>{product?.name}</Text>
								<Text opacity={0.6}>{`(Variant ${variantIndex})`}</Text>
							</Stack>
							<Text>
								{variant?.selectedOption?.map(
									(option, index) =>
										`${option.KEY}: ${option.VALUE}${
											variant?.selectedOption?.length !== index + 1 ? " - " : ""
										}`
								)}
							</Text>
						</Stack>
					),
					store: (
						<Link
							as={RouterLink}
							to={`/admin/stores/${product?.store?._id}`}
							color='blue.300'>
							{product?.store?.name}
						</Link>
					),
					sku: variant?.inventory?.sku,
					barcode: variant?.inventory?.barCode,
					lastModified: moment(variant?.updatedAt).format("LLL"),

					quantity: (
						<QuantityPopover
							quantity={variant.inventory?.qunatity || 0}
							productID={variant?._id}
						/>
					),

					action: (
						<Button
							variant='link'
							colorScheme='blue'
							leftIcon={<BiTime size={18} />}
							fontWeight='semibold'
							size='sm'
							onClick={() =>
								navigate(
									`/admin/inventory/${product._id}/variant/${variant._id}`,
									{
										state: {
											from: `/admin/inventory`,
											pageName: "Inventory",
										},
									}
								)
							}>
							History
						</Button>
					),
				});
			});
		});

		return list;
	};

	const getVendorProductsList = () => {
		let list: {
			id: any;
			image: JSX.Element;
			name: JSX.Element | string | null | undefined;
			sku: string | null | undefined;
			barcode: string | null | undefined;
			lastModified: string;
			quantity: JSX.Element;
			action: JSX.Element;
		}[] = [];
		productsVendorData?.GetCurrentSessionUserProducts?.products?.forEach(
			(product, index) => {
				list.push({
					id: product._id,
					image: (
						<Box className='table_image'>
							<img
								src={product.images?.[0] || picturePlaceholder}
								alt='product_profile_picture'
							/>
						</Box>
					),
					name: product?.name,
					sku: product?.inventory?.sku,
					barcode: product?.inventory?.barCode,
					lastModified: moment(product?.updatedAt).format("LLL"),

					quantity: (
						<QuantityPopover
							quantity={product.inventory?.qunatity || 0}
							productID={product?._id}
						/>
					),

					action: (
						<Button
							variant='link'
							colorScheme='blue'
							leftIcon={<BiTime size={18} />}
							fontWeight='semibold'
							size='sm'
							onClick={() =>
								navigate(`/${storeID}/inventory/${product._id}`, {
									state: {
										from: `/${storeID}/inventory`,
										pageName: "Inventory",
									},
								})
							}>
							History
						</Button>
					),
				});
				product.variations.forEach((variant, variantIndex) => {
					list.push({
						id: variant._id,
						image: (
							<Box className='table_image'>
								<img
									src={variant.images?.[0] || picturePlaceholder}
									alt='variant_profile_picture'
								/>
							</Box>
						),
						name: (
							<Stack>
								<Stack direction='row'>
									<Text whiteSpace='nowrap'>{product?.name}</Text>
									<Text opacity={0.6}>{`(Variant ${variantIndex})`}</Text>
								</Stack>
								<Text>
									{variant?.selectedOption?.map(
										(option, index) =>
											`${option.KEY}: ${option.VALUE}${
												variant?.selectedOption?.length !== index + 1
													? " - "
													: ""
											}`
									)}
								</Text>
							</Stack>
						),
						sku: variant?.inventory?.sku,
						barcode: variant?.inventory?.barCode,
						lastModified: moment(variant?.updatedAt).format("LLL"),

						quantity: (
							<QuantityPopover
								quantity={variant.inventory?.qunatity || 0}
								productID={variant?._id}
							/>
						),

						action: (
							<Button
								variant='link'
								colorScheme='blue'
								leftIcon={<BiTime size={18} />}
								fontWeight='semibold'
								size='sm'
								onClick={() =>
									navigate(
										`/${storeID}/inventory/${product._id}/variant/${variant._id}`,
										{
											state: {
												from: `/${storeID}/inventory`,
												pageName: "Inventory",
											},
										}
									)
								}>
								History
							</Button>
						),
					});
				});
			}
		);

		return list;
	};

	const productsAdminTableData = getAdminProductsList();

	type ProductsAdminTableData = typeof productsAdminTableData[number];

	const productsAdminTableHead: TableHeadType<ProductsAdminTableData>[] = [
		{ accessor: "image", label: "" },
		{ accessor: "name", label: "Name" },
		{ accessor: "store", label: "Store" },
		{ accessor: "sku", label: "Sku" },
		{ accessor: "barcode", label: "Barcode" },
		{ accessor: "lastModified", label: "Last modified" },
		{
			accessor: "quantity",
			label: "Quantity",
			headCellProps: { isNumeric: true },
		},
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	const productsVendorTableData = getVendorProductsList();

	type ProductsVendorTableData = typeof productsVendorTableData[number];

	const productsVendorTableHead: TableHeadType<ProductsVendorTableData>[] = [
		{ accessor: "image", label: "" },
		{ accessor: "name", label: "Name" },
		{ accessor: "sku", label: "Sku" },
		{ accessor: "barcode", label: "Barcode" },
		{ accessor: "lastModified", label: "Last modified" },
		{
			accessor: "quantity",
			label: "Quantity",
			headCellProps: { isNumeric: true },
		},
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Heading fontSize='2xl'>Inventory list</Heading>
					</Box>
				</Box>
			</Box>
			<Box className='page_actions'>
				<Input
					inputProps={{
						placeholder: "Search in inventory",
						onKeyUp: handleSearchSubmit,
					}}
					inputLeftElementProps={{ children: <BiSearch /> }}
				/>
				<Divider orientation='vertical' />
				<Button
					variant='outline'
					leftIcon={
						getFiltersCount() === 0 ? (
							<BiFilterAlt />
						) : (
							<Text
								bgColor='main.400'
								color='white'
								px='2'
								py='1'
								rounded='full'
								fontSize='sm'>
								{getFiltersCount()}
							</Text>
						)
					}
					onClick={onOpenFilter}>
					Filter
				</Button>
			</Box>
			{userRole === "VENDOR" ? (
				<Table
					head={productsVendorTableHead}
					data={productsVendorTableData}
					isLoading={productsVendorLoading}
					emptyState={
						<Box className='empty_table_container'>
							<BiBriefcase size={42} />
							<Text fontSize='md' fontWeight='medium'>
								{search?.search
									? `No results for "${search.search}"`
									: getFiltersCount() !== 0
									? "There is no products with these filters"
									: "There is no products yet."}
							</Text>
						</Box>
					}
					pagination={{
						count:
							productsVendorData?.GetCurrentSessionUserProducts.queryCount || 0,
						limit: pagination.limit,
						skip: pagination.skip,
						onPageChange: handlePageChange,
						onChangeLimit: handleLimitChange,
					}}
				/>
			) : (
				<Table
					head={productsAdminTableHead}
					data={productsAdminTableData}
					isLoading={productsAdminLoading}
					emptyState={
						<Box className='empty_table_container'>
							<BiBriefcase size={42} />
							<Text fontSize='md' fontWeight='medium'>
								{search?.search
									? `No results for "${search.search}"`
									: getFiltersCount() !== 0
									? "There is no products with these filters"
									: "There is no products yet."}
							</Text>
						</Box>
					}
					pagination={{
						count: productsAdminData?.GetAllProducts.queryCount || 0,
						limit: pagination.limit,
						skip: pagination.skip,
						onPageChange: handlePageChange,
						onChangeLimit: handleLimitChange,
					}}
				/>
			)}
			<ProductsFilterDrawer
				isOpen={openFilter}
				onClose={onCloseFilter}
				handleSubmitFilter={handleFilter}
				defaultValues={defaultFilterValues}
				handleResetFilter={handleResetFilter}
			/>
		</PageWrapper>
	);
};

export default Inventory;

type QuantityPopoverProps = {
	quantity: number;
	productID: string;
};

const QuantityPopover = (props: QuantityPopoverProps) => {
	const { quantity, productID } = props;

	const { isOpen, onOpen, onClose } = useDisclosure();

	const toast = useToast();

	const [updateQuantityMutation] = useUpdateInventoryQuantityMutation();

	const quantityUpdateFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			update: 0,
			reason: "STOCK_ENTRY",
		},
		onSubmit: (values, { setSubmitting }) => {
			updateQuantityMutation({
				variables: {
					activity: values.reason as InventoryActivity,
					_id: productID,
					quantity: quantity + quantityUpdateFormik.values.update,
				},
				onCompleted: (data) => {
					if (data.UpdateProductInventoryQuantity?._id) {
						setSubmitting(false);
						toast({
							title: "Inventory has been updated with success.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
						quantityUpdateFormik.setFieldValue("update", 0);
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while updating inventory.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	return (
		<Popover
			placement='bottom-start'
			isOpen={isOpen}
			onClose={onClose}
			onOpen={onOpen}
			isLazy>
			<PopoverTrigger>
				<Stack
					alignItems='center'
					justifyContent='flex-end'
					direction='row'
					w='full'>
					{quantityUpdateFormik.isSubmitting ? (
						<Spinner size='sm' />
					) : (
						<Text>{quantity + quantityUpdateFormik.values.update}</Text>
					)}
					<IconButton aria-label='edit-quantity-button' variant='ghost'>
						<BiSlider size={18} />
					</IconButton>
				</Stack>
			</PopoverTrigger>
			<PopoverContent width='220px' p='1.5' shadow='lg'>
				<PopoverBody>
					<form onSubmit={quantityUpdateFormik?.handleSubmit}>
						<Stack spacing='12px'>
							<NumberInput
								label='Update by'
								formLabelProps={{
									fontSize: "sm",
								}}
								numberInputProps={{
									allowMouseWheel: true,
									size: "sm",
									placeholder: "Update by",
									name: "update",
									onChange: (vString, vNumber) =>
										quantityUpdateFormik?.setFieldValue("update", vNumber),
									precision: 0,
									value: quantityUpdateFormik.values.update,
								}}
								helperText={`New quantity: ${
									quantity + quantityUpdateFormik.values.update
								}`}
							/>
							<SelectInput
								label='Reason'
								formLabelProps={{
									fontSize: "sm",
								}}
								options={[
									{
										label: "Stock entry",
										value: "STOCK_ENTRY",
									},
									{
										label: "Correction",
										value: "CORRECTION",
									},
									{
										label: "Count",
										value: "COUNT",
									},
									{
										label: "Received",
										value: "RECEIVED",
									},
									{
										label: "Return restock",
										value: "RETURN_RESTOCK",
									},
									{
										label: "Damaged",
										value: "DAMAGED",
									},
									{
										label: "Theft or loss",
										value: "THEFT_LOSS",
									},
									{
										label: "Promotion or donation",
										value: "PROMOTION_DONATION",
									},
								]}
								selectProps={{
									name: "reason",
									value: quantityUpdateFormik.values.reason,
									onChange: quantityUpdateFormik.handleChange,
									size: "sm",
								}}
							/>
							<Stack>
								<Button
									colorScheme='main'
									size='sm'
									type='submit'
									isLoading={quantityUpdateFormik.isSubmitting}>
									Save
								</Button>
								<Button variant='ghost' size='sm' onClick={onClose}>
									Discard
								</Button>
							</Stack>
						</Stack>
					</form>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
