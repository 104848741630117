import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Skeleton,
	Spacer,
	Stack,
	Text,
	Textarea,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { FormikErrors } from "formik";
import { useState } from "react";
import { BiBriefcaseAlt, BiPlus, BiTrash } from "react-icons/bi";
import ReactQuill from "react-quill";
import { apolloClient } from "../../..";
import {
	useAssignProductsToCategoryMutation,
	useGetCategoryProductsByIdQuery,
	useGetProductsToAssignQuery,
	useUnassginProductCategoryMutation,
} from "../../../api/generated/graphql";
import { picturePlaceholder } from "../../../assets";
import {
	Input,
	ImageUpload,
	SelectInput,
	TableHeadType,
	Table,
} from "../../../components";

const modules = {
	toolbar: [
		[{ header: "1" }, { header: "2" }, { font: [] }],
		[{ size: [] }],
		["bold", "italic", "underline", "strike", "blockquote"],
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" },
		],
		["link", "image"],
	],
	clipboard: {
		matchVisual: false,
	},
};

type GeneralCategoryFormProps = {
	onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
	values: {
		name: string;
		description: string;
		status: string;
	};
	handleChange: {
		(e: React.ChangeEvent<any>): void;
		<T_1 = string | React.ChangeEvent<any>>(
			field: T_1
		): T_1 extends React.ChangeEvent<any>
			? void
			: (e: string | React.ChangeEvent<any>) => void;
	};
	errors: FormikErrors<{
		name: string;
		description: string;
		status: string;
	}>;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) =>
		| Promise<void>
		| Promise<
				FormikErrors<{
					name: string;
					description: string;
					status: string;
				}>
		  >;
	files: {
		progress: boolean;
		id: string;
		src: string | File | undefined;
	}[];
	handleFile: (file: File) => Promise<void>;
	handleDelete: (id: string) => void;
	isLoading: boolean;
};

const GeneralCategoryFormLoading = () => {
	return (
		<Stack spacing='14px' opacity={0.4}>
			<Skeleton height='245px' width='470px' />
			<Stack direction='row'>
				<Skeleton height='32px' width='full' />
				<Skeleton height='32px' width='420px' />
			</Stack>
			<Skeleton height='310px' />
		</Stack>
	);
};

export const GeneralCategoryForm = (props: GeneralCategoryFormProps) => {
	const {
		onSubmit,
		values,
		handleChange,
		errors,
		setFieldValue,
		files,
		handleFile,
		handleDelete,
		isLoading,
	} = props;

	return (
		<form onSubmit={onSubmit} id='update-general-category-form'>
			{isLoading ? (
				<GeneralCategoryFormLoading />
			) : (
				<Stack spacing='14px'>
					<ImageUpload
						height='230px'
						width='470px'
						images={files}
						handleUpload={handleFile}
						handleDelete={handleDelete}
						label='Category image'
					/>
					<Stack direction='row'>
						<Input
							label='Category name'
							inputProps={{
								placeholder: "Category name",
								name: "name",
								onChange: handleChange,
								value: values.name,
							}}
							errorMessage={errors.name}
							isError={errors.name !== undefined}
						/>
						<Spacer />
						<SelectInput
							formControlProps={{
								w: "420px",
							}}
							options={[
								{ label: "Draft", value: "DRAFT" },
								{ label: "Published", value: "PUBLISHED" },
							]}
							label='Status'
							selectProps={{
								name: "status",
								value: values.status,
								onChange: handleChange,
							}}
							errorMessage={errors.status}
							isError={errors.status !== undefined}
						/>
					</Stack>
					<Box>
						<FormLabel marginBottom='1'>Description</FormLabel>
						<ReactQuill
							value={values.description}
							onChange={(content) => setFieldValue("description", content)}
							modules={modules}
							placeholder='Describe this category'
						/>
					</Box>
				</Stack>
			)}
		</form>
	);
};

export const ProductCategory = (props: { categoryID?: string | null }) => {
	const { categoryID } = props;

	const [pagination, setPagination] = useState({
		limit: 10,
		skip: 0,
	});

	const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

	const handleSelectProduct = (id: string) => {
		setSelectedProducts((prevState) => [...prevState, id]);
	};

	const handleRemoveSelectedProduct = (id: string) => {
		setSelectedProducts((prevState) => {
			let temp = [...prevState];
			const index = temp.indexOf(id, 0);
			if (index > -1) {
				temp.splice(index, 1);
			}
			return temp;
		});
	};

	const handlePageChange = (dir: "next" | "prev") => {
		if (dir === "next") {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip + temp.limit;
				return temp;
			});
		} else {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip - temp.limit;
				return temp;
			});
		}
	};

	const handleLimitChange = (value: number) => {
		setPagination((prevState) => {
			let temp = { ...prevState };
			temp.limit = +value;
			return temp;
		});
	};

	const [modalPagination, setModalPagination] = useState({
		limit: 10,
		skip: 0,
	});

	const handleModalPageChange = (dir: "next" | "prev") => {
		if (dir === "next") {
			setModalPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip + temp.limit;
				return temp;
			});
		} else {
			setModalPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip - temp.limit;
				return temp;
			});
		}
	};

	const handleModalLimitChange = (value: number) => {
		setModalPagination((prevState) => {
			let temp = { ...prevState };
			temp.limit = +value;
			return temp;
		});
	};

	const [unassignProductMutation, unassignProductsParam] =
		useUnassginProductCategoryMutation();

	const handleUnassignProduct = (id: string) => {
		unassignProductMutation({
			variables: {
				product_id: id,
			},
			onCompleted: (data) => {
				if (data.UnassginProductCategory.code) {
					toast({
						title: "Products has has unassigned from category with success.",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					apolloClient.refetchQueries({
						include: "active",
					});
				}
			},
			onError: (error) => {
				toast({
					title: "An error occured while updating category.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	const { data: productListData, loading: productListLoading } =
		useGetCategoryProductsByIdQuery({
			variables: {
				id: categoryID,
				pagination: pagination,
			},
		});

	const categoryProductsTableData =
		productListData?.GetAllProducts?.products?.map((product, index) => ({
			id: product._id,
			image: (
				<Box className='table_image'>
					<img
						src={product.images?.[0] || picturePlaceholder}
						alt='product_profile_picture'
					/>
				</Box>
			),
			name: product?.name,
			sku: product.inventory?.sku,
			store: product?.store?.name,
			action: (
				<IconButton
					size='sm'
					aria-label='unassign_product'
					variant='ghost'
					colorScheme='red'
					isLoading={unassignProductsParam.loading}
					onClick={() => handleUnassignProduct(product._id)}>
					<BiTrash size={18} />
				</IconButton>
			),
		})) || [];

	type CategoryProductTableType = typeof categoryProductsTableData[number];

	const categoryProductsTableHead: TableHeadType<CategoryProductTableType>[] = [
		{ accessor: "image", label: "" },
		{ accessor: "name", label: "Name" },
		{ accessor: "sku", label: "SKU" },
		{ accessor: "store", label: "store" },
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	const { isOpen, onOpen, onClose } = useDisclosure();

	const { data: productToAssignListData, loading: productToAssignListLoading } =
		useGetProductsToAssignQuery({
			variables: {
				pagination: pagination,
				selected_category: categoryID,
			},
			skip: !isOpen,
		});

	const productsToAssignTableData =
		productToAssignListData?.GetAllProducts?.products?.map(
			(product, index) => ({
				id: product._id,
				check: (
					<Checkbox
						value={product._id}
						onChange={(e) => {
							if (e.target.checked) {
								handleSelectProduct(e.target.value);
							} else {
								handleRemoveSelectedProduct(e.target.value);
							}
						}}
					/>
				),
				name: product?.name,
				sku: product?.inventory?.sku,
				category: product?.category?.name,
				store: product?.store?.name,
			})
		) || [];

	type ProductsToAssignTableType = typeof productsToAssignTableData[number];

	const productsToAssignTableHead: TableHeadType<ProductsToAssignTableType>[] =
		[
			{ accessor: "check", label: "" },
			{ accessor: "name", label: "Name" },
			{ accessor: "sku", label: "SKU" },
			{ accessor: "category", label: "Category" },
			{ accessor: "store", label: "store" },
		];

	const toast = useToast();

	const handleCloseModal = () => {
		setSelectedProducts([]);
		onClose();
	};

	const [assignProductsMutation, assignProductsParm] =
		useAssignProductsToCategoryMutation();

	const handleAssignProduct = () => {
		assignProductsMutation({
			variables: {
				categeory_id: categoryID,
				products: selectedProducts,
			},
			onCompleted: (data) => {
				if (data.AssignProductsCategory.code) {
					toast({
						title: "Products has has been assigned to category.",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					apolloClient.refetchQueries({
						include: "active",
					});
					handleCloseModal();
				}
			},
			onError: (error) => {
				toast({
					title: "An error occured while updating category.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	return (
		<Stack spacing='14px'>
			<Flex direction='row' justifyContent='space-between'>
				<Heading size='md'>Category products</Heading>
				<Button variant='outline' size='sm' onClick={onOpen}>
					Assign products to category
				</Button>
			</Flex>
			<Table
				head={categoryProductsTableHead}
				data={categoryProductsTableData}
				isLoading={productListLoading}
				emptyState={
					<Box className='empty_table_container'>
						<BiBriefcaseAlt size={42} />
						<Text fontSize='md' fontWeight='medium'>
							There is no assigned products yet!
						</Text>
					</Box>
				}
				pagination={{
					count: productListData?.GetAllProducts.queryCount || 0,
					limit: pagination.limit,
					skip: pagination.skip,
					onPageChange: handlePageChange,
					onChangeLimit: handleLimitChange,
				}}
			/>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				scrollBehavior='inside'
				size='2xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader borderBottomWidth='1px'>Modal Title</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing='14px' py='14px'>
							<Heading size='md'>
								Select products wish you want to assign to category
							</Heading>
							<Box>
								{selectedProducts.length !== 0 && (
									<Text fontSize='sm' opacity={0.6}>
										{selectedProducts.length} Selected
									</Text>
								)}
								<Table
									head={productsToAssignTableHead}
									data={productsToAssignTableData}
									isLoading={productToAssignListLoading}
									emptyState={
										<Box className='empty_table_container'>
											<BiBriefcaseAlt size={42} />
											<Text fontSize='md' fontWeight='medium'>
												There is no products!
											</Text>
										</Box>
									}
									pagination={{
										count:
											productToAssignListData?.GetAllProducts.queryCount || 0,
										limit: modalPagination.limit,
										skip: modalPagination.skip,
										onPageChange: handleModalPageChange,
										onChangeLimit: handleModalLimitChange,
									}}
								/>
							</Box>
						</Stack>
					</ModalBody>

					<ModalFooter borderTopWidth='1px'>
						<Button variant='Outline' onClick={onClose}>
							Cancel
						</Button>
						<Button
							colorScheme='main'
							onClick={handleAssignProduct}
							isLoading={assignProductsParm.loading}>
							Assign products
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Stack>
	);
};

type SEOCategoryFormProps = {
	onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
	values: {
		seotitle: string;
		slug: string;
		metadescription: string;
	};
	handleChange: {
		(e: React.ChangeEvent<any>): void;
		<T_1 = string | React.ChangeEvent<any>>(
			field: T_1
		): T_1 extends React.ChangeEvent<any>
			? void
			: (e: string | React.ChangeEvent<any>) => void;
	};
	errors: FormikErrors<{
		seotitle: string;
		slug: string;
		metadescription: string;
	}>;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) =>
		| Promise<void>
		| Promise<
				FormikErrors<{
					seotitle: string;
					slug: string;
					metadescription: string;
				}>
		  >;
	isLoading: boolean;
	metaTags: { key?: string | null; value?: string | null }[];
	handleChangeMetaTags: (
		value: string,
		index: number,
		type: "key" | "value"
	) => void;
	handleAddMetatag: () => void;
	handleDeleteMetaTag: (index: number) => void;
};

export const SEOCategoryForm = (props: SEOCategoryFormProps) => {
	const {
		errors,
		handleChange,
		isLoading,
		onSubmit,
		values,
		metaTags,
		handleChangeMetaTags,
		handleAddMetatag,
		handleDeleteMetaTag,
	} = props;

	return (
		<form onSubmit={onSubmit} id='update-seo-category-form'>
			{isLoading ? (
				<GeneralCategoryFormLoading />
			) : (
				<Stack spacing='14px'>
					<Box>
						<Heading size='md'>SEO</Heading>
						<Text fontSize='sm' opacity={0.4}>
							Add a title and description to see how this product might appear
							in a search engine listing.
						</Text>
					</Box>
					<Input
						label='Page title'
						inputProps={{
							placeholder: "Page title",
							name: "seotitle",
							onChange: handleChange,
							value: values.seotitle,
						}}
						errorMessage={errors.seotitle}
						isError={errors.seotitle !== undefined}
					/>
					<Input
						label='Page slug (read only)'
						inputProps={{
							placeholder: "Page slug",
							name: "slug",
							onChange: handleChange,
							value: values.slug,
							isReadOnly: true,
						}}
						inputLeftAddon={{
							children: process.env.REACT_APP_MARKETPLACE_URL + "category/",
						}}
						errorMessage={errors.slug}
						isError={errors.slug !== undefined}
					/>
					<FormControl isInvalid={errors.metadescription !== undefined}>
						<FormLabel marginBottom='1'>Meta description</FormLabel>
						<Textarea
							placeholder='Meta Description'
							name='metadescription'
							onChange={handleChange}
							value={values.metadescription}
						/>
						{errors.metadescription && (
							<FormErrorMessage>{errors.metadescription}</FormErrorMessage>
						)}
					</FormControl>
					<Box>
						<FormLabel>Meta tags</FormLabel>
						{metaTags.map((tag, index) => (
							<Box>
								<Text>Tag {index + 1}</Text>
								<Stack
									mb='12px'
									direction='row'
									alignItems='flex-end'
									spacing='14px'>
									<Input
										label='Key'
										inputProps={{
											placeholder: "Key",
											onChange: (e) =>
												handleChangeMetaTags(e.target.value, index, "key"),
											value: tag.key || "",
										}}
									/>
									<Input
										label='Value'
										inputProps={{
											placeholder: "Value",
											onChange: (e) =>
												handleChangeMetaTags(e.target.value, index, "value"),
											value: tag.value || "",
										}}
									/>
									<IconButton
										variant='ghost'
										colorScheme='red'
										aria-label='delete_meta-tags'
										onClick={() => handleDeleteMetaTag(index)}>
										<BiTrash size='18' />
									</IconButton>
								</Stack>
							</Box>
						))}
						<Button
							onClick={handleAddMetatag}
							variant='outline'
							leftIcon={<BiPlus />}
							isFullWidth>
							Add a meta tag
						</Button>
					</Box>
				</Stack>
			)}
		</form>
	);
};
