import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const LayoutWrapper = styled(Box)`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: start;
	justify-content: start;
	.app_container {
		height: 100%;
		width: 100%;
		overflow-y: auto;
		overflow-x: none;
		.app_content {
			padding: 8px 18px;
		}
	}
`;
