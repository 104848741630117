import {
	Box,
	Heading,
	Stack,
	useColorModeValue,
	Text,
	Skeleton,
	useToast,
	Button,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { BiReset } from "react-icons/bi";
import { apolloClient } from "../..";
import {
	useGetAllCountriesQuery,
	useGetProfileInfoQuery,
	useUpdateProfileSettingsMutation,
} from "../../api/generated/graphql";
import {
	DateTimeInput,
	DropDownInput,
	ImageUpload,
	Input,
} from "../../components";
import SettingsWrapper from "../../components/SettingsWrapper/SettingsWrapper";
import { getDataFromCache } from "../../utils/cache";
import { CacheUserDataType } from "../../utils/globalTypes";

const ProfileSettings = () => {
	const sectionBgColor = useColorModeValue("white", "gray.900");

	const userCacheData: CacheUserDataType = getDataFromCache("user_data");

	const toast = useToast();

	const [files, setFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const handleFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append(
			"public_id",
			userCacheData._id
				? `${userCacheData._id}/${file.name}`
				: `no_ID/${file.name}`
		);
		setFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleDelete = (id: string) => {
		setFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	const { data: userData, loading: userLoading } = useGetProfileInfoQuery();

	const [updateProfileMutation] = useUpdateProfileSettingsMutation();

	const profileFormInitialValues: {
		email: string;
		firstName: string;
		lastName: string;
		nationality: string;
		phoneNumber: string;
		phonePrefix: string;
		zipCode: string;
		city: string;
		country: string;
		streetAddress: string;
		apartment: string;
		dateOfBirth: Date | null;
	} = {
		email: "",
		firstName: "",
		lastName: "",
		nationality: "",
		phoneNumber: "",
		phonePrefix: "",
		zipCode: "",
		city: "",
		country: "",
		streetAddress: "",
		apartment: "",
		dateOfBirth: null,
	};

	const profileFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: profileFormInitialValues,
		validate: (values) => {
			const errors: FormikErrors<typeof values> = {};
			if (!values.email) {
				errors.email = "Email is required";
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
			) {
				errors.email = "Invalid email address";
			}
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			updateProfileMutation({
				variables: {
					apartment: values.apartment,
					avatar: files?.[0]?.src ? (files?.[0]?.src as string) : "",
					city: values.city,
					country: values.country,
					dateOfBirth: values.dateOfBirth
						? moment(values.dateOfBirth).format()
						: null,
					email: values.email,
					firstName: values.firstName,
					lastName: values.lastName,
					nationality: values.nationality,
					phoneNumber: values.phoneNumber.toString(),
					phonePrefix: values.phonePrefix.toString(),
					streetAddress: values.streetAddress,
					zipCode: values.zipCode,
				},
				onCompleted: (data) => {
					if (data.UpdateCurrentSessionUser.code) {
						setSubmitting(false);
						toast({
							title: "Your profile has been updated.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
					}
				},
				onError: (error) => {
					setSubmitting(false);
					if (error.networkError) {
						toast({
							title: "Network error.",
							description:
								"Please check your internet connection, or refresh the page",
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
					} else {
						toast({
							title: "An error occured while updating your profile.",
							description: error.message,
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
					}
				},
			});
		},
	});

	const { data: countriesData, loading: countriesLoading } =
		useGetAllCountriesQuery();

	const [countriesList, setCountriesList] = useState<
		{
			render: ReactNode;
			value: string;
		}[]
	>([]);

	const [prefixList, setPrefixList] = useState<
		{
			render: ReactNode;
			value: string;
		}[]
	>([]);

	useEffect(() => {
		setCountriesList(
			countriesData?.GetAllCountries.map((country) => ({
				value: country.name || "",
				render: (
					<Stack direction='row' spacing='10px' alignItems='center'>
						<img
							className='select_country_image'
							src={country.flag || ""}
							alt={country.name || "countryflag"}
						/>{" "}
						<Text>{country.name}</Text>
					</Stack>
				),
			})) || []
		);
		setPrefixList(
			countriesData?.GetAllCountries.map((country) => ({
				value: country.dialCode || "",
				render: (
					<Stack direction='row' spacing='10px' alignItems='center'>
						<img
							className='select_country_image'
							src={country.flag || ""}
							alt={country.dialCode || "countryflag"}
						/>{" "}
						<Text>{country.dialCode}</Text>
					</Stack>
				),
			})) || []
		);
	}, [countriesData]);

	const handleResetForm = () => {
		profileFormik.setValues({
			email: userData?.GetCurrentSessionUser?.email || "",
			firstName: userData?.GetCurrentSessionUser?.firstName || "",
			lastName: userData?.GetCurrentSessionUser?.lastName || "",
			nationality: userData?.GetCurrentSessionUser?.nationality || "",
			phoneNumber:
				userData?.GetCurrentSessionUser?.userAddress?.[0]?.phoneNumber
					?.number || "",
			phonePrefix:
				userData?.GetCurrentSessionUser?.userAddress?.[0]?.phoneNumber
					?.prefix || "",
			zipCode: userData?.GetCurrentSessionUser?.userAddress?.[0]?.zipCode || "",
			city: userData?.GetCurrentSessionUser?.userAddress?.[0]?.city || "",
			country: userData?.GetCurrentSessionUser?.userAddress?.[0]?.country || "",
			streetAddress:
				userData?.GetCurrentSessionUser?.userAddress?.[0]?.streetAddress || "",
			apartment:
				userData?.GetCurrentSessionUser?.userAddress?.[0]?.apartment || "",
			dateOfBirth: userData?.GetCurrentSessionUser?.dateOfBirth
				? moment(userData?.GetCurrentSessionUser?.dateOfBirth).toDate()
				: null,
		});
		setFiles(
			userData?.GetCurrentSessionUser?.avatar
				? [
						{
							progress: false,
							id: userData?.GetCurrentSessionUser?._id,
							src: userData?.GetCurrentSessionUser?.avatar,
						},
				  ]
				: []
		);
	};

	useEffect(() => {
		profileFormik.setValues({
			email: userData?.GetCurrentSessionUser?.email || "",
			firstName: userData?.GetCurrentSessionUser?.firstName || "",
			lastName: userData?.GetCurrentSessionUser?.lastName || "",
			nationality: userData?.GetCurrentSessionUser?.nationality || "",
			phoneNumber:
				userData?.GetCurrentSessionUser?.userAddress?.[0]?.phoneNumber
					?.number || "",
			phonePrefix:
				userData?.GetCurrentSessionUser?.userAddress?.[0]?.phoneNumber
					?.prefix || "",
			zipCode: userData?.GetCurrentSessionUser?.userAddress?.[0]?.zipCode || "",
			city: userData?.GetCurrentSessionUser?.userAddress?.[0]?.city || "",
			country: userData?.GetCurrentSessionUser?.userAddress?.[0]?.country || "",
			streetAddress:
				userData?.GetCurrentSessionUser?.userAddress?.[0]?.streetAddress || "",
			apartment:
				userData?.GetCurrentSessionUser?.userAddress?.[0]?.apartment || "",
			dateOfBirth: userData?.GetCurrentSessionUser?.dateOfBirth
				? moment(userData?.GetCurrentSessionUser?.dateOfBirth).toDate()
				: null,
		});
		setFiles(
			userData?.GetCurrentSessionUser?.avatar
				? [
						{
							progress: false,
							id: userData?.GetCurrentSessionUser?._id,
							src: userData?.GetCurrentSessionUser?.avatar,
						},
				  ]
				: []
		);
	}, [userData]);

	return (
		<SettingsWrapper>
			<form onSubmit={profileFormik.handleSubmit}>
				<Heading size='lg' fontSize='2xl' fontWeight='medium'>
					Profile settings
				</Heading>
				<Stack marginTop='24px' spacing='20px'>
					<Box
						p='16px'
						bgColor={sectionBgColor}
						width='100%'
						shadow='md'
						rounded='base'>
						<Heading size='md' fontSize='xl' fontWeight='medium'>
							General details
						</Heading>
						<Stack mt='4'>
							<Skeleton isLoaded={!userLoading} w='160px'>
								<ImageUpload
									height='140px'
									width='140px'
									images={files}
									handleUpload={handleFile}
									handleDelete={handleDelete}
									label='Profile picture'
								/>
							</Skeleton>
							<Stack direction='row' spacing='14px'>
								<Skeleton isLoaded={!userLoading} w='full'>
									<Input
										label='First name'
										inputProps={{
											placeholder: "First name",
											name: "firstName",
											value: profileFormik.values.firstName,
											onChange: profileFormik.handleChange,
										}}
									/>
								</Skeleton>
								<Skeleton isLoaded={!userLoading} w='full'>
									<Input
										label='Last name'
										inputProps={{
											placeholder: "Last name",
											name: "lastName",
											value: profileFormik.values.lastName,
											onChange: profileFormik.handleChange,
										}}
									/>
								</Skeleton>
							</Stack>
							<Stack direction='row' spacing='14px'>
								<Skeleton isLoaded={!userLoading} w='full'>
									<DateTimeInput
										name='dateOfBirth'
										label='Date of birth'
										onChange={(date) =>
											profileFormik.setFieldValue("dateOfBirth", date)
										}
										selected={profileFormik.values?.dateOfBirth}
										errorMessage={profileFormik.errors.dateOfBirth}
										isError={profileFormik.errors.dateOfBirth !== undefined}
									/>
								</Skeleton>
								<Skeleton isLoaded={!userLoading} w='full'>
									<DropDownInput
										label='Nationality'
										placeholder='Nationality'
										name='nationality'
										selected={profileFormik.values.nationality}
										errorMessage={profileFormik.errors.nationality}
										list={countriesList}
										isLoading={countriesLoading}
										onSelect={(value) =>
											profileFormik.setFieldValue("nationality", value)
										}
									/>
								</Skeleton>
							</Stack>
							<Skeleton isLoaded={!userLoading} w='full'>
								<Input
									label='Email'
									inputProps={{
										placeholder: "Email",
										name: "email",
										value: profileFormik.values.email,
										onChange: profileFormik.handleChange,
									}}
									errorMessage={profileFormik.errors.email}
									isError={profileFormik.errors.email !== undefined}
								/>
							</Skeleton>
						</Stack>
					</Box>
					<Box
						p='16px'
						bgColor={sectionBgColor}
						width='100%'
						shadow='md'
						rounded='base'>
						<Heading size='md' fontSize='xl' fontWeight='medium'>
							Your personal address
						</Heading>
						<Stack mt='4'>
							<Skeleton isLoaded={!userLoading}>
								<Stack direction='row' spacing='18px'>
									<Input
										label='Address'
										inputProps={{
											placeholder: "Address",
											name: "streetAddress",
											onChange: profileFormik.handleChange,
											value: profileFormik.values.streetAddress || "",
										}}
										errorMessage={profileFormik.errors.streetAddress}
										isError={profileFormik.errors.streetAddress !== undefined}
									/>
									<Input
										label='Apartment, suite, etc.'
										inputProps={{
											placeholder: "Apartment, suite, etc.",
											name: "apartment",
											onChange: profileFormik.handleChange,
											value: profileFormik.values.apartment,
										}}
										errorMessage={profileFormik.errors.apartment}
										isError={profileFormik.errors.apartment !== undefined}
									/>
								</Stack>
							</Skeleton>

							<Skeleton isLoaded={!userLoading}>
								<Stack direction='row' spacing='16px'>
									<DropDownInput
										label='Country'
										placeholder='Country'
										name='country'
										selected={profileFormik.values.country}
										errorMessage={profileFormik.errors.country}
										list={countriesList}
										isLoading={countriesLoading}
										onSelect={(value) =>
											profileFormik.setFieldValue("country", value)
										}
									/>
									<Input
										label='City'
										inputProps={{
											placeholder: "City",
											name: "city",
											onChange: profileFormik.handleChange,
											value: profileFormik.values.city,
										}}
										errorMessage={profileFormik.errors.city}
										isError={profileFormik.errors.city !== undefined}
									/>
								</Stack>
							</Skeleton>
							<Skeleton isLoaded={!userLoading}>
								<Stack direction='row' spacing='16px'>
									<Input
										label='Phone number'
										inputProps={{
											placeholder: "Phone number",
											type: "number",
											name: "phoneNumber",
											onChange: profileFormik.handleChange,
											value: profileFormik.values.phoneNumber,
										}}
										errorMessage={
											profileFormik.errors.phoneNumber ||
											profileFormik.errors.phonePrefix
										}
										isError={
											profileFormik.errors.phoneNumber !== undefined ||
											profileFormik.errors.phonePrefix !== undefined
										}
										inputLeftAddon={{
											background: "transparent",
											p: 0,
											border: "none",
											children: (
												<Box w='100px'>
													<DropDownInput
														name='phonePrefix'
														placeholder='Prefix'
														selected={profileFormik.values.phonePrefix}
														list={prefixList}
														isLoading={countriesLoading}
														onSelect={(value) =>
															profileFormik.setFieldValue("phonePrefix", value)
														}
													/>
												</Box>
											),
										}}
									/>
									<Input
										label='Zip/Postal code'
										inputProps={{
											placeholder: "Zip/Postal code",
											name: "zipCode",
											value: profileFormik.values.zipCode,
											onChange: profileFormik.handleChange,
										}}
										errorMessage={profileFormik.errors.zipCode}
										isError={profileFormik.errors.zipCode !== undefined}
									/>
								</Stack>
							</Skeleton>
						</Stack>
					</Box>
					<Box textAlign='right'>
						<Button
							onClick={handleResetForm}
							leftIcon={<BiReset size={18} />}
							mr={4}>
							Reset
						</Button>
						<Button
							colorScheme='main'
							type='submit'
							isLoading={profileFormik?.isSubmitting}>
							Save changes
						</Button>
					</Box>
				</Stack>
			</form>
		</SettingsWrapper>
	);
};

export default ProfileSettings;
