import {
	Box,
	Button,
	Divider,
	FormLabel,
	Heading,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Skeleton,
	Stack,
	Switch,
	Tag,
	Text,
	useColorModeValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { TypeOfTag } from "typescript";
import { apolloClient } from "../../..";
import {
	UpdateProductOptionsMutationVariables,
	useGetProductOptionsByIdQuery,
	useUpdateProductOptionsMutation,
} from "../../../api/generated/graphql";
import {
	CategorySelectInput,
	TagsSelectInput,
	Input,
	Table,
	TableHeadType,
	SelectInput,
} from "../../../components";
import { getDataFromCache } from "../../../utils/cache";

type ProductOptionsFormProps = {
	actionButtons: (node: ReactNode) => void;
	productID?: string;
};

const ProductOptionsForm = (props: ProductOptionsFormProps) => {
	const { productID, actionButtons } = props;

	const borderColor = useColorModeValue("gray.100", "gray.700");

	const toast = useToast();

	const [updateOptionsMutation] = useUpdateProductOptionsMutation();

	const { data: optionsProductData, loading: optionsProductLoading } =
		useGetProductOptionsByIdQuery({
			variables: {
				product_id: productID,
			},
		});

	const optionsProductInitialValues: Omit<
		UpdateProductOptionsMutationVariables,
		"tags" | "product_id" | "category" | "options"
	> & {
		category: {
			id: string;
			label: string;
		};
		tags: string[];
		options: {
			inputType: string;
			isActive: boolean;
			name: string;
			values: string[];
		}[];
	} = {
		brand: "",
		isAvilable: true,
		options: [],
		category: {
			id: "",
			label: "",
		},
		tags: [],
	};

	const optionsProductFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: optionsProductInitialValues,
		// validate: (values) => {
		// 	const errors: FormikErrors<typeof values> = {};
		// 	if (!values.name) {
		// 		errors.name = "Product name is required!";
		// 	}
		// 	return errors;
		// },
		onSubmit: (values, { setSubmitting }) => {
			updateOptionsMutation({
				variables: {
					product_id: productID,
					brand: values.brand || undefined,
					category: values.category.id || undefined,
					options: values.options || undefined,
					isAvilable: values.isAvilable,
					tags: values.tags || undefined,
				},
				onCompleted: (data) => {
					if (data.UpdateProduct?._id) {
						setSubmitting(false);
						toast({
							title: "Product options has been updated with success.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while updating product.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	const handleDeleteOption = (index: number) => {
		let temp = [...optionsProductFormik.values.options];
		temp.splice(index, 1);

		return temp;
	};

	const optionsTableData =
		optionsProductFormik?.values?.options?.map((option, index) => ({
			optionName: option?.name,
			inputType: option?.inputType,
			values: (
				<Stack direction='row'>
					{option?.values?.map((value, index) => (
						<Tag key={value + index}>{value}</Tag>
					))}
				</Stack>
			),
			// status: (
			// 	<Text color={option.isActive ? "green.400" : undefined}>
			// 		{option.isActive ? "Active" : "Deactivated"}
			// 	</Text>
			// ),
			action: (
				<IconButton
					aria-label='delete-option-button'
					colorScheme='error'
					variant='ghost'
					onClick={() =>
						optionsProductFormik.setFieldValue(
							"options",
							handleDeleteOption(index)
						)
					}>
					<BiTrash size={18} />
				</IconButton>
			),
		})) || [];

	type OptionsTableData = typeof optionsTableData[number];

	const optionsTableHead: TableHeadType<OptionsTableData>[] = [
		{ accessor: "optionName", label: "Option name" },
		{ accessor: "inputType", label: "Input type" },
		{ accessor: "values", label: "Values" },
		// { accessor: "status", label: "Status" },
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	useEffect(() => {
		optionsProductFormik.setValues({
			brand: optionsProductData?.GetProductById?.brand || "",
			isAvilable: optionsProductData?.GetProductById?.isAvilable,
			options:
				optionsProductData?.GetProductById?.options.map((option, index) => ({
					inputType: option.inputType || "",
					isActive: option.isActive ? true : false,
					name: option.name || "",
					values: option.values || [],
				})) || [],
			category: {
				id: optionsProductData?.GetProductById?.category?._id || "",
				label: optionsProductData?.GetProductById?.category?.name || "",
			},
			tags: optionsProductData?.GetProductById.tags || [],
		});
	}, [optionsProductData]);

	const userRole: string = getDataFromCache("user_role", true);

	const navigate = useNavigate();

	const storeID = getDataFromCache("store_id", true);

	useEffect(() => {
		actionButtons(
			<>
				<Button
					variant='outline'
					onClick={() =>
						navigate(
							userRole === "ADMIN" ? `/admin/products` : `/${storeID}/products`,
							{
								state: {
									pageName: "Products",
								},
							}
						)
					}>
					Discard
				</Button>
				<Button
					colorScheme='main'
					type='submit'
					form='update-product-options'
					isLoading={optionsProductFormik.isSubmitting}>
					Save
				</Button>
			</>
		);
	}, [optionsProductFormik.isSubmitting]);

	const {
		isOpen: isOpenOptionModal,
		onOpen: onOpenOptionModal,
		onClose: onCloseOptionModal,
	} = useDisclosure();

	return (
		<>
			<form
				id='update-product-options'
				onSubmit={optionsProductFormik.handleSubmit}>
				<Stack direction='row' spacing='14px' alignItems='flex-start'>
					<Stack flex={0.75} spacing='12px'>
						<Skeleton
							isLoaded={!optionsProductLoading}
							opacity={optionsProductLoading ? 0.4 : 1}>
							<Stack
								padding='18px 18px'
								border='1px'
								borderColor={borderColor}
								rounded='base'>
								<Stack
									width='full'
									direction='row'
									justifyContent='space-between'>
									<Box>
										<Heading size='md' mb='2'>
											Options
										</Heading>
										<Text
											opacity={0.6}
											lineHeight='1.2'
											fontSize='sm'
											width='650px'>
											Product Option is used for products that has different
											characteristics. Size and color are common Examples of
											product options. Options can be used with variation to
											create a more detailed product and offer more diversity
											for your customers.
										</Text>
									</Box>
									<Button
										onClick={onOpenOptionModal}
										colorScheme='main'
										size='sm'>
										Add new option
									</Button>
								</Stack>
								<Table
									head={optionsTableHead}
									data={optionsTableData}
									isLoading={optionsProductLoading}
									emptyState={
										<Box className='empty_table_container'>
											<Text fontSize='md' fontWeight='medium'>
												Product has no options
											</Text>
										</Box>
									}
								/>
							</Stack>
						</Skeleton>
					</Stack>
					<Stack flex={0.25} spacing='12px'>
						<Skeleton
							isLoaded={!optionsProductLoading}
							opacity={optionsProductLoading ? 0.4 : 1}>
							<Stack
								padding='18px 24px'
								border='1px'
								borderColor={borderColor}
								rounded='base'>
								<Input
									label='Brand'
									inputProps={{
										placeholder: "Brand",
										name: "brand",
										onChange: optionsProductFormik.handleChange,
										value: optionsProductFormik.values.brand || "",
									}}
									errorMessage={optionsProductFormik.errors.brand}
									isError={optionsProductFormik.errors.brand !== undefined}
								/>
								<CategorySelectInput
									selected={optionsProductFormik.values.category}
									handleSelectCategory={(id, label) =>
										optionsProductFormik.setFieldValue("category", {
											id,
											label,
										})
									}
								/>
								<Box paddingTop='12px' paddingBottom='6px'>
									<Divider color={borderColor} />
								</Box>
								<TagsSelectInput
									tags={optionsProductFormik.values.tags}
									onCreateTag={(value) =>
										optionsProductFormik.setFieldValue("tags", [
											value,
											...optionsProductFormik.values.tags,
										])
									}
									onDeleteTag={(index) => {
										let tempValues = [...optionsProductFormik.values.tags];
										tempValues.splice(index, 1);
										optionsProductFormik.setFieldValue("tags", tempValues);
									}}
								/>
							</Stack>
						</Skeleton>
						<Skeleton
							isLoaded={!optionsProductLoading}
							opacity={optionsProductLoading ? 0.4 : 1}>
							<Stack
								padding='18px 24px'
								border='1px'
								borderColor={borderColor}
								rounded='base'>
								<FormLabel>Product availability</FormLabel>
								<Stack direction='row' alignItems='center'>
									<Switch
										size='md'
										colorScheme='green'
										name='isAvilable'
										onChange={optionsProductFormik.handleChange}
										isChecked={
											optionsProductFormik.values.isAvilable !== null &&
											optionsProductFormik.values.isAvilable
										}
									/>
									<Text
										color={
											optionsProductFormik.values.isAvilable
												? "green.400"
												: undefined
										}>
										{optionsProductFormik.values.isAvilable
											? "Available"
											: "Not available"}
									</Text>
								</Stack>
							</Stack>
						</Skeleton>
					</Stack>
				</Stack>
			</form>
			<Modal
				isOpen={isOpenOptionModal}
				onClose={onCloseOptionModal}
				isCentered
				scrollBehavior='inside'>
				<ModalOverlay />
				<CreateOptionModal
					onClose={onCloseOptionModal}
					onSubmit={(value) =>
						optionsProductFormik.setFieldValue("options", [
							...optionsProductFormik.values.options,
							{
								inputType: value.inputType,
								isActive: true,
								name: value.optionName,
								values: value.values,
							},
						])
					}
				/>
			</Modal>
		</>
	);
};

export default ProductOptionsForm;

type CreateOptionModalProps = {
	onClose: () => void;
	onSubmit: (value: {
		optionName: string;
		inputType: string;
		values: string[];
	}) => void;
};

const CreateOptionModal = (props: CreateOptionModalProps) => {
	const [values, setValues] = useState<string[]>([]);

	const handleChangeOptionValue = (value: string, index: number) => {
		setValues((prevState) => {
			let temp = [...prevState];
			temp[index] = value;
			return temp;
		});
	};

	const handleAddOption = () => {
		setValues((prevState) => [...prevState, ""]);
	};

	const handleDeleteOption = (index: number) => {
		setValues((prevState) => {
			let temp = [...prevState];
			temp.splice(index, 1);
			return temp;
		});
	};

	const handleCreateOption: React.FormEventHandler<HTMLFormElement> = (
		event
	) => {
		event.preventDefault();
		const target = event.target as typeof event.target & {
			optionName: {
				value: string;
			};
			inputType: {
				value: string;
			};
		};

		props.onSubmit({
			optionName: target.optionName.value,
			inputType: target.inputType.value,
			values: values,
		});
		props.onClose();
	};

	return (
		<ModalContent>
			<ModalHeader borderBottomWidth='1px'>Create option</ModalHeader>
			<ModalCloseButton />
			<ModalBody>
				<form id='create-option' onSubmit={handleCreateOption}>
					<Stack spacing='12px'>
						<Input
							label='Option name'
							inputProps={{
								name: "optionName",
								placeholder: "Option name",
							}}
						/>
						<SelectInput
							label='Input type'
							options={[
								{ label: "Radio buttons", value: "RADIO" },
								{ label: "Checkbox", value: "CHECKBOX" },
								{ label: "Select input", value: "SELECT" },
							]}
							selectProps={{
								name: "inputType",
							}}
						/>
						<Box>
							<FormLabel>Values</FormLabel>
							{values.length > 0 && (
								<Box width='full'>
									<Box p='4px 8px' opacity={0.9}>
										<Text fontSize='xs' fontWeight='semibold'>
											VALUE NAME
										</Text>
									</Box>
									<Divider />
								</Box>
							)}

							{values.map((value, index) => (
								<Box width='full' key={index}>
									<Box p='8px' display='flex' alignItems='center' gap='8px'>
										<Input
											inputProps={{
												size: "sm",
												value: value,
												placeholder: "Value name",
												onChange: (e) =>
													handleChangeOptionValue(e.target.value, index),
											}}
										/>
										<IconButton
											aria-label='delete-option-button'
											colorScheme='error'
											variant='ghost'
											size='sm'
											onClick={() => handleDeleteOption(index)}>
											<BiTrash size={18} />
										</IconButton>
									</Box>
									<Divider />
								</Box>
							))}
							<Button
								mt='2'
								variant='ghost'
								leftIcon={<BiPlus size={18} />}
								onClick={handleAddOption}
								fontSize='sm'
								size='sm'
								isFullWidth>
								Add a value
							</Button>
						</Box>
					</Stack>
				</form>
			</ModalBody>

			<ModalFooter borderTopWidth='1px'>
				<Button variant='ghost' mr={3} onClick={props.onClose}>
					Discard
				</Button>
				<Button type='submit' form='create-option' colorScheme='main'>
					Create option
				</Button>
			</ModalFooter>
		</ModalContent>
	);
};
