import {
	Box,
	Heading,
	Skeleton,
	Button,
	Tabs,
	TabList,
	Tab,
	TabPanel,
	TabPanels,
	useToast,
	IconButton,
	Stack,
	useDisclosure,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
	StatusType,
	useDeleteCategoryMutation,
	useGetCategoryGeneralByIdQuery,
	useGetCategoryNameByIdQuery,
	useGetCategorySeoByIdQuery,
	useUpdateCategoryGeneralMutation,
	useUpdateCategorySeoMutation,
} from "../../../api/generated/graphql";
import { CategoryFormWrapper } from "../Categories.styles";

import "react-quill/dist/quill.snow.css";
import { apolloClient } from "../../..";
import {
	GeneralCategoryForm,
	ProductCategory,
	SEOCategoryForm,
} from "./UpdateCategoriesForms";
import { BiTrash } from "react-icons/bi";
import { ConfirmDeleteDialog } from "../../../components";

type UpdateCategoryProps = {
	selectedID: string | null;
	onDeleteCategory?: () => void;
};

const UpdateCategory = (props: UpdateCategoryProps) => {
	const { selectedID, onDeleteCategory } = props;

	const { data: categoryNameData, loading: categoryNameLoading } =
		useGetCategoryNameByIdQuery({
			variables: {
				id: selectedID,
			},
		});

	const { data: categoryGeneralData, loading: categoryGeneralLoading } =
		useGetCategoryGeneralByIdQuery({
			variables: {
				id: selectedID,
			},
		});

	const [files, setFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const handleFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append("public_id", `categories/${file.name}`);
		setFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleDelete = (id: string) => {
		console.log(
			id,
			files.filter(({ id: imageId }) => id !== imageId)
		);
		setFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	const [updateCategoryGeneralMutation] = useUpdateCategoryGeneralMutation();

	const [tabIndex, setTabIndex] = useState(0);

	const toast = useToast();

	const categoryGeneralSettingsFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			name: "",
			description: "",
			status: "DRAFT",
		},
		onSubmit: (values, { setSubmitting }) => {
			updateCategoryGeneralMutation({
				variables: {
					id: selectedID,
					description: values.description,
					image: files?.[0]?.src ? (files?.[0]?.src as string) : "",
					name: values.name,
					status:
						values.status === "DRAFT" ? StatusType.Draft : StatusType.Published,
				},
				onCompleted: (data) => {
					if (data.UpdateCategory?.code) {
						toast({
							title: "Category has been updated.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
						setSubmitting(false);
					}
				},
				onError: (error) => {
					toast({
						title: "An error occured while updating category.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	useEffect(() => {
		setFiles(
			categoryGeneralData?.GetCategoryById?.image
				? [
						{
							progress: false,
							id: categoryGeneralData?.GetCategoryById?._id,
							src: categoryGeneralData?.GetCategoryById?.image,
						},
				  ]
				: []
		);

		categoryGeneralSettingsFormik.setFieldValue(
			"name",
			categoryGeneralData?.GetCategoryById?.name || ""
		);
		categoryGeneralSettingsFormik.setFieldValue(
			"description",
			categoryGeneralData?.GetCategoryById?.description || ""
		);
		categoryGeneralSettingsFormik.setFieldValue(
			"status",
			categoryGeneralData?.GetCategoryById?.status || ""
		);
	}, [categoryGeneralData]);

	const [metaTags, setMetaTags] = useState<
		{ key?: string | null; value?: string | null }[]
	>([]);

	const handleChangeMetaTags = (
		value: string,
		index: number,
		type: "key" | "value"
	) => {
		setMetaTags((prevState) => {
			let temp = [...prevState];
			if (type === "key") {
				temp[index].key = value;
			} else {
				temp[index].value = value;
			}
			return temp;
		});
	};

	const handleDeleteMetaTag = (index: number) => {
		setMetaTags((prevState) => {
			let temp = [...prevState];
			temp.splice(index, 1);
			return temp;
		});
	};

	const handleAddMetatag = () => {
		setMetaTags((prevState) => [...prevState, { key: "", value: "" }]);
	};

	const { data: categorySEOdata, loading: categorySEOloading } =
		useGetCategorySeoByIdQuery({
			variables: {
				id: selectedID,
			},
		});

	useEffect(() => {
		setMetaTags(
			categorySEOdata?.GetCategoryById?.seo?.metatags?.map((tag) => ({
				key: tag.key || "",
				value: tag.value || "",
			})) || []
		);
		categorySEOSettingsFormik.setFieldValue(
			"seotitle",
			categorySEOdata?.GetCategoryById?.seo?.seotitle || ""
		);
		categorySEOSettingsFormik.setFieldValue(
			"slug",
			categorySEOdata?.GetCategoryById?.slug || ""
		);
		categorySEOSettingsFormik.setFieldValue(
			"metadescription",
			categorySEOdata?.GetCategoryById?.seo?.metadescription || ""
		);
	}, [categorySEOdata]);

	const [updateCategorySEOMutation] = useUpdateCategorySeoMutation();

	const categorySEOSettingsFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			seotitle: "",
			slug: "",
			metadescription: "",
		},
		onSubmit: (values, { setSubmitting }) => {
			updateCategorySEOMutation({
				variables: {
					categeory_id: selectedID,
					metadescription: values.metadescription,
					metatags: metaTags,
					seoTitle: values.seotitle,
				},
				onCompleted: (data) => {
					if (data.UpdateCategory?.code) {
						toast({
							title: "Category SEO has been updated.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
						setSubmitting(false);
					}
				},
				onError: (error) => {
					toast({
						title: "An error occured while updating category.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	const [deleteCategoryMutation, deleteCatgoryParam] =
		useDeleteCategoryMutation();

	const handleDeleteCategory = () => {
		deleteCategoryMutation({
			variables: {
				category_id: selectedID,
			},
			onCompleted: (data) => {
				if (data?.DeleteCategory?.code) {
					toast({
						title: "Category deleted with success.",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					apolloClient.refetchQueries({
						include: "active",
					});
					onDeleteCategory?.();
				}
			},
			onError: (error) => {
				toast({
					title: "An error occured while deleting category.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	return (
		<CategoryFormWrapper>
			<Box className='category_form_header'>
				{selectedID ? (
					<Skeleton isLoaded={!categoryNameLoading}>
						<Stack direction='row' alignItems='center'>
							<Heading size='md'>
								Category "{categoryNameData?.GetCategoryById?.name}"
							</Heading>
							<ConfirmDeleteDialog
								dialogButtonProps={{
									type: "IconButton",

									children: <BiTrash size={18} />,
									props: {
										variant: "ghost",
										size: "sm",
										isLoading: deleteCatgoryParam.loading,
									},
								}}
								onDelete={handleDeleteCategory}
								isLoading={deleteCatgoryParam.loading}
							/>
						</Stack>
					</Skeleton>
				) : (
					<Heading size='md'>Creating new category</Heading>
				)}
				{tabIndex !== 1 ? (
					<Button
						colorScheme='main'
						size='sm'
						type='submit'
						isLoading={
							categoryGeneralSettingsFormik.isSubmitting ||
							categorySEOSettingsFormik.isSubmitting
						}
						form={
							tabIndex === 0
								? "update-general-category-form"
								: "update-seo-category-form"
						}>
						Save changes
					</Button>
				) : (
					<Box className='filler' height='32px'></Box>
				)}
			</Box>
			<Tabs
				index={tabIndex}
				onChange={(index: number) => setTabIndex(index)}
				colorScheme='blue'
				isLazy>
				<TabList>
					<Tab>General</Tab>
					<Tab px={4}>Products</Tab>

					<Tab px={4}>SEO</Tab>
				</TabList>

				<TabPanels>
					<TabPanel p='12px 0px'>
						<GeneralCategoryForm
							files={files}
							handleDelete={handleDelete}
							handleFile={handleFile}
							setFieldValue={categoryGeneralSettingsFormik.setFieldValue}
							errors={categoryGeneralSettingsFormik.errors}
							handleChange={categoryGeneralSettingsFormik.handleChange}
							onSubmit={categoryGeneralSettingsFormik.handleSubmit}
							values={categoryGeneralSettingsFormik.values}
							isLoading={categoryGeneralLoading}
						/>
					</TabPanel>
					<TabPanel p='12px 0px'>
						<ProductCategory categoryID={selectedID} />
					</TabPanel>
					<TabPanel>
						<SEOCategoryForm
							setFieldValue={categorySEOSettingsFormik.setFieldValue}
							errors={categorySEOSettingsFormik.errors}
							handleChange={categorySEOSettingsFormik.handleChange}
							onSubmit={categorySEOSettingsFormik.handleSubmit}
							values={categorySEOSettingsFormik.values}
							isLoading={categorySEOloading}
							handleAddMetatag={handleAddMetatag}
							handleChangeMetaTags={handleChangeMetaTags}
							handleDeleteMetaTag={handleDeleteMetaTag}
							metaTags={metaTags}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</CategoryFormWrapper>
	);
};

export default UpdateCategory;
