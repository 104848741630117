import {
	Box,
	Link,
	Text,
	Heading,
	useToast,
	Stack,
	Button,
	Tabs,
	TabList,
	Tab,
	Divider,
	useDisclosure,
	Tooltip,
	IconButton,
	Menu,
	MenuItem,
	MenuButton,
	MenuList,
	MenuDivider,
	Spinner,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import {
	BiArrowBack,
	BiBlock,
	BiCheckCircle,
	BiDotsHorizontalRounded,
	BiEdit,
	BiFilterAlt,
	BiLinkExternal,
	BiRadioCircleMarked,
	BiSearch,
	BiShowAlt,
	BiStore,
	BiTrash,
} from "react-icons/bi";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { apolloClient } from "../..";
import {
	GetAllStoresQueryVariables,
	useDeleteStoreMutation,
	useGetAllStoresQuery,
	useUpdateStoreStatusMutation,
} from "../../api/generated/graphql";
import { picturePlaceholder } from "../../assets";
import {
	ConfirmDeleteDialog,
	Input,
	Table,
	TableHeadType,
} from "../../components";
import { getDataFromCache } from "../../utils/cache";
import { PageWrapper } from "../../utils/globalStyle";
import StoreFilterDrawer from "./Filters/StoreFilterDrawer";

const Stores = () => {
	const location = useLocation();

	const toast = useToast();

	const navigate = useNavigate();

	const storeID = getDataFromCache("store_id", true);

	const { from } = (location?.state as { from?: string }) || "";

	const renderStoreStatus = (status: string | null | undefined) => {
		switch (status) {
			case "DRAFT":
				return <Text color='yellow.400'>Review request</Text>;
			case "DECLINED":
				return <Text color='red.300'>Declined</Text>;
			case "PUBLISHED":
				return (
					<Stack
						direction='row'
						alignItems='center'
						spacing='2px'
						color='green.400'>
						<BiRadioCircleMarked />
						<Text> Online</Text>
					</Stack>
				);
		}
	};

	const [tabIndex, setTabIndex] = useState<number>(0);

	const [status, setStatus] = useState<string>();

	const handleChangeTabIndex = (index: number) => {
		switch (index) {
			case 1: {
				setStatus("DRAFT");
				break;
			}
			case 2: {
				setStatus("PUBLISHED");
				break;
			}
			case 3: {
				setStatus("DECLINED");
				break;
			}
			default:
				setStatus(undefined);
		}
		setTabIndex(index);
	};

	const [search, setSearch] =
		useState<Pick<GetAllStoresQueryVariables, "contains">>();

	const [filters, setFilters] =
		useState<Pick<GetAllStoresQueryVariables, "name" | "status" | "vendor">>();

	const [pagination, setPagination] = useState({
		limit: 10,
		skip: 0,
	});
	const [defaultFilterValues, setDefaultFilterValues] = useState({
		name: "",
		vendor: "",
	});

	const handleFilter = (values: { name: string; vendor: string }) => {
		setDefaultFilterValues(values);
		setFilters({
			name: values?.name || undefined,
			vendor: values?.vendor || undefined,
		});
		setPagination({
			limit: 10,
			skip: 0,
		});
	};

	const getFiltersCount = () => {
		let count = 0;
		if (filters) {
			Object.values(filters).forEach((value, index) => {
				if (value) {
					count = count + 1;
				}
			});
		}
		return count;
	};

	const handleResetFilter = () => {
		setFilters(undefined);
		setDefaultFilterValues({
			name: "",
			vendor: "",
		});
		setPagination({
			limit: 10,
			skip: 0,
		});
	};

	const handleSearchSubmit: React.KeyboardEventHandler<HTMLInputElement> = (
		event
	) => {
		if (event.code === "Enter") {
			let target = event.target as typeof event.target & {
				value: string;
			};
			setSearch({
				contains: target.value,
			});
			setPagination({
				limit: 10,
				skip: 0,
			});
		}
	};

	const {
		isOpen: openFilter,
		onOpen: onOpenFilter,
		onClose: onCloseFilter,
	} = useDisclosure();

	const handlePageChange = (dir: "next" | "prev") => {
		if (dir === "next") {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip + temp.limit;
				return temp;
			});
		} else {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip - temp.limit;
				return temp;
			});
		}
	};

	const handleLimitChange = (value: number) => {
		setPagination((prevState) => {
			let temp = { ...prevState };
			temp.limit = +value;
			return temp;
		});
	};

	const {
		loading: storesLoading,
		error: storesError,
		data: storesData,
		refetch: refetchStoreData,
	} = useGetAllStoresQuery({
		variables: {
			contains: search?.contains || undefined,
			name: filters?.name || undefined,
			status: status || undefined,
			vendor: filters?.vendor || undefined,
			pagination: pagination,
		},
	});

	const [setStoreStatus, setStoreStatusParam] = useUpdateStoreStatusMutation();

	const handleChangeStoreStatus = (id: string, status: string) => {
		setStoreStatus({
			variables: {
				store_id: id,
				status: status,
			},
			onCompleted: (data) => {
				if (data.UpdateStore._id) {
					toast({
						title: "Store status has been updated with success!",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
				}
				apolloClient.refetchQueries({
					include: "active",
				});
				refetchStoreData();
			},
			onError: (error) => {
				toast({
					title: "An error occured while updating store status.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	const [deleteStoreMutation, deleteStoreParam] = useDeleteStoreMutation();

	const handleDeleteStore = (id: string) => {
		deleteStoreMutation({
			variables: {
				store_id: id,
			},
			onCompleted: (data) => {
				if (data?.DeleteStore?.code) {
					toast({
						title: "Store has been deleted with success.",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					apolloClient.refetchQueries({
						include: "active",
					});
					refetchStoreData();
				}
			},
			onError: (error) => {
				toast({
					title: "An error occurred while deleting a store.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	const storesTableData =
		storesData?.GetAllStores?.stores?.map((store, index) => ({
			id: store?._id,
			image: (
				<Box className='table_image'>
					<img
						src={store.storeMedia?.logo || picturePlaceholder}
						alt='product_profile_picture'
					/>
				</Box>
			),
			name: store.name,
			owner: store?.vendor?.firstName + " " + store?.vendor?.lastName,
			phone:
				store?.storeAddress?.phoneNumber?.prefix +
				" " +
				store?.storeAddress?.phoneNumber?.number,
			country: store?.storeAddress?.country,
			status: setStoreStatusParam.loading ? (
				<Stack direction='row' alignItems='center' color={"blue.400"}>
					<Spinner size='sm' />
					<Text>Loading...</Text>
				</Stack>
			) : (
				renderStoreStatus(store?.status)
			),
			createdAT: moment(store.createdAt).format("lll"),
			action: (
				<Stack
					direction='row'
					display='inline-flex'
					width='fit-content'
					alignItems='center'
					justifyContent='flex-end'>
					{(store.status === "PUBLISHED" || store.status === "DRAFT") && (
						<>
							{store.status === "PUBLISHED" ? (
								<Link
									color='blue.300'
									target='_blank'
									href={`${process.env.REACT_APP_MARKETPLACE_URL}store/${store?.slug}`}>
									<Stack direction='row' alignItems='center' spacing='4px'>
										<Text whiteSpace='nowrap'>See in store</Text>{" "}
										<BiLinkExternal size={16} />
									</Stack>
								</Link>
							) : (
								<Button
									colorScheme='green'
									size='sm'
									leftIcon={<BiCheckCircle size={18} />}
									onClick={() =>
										handleChangeStoreStatus(store._id, "PUBLISHED")
									}>
									Publish
								</Button>
							)}
							<Tooltip label='Decline store' aria-label='A tooltip'>
								<IconButton
									aria-label='decline_button'
									size='sm'
									variant='outline'
									colorScheme='red'
									onClick={() =>
										handleChangeStoreStatus(store._id, "DECLINED")
									}>
									<BiBlock size={14} />
								</IconButton>
							</Tooltip>
						</>
					)}
					{deleteStoreParam.loading ? (
						<Spinner size='sm' color='blue.400' />
					) : (
						<Menu>
							<MenuButton
								as={IconButton}
								aria-label='menu_more'
								size='sm'
								variant='ghost'
								className='menu_iconButton'>
								<BiDotsHorizontalRounded size={18} />
							</MenuButton>
							<MenuList px='2' shadow='lg'>
								<MenuItem
									rounded='base'
									icon={<BiEdit size={16} />}
									onClick={() =>
										navigate(`/admin/stores/${store._id}`, {
											state: {
												from: `/admin/stores`,
												pageName: "Stores",
											},
										})
									}>
									Edit store
								</MenuItem>
								<MenuDivider />
								<ConfirmDeleteDialog
									dialogButtonProps={{
										type: "MenuItem",
										children: "Delete store",
										props: {
											icon: <BiTrash size={18} />,
											color: "status.error",
											rounded: "base",
										},
									}}
									onDelete={() => handleDeleteStore(store?._id)}
									isLoading={deleteStoreParam.loading}
								/>
							</MenuList>
						</Menu>
					)}
				</Stack>
			),
		})) || [];

	type StoresTableData = typeof storesTableData[number];

	const storesTableHead: TableHeadType<StoresTableData>[] = [
		{ accessor: "image", label: "" },
		{ accessor: "name", label: "Name" },
		{ accessor: "owner", label: "Owner" },
		{ accessor: "phone", label: "Phone" },
		{ accessor: "country", label: "Country" },
		{ accessor: "status", label: "Status" },
		{ accessor: "createdAT", label: "Created at" },
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Heading fontSize='2xl'>Stores list</Heading>
					</Box>
				</Box>
				<Stack direction='row'>
					{/* <Button variant='outline'>Export</Button> */}
					<Button
						colorScheme='main'
						onClick={() =>
							navigate(`/admin/stores/create`, {
								state: {
									from: location.pathname,
									pageName: "Stores",
								},
							})
						}>
						Create new store
					</Button>
				</Stack>
			</Box>
			<Tabs
				colorScheme='blue'
				mb='14px'
				index={tabIndex}
				onChange={handleChangeTabIndex}>
				<TabList>
					<Tab>All</Tab>
					<Tab>Review requests</Tab>
					<Tab>Online</Tab>
					<Tab>Declined</Tab>
				</TabList>
			</Tabs>
			<Box className='page_actions'>
				<Input
					inputProps={{
						placeholder: "Search for product",
						onKeyUp: handleSearchSubmit,
					}}
					inputLeftElementProps={{ children: <BiSearch /> }}
				/>
				<Divider orientation='vertical' />
				<Button
					variant='outline'
					leftIcon={
						getFiltersCount() === 0 ? (
							<BiFilterAlt />
						) : (
							<Text
								bgColor='main.400'
								color='white'
								px='2'
								py='1'
								rounded='full'
								fontSize='sm'>
								{getFiltersCount()}
							</Text>
						)
					}
					onClick={onOpenFilter}>
					Filter
				</Button>
			</Box>
			<Table
				head={storesTableHead}
				data={storesTableData}
				isLoading={storesLoading}
				emptyState={
					<Box className='empty_table_container'>
						<BiStore size={42} />
						<Text fontSize='md' fontWeight='medium'>
							{search?.contains
								? `No results for "${search.contains}"`
								: getFiltersCount() !== 0 || tabIndex > 0
								? "There is no stores with these filters"
								: "There is no stores."}
						</Text>
					</Box>
				}
				pagination={{
					count: storesData?.GetAllStores.queryCount || 0,
					limit: pagination.limit,
					skip: pagination.skip,
					onPageChange: handlePageChange,
					onChangeLimit: handleLimitChange,
				}}
			/>
			<StoreFilterDrawer
				isOpen={openFilter}
				onClose={onCloseFilter}
				handleSubmitFilter={handleFilter}
				defaultValues={defaultFilterValues}
				handleResetFilter={handleResetFilter}
			/>
		</PageWrapper>
	);
};

export default Stores;
