import {
	Box,
	Button,
	Text,
	FormControl,
	Menu,
	MenuButton,
	MenuList,
	Spinner,
	Stack,
	useColorModeValue,
	Divider,
	IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { BiChevronDown, BiChevronUp, BiPlus } from "react-icons/bi";
import {
	useGetCategoryNodesLazyQuery,
	useGetMainCategoriesQuery,
} from "../../../api/generated/graphql";
import { Input } from "../../commons";

type CategoriesListDisplayProps = {
	loading?: boolean;
	onCategorySelect?: (id: string, label: string) => void;
	selected?: { label: string; id: string };
	list?:
		| {
				_id?: string | null;
				name?: string | null;
				hasNodes?: boolean | null;
		  }[]
		| null;
};

const CategoriesListDisplay = (props: CategoriesListDisplayProps) => {
	const { loading, list, onCategorySelect, selected } = props;

	const borderColor = useColorModeValue("gray.100", "gray.700");

	const [getNodes, { data: categoryNodesData, loading: categoryNodesLoading }] =
		useGetCategoryNodesLazyQuery();

	const [moreCategoryID, setMoreCategoryID] = useState<string>("");

	const handleSelectCategory = (id: string, label: string) => {
		onCategorySelect?.(id, label);
		handleCategoryNodes(id);
	};

	const handleCategoryNodes = (id: string) => {
		if (id) {
			setMoreCategoryID(id);
			getNodes({ variables: { id } });
		}
		if (moreCategoryID === id) {
			setMoreCategoryID("");
		}
	};

	if (loading)
		return (
			<Stack direction='row' spacing='8px' align='center'>
				<Spinner color='blue.400' size='xs' thickness='2px' />{" "}
				<Text color='blue.400' fontWeight='medium' fontSize='sm'>
					Loading...
				</Text>
			</Stack>
		);

	return (
		<Stack width='full' minWidth='fit-content' spacing='0'>
			<Stack spacing='4px' width='full'>
				{list?.map((category, index) => (
					<Box key={category?._id}>
						<Stack direction='row' align='center' spacing='0'>
							<IconButton
								aria-label='more_category'
								size='sm'
								variant='ghost'
								opacity={category.hasNodes ? 1 : 0}
								onClick={() =>
									category.hasNodes && handleCategoryNodes(category._id || "")
								}>
								{moreCategoryID === category._id ? (
									<BiChevronUp size={18} />
								) : (
									<BiChevronDown size={18} />
								)}
							</IconButton>
							<Button
								justifyContent='start'
								size='sm'
								isFullWidth
								px={1}
								variant={selected?.id === category?._id ? "solid" : "ghost"}
								fontSize='14px'
								onClick={() =>
									handleSelectCategory(
										category?._id || "",
										category?.name || ""
									)
								}>
								{category.name}
							</Button>
						</Stack>
						{moreCategoryID === category._id && (
							<Stack
								direction='row'
								pl={2}
								py={2}
								// spacing={"14px"}
								// w='full'
							>
								<Divider
									orientation='vertical'
									color={borderColor}
									height='inherit'
								/>
								<CategoriesListDisplay
									list={categoryNodesData?.GetCategoryNodes}
									loading={categoryNodesLoading}
									onCategorySelect={onCategorySelect}
									selected={selected}
								/>
							</Stack>
						)}
					</Box>
				))}
			</Stack>
		</Stack>
	);
};

const CategorySelectInput = (props: {
	selected: { label: string; id: string };
	handleSelectCategory: (id: string, label: string) => void;
}) => {
	const { selected, handleSelectCategory } = props;

	const [openDropDown, setOpenDropDown] = useState<boolean>(false);

	const { data: categoriesData, loading: categoriesLoading } =
		useGetMainCategoriesQuery({
			pollInterval: 300000,
		});

	const handleSubmitCategory = (id: string, label: string) => {
		handleSelectCategory(id, label);
		setOpenDropDown(false);
	};

	return (
		<FormControl position='relative'>
			<Input
				label='Category'
				inputProps={{
					placeholder: "Select a category",
					name: "category",
					value: selected.label,
					isReadOnly: true,
					onChange: undefined,
					onClickCapture: () => setOpenDropDown(true),
				}}
				inputRightElementProps={{
					children: <BiChevronDown size={18} />,
				}}
				// errorMessage={errorMessage}
				// isError={errorMessage !== undefined}
			/>
			<Box w='full'>
				<Menu
					computePositionOnMount
					placement='bottom-end'
					isLazy
					matchWidth
					isOpen={openDropDown}
					onClose={() => setOpenDropDown(!openDropDown)}
					closeOnSelect={false}>
					<MenuButton w='100%' h='0' position='absolute'></MenuButton>
					<MenuList maxWidth='360px' px='8px' overflowX='scroll' zIndex={99}>
						<CategoriesListDisplay
							list={categoriesData?.GetAllCategories?.categories}
							loading={categoriesLoading}
							onCategorySelect={handleSubmitCategory}
							selected={selected}
						/>
					</MenuList>
				</Menu>
			</Box>
		</FormControl>
	);
};

export default CategorySelectInput;
