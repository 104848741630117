import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const CreateStoreWrapper = styled(Box)`
	padding: 26px;
	overflow: scroll;
	.logo_container {
		width: 160px;
		margin-bottom: 14px;
	}
	.forms_container {
		position: relative;
		height: 100%;

		.slide_content {
			position: absolute;
			width: 100%;
			/* top: 0; */
		}
	}
	.stepper {
		height: 2px;
		width: 100%;
		margin-top: 8px;
		border-radius: 20px;
		transition: all 0.3s;
		.stepper_value {
			height: 2px;
			outline-width: thin;
			border-radius: 20px;
			transition: all 0.3s;
		}
		.store_form {
			width: 100%;

			margin-bottom: 26px;
		}
	}
`;
