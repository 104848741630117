import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const PageWrapper = styled(Box)`
	.page_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;
		height: 48px;
		.page_header_title {
			.page_header_backlink {
			}
			.page_header_title_content {
				display: flex;
				align-items: center;
				gap: 6px;
			}
		}
	}
	.page_actions {
		display: flex;
		gap: 6px;
		height: 40px;
		margin-bottom: 12px;
	}
	.empty_table_container {
		/* width: 100%; */
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 48px 0px;
		opacity: 0.6;
	}

	.quill {
		:hover {
			& > .ql-toolbar,
			.ql-container {
				border-color: #cbd5e0;
			}
		}
		:focus-within {
			& > .ql-toolbar,
			.ql-container {
				border-color: #0b5eda;
				box-shadow: 0 0 0 1px #0b5eda;
			}
		}
	}

	.ql-toolbar {
		border-top-left-radius: 0.375rem;
		border-top-right-radius: 0.375rem;
	}
	.ql-container {
		border-bottom-left-radius: 0.375rem;
		border-bottom-right-radius: 0.375rem;
	}

	.ql-container,
	.ql-toolbar {
		font-family: unset;
		border-color: #e2e8f0;
		background-color: #ffffff;
		color: #1a202c;
		transition: all 0.2s;
	}

	.ql-editor {
		height: 180px;
		resize: vertical;
	}
`;
