import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** Mongo object id scalar type */
  ObjectId: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Action = {
  __typename?: 'Action';
  to?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ActionInput = {
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** A user is a person who utilizes a computer or network service. A user often has a user account and is identified to the system by a username */
export type Actions = {
  __typename?: 'Actions';
  CREATE?: Maybe<Scalars['Boolean']>;
  DELETE?: Maybe<Scalars['Boolean']>;
  READ?: Maybe<Scalars['Boolean']>;
  UPDATE?: Maybe<Scalars['Boolean']>;
};

/** Actions Represent the User Action on a Service */
export type ActionsInput = {
  CREATE?: InputMaybe<Scalars['Boolean']>;
  DELETE?: InputMaybe<Scalars['Boolean']>;
  READ?: InputMaybe<Scalars['Boolean']>;
  UPDATE?: InputMaybe<Scalars['Boolean']>;
};

export type Activity = {
  __typename?: 'Activity';
  activity?: Maybe<Scalars['String']>;
  activityId?: Maybe<Scalars['ObjectId']>;
};

export type AddOn = {
  __typename?: 'AddOn';
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type AddOnInput = {
  description?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  apartment?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  langintude?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumber>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  suiteNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  apartment?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  langintude?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  suiteNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** Authenticate */
export type Authenticate = {
  __typename?: 'Authenticate';
  token?: Maybe<Authorization>;
  user: User;
};

/** Authorization */
export type Authorization = {
  __typename?: 'Authorization';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

/** Total Market Place Blance */
export type Balance = {
  __typename?: 'Balance';
  totalBlance: Scalars['Float'];
};

export type Banners = {
  __typename?: 'Banners';
  _id: Scalars['ObjectId'];
  image?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type BannersInput = {
  image?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Brands = {
  __typename?: 'Brands';
  brands?: Maybe<Array<Scalars['String']>>;
};

/** each of a possibly exhaustive set of classes among which all things might be distributed */
export type Categories = {
  __typename?: 'Categories';
  categories?: Maybe<Array<Category>>;
  categoriesCount?: Maybe<Scalars['Float']>;
  queryCount?: Maybe<Scalars['Float']>;
};

/** a class or division of objects or things regarded as having particular shared characteristics. */
export type Category = {
  __typename?: 'Category';
  _id?: Maybe<Scalars['ObjectId']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  hasLocalField?: Maybe<Scalars['Boolean']>;
  hasNodes?: Maybe<Scalars['Boolean']>;
  hasRoot?: Maybe<Scalars['Boolean']>;
  i18n?: Maybe<I18n>;
  image?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nodes?: Maybe<Array<Category>>;
  seo?: Maybe<Seo>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryFiltersInput = {
  contains?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  hasNodes?: InputMaybe<Scalars['Boolean']>;
  hasRoot?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Pagination>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type CategoryInput = {
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nodes?: InputMaybe<Array<Scalars['ObjectId']>>;
  seo?: InputMaybe<SeoInput>;
  status?: InputMaybe<StatusType>;
};

export type CategorySortInput = {
  createdAt?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['Float']>;
  level?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['Float']>;
  slug?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['Float']>;
};

export type Collection = {
  __typename?: 'Collection';
  _id: Scalars['ObjectId'];
  color?: Maybe<Color>;
  description?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Product>>;
  title?: Maybe<Scalars['String']>;
};

export type CollectionInput = {
  color?: InputMaybe<ColorInput>;
  description?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<Scalars['ObjectId']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type Color = {
  __typename?: 'Color';
  _id?: Maybe<Scalars['ObjectId']>;
  hexColor?: Maybe<Scalars['String']>;
  hslaColor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rgbaColor?: Maybe<Scalars['String']>;
};

export type ColorInput = {
  hexColor?: InputMaybe<Scalars['String']>;
  hslaColor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rgbaColor?: InputMaybe<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  contactInfromation?: Maybe<Scalars['String']>;
  privacyAndPolicy?: Maybe<Scalars['String']>;
  refundPolicy?: Maybe<Scalars['String']>;
  shippingPolicy?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
};

export type ContentInput = {
  contactInfromation?: InputMaybe<Scalars['String']>;
  privacyAndPolicy?: InputMaybe<Scalars['String']>;
  refundPolicy?: InputMaybe<Scalars['String']>;
  shippingPolicy?: InputMaybe<Scalars['String']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  dialCode?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CountryFiltersInput = {
  contains?: InputMaybe<Scalars['String']>;
  dialCode?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['String']>;
  isoCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Paginations>;
};

export type CreateCategoryInput = {
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ObjectId']>;
  seo?: InputMaybe<SeoInput>;
  status?: InputMaybe<StatusType>;
};

export type CredentialInput = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type CustomFields = {
  __typename?: 'CustomFields';
  KEY?: Maybe<Scalars['String']>;
  TYPE?: Maybe<Scalars['String']>;
  VALUE?: Maybe<Scalars['String']>;
};

export type CustomFieldsInput = {
  KEY?: InputMaybe<Scalars['String']>;
  TYPE?: InputMaybe<Scalars['String']>;
  VALUE?: InputMaybe<Scalars['String']>;
};

export type ExcludeInput = {
  category?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<ProductStatusType>;
  store?: InputMaybe<Scalars['ObjectId']>;
};

export type FlashSale = {
  __typename?: 'FlashSale';
  _id: Scalars['ObjectId'];
  color?: Maybe<Color>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  products?: Maybe<Array<Product>>;
  startDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type FlashSaleInput = {
  color?: InputMaybe<ColorInput>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  products?: InputMaybe<Array<Scalars['ObjectId']>>;
  startingDate?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Font = {
  __typename?: 'Font';
  fontFamily?: Maybe<Scalars['String']>;
  fontSoruce?: Maybe<Scalars['String']>;
};

export type FontInput = {
  fontFamily?: InputMaybe<Scalars['String']>;
  fontSoruce?: InputMaybe<Scalars['String']>;
};

export type Home = {
  __typename?: 'Home';
  _id: Scalars['ObjectId'];
  banners?: Maybe<Array<Banners>>;
  collections?: Maybe<Array<Collection>>;
  flashSales?: Maybe<Array<FlashSale>>;
  name?: Maybe<Scalars['String']>;
  seo?: Maybe<Seo>;
  slides?: Maybe<Array<Slides>>;
};

export type HomeInput = {
  banners?: InputMaybe<Array<BannersInput>>;
  collections?: InputMaybe<Array<CollectionInput>>;
  flashSales?: InputMaybe<Array<FlashSaleInput>>;
  name?: InputMaybe<Scalars['String']>;
  seo?: InputMaybe<SeoInput>;
  slides?: InputMaybe<Array<SlidesInput>>;
};

export type I18n = {
  __typename?: 'I18n';
  _id: Scalars['ObjectId'];
  ar?: Maybe<ToLocal>;
  fr?: Maybe<ToLocal>;
};

export type Inventory = {
  __typename?: 'Inventory';
  _id: Scalars['ObjectId'];
  barCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  informOn?: Maybe<Scalars['Float']>;
  qunatity?: Maybe<Scalars['Float']>;
  sellOutOfStock?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  trackQunatity?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weightPerUnit?: Maybe<Scalars['Float']>;
};

/** The basic InventoryActivity */
export enum InventoryActivity {
  Correction = 'CORRECTION',
  Count = 'COUNT',
  Damaged = 'DAMAGED',
  Order = 'ORDER',
  PromotionDonation = 'PROMOTION_DONATION',
  Received = 'RECEIVED',
  ReturnRestock = 'RETURN_RESTOCK',
  StockEntry = 'STOCK_ENTRY',
  TheftLoss = 'THEFT_LOSS'
}

export type InventoryHistory = {
  __typename?: 'InventoryHistory';
  _id?: Maybe<Scalars['ObjectId']>;
  activity?: Maybe<Activity>;
  createdAt?: Maybe<Scalars['DateTime']>;
  newQuantity?: Maybe<Scalars['Float']>;
  oldQuantity?: Maybe<Scalars['Float']>;
  refId?: Maybe<Scalars['ObjectId']>;
  sales?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InventoryHistoryInput = {
  activity?: InputMaybe<InventoryActivity>;
  inventory?: InputMaybe<InventoryInput>;
};

export type InventoryInput = {
  barCode?: InputMaybe<Scalars['String']>;
  informOn?: InputMaybe<Scalars['Float']>;
  qunatity?: InputMaybe<Scalars['Float']>;
  sellOutOfStock?: InputMaybe<Scalars['Boolean']>;
  sku?: InputMaybe<Scalars['String']>;
  trackQunatity?: InputMaybe<Scalars['Boolean']>;
  weightPerUnit?: InputMaybe<Scalars['Float']>;
};

export type Item = {
  __typename?: 'Item';
  images?: Maybe<Array<Scalars['String']>>;
  itemName?: Maybe<Scalars['String']>;
  itemOption?: Maybe<Array<ItemOption>>;
  itemPrice?: Maybe<Scalars['Float']>;
  itemSku?: Maybe<Scalars['String']>;
  itemStatus?: Maybe<Scalars['String']>;
  itemStore: Store;
  quntity?: Maybe<Scalars['Float']>;
  refId?: Maybe<Scalars['ObjectId']>;
};

export type ItemInput = {
  images?: InputMaybe<Array<Scalars['String']>>;
  itemName?: InputMaybe<Scalars['String']>;
  itemOption?: InputMaybe<Array<ItemOptionInput>>;
  itemPrice?: InputMaybe<Scalars['Float']>;
  itemSku?: InputMaybe<Scalars['String']>;
  itemStore: Scalars['ObjectId'];
  quntity?: InputMaybe<Scalars['Float']>;
  refId?: InputMaybe<Scalars['ObjectId']>;
};

export type ItemOption = {
  __typename?: 'ItemOption';
  KEY?: Maybe<Scalars['String']>;
  VALUE?: Maybe<Scalars['String']>;
};

export type ItemOptionInput = {
  KEY?: InputMaybe<Scalars['String']>;
  VALUE?: InputMaybe<Scalars['String']>;
};

/** The basic ItemStatusType */
export enum ItemStatusType {
  Notready = 'NOTREADY',
  Ready = 'READY'
}

export type Language = {
  __typename?: 'Language';
  name?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type LanguageInput = {
  name?: InputMaybe<Scalars['String']>;
  nativeName?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type MetaData = {
  __typename?: 'MetaData';
  _id?: Maybe<Scalars['ObjectId']>;
  appFavIcon?: Maybe<Scalars['String']>;
  appLogo?: Maybe<Scalars['String']>;
  appName?: Maybe<Scalars['String']>;
};

export type MetaDataInput = {
  appFavIcon?: InputMaybe<Scalars['String']>;
  appLogo?: InputMaybe<Scalars['String']>;
  appName?: InputMaybe<Scalars['String']>;
};

export type MetaTags = {
  __typename?: 'MetaTags';
  _id: Scalars['ObjectId'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MetaTagsInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ApproveUser: SuccessResponse;
  ArchiveCategory?: Maybe<SuccessResponse>;
  ArchiveProduct: SuccessResponse;
  ArchiveStore: SuccessResponse;
  ArchiveUser: SuccessResponse;
  AssignProductsCategory: SuccessResponse;
  AuthenticateUser: Authenticate;
  BlockUser: SuccessResponse;
  CancelCheckOutSession: SuccessResponse;
  ChaineCategoryNodes?: Maybe<SuccessResponse>;
  ConfirmUser: SuccessResponse;
  ConfirmUserEmail: Authenticate;
  CreateCategory?: Maybe<SuccessResponse>;
  CreateCheckOutSession: Scalars['String'];
  CreateFlashSaleProduct: SuccessResponse;
  CreateOrder: SuccessResponse;
  CreatePayout: SuccessResponse;
  CreateProduct: SuccessResponse;
  CreateStore: SuccessResponse;
  CreateUser: SuccessResponse;
  CreateVariant: Variant;
  DeclineProduct: SuccessResponse;
  DeclineStore: SuccessResponse;
  DeleteCategory?: Maybe<SuccessResponse>;
  DeleteProduct: SuccessResponse;
  DeleteProductVariant: SuccessResponse;
  DeleteStore: SuccessResponse;
  DeleteUser: SuccessResponse;
  ForgetPassword: SuccessResponse;
  ImportProducts: SuccessResponse;
  NewCheckOutSession: Scalars['String'];
  NewCreateCheckOutSession: Scalars['String'];
  RefreshUserAuthorization: Authorization;
  RegisterClient: Authenticate;
  RegisterVendor: Authenticate;
  ResendConfrimEmail: SuccessResponse;
  RestUserCredentials: SuccessResponse;
  SetOrderItemStatus: SuccessResponse;
  SetOrderStatus: SuccessResponse;
  SetProductStatus: SuccessResponse;
  SetStoreStatus: SuccessResponse;
  TempSetStoreBlance: SuccessResponse;
  UnassginProductCategory: SuccessResponse;
  UpdateCategory?: Maybe<SuccessResponse>;
  UpdateCurrentSessionUser: SuccessResponse;
  UpdateCurrentSessionUserPassword: SuccessResponse;
  UpdateHomePageById: SuccessResponse;
  UpdateOrder: SuccessResponse;
  UpdateProduct: SuccessResponse;
  UpdateProductInventoryQuantity: SuccessResponse;
  UpdateProductVariant: Variant;
  UpdateSettingsById: SuccessResponse;
  UpdateStore: SuccessResponse;
  UpdateUser: SuccessResponse;
  UpdateUserPermissions: SuccessResponse;
  UpdateUserRole: SuccessResponse;
};


export type MutationApproveUserArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationArchiveCategoryArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationArchiveProductArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationArchiveStoreArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationArchiveUserArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationAssignProductsCategoryArgs = {
  _id: Scalars['ObjectId'];
  products: Array<Scalars['ObjectId']>;
};


export type MutationAuthenticateUserArgs = {
  entry: CredentialInput;
};


export type MutationBlockUserArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationCancelCheckOutSessionArgs = {
  session_id: Scalars['String'];
};


export type MutationChaineCategoryNodesArgs = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  node: Scalars['ObjectId'];
};


export type MutationConfirmUserArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationConfirmUserEmailArgs = {
  token: Scalars['String'];
};


export type MutationCreateCategoryArgs = {
  entry: CreateCategoryInput;
};


export type MutationCreateCheckOutSessionArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationCreateFlashSaleProductArgs = {
  _id: Scalars['ObjectId'];
  newName: Scalars['String'];
  price: Scalars['Float'];
};


export type MutationCreateOrderArgs = {
  entry: OrderInput;
};


export type MutationCreatePayoutArgs = {
  entry: PayOutInput;
};


export type MutationCreateProductArgs = {
  entry: ProductInput;
};


export type MutationCreateStoreArgs = {
  entry: StoreInput;
};


export type MutationCreateUserArgs = {
  entry: UserInput;
  role: RoleType;
};


export type MutationCreateVariantArgs = {
  _id: Scalars['ObjectId'];
  entry: VariantInput;
};


export type MutationDeclineProductArgs = {
  _id: Scalars['ObjectId'];
  note: Scalars['String'];
};


export type MutationDeclineStoreArgs = {
  _id: Scalars['ObjectId'];
  note: Scalars['String'];
};


export type MutationDeleteCategoryArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationDeleteProductArgs = {
  _id: Array<Scalars['ObjectId']>;
};


export type MutationDeleteProductVariantArgs = {
  _id: Scalars['ObjectId'];
  variant_id: Scalars['ObjectId'];
};


export type MutationDeleteStoreArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationDeleteUserArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationForgetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationImportProductsArgs = {
  file: Scalars['Upload'];
};


export type MutationNewCheckOutSessionArgs = {
  _id: Scalars['String'];
};


export type MutationNewCreateCheckOutSessionArgs = {
  _id: Scalars['ObjectId'];
  provider: PaymentProvider;
};


export type MutationRefreshUserAuthorizationArgs = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};


export type MutationRegisterClientArgs = {
  entry: RegisterClientInput;
};


export type MutationRegisterVendorArgs = {
  entry: RegisterVendorInput;
};


export type MutationResendConfrimEmailArgs = {
  email: Scalars['String'];
};


export type MutationRestUserCredentialsArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSetOrderItemStatusArgs = {
  _id: Scalars['ObjectId'];
  refId: Scalars['ObjectId'];
  status: ItemStatusType;
};


export type MutationSetOrderStatusArgs = {
  _id: Scalars['ObjectId'];
  status: Scalars['String'];
};


export type MutationSetProductStatusArgs = {
  _id: Scalars['ObjectId'];
  status: ProductStatusType;
};


export type MutationSetStoreStatusArgs = {
  _id: Scalars['ObjectId'];
  status: StoreStatusType;
};


export type MutationTempSetStoreBlanceArgs = {
  _id: Scalars['ObjectId'];
  balance: Scalars['Float'];
};


export type MutationUnassginProductCategoryArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationUpdateCategoryArgs = {
  _id: Scalars['ObjectId'];
  entry: CategoryInput;
};


export type MutationUpdateCurrentSessionUserArgs = {
  entry: UpdateUserProfileInput;
};


export type MutationUpdateCurrentSessionUserPasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationUpdateHomePageByIdArgs = {
  _id: Scalars['ObjectId'];
  entry: HomeInput;
};


export type MutationUpdateOrderArgs = {
  _id: Scalars['ObjectId'];
  entry: UpdateOrderInput;
};


export type MutationUpdateProductArgs = {
  _id: Scalars['ObjectId'];
  entry: ProductInput;
};


export type MutationUpdateProductInventoryQuantityArgs = {
  _id: Scalars['ObjectId'];
  entry: InventoryHistoryInput;
};


export type MutationUpdateProductVariantArgs = {
  _id: Scalars['ObjectId'];
  entry: VariantInput;
};


export type MutationUpdateSettingsByIdArgs = {
  _id: Scalars['ObjectId'];
  entry: SettingInput;
};


export type MutationUpdateStoreArgs = {
  _id: Scalars['ObjectId'];
  entry: StoreInput;
};


export type MutationUpdateUserArgs = {
  _id: Scalars['ObjectId'];
  entry: UpdateUserProfileInput;
};


export type MutationUpdateUserPermissionsArgs = {
  _id: Scalars['ObjectId'];
  entry: PermissionsInput;
};


export type MutationUpdateUserRoleArgs = {
  _id: Scalars['ObjectId'];
  role: RoleType;
};

export type Option = {
  __typename?: 'Option';
  inputType?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type OptionInput = {
  inputType?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type Order = {
  __typename?: 'Order';
  _id: Scalars['ObjectId'];
  createdAt?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<Item>>;
  orderNotes?: Maybe<Array<OrderNote>>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  refId: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  totaleItems?: Maybe<Scalars['Float']>;
  totalePrice?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type OrderFiltersInput = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  contains?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  pagination?: InputMaybe<Paginations>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<Scalars['ObjectId']>;
};

export type OrderInput = {
  items?: InputMaybe<Array<ItemInput>>;
  orderNotes?: InputMaybe<Array<OrderNoteInput>>;
  paymentType?: InputMaybe<Scalars['String']>;
  totalePrice?: InputMaybe<Scalars['Float']>;
  user: Scalars['ObjectId'];
};

export type OrderNote = {
  __typename?: 'OrderNote';
  createdAt?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
};

export type OrderNoteInput = {
  note?: InputMaybe<Scalars['String']>;
};

export type OrderSortsInput = {
  createdAt?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['Float']>;
};

export type Orders = {
  __typename?: 'Orders';
  orders?: Maybe<Array<Order>>;
  ordersCount?: Maybe<Scalars['Float']>;
  queryCount?: Maybe<Scalars['Float']>;
};

export type Pagination = {
  disabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  skip?: InputMaybe<Scalars['Float']>;
};

export type Paginations = {
  disabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  skip?: InputMaybe<Scalars['Float']>;
};

export type PayOut = {
  __typename?: 'PayOut';
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  issueDate: Scalars['DateTime'];
  stores?: Maybe<Array<Store>>;
  totalAmount: Scalars['Float'];
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PayOutFiltersInput = {
  contains?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  issueDate?: InputMaybe<Scalars['DateTime']>;
  pagination?: InputMaybe<Paginations>;
  totalAmount?: InputMaybe<Scalars['Float']>;
};

export type PayOutInput = {
  issueDate: Scalars['DateTime'];
  stores?: InputMaybe<Array<Scalars['ObjectId']>>;
  totalAmount: Scalars['Float'];
};

export type PayOutSortInput = {
  createdAt?: InputMaybe<Scalars['Float']>;
  issueDate?: InputMaybe<Scalars['Float']>;
  totalAmount?: InputMaybe<Scalars['Float']>;
};

export type PayOuts = {
  __typename?: 'PayOuts';
  payOutsCount?: Maybe<Scalars['Float']>;
  payouts?: Maybe<Array<PayOut>>;
  queryCount?: Maybe<Scalars['Float']>;
};

export type Payment = {
  __typename?: 'Payment';
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  error: Scalars['String'];
  order: Order;
  payedAmount: Scalars['Float'];
  status: Scalars['String'];
  transactionId: Scalars['String'];
  transactionProvider: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** The basic PaymentProvider */
export enum PaymentProvider {
  Payme = 'PAYME',
  Stripe = 'STRIPE'
}

/** A user is a person who utilizes a computer or network service. A user often has a user account and is identified to the system by a username */
export type Permissions = {
  __typename?: 'Permissions';
  CATEGORIES_MANAGMENT?: Maybe<Actions>;
  COUPONS_MANAGMENT?: Maybe<Actions>;
  DISCOUNT_MANAGMENT?: Maybe<Actions>;
  HISTORY_MANAGMENT?: Maybe<Actions>;
  HOME_MANAGMENT?: Maybe<Actions>;
  ORDERS_MANAGMENT?: Maybe<Actions>;
  PRODUCTS_MANAGMENT?: Maybe<Actions>;
  STORE_MANAGMENT?: Maybe<Actions>;
  USER_MANAGMENT?: Maybe<Actions>;
};

/** Possible Entities to create actions on it controlled by USER actions */
export type PermissionsInput = {
  CATEGORIES_MANAGMENT?: InputMaybe<ActionsInput>;
  COUPONS_MANAGMENT?: InputMaybe<ActionsInput>;
  DISCOUNT_MANAGMENT?: InputMaybe<ActionsInput>;
  HISTORY_MANAGMENT?: InputMaybe<ActionsInput>;
  HOME_MANAGMENT?: InputMaybe<ActionsInput>;
  ORDERS_MANAGMENT?: InputMaybe<ActionsInput>;
  PRODUCTS_MANAGMENT?: InputMaybe<ActionsInput>;
  STORE_MANAGMENT?: InputMaybe<ActionsInput>;
  USER_MANAGMENT?: InputMaybe<ActionsInput>;
};

/** Your user phone-numbers list is available for use in call routing—and in your voicemail, fax, and business-text-message notifications */
export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  number?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
};

export type PhoneNumberInput = {
  number?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
};

export type Price = {
  __typename?: 'Price';
  chargeAtTax?: Maybe<Scalars['Boolean']>;
  compareAtPrice?: Maybe<Scalars['Float']>;
  costPerItem?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type PriceInput = {
  chargeAtTax?: InputMaybe<Scalars['Boolean']>;
  compareAtPrice?: InputMaybe<Scalars['Float']>;
  costPerItem?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
};

export type PriceSortInput = {
  compareAtPrice?: InputMaybe<Scalars['Float']>;
  costPerItem?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
};

export type Product = {
  __typename?: 'Product';
  _id?: Maybe<Scalars['ObjectId']>;
  brand?: Maybe<Scalars['String']>;
  bundles: Array<Product>;
  category?: Maybe<Category>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFields: Array<CustomFields>;
  declineNote?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  files: Array<AddOn>;
  hasLocalField?: Maybe<Scalars['Boolean']>;
  i18n?: Maybe<I18n>;
  images?: Maybe<Array<Scalars['String']>>;
  inventory?: Maybe<Inventory>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isAvilable?: Maybe<Scalars['Boolean']>;
  isReplica?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  options: Array<Option>;
  price?: Maybe<Price>;
  seo?: Maybe<Seo>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  store?: Maybe<Store>;
  tags?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variations: Array<Variant>;
};

export type ProductFiltersInput = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ObjectId']>;
  contains?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<ExcludeInput>;
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Pagination>;
  price?: InputMaybe<RangeInput>;
  status?: InputMaybe<ProductStatusType>;
  store?: InputMaybe<Scalars['ObjectId']>;
  type?: InputMaybe<ProductType>;
};

export type ProductInput = {
  brand?: InputMaybe<Scalars['String']>;
  bundles?: InputMaybe<Array<Scalars['ObjectId']>>;
  category?: InputMaybe<Scalars['ObjectId']>;
  customFields?: InputMaybe<Array<CustomFieldsInput>>;
  description?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<AddOnInput>>;
  images?: InputMaybe<Array<Scalars['String']>>;
  inventory?: InputMaybe<InventoryInput>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isAvilable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<OptionInput>>;
  price?: InputMaybe<PriceInput>;
  seo?: InputMaybe<SeoInput>;
  status?: InputMaybe<ProductStatusType>;
  store?: InputMaybe<Scalars['ObjectId']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<ProductType>;
  variations?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type ProductSortInput = {
  brand?: InputMaybe<Scalars['Float']>;
  category?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<PriceSortInput>;
  store?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['Float']>;
};

/** The basic statusType of a product */
export enum ProductStatusType {
  Archive = 'ARCHIVE',
  Declined = 'DECLINED',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Published = 'PUBLISHED'
}

/** The basic ProductType */
export enum ProductType {
  Downloadable = 'DOWNLOADABLE',
  Mixed = 'MIXED',
  Physical = 'PHYSICAL',
  Service = 'Service'
}

export type Products = {
  __typename?: 'Products';
  products?: Maybe<Array<Product>>;
  productsCount?: Maybe<Scalars['Float']>;
  queryCount?: Maybe<Scalars['Float']>;
};

export type PublicProductFiltersInput = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Array<Scalars['ObjectId']>>;
  contains?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<ExcludeInput>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<Scalars['String']>>;
  pagination?: InputMaybe<Pagination>;
  price?: InputMaybe<RangeInput>;
  status?: InputMaybe<ProductStatusType>;
  store?: InputMaybe<Scalars['ObjectId']>;
  type?: InputMaybe<ProductType>;
};

export type Query = {
  __typename?: 'Query';
  ExportProducts: Array<Scalars['Float']>;
  GetAllCategories?: Maybe<Categories>;
  GetAllCountries: Array<Country>;
  GetAllFonts: Array<Font>;
  GetAllLanguages: Array<Language>;
  GetAllOrders: Orders;
  GetAllPayOuts: PayOuts;
  GetAllProductBrands: Brands;
  GetAllProducts: Products;
  GetAllStores: Stores;
  GetAllUsers: Users;
  GetAvailableBlance: Balance;
  GetCategoryById?: Maybe<Category>;
  GetCategoryBySlug?: Maybe<Category>;
  GetCategoryNodes?: Maybe<Array<Category>>;
  GetCheckoutSession: Scalars['String'];
  GetCurrentSessionOrders: Orders;
  GetCurrentSessionUser: User;
  GetCurrentSessionUserProducts: Products;
  GetCurrentSessionUserStores: Stores;
  GetHomePage: Home;
  GetOrderById: Order;
  GetProductById: Product;
  GetProductBySku: Product;
  GetProductBySlug: Product;
  GetProductInventoryHistory: Array<InventoryHistory>;
  GetProductsByVendor: Products;
  GetSettings: Setting;
  GetSomeRelatedProduct: Products;
  GetStoreById: Store;
  GetStoreBySlug: Store;
  GetUserById: User;
  GetVariantById: Variant;
  PublicGetAllCategories?: Maybe<Categories>;
  PublicGetAllProducts: Products;
  PublicGetAllProductsOptions: Array<Option>;
  PublicGetAllStores: Stores;
};


export type QueryExportProductsArgs = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  selectedProducts?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type QueryGetAllCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  sort?: InputMaybe<CategorySortInput>;
};


export type QueryGetAllCountriesArgs = {
  filters: CountryFiltersInput;
};


export type QueryGetAllOrdersArgs = {
  filters?: InputMaybe<OrderFiltersInput>;
  sort?: InputMaybe<OrderSortsInput>;
};


export type QueryGetAllPayOutsArgs = {
  filters?: InputMaybe<PayOutFiltersInput>;
  sort?: InputMaybe<PayOutSortInput>;
};


export type QueryGetAllProductsArgs = {
  filters?: InputMaybe<ProductFiltersInput>;
  sort?: InputMaybe<ProductSortInput>;
};


export type QueryGetAllStoresArgs = {
  filters?: InputMaybe<StoreFiltersInput>;
  sort?: InputMaybe<StoreSortsInput>;
};


export type QueryGetAllUsersArgs = {
  filters?: InputMaybe<UserFiltersInput>;
  sort?: InputMaybe<UserSortInput>;
};


export type QueryGetCategoryByIdArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryGetCategoryBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetCategoryNodesArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryGetCheckoutSessionArgs = {
  session_id: Scalars['String'];
};


export type QueryGetCurrentSessionOrdersArgs = {
  filters: OrderFiltersInput;
  sort?: InputMaybe<OrderSortsInput>;
  store?: InputMaybe<Scalars['ObjectId']>;
};


export type QueryGetCurrentSessionUserProductsArgs = {
  filters?: InputMaybe<ProductFiltersInput>;
  sort?: InputMaybe<ProductSortInput>;
};


export type QueryGetCurrentSessionUserStoresArgs = {
  filters?: InputMaybe<StoreFiltersInput>;
  sort?: InputMaybe<StoreSortsInput>;
};


export type QueryGetOrderByIdArgs = {
  _id: Scalars['ObjectId'];
  store?: InputMaybe<Scalars['ObjectId']>;
};


export type QueryGetProductByIdArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryGetProductBySkuArgs = {
  sku: Scalars['String'];
};


export type QueryGetProductBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetProductInventoryHistoryArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryGetProductsByVendorArgs = {
  filters?: InputMaybe<ProductFiltersInput>;
  sort?: InputMaybe<ProductSortInput>;
  vendor: Scalars['ObjectId'];
};


export type QueryGetSomeRelatedProductArgs = {
  brand: Scalars['String'];
};


export type QueryGetStoreByIdArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryGetStoreBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetUserByIdArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryGetVariantByIdArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryPublicGetAllCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  sort?: InputMaybe<CategorySortInput>;
};


export type QueryPublicGetAllProductsArgs = {
  filters?: InputMaybe<PublicProductFiltersInput>;
  sort?: InputMaybe<ProductSortInput>;
};


export type QueryPublicGetAllProductsOptionsArgs = {
  categories?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type QueryPublicGetAllStoresArgs = {
  filters?: InputMaybe<StoreFiltersInput>;
  sort?: InputMaybe<StoreSortsInput>;
};

export type RangeInput = {
  from?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['Float']>;
};

export type RegisterClientInput = {
  avatar?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  userAddress?: InputMaybe<Array<AddressInput>>;
};

export type RegisterVendorInput = {
  avatar?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

/** The basic RoleType */
export enum RoleType {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Public = 'PUBLIC',
  User = 'USER',
  Vendor = 'VENDOR'
}

export type SelectedOption = {
  __typename?: 'SelectedOption';
  KEY?: Maybe<Scalars['String']>;
  VALUE?: Maybe<Scalars['String']>;
};

export type SelectedOptionInput = {
  KEY?: InputMaybe<Scalars['String']>;
  VALUE?: InputMaybe<Scalars['String']>;
};

export type Seo = {
  __typename?: 'Seo';
  _id: Scalars['ObjectId'];
  keywords?: Maybe<Scalars['String']>;
  metadescription?: Maybe<Scalars['String']>;
  metatags?: Maybe<Array<MetaTags>>;
  seotitle?: Maybe<Scalars['String']>;
};

export type SeoInput = {
  keywords?: InputMaybe<Scalars['String']>;
  metadescription?: InputMaybe<Scalars['String']>;
  metatags?: InputMaybe<Array<MetaTagsInput>>;
  seotitle?: InputMaybe<Scalars['String']>;
};

export type Setting = {
  __typename?: 'Setting';
  _id?: Maybe<Scalars['ObjectId']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dashboardMetaData?: Maybe<MetaData>;
  dashboardTheme?: Maybe<Theme>;
  internationalization?: Maybe<Array<Language>>;
  marektplaceMetaData?: Maybe<MetaData>;
  marektplaceTheme?: Maybe<Theme>;
  moreInfo?: Maybe<Content>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SettingInput = {
  dashboardMetaData?: InputMaybe<MetaDataInput>;
  dashboardTheme?: InputMaybe<ThemeInput>;
  internationalization?: InputMaybe<Array<LanguageInput>>;
  marektplaceMetaData?: InputMaybe<MetaDataInput>;
  marektplaceTheme?: InputMaybe<ThemeInput>;
  moreInfo?: InputMaybe<ContentInput>;
};

export type Settings = {
  __typename?: 'Settings';
  queryCount?: Maybe<Scalars['Float']>;
  settings?: Maybe<Array<Setting>>;
  settingsCount?: Maybe<Scalars['Float']>;
};

export type Slides = {
  __typename?: 'Slides';
  _id: Scalars['ObjectId'];
  action?: Maybe<Action>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  showOverlay?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type SlidesInput = {
  action?: InputMaybe<ActionInput>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  showOverlay?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

/** The basic statusType */
export enum StatusType {
  Archive = 'ARCHIVE',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Published = 'PUBLISHED'
}

/** a shop of any size or kind. */
export type Store = {
  __typename?: 'Store';
  _id?: Maybe<Scalars['ObjectId']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  declineNote?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hasLocalField?: Maybe<Scalars['Boolean']>;
  i18n?: Maybe<I18n>;
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  oldStoreBalance: Scalars['Float'];
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  storeAddress?: Maybe<Address>;
  storeBalance: Scalars['Float'];
  storeKyc?: Maybe<StoreKyc>;
  storeMedia?: Maybe<StoreMedia>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<User>;
};

export type StoreFiltersInput = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  contains?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Pagination>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type StoreInput = {
  description?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  storeAddress?: InputMaybe<AddressInput>;
  storeKyc?: InputMaybe<StoreKycInput>;
  storeMedia?: InputMaybe<StoreMediaInput>;
  vendor?: InputMaybe<Scalars['String']>;
};

/** Know Your Customer and sometimes Know Your Client. KYC or KYC check is the mandatory process of identifying and verifying the client's identity when opening an account and periodically over time */
export type StoreKyc = {
  __typename?: 'StoreKyc';
  additionalSellingPlatforms?: Maybe<Array<Scalars['String']>>;
  alreadySelling?: Maybe<Scalars['Boolean']>;
  businessIndustry?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  customersAgeRange?: Maybe<Scalars['String']>;
  dateOfEstablishment?: Maybe<Scalars['DateTime']>;
  mainProducts?: Maybe<Scalars['String']>;
  typeOfCompany?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  yearlyRevenue?: Maybe<Scalars['String']>;
};

export type StoreKycInput = {
  additionalSellingPlatforms?: InputMaybe<Array<Scalars['String']>>;
  alreadySelling?: InputMaybe<Scalars['Boolean']>;
  businessIndustry?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  customersAgeRange?: InputMaybe<Scalars['String']>;
  dateOfEstablishment?: InputMaybe<Scalars['DateTime']>;
  mainProducts?: InputMaybe<Scalars['String']>;
  typeOfCompany?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  yearlyRevenue?: InputMaybe<Scalars['String']>;
};

export type StoreMedia = {
  __typename?: 'StoreMedia';
  coverPicture?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

export type StoreMediaInput = {
  coverPicture?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
};

export type StoreSortsInput = {
  createdAt?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['Float']>;
  slug?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['Float']>;
  vendor?: InputMaybe<Scalars['Float']>;
};

/** The basic statusType of a store */
export enum StoreStatusType {
  Archive = 'ARCHIVE',
  Declined = 'DECLINED',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Published = 'PUBLISHED'
}

export type Stores = {
  __typename?: 'Stores';
  queryCount?: Maybe<Scalars['Float']>;
  stores?: Maybe<Array<Store>>;
  storesCount?: Maybe<Scalars['Float']>;
};

/** Object SuccessResponse */
export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  _id?: Maybe<Scalars['ObjectId']>;
  code: Scalars['Float'];
  info: Scalars['String'];
  status: Scalars['String'];
};

export type Theme = {
  __typename?: 'Theme';
  _id?: Maybe<Scalars['ObjectId']>;
  font?: Maybe<Font>;
  primaryColor?: Maybe<Color>;
  secondaryColor?: Maybe<Color>;
  tertiaryColors?: Maybe<Color>;
};

export type ThemeInput = {
  font?: InputMaybe<FontInput>;
  primaryColor?: InputMaybe<ColorInput>;
  secondaryColor?: InputMaybe<ColorInput>;
  tertiaryColors?: InputMaybe<ColorInput>;
};

export type ToLocal = {
  __typename?: 'ToLocal';
  _id: Scalars['ObjectId'];
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateOrderInput = {
  items?: InputMaybe<Array<ItemInput>>;
  orderNotes?: InputMaybe<Array<OrderNoteInput>>;
  paymentType?: InputMaybe<Scalars['String']>;
  totalePrice?: InputMaybe<Scalars['Float']>;
};

export type UpdateUserProfileInput = {
  avatar?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  userAddress?: InputMaybe<Array<AddressInput>>;
};

/** A user is a person who utilizes a computer or network service. A user often has a user account and is identified to the system by a username */
export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ObjectId']>;
  avatar?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAddress?: Maybe<Array<Address>>;
  userPermessions: UserPermissions;
};

export type UserFiltersInput = {
  contains?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isBlocked?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Pagination>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  role?: InputMaybe<RoleType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserInput = {
  avatar?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isBlocked?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  userAddress?: InputMaybe<Array<AddressInput>>;
};

/** A user is a person who utilizes a computer or network service. A user often has a user account and is identified to the system by a username */
export type UserPermissions = {
  __typename?: 'UserPermissions';
  _id?: Maybe<Scalars['ObjectId']>;
  permission?: Maybe<Permissions>;
};

export type UserSortInput = {
  createdAt?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['Float']>;
  firstName?: InputMaybe<Scalars['Float']>;
  lastName?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['Float']>;
};

/** A user is a person who utilizes a computer or network service. A user often has a user account and is identified to the system by a username */
export type Users = {
  __typename?: 'Users';
  queryCount?: Maybe<Scalars['Float']>;
  users?: Maybe<Array<User>>;
  usersCount?: Maybe<Scalars['Float']>;
};

export type Variant = {
  __typename?: 'Variant';
  _id?: Maybe<Scalars['ObjectId']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  images?: Maybe<Array<Scalars['String']>>;
  inventory?: Maybe<Inventory>;
  isActive?: Maybe<Scalars['Boolean']>;
  selectedOption?: Maybe<Array<SelectedOption>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variantPrice?: Maybe<Scalars['Float']>;
};

export type VariantInput = {
  images?: InputMaybe<Array<Scalars['String']>>;
  inventory?: InputMaybe<InventoryInput>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  selectedOption?: InputMaybe<Array<SelectedOptionInput>>;
  variantPrice?: InputMaybe<Scalars['Float']>;
};

export type UpdateCategoryGeneralMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  status?: InputMaybe<StatusType>;
}>;


export type UpdateCategoryGeneralMutation = { __typename?: 'Mutation', UpdateCategory?: { __typename?: 'SuccessResponse', code: number, info: string, status: string } | null };

export type AddOrderNoteMutationVariables = Exact<{
  orderNotes?: InputMaybe<Array<OrderNoteInput> | OrderNoteInput>;
  orderId: Scalars['ObjectId'];
}>;


export type AddOrderNoteMutation = { __typename?: 'Mutation', UpdateOrder: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type AssignProductsToCategoryMutationVariables = Exact<{
  products: Array<Scalars['ObjectId']> | Scalars['ObjectId'];
  categeory_id: Scalars['ObjectId'];
}>;


export type AssignProductsToCategoryMutation = { __typename?: 'Mutation', AssignProductsCategory: { __typename?: 'SuccessResponse', code: number, info: string, status: string } };

export type ConfirmUserEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmUserEmailMutation = { __typename?: 'Mutation', ConfirmUserEmail: { __typename?: 'Authenticate', token?: { __typename?: 'Authorization', accessToken: string, refreshToken: string } | null } };

export type CreateCategoryMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<StatusType>;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', CreateCategory?: { __typename?: 'SuccessResponse', code: number, info: string, status: string } | null };

export type CreatePayoutMutationVariables = Exact<{
  issueDate: Scalars['DateTime'];
  totalAmount: Scalars['Float'];
  stores?: InputMaybe<Array<Scalars['ObjectId']> | Scalars['ObjectId']>;
}>;


export type CreatePayoutMutation = { __typename?: 'Mutation', CreatePayout: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type CreateProductMutationVariables = Exact<{
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProductType>;
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ObjectId']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isAvilable?: InputMaybe<Scalars['Boolean']>;
  images?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  price?: InputMaybe<PriceInput>;
  store_id: Scalars['ObjectId'];
}>;


export type CreateProductMutation = { __typename?: 'Mutation', CreateProduct: { __typename?: 'SuccessResponse', status: string, info: string, code: number, _id?: any | null } };

export type CreateProductVariationMutationVariables = Exact<{
  images?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  variantPrice?: InputMaybe<Scalars['Float']>;
  sku?: InputMaybe<Scalars['String']>;
  barcode?: InputMaybe<Scalars['String']>;
  weightPerUnit?: InputMaybe<Scalars['Float']>;
  selectedOption?: InputMaybe<Array<SelectedOptionInput> | SelectedOptionInput>;
  product_id: Scalars['ObjectId'];
}>;


export type CreateProductVariationMutation = { __typename?: 'Mutation', CreateVariant: { __typename?: 'Variant', _id?: any | null } };

export type CreateProductVariantMutationVariables = Exact<{
  selectedOption?: InputMaybe<Array<SelectedOptionInput> | SelectedOptionInput>;
  weightPerUnit?: InputMaybe<Scalars['Float']>;
  barcode?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  variantPrice?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  images?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  product_id: Scalars['ObjectId'];
}>;


export type CreateProductVariantMutation = { __typename?: 'Mutation', CreateVariant: { __typename?: 'Variant', _id?: any | null, images?: Array<string> | null, isActive?: boolean | null, variantPrice?: number | null, inventory?: { __typename?: 'Inventory', _id: any, weightPerUnit?: number | null, sku?: string | null, barCode?: string | null } | null, selectedOption?: Array<{ __typename?: 'SelectedOption', KEY?: string | null, VALUE?: string | null }> | null } };

export type CreateStoreMutationVariables = Exact<{
  vendor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  coverPicture?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  apartment?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  phonePrefix?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
}>;


export type CreateStoreMutation = { __typename?: 'Mutation', CreateStore: { __typename?: 'SuccessResponse', _id?: any | null, code: number, status: string, info: string } };

export type CreateVendorStoreMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
}>;


export type CreateVendorStoreMutation = { __typename?: 'Mutation', CreateStore: { __typename?: 'SuccessResponse', code: number, info: string, status: string, _id?: any | null } };

export type DeleteCategoryMutationVariables = Exact<{
  category_id: Scalars['ObjectId'];
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', DeleteCategory?: { __typename?: 'SuccessResponse', code: number, info: string, status: string } | null };

export type DeleteProductMutationVariables = Exact<{
  product_id: Array<Scalars['ObjectId']> | Scalars['ObjectId'];
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', DeleteProduct: { __typename?: 'SuccessResponse', code: number, _id?: any | null, info: string, status: string } };

export type DeleteProductVariantMutationVariables = Exact<{
  product_id: Scalars['ObjectId'];
  variant_id: Scalars['ObjectId'];
}>;


export type DeleteProductVariantMutation = { __typename?: 'Mutation', DeleteProductVariant: { __typename?: 'SuccessResponse', _id?: any | null, code: number, status: string, info: string } };

export type DeleteStoreMutationVariables = Exact<{
  store_id: Scalars['ObjectId'];
}>;


export type DeleteStoreMutation = { __typename?: 'Mutation', DeleteStore: { __typename?: 'SuccessResponse', _id?: any | null, code: number, status: string, info: string } };

export type ExportProductsQueryVariables = Exact<{
  storeID?: InputMaybe<Scalars['ObjectId']>;
  selectedProducts?: InputMaybe<Array<Scalars['ObjectId']> | Scalars['ObjectId']>;
}>;


export type ExportProductsQuery = { __typename?: 'Query', ExportProducts: Array<number> };

export type ForgetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgetPasswordMutation = { __typename?: 'Mutation', ForgetPassword: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type GetAllCountriesQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetAllCountriesQuery = { __typename?: 'Query', GetAllCountries: Array<{ __typename?: 'Country', dialCode?: string | null, flag?: string | null, isoCode?: string | null, name?: string | null }> };

export type GetAllOrdersQueryVariables = Exact<{
  contains?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Paginations>;
  user?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<Scalars['String']>;
}>;


export type GetAllOrdersQuery = { __typename?: 'Query', GetAllOrders: { __typename?: 'Orders', ordersCount?: number | null, queryCount?: number | null, orders?: Array<{ __typename?: 'Order', _id: any, createdAt?: any | null, paymentStatus?: string | null, paymentType?: string | null, refId: string, status?: string | null, totalePrice?: number | null, updatedAt?: any | null, totaleItems?: number | null, user?: { __typename?: 'User', lastName?: string | null, firstName?: string | null, email?: string | null, _id?: any | null, userAddress?: Array<{ __typename?: 'Address', phoneNumber?: { __typename?: 'PhoneNumber', number?: string | null, prefix?: string | null } | null }> | null } | null, orderNotes?: Array<{ __typename?: 'OrderNote', note?: string | null }> | null }> | null } };

export type GetAllProductsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  name?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProductType>;
  category?: InputMaybe<Scalars['ObjectId']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatusType>;
  store?: InputMaybe<Scalars['ObjectId']>;
}>;


export type GetAllProductsQuery = { __typename?: 'Query', GetAllProducts: { __typename?: 'Products', productsCount?: number | null, queryCount?: number | null, products?: Array<{ __typename?: 'Product', _id?: any | null, status?: string | null, brand?: string | null, images?: Array<string> | null, name?: string | null, slug?: string | null, updatedAt?: any | null, createdAt?: any | null, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null, store?: { __typename?: 'Store', _id?: any | null, name: string } | null, options: Array<{ __typename?: 'Option', name?: string | null }>, price?: { __typename?: 'Price', price?: number | null } | null, inventory?: { __typename?: 'Inventory', _id: any, barCode?: string | null, createdAt?: any | null, informOn?: number | null, qunatity?: number | null, sellOutOfStock?: boolean | null, sku?: string | null, trackQunatity?: boolean | null, updatedAt?: any | null, weightPerUnit?: number | null } | null, variations: Array<{ __typename?: 'Variant', variantPrice?: number | null, updatedAt?: any | null, isActive?: boolean | null, images?: Array<string> | null, createdAt?: any | null, _id?: any | null, selectedOption?: Array<{ __typename?: 'SelectedOption', KEY?: string | null, VALUE?: string | null }> | null, inventory?: { __typename?: 'Inventory', _id: any, createdAt?: any | null, barCode?: string | null, informOn?: number | null, qunatity?: number | null, sellOutOfStock?: boolean | null, sku?: string | null, trackQunatity?: boolean | null, updatedAt?: any | null, weightPerUnit?: number | null } | null }> }> | null } };

export type GetAllStoresQueryVariables = Exact<{
  vendor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  contains?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Pagination>;
}>;


export type GetAllStoresQuery = { __typename?: 'Query', GetAllStores: { __typename?: 'Stores', storesCount?: number | null, queryCount?: number | null, stores?: Array<{ __typename?: 'Store', updatedAt?: any | null, _id?: any | null, createdAt?: any | null, status?: string | null, name: string, slug?: string | null, vendor?: { __typename?: 'User', _id?: any | null, firstName?: string | null, lastName?: string | null } | null, storeKyc?: { __typename?: 'StoreKyc', companyName?: string | null } | null, storeAddress?: { __typename?: 'Address', country?: string | null, phoneNumber?: { __typename?: 'PhoneNumber', number?: string | null, prefix?: string | null } | null } | null, storeMedia?: { __typename?: 'StoreMedia', logo?: string | null } | null }> | null } };

export type GetAllStoresPayoutQueryVariables = Exact<{
  contains?: InputMaybe<Scalars['String']>;
}>;


export type GetAllStoresPayoutQuery = { __typename?: 'Query', GetAllStores: { __typename?: 'Stores', queryCount?: number | null, storesCount?: number | null, stores?: Array<{ __typename?: 'Store', _id?: any | null, name: string, storeBalance: number, vendor?: { __typename?: 'User', _id?: any | null, firstName?: string | null, lastName?: string | null } | null }> | null } };

export type GetAllUsersQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isBlocked?: InputMaybe<Scalars['Boolean']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  emailSort?: InputMaybe<Scalars['Float']>;
  lastNameSort?: InputMaybe<Scalars['Float']>;
  firstNameSort?: InputMaybe<Scalars['Float']>;
}>;


export type GetAllUsersQuery = { __typename?: 'Query', GetAllUsers: { __typename?: 'Users', queryCount?: number | null, users?: Array<{ __typename?: 'User', _id?: any | null, email?: string | null, firstName?: string | null, avatar?: string | null, lastName?: string | null, nationality?: string | null, dateOfBirth?: any | null, isBlocked?: boolean | null, isArchived?: boolean | null, userAddress?: Array<{ __typename?: 'Address', zipCode?: string | null, suiteNumber?: string | null, state?: string | null, streetAddress?: string | null, latitude?: string | null, langintude?: string | null, country?: string | null, city?: string | null, apartment?: string | null, phoneNumber?: { __typename?: 'PhoneNumber', number?: string | null, prefix?: string | null } | null }> | null }> | null } };

export type GetAllVendorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllVendorsQuery = { __typename: 'Query', GetAllUsers: { __typename?: 'Users', users?: Array<{ __typename?: 'User', _id?: any | null, firstName?: string | null, lastName?: string | null }> | null } };

export type GetCategoryNodesQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetCategoryNodesQuery = { __typename?: 'Query', GetCategoryNodes?: Array<{ __typename?: 'Category', _id?: any | null, name?: string | null, hasNodes?: boolean | null }> | null };

export type GetCategoryGeneralByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetCategoryGeneralByIdQuery = { __typename?: 'Query', GetCategoryById?: { __typename?: 'Category', _id?: any | null, description?: string | null, image?: string | null, name?: string | null, status?: string | null } | null };

export type GetCategoryNameByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetCategoryNameByIdQuery = { __typename?: 'Query', GetCategoryById?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null };

export type GetCategoryProductsByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
  pagination?: InputMaybe<Pagination>;
}>;


export type GetCategoryProductsByIdQuery = { __typename?: 'Query', GetAllProducts: { __typename?: 'Products', queryCount?: number | null, productsCount?: number | null, products?: Array<{ __typename?: 'Product', _id?: any | null, images?: Array<string> | null, name?: string | null, store?: { __typename?: 'Store', _id?: any | null, name: string } | null, inventory?: { __typename?: 'Inventory', sku?: string | null, _id: any } | null }> | null } };

export type GetCategorySeoByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetCategorySeoByIdQuery = { __typename?: 'Query', GetCategoryById?: { __typename?: 'Category', _id?: any | null, slug?: string | null, seo?: { __typename?: 'Seo', seotitle?: string | null, metadescription?: string | null, keywords?: string | null, _id: any, metatags?: Array<{ __typename?: 'MetaTags', key?: string | null, value?: string | null }> | null } | null } | null };

export type GetCurrentSessionProductsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  name?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProductType>;
  category?: InputMaybe<Scalars['ObjectId']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatusType>;
}>;


export type GetCurrentSessionProductsQuery = { __typename?: 'Query', GetCurrentSessionUserProducts: { __typename?: 'Products', queryCount?: number | null, productsCount?: number | null, products?: Array<{ __typename?: 'Product', _id?: any | null, status?: string | null, brand?: string | null, images?: Array<string> | null, name?: string | null, slug?: string | null, updatedAt?: any | null, createdAt?: any | null, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null, store?: { __typename?: 'Store', _id?: any | null, name: string } | null, options: Array<{ __typename?: 'Option', name?: string | null }>, price?: { __typename?: 'Price', price?: number | null } | null, inventory?: { __typename?: 'Inventory', _id: any, barCode?: string | null, createdAt?: any | null, informOn?: number | null, qunatity?: number | null, sellOutOfStock?: boolean | null, sku?: string | null, trackQunatity?: boolean | null, updatedAt?: any | null, weightPerUnit?: number | null } | null, variations: Array<{ __typename?: 'Variant', variantPrice?: number | null, updatedAt?: any | null, isActive?: boolean | null, images?: Array<string> | null, createdAt?: any | null, _id?: any | null, selectedOption?: Array<{ __typename?: 'SelectedOption', KEY?: string | null, VALUE?: string | null }> | null, inventory?: { __typename?: 'Inventory', _id: any, createdAt?: any | null, barCode?: string | null, informOn?: number | null, qunatity?: number | null, sellOutOfStock?: boolean | null, sku?: string | null, trackQunatity?: boolean | null, updatedAt?: any | null, weightPerUnit?: number | null } | null }> }> | null } };

export type GetFilterUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFilterUsersQuery = { __typename?: 'Query', GetAllUsers: { __typename?: 'Users', users?: Array<{ __typename?: 'User', _id?: any | null, firstName?: string | null, lastName?: string | null }> | null } };

export type GetHomePageSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomePageSettingsQuery = { __typename?: 'Query', GetHomePage: { __typename?: 'Home', _id: any, name?: string | null, banners?: Array<{ __typename?: 'Banners', _id: any, image?: string | null, url?: string | null }> | null, collections?: Array<{ __typename?: 'Collection', _id: any, description?: string | null, title?: string | null, color?: { __typename?: 'Color', _id?: any | null, hexColor?: string | null, hslaColor?: string | null, name?: string | null, rgbaColor?: string | null } | null, products?: Array<{ __typename?: 'Product', _id?: any | null, name?: string | null, slug?: string | null, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null }> | null }> | null, seo?: { __typename?: 'Seo', _id: any, keywords?: string | null, metadescription?: string | null, seotitle?: string | null, metatags?: Array<{ __typename?: 'MetaTags', _id: any, key?: string | null, value?: string | null }> | null } | null, slides?: Array<{ __typename?: 'Slides', _id: any, description?: string | null, image?: string | null, showOverlay?: boolean | null, title?: string | null, action?: { __typename?: 'Action', to?: string | null } | null }> | null, flashSales?: Array<{ __typename?: 'FlashSale', _id: any, description?: string | null, endDate?: any | null, title?: string | null, color?: { __typename?: 'Color', _id?: any | null, hexColor?: string | null, hslaColor?: string | null, name?: string | null, rgbaColor?: string | null } | null, products?: Array<{ __typename?: 'Product', _id?: any | null, name?: string | null, images?: Array<string> | null, slug?: string | null, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null, price?: { __typename?: 'Price', price?: number | null, compareAtPrice?: number | null } | null }> | null }> | null } };

export type GetInventoryHistoryQueryVariables = Exact<{
  productID: Scalars['ObjectId'];
  inventoryID: Scalars['ObjectId'];
}>;


export type GetInventoryHistoryQuery = { __typename?: 'Query', GetProductInventoryHistory: Array<{ __typename?: 'InventoryHistory', _id?: any | null, createdAt?: any | null, refId?: any | null, sales?: number | null, updatedAt?: any | null, oldQuantity?: number | null, newQuantity?: number | null, activity?: { __typename?: 'Activity', activity?: string | null, activityId?: any | null } | null }>, GetProductById: { __typename?: 'Product', name?: string | null, _id?: any | null, variations: Array<{ __typename?: 'Variant', _id?: any | null, selectedOption?: Array<{ __typename?: 'SelectedOption', KEY?: string | null, VALUE?: string | null }> | null }> } };

export type GetMainCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMainCategoriesQuery = { __typename?: 'Query', GetAllCategories?: { __typename?: 'Categories', categories?: Array<{ __typename?: 'Category', _id?: any | null, name?: string | null, hasNodes?: boolean | null }> | null } | null };

export type GetOrderByIdQueryVariables = Exact<{
  orderID: Scalars['ObjectId'];
  storeID?: InputMaybe<Scalars['ObjectId']>;
}>;


export type GetOrderByIdQuery = { __typename: 'Query', GetOrderById: { __typename?: 'Order', _id: any, createdAt?: any | null, paymentStatus?: string | null, paymentType?: string | null, refId: string, status?: string | null, totaleItems?: number | null, totalePrice?: number | null, updatedAt?: any | null, items?: Array<{ __typename?: 'Item', images?: Array<string> | null, itemName?: string | null, itemPrice?: number | null, itemSku?: string | null, itemStatus?: string | null, quntity?: number | null, refId?: any | null, itemOption?: Array<{ __typename?: 'ItemOption', KEY?: string | null, VALUE?: string | null }> | null, itemStore: { __typename?: 'Store', _id?: any | null, name: string } }> | null, orderNotes?: Array<{ __typename?: 'OrderNote', createdAt?: any | null, note?: string | null }> | null, user?: { __typename?: 'User', _id?: any | null, email?: string | null, firstName?: string | null, avatar?: string | null, lastName?: string | null, userAddress?: Array<{ __typename?: 'Address', zipCode?: string | null, city?: string | null, country?: string | null, streetAddress?: string | null, apartment?: string | null, phoneNumber?: { __typename?: 'PhoneNumber', number?: string | null, prefix?: string | null } | null }> | null } | null } };

export type GetAllPayOutsQueryVariables = Exact<{
  pagination?: InputMaybe<Paginations>;
}>;


export type GetAllPayOutsQuery = { __typename?: 'Query', GetAllPayOuts: { __typename?: 'PayOuts', payOutsCount?: number | null, queryCount?: number | null, payouts?: Array<{ __typename?: 'PayOut', _id: any, issueDate: any, createdAt: any, totalAmount: number, transactionId: string, updatedAt: any, stores?: Array<{ __typename?: 'Store', _id?: any | null, name: string, slug?: string | null, vendor?: { __typename?: 'User', _id?: any | null, firstName?: string | null, lastName?: string | null } | null }> | null }> | null }, GetAvailableBlance: { __typename?: 'Balance', totalBlance: number } };

export type GetProductFilesByIdQueryVariables = Exact<{
  product_id: Scalars['ObjectId'];
}>;


export type GetProductFilesByIdQuery = { __typename?: 'Query', GetProductById: { __typename?: 'Product', _id?: any | null, brand?: string | null, isAvilable?: boolean | null, tags?: Array<string> | null, name?: string | null, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null, files: Array<{ __typename?: 'AddOn', description?: string | null, extension?: string | null, fileName?: string | null, size?: string | null, source?: string | null }> } };

export type GetProductGeneralByIdQueryVariables = Exact<{
  product_id: Scalars['ObjectId'];
}>;


export type GetProductGeneralByIdQuery = { __typename: 'Query', GetProductById: { __typename?: 'Product', _id?: any | null, brand?: string | null, description?: string | null, images?: Array<string> | null, name?: string | null, status?: string | null, tags?: Array<string> | null, type?: string | null, isAvilable?: boolean | null, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null, price?: { __typename?: 'Price', chargeAtTax?: boolean | null, compareAtPrice?: number | null, costPerItem?: number | null, price?: number | null } | null } };

export type GetProductInventoryByIdQueryVariables = Exact<{
  product_id: Scalars['ObjectId'];
}>;


export type GetProductInventoryByIdQuery = { __typename?: 'Query', GetProductById: { __typename?: 'Product', _id?: any | null, brand?: string | null, isAvilable?: boolean | null, tags?: Array<string> | null, name?: string | null, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null, inventory?: { __typename?: 'Inventory', _id: any, barCode?: string | null, informOn?: number | null, qunatity?: number | null, sellOutOfStock?: boolean | null, sku?: string | null, trackQunatity?: boolean | null, weightPerUnit?: number | null, updatedAt?: any | null, createdAt?: any | null } | null, variations: Array<{ __typename?: 'Variant', _id?: any | null, selectedOption?: Array<{ __typename?: 'SelectedOption', KEY?: string | null, VALUE?: string | null }> | null, inventory?: { __typename?: 'Inventory', _id: any, barCode?: string | null, createdAt?: any | null, informOn?: number | null, qunatity?: number | null, sellOutOfStock?: boolean | null, sku?: string | null, trackQunatity?: boolean | null, updatedAt?: any | null, weightPerUnit?: number | null } | null }> } };

export type GetProductNameByIdQueryVariables = Exact<{
  product_id: Scalars['ObjectId'];
}>;


export type GetProductNameByIdQuery = { __typename: 'Query', GetProductById: { __typename?: 'Product', _id?: any | null, name?: string | null } };

export type GetProductOptionsByIdQueryVariables = Exact<{
  product_id: Scalars['ObjectId'];
}>;


export type GetProductOptionsByIdQuery = { __typename?: 'Query', GetProductById: { __typename?: 'Product', _id?: any | null, tags?: Array<string> | null, brand?: string | null, isAvilable?: boolean | null, options: Array<{ __typename?: 'Option', inputType?: string | null, isActive?: boolean | null, name?: string | null, values?: Array<string> | null }>, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null } };

export type GetProductRelatedProductsByIdQueryVariables = Exact<{
  product_id: Scalars['ObjectId'];
}>;


export type GetProductRelatedProductsByIdQuery = { __typename?: 'Query', GetProductById: { __typename?: 'Product', _id?: any | null, tags?: Array<string> | null, brand?: string | null, isAvilable?: boolean | null, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null, bundles: Array<{ __typename?: 'Product', _id?: any | null, name?: string | null, images?: Array<string> | null, price?: { __typename?: 'Price', price?: number | null } | null, inventory?: { __typename?: 'Inventory', sku?: string | null } | null }> } };

export type GetProductSeoByIdQueryVariables = Exact<{
  product_id: Scalars['ObjectId'];
}>;


export type GetProductSeoByIdQuery = { __typename?: 'Query', GetProductById: { __typename?: 'Product', _id?: any | null, brand?: string | null, isAvilable?: boolean | null, tags?: Array<string> | null, name?: string | null, slug?: string | null, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null, seo?: { __typename?: 'Seo', seotitle?: string | null, keywords?: string | null, _id: any, metadescription?: string | null, metatags?: Array<{ __typename?: 'MetaTags', _id: any, key?: string | null, value?: string | null }> | null } | null } };

export type GetProductVariationsByIdQueryVariables = Exact<{
  product_id: Scalars['ObjectId'];
}>;


export type GetProductVariationsByIdQuery = { __typename?: 'Query', GetProductById: { __typename?: 'Product', _id?: any | null, tags?: Array<string> | null, brand?: string | null, isAvilable?: boolean | null, variations: Array<{ __typename?: 'Variant', variantPrice?: number | null, isActive?: boolean | null, images?: Array<string> | null, createdAt?: any | null, _id?: any | null, selectedOption?: Array<{ __typename?: 'SelectedOption', KEY?: string | null, VALUE?: string | null }> | null, inventory?: { __typename?: 'Inventory', _id: any, barCode?: string | null, sku?: string | null, weightPerUnit?: number | null } | null }>, category?: { __typename?: 'Category', _id?: any | null, name?: string | null } | null } };

export type GetProductsForRelatedProductsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type GetProductsForRelatedProductsQuery = { __typename?: 'Query', GetAllProducts: { __typename?: 'Products', queryCount?: number | null, productsCount?: number | null, products?: Array<{ __typename?: 'Product', _id?: any | null, name?: string | null, slug?: string | null, images?: Array<string> | null, price?: { __typename?: 'Price', price?: number | null } | null, inventory?: { __typename?: 'Inventory', sku?: string | null } | null, category?: { __typename?: 'Category', name?: string | null } | null, store?: { __typename?: 'Store', name: string } | null }> | null } };

export type GetProductsToAssignQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  selected_category?: InputMaybe<Scalars['ObjectId']>;
}>;


export type GetProductsToAssignQuery = { __typename?: 'Query', GetAllProducts: { __typename?: 'Products', queryCount?: number | null, productsCount?: number | null, products?: Array<{ __typename?: 'Product', _id?: any | null, name?: string | null, slug?: string | null, inventory?: { __typename?: 'Inventory', sku?: string | null } | null, category?: { __typename?: 'Category', name?: string | null } | null, store?: { __typename?: 'Store', name: string } | null }> | null } };

export type GetProfileInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileInfoQuery = { __typename?: 'Query', GetCurrentSessionUser: { __typename?: 'User', _id?: any | null, avatar?: string | null, dateOfBirth?: any | null, email?: string | null, firstName?: string | null, lastName?: string | null, nationality?: string | null, userAddress?: Array<{ __typename?: 'Address', zipCode?: string | null, streetAddress?: string | null, country?: string | null, city?: string | null, apartment?: string | null, phoneNumber?: { __typename?: 'PhoneNumber', number?: string | null, prefix?: string | null } | null }> | null } };

export type GetStoreByIdQueryVariables = Exact<{
  store_id: Scalars['ObjectId'];
}>;


export type GetStoreByIdQuery = { __typename?: 'Query', GetStoreById: { __typename?: 'Store', _id?: any | null, createdAt?: any | null, description?: string | null, name: string, slug?: string | null, status?: string | null, updatedAt?: any | null, storeAddress?: { __typename?: 'Address', apartment?: string | null, city?: string | null, country?: string | null, streetAddress?: string | null, zipCode?: string | null, phoneNumber?: { __typename?: 'PhoneNumber', number?: string | null, prefix?: string | null } | null } | null, storeMedia?: { __typename?: 'StoreMedia', coverPicture?: string | null, logo?: string | null } | null, vendor?: { __typename?: 'User', _id?: any | null, firstName?: string | null, lastName?: string | null } | null } };

export type GetStoreSettingsInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoreSettingsInformationQuery = { __typename?: 'Query', GetCurrentSessionUserStores: { __typename?: 'Stores', stores?: Array<{ __typename?: 'Store', _id?: any | null, description?: string | null, name: string, slug?: string | null, status?: string | null, storeBalance: number, storeAddress?: { __typename?: 'Address', zipCode?: string | null, suiteNumber?: string | null, streetAddress?: string | null, state?: string | null, country?: string | null, city?: string | null, apartment?: string | null, phoneNumber?: { __typename?: 'PhoneNumber', number?: string | null, prefix?: string | null } | null } | null, storeKyc?: { __typename?: 'StoreKyc', additionalSellingPlatforms?: Array<string> | null, alreadySelling?: boolean | null, businessIndustry?: string | null, companyName?: string | null, customersAgeRange?: string | null, dateOfEstablishment?: any | null, mainProducts?: string | null, typeOfCompany?: string | null, website?: string | null, yearlyRevenue?: string | null } | null, storeMedia?: { __typename?: 'StoreMedia', coverPicture?: string | null, logo?: string | null } | null }> | null } };

export type GetStoreStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoreStatusQuery = { __typename: 'Query', GetCurrentSessionUserStores: { __typename?: 'Stores', stores?: Array<{ __typename?: 'Store', _id?: any | null, status?: string | null, slug?: string | null, storeBalance: number }> | null } };

export type GetUserDashboardQueryVariables = Exact<{
  storeID: Scalars['ObjectId'];
}>;


export type GetUserDashboardQuery = { __typename?: 'Query', GetCurrentSessionOrders: { __typename?: 'Orders', ordersCount?: number | null, queryCount?: number | null, orders?: Array<{ __typename?: 'Order', totalePrice?: number | null }> | null } };

export type GetUserDetailsQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetUserDetailsQuery = { __typename?: 'Query', GetUserById: { __typename?: 'User', _id?: any | null, avatar?: string | null, createdAt?: any | null, dateOfBirth?: any | null, email?: string | null, firstName?: string | null, isApproved?: boolean | null, isArchived?: boolean | null, isBlocked?: boolean | null, isConfirmed?: boolean | null, lastName?: string | null, nationality?: string | null, updatedAt?: any | null, role?: string | null, userAddress?: Array<{ __typename?: 'Address', zipCode?: string | null, suiteNumber?: string | null, streetAddress?: string | null, state?: string | null, latitude?: string | null, langintude?: string | null, country?: string | null, city?: string | null, apartment?: string | null, phoneNumber?: { __typename?: 'PhoneNumber', number?: string | null, prefix?: string | null } | null }> | null } };

export type GetVendorOrdersQueryVariables = Exact<{
  contains?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Paginations>;
  user?: InputMaybe<Scalars['ObjectId']>;
  status?: InputMaybe<Scalars['String']>;
  storeID?: InputMaybe<Scalars['ObjectId']>;
}>;


export type GetVendorOrdersQuery = { __typename?: 'Query', GetCurrentSessionOrders: { __typename?: 'Orders', ordersCount?: number | null, queryCount?: number | null, orders?: Array<{ __typename?: 'Order', _id: any, createdAt?: any | null, paymentStatus?: string | null, paymentType?: string | null, refId: string, status?: string | null, totalePrice?: number | null, updatedAt?: any | null, totaleItems?: number | null, user?: { __typename?: 'User', lastName?: string | null, firstName?: string | null, email?: string | null, _id?: any | null, userAddress?: Array<{ __typename?: 'Address', phoneNumber?: { __typename?: 'PhoneNumber', number?: string | null, prefix?: string | null } | null }> | null } | null, orderNotes?: Array<{ __typename?: 'OrderNote', note?: string | null }> | null }> | null } };

export type GetVendorStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVendorStoresQuery = { __typename?: 'Query', GetCurrentSessionUserStores: { __typename?: 'Stores', stores?: Array<{ __typename?: 'Store', _id?: any | null, name: string, storeMedia?: { __typename?: 'StoreMedia', logo?: string | null } | null }> | null } };

export type ResendVerificationEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendVerificationEmailMutation = { __typename?: 'Mutation', ResendConfrimEmail: { __typename?: 'SuccessResponse', code: number, info: string, status: string } };

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', RestUserCredentials: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type SetAuthenticateMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SetAuthenticateMutation = { __typename?: 'Mutation', AuthenticateUser: { __typename?: 'Authenticate', token?: { __typename?: 'Authorization', accessToken: string, refreshToken: string } | null, user: { __typename?: 'User', isApproved?: boolean | null, isArchived?: boolean | null, isBlocked?: boolean | null, isConfirmed?: boolean | null, lastName?: string | null, role?: string | null, firstName?: string | null, _id?: any | null, avatar?: string | null, email?: string | null } } };

export type SetOrderItemStatusMutationVariables = Exact<{
  status: ItemStatusType;
  refId: Scalars['ObjectId'];
  _id: Scalars['ObjectId'];
}>;


export type SetOrderItemStatusMutation = { __typename?: 'Mutation', SetOrderItemStatus: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type SetProductStatusMutationVariables = Exact<{
  status: ProductStatusType;
  product_id: Scalars['ObjectId'];
}>;


export type SetProductStatusMutation = { __typename?: 'Mutation', SetProductStatus: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type SetRegisterVendorMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type SetRegisterVendorMutation = { __typename?: 'Mutation', RegisterVendor: { __typename?: 'Authenticate', token?: { __typename?: 'Authorization', accessToken: string, refreshToken: string } | null, user: { __typename?: 'User', _id?: any | null } } };

export type UnassginProductCategoryMutationVariables = Exact<{
  product_id: Scalars['ObjectId'];
}>;


export type UnassginProductCategoryMutation = { __typename?: 'Mutation', UnassginProductCategory: { __typename?: 'SuccessResponse', code: number, info: string, status: string } };

export type UpdateCategorySeoMutationVariables = Exact<{
  categeory_id: Scalars['ObjectId'];
  seoTitle?: InputMaybe<Scalars['String']>;
  metadescription?: InputMaybe<Scalars['String']>;
  metatags?: InputMaybe<Array<MetaTagsInput> | MetaTagsInput>;
}>;


export type UpdateCategorySeoMutation = { __typename?: 'Mutation', UpdateCategory?: { __typename?: 'SuccessResponse', code: number, info: string } | null };

export type UpdateHomeSettingsMutationVariables = Exact<{
  homePageID: Scalars['ObjectId'];
  collections?: InputMaybe<Array<CollectionInput> | CollectionInput>;
  slides?: InputMaybe<Array<SlidesInput> | SlidesInput>;
  banners?: InputMaybe<Array<BannersInput> | BannersInput>;
  seo?: InputMaybe<SeoInput>;
  flashSales?: InputMaybe<Array<FlashSaleInput> | FlashSaleInput>;
}>;


export type UpdateHomeSettingsMutation = { __typename?: 'Mutation', UpdateHomePageById: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateInventoryQuantityMutationVariables = Exact<{
  quantity?: InputMaybe<Scalars['Float']>;
  activity?: InputMaybe<InventoryActivity>;
  _id: Scalars['ObjectId'];
}>;


export type UpdateInventoryQuantityMutation = { __typename?: 'Mutation', UpdateProductInventoryQuantity: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateOrderStatusMutationVariables = Exact<{
  status: Scalars['String'];
  orderID: Scalars['ObjectId'];
}>;


export type UpdateOrderStatusMutation = { __typename?: 'Mutation', SetOrderStatus: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateProductsFilesByIdMutationVariables = Exact<{
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ObjectId']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isAvilable?: InputMaybe<Scalars['Boolean']>;
  product_id: Scalars['ObjectId'];
  files?: InputMaybe<Array<AddOnInput> | AddOnInput>;
}>;


export type UpdateProductsFilesByIdMutation = { __typename?: 'Mutation', UpdateProduct: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateProductMutationVariables = Exact<{
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProductType>;
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ObjectId']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isAvilable?: InputMaybe<Scalars['Boolean']>;
  images?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  price?: InputMaybe<PriceInput>;
  product_id: Scalars['ObjectId'];
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', UpdateProduct: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateProductsInventoryByIdMutationVariables = Exact<{
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ObjectId']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isAvilable?: InputMaybe<Scalars['Boolean']>;
  product_id: Scalars['ObjectId'];
}>;


export type UpdateProductsInventoryByIdMutation = { __typename?: 'Mutation', UpdateProduct: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateProductInventoryQuantityMutationVariables = Exact<{
  quantity?: InputMaybe<Scalars['Float']>;
  weightPerUnit?: InputMaybe<Scalars['Float']>;
  informOn?: InputMaybe<Scalars['Float']>;
  trackQunatity?: InputMaybe<Scalars['Boolean']>;
  sellOutOfStock?: InputMaybe<Scalars['Boolean']>;
  sku?: InputMaybe<Scalars['String']>;
  barCode?: InputMaybe<Scalars['String']>;
  activity?: InputMaybe<InventoryActivity>;
  _id: Scalars['ObjectId'];
}>;


export type UpdateProductInventoryQuantityMutation = { __typename?: 'Mutation', UpdateProductInventoryQuantity: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateProductOptionsMutationVariables = Exact<{
  options?: InputMaybe<Array<OptionInput> | OptionInput>;
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ObjectId']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isAvilable?: InputMaybe<Scalars['Boolean']>;
  product_id: Scalars['ObjectId'];
}>;


export type UpdateProductOptionsMutation = { __typename?: 'Mutation', UpdateProduct: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateProductRelatedProductsMutationVariables = Exact<{
  bundles?: InputMaybe<Array<Scalars['ObjectId']> | Scalars['ObjectId']>;
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ObjectId']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isAvilable?: InputMaybe<Scalars['Boolean']>;
  product_id: Scalars['ObjectId'];
}>;


export type UpdateProductRelatedProductsMutation = { __typename?: 'Mutation', UpdateProduct: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateProductsSeoByIdMutationVariables = Exact<{
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ObjectId']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isAvilable?: InputMaybe<Scalars['Boolean']>;
  product_id: Scalars['ObjectId'];
  seo?: InputMaybe<SeoInput>;
}>;


export type UpdateProductsSeoByIdMutation = { __typename?: 'Mutation', UpdateProduct: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateProductsVariationsByIdMutationVariables = Exact<{
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ObjectId']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isAvilable?: InputMaybe<Scalars['Boolean']>;
  product_id: Scalars['ObjectId'];
}>;


export type UpdateProductsVariationsByIdMutation = { __typename?: 'Mutation', UpdateProduct: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateProfileSettingsMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phonePrefix?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  apartment?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  avatar?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProfileSettingsMutation = { __typename?: 'Mutation', UpdateCurrentSessionUser: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateSecuritySettingsMutationVariables = Exact<{
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}>;


export type UpdateSecuritySettingsMutation = { __typename?: 'Mutation', UpdateCurrentSessionUserPassword: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateStoreMutationVariables = Exact<{
  vendor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  coverPicture?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  apartment?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  phonePrefix?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
  store_id: Scalars['ObjectId'];
}>;


export type UpdateStoreMutation = { __typename?: 'Mutation', UpdateStore: { __typename?: 'SuccessResponse', _id?: any | null, code: number, status: string, info: string } };

export type UpdateStoreCompanyDataMutationVariables = Exact<{
  website?: InputMaybe<Scalars['String']>;
  typeOfCompany?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  dateOfEstablishment?: InputMaybe<Scalars['DateTime']>;
  country?: InputMaybe<Scalars['String']>;
  apartment?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  store_id: Scalars['ObjectId'];
}>;


export type UpdateStoreCompanyDataMutation = { __typename?: 'Mutation', UpdateStore: { __typename?: 'SuccessResponse', status: string, info: string, code: number } };

export type UpdateStoreKycMutationVariables = Exact<{
  alreadySelling?: InputMaybe<Scalars['Boolean']>;
  yearlyRevenue?: InputMaybe<Scalars['String']>;
  mainProducts?: InputMaybe<Scalars['String']>;
  customersAgeRange?: InputMaybe<Scalars['String']>;
  businessIndustry?: InputMaybe<Scalars['String']>;
  additionalSellingPlatforms?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  store_id: Scalars['ObjectId'];
}>;


export type UpdateStoreKycMutation = { __typename?: 'Mutation', UpdateStore: { __typename?: 'SuccessResponse', status: string, info: string, code: number } };

export type UpdateStoreSettingsMutationVariables = Exact<{
  storeId: Scalars['ObjectId'];
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  coverPicture?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phonePrefix?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  apartment?: InputMaybe<Scalars['String']>;
  businessIndustry?: InputMaybe<Scalars['String']>;
}>;


export type UpdateStoreSettingsMutation = { __typename?: 'Mutation', UpdateStore: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateStoreStatusMutationVariables = Exact<{
  status?: InputMaybe<Scalars['String']>;
  store_id: Scalars['ObjectId'];
}>;


export type UpdateStoreStatusMutation = { __typename?: 'Mutation', UpdateStore: { __typename?: 'SuccessResponse', _id?: any | null, code: number, info: string, status: string } };

export type UpdateUserDetailsMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  avatar?: InputMaybe<Scalars['String']>;
  userID: Scalars['ObjectId'];
}>;


export type UpdateUserDetailsMutation = { __typename?: 'Mutation', UpdateUser: { __typename?: 'SuccessResponse', code: number, info: string, status: string } };

export type UpdateVariationMutationVariables = Exact<{
  images?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  variantPrice?: InputMaybe<Scalars['Float']>;
  sku?: InputMaybe<Scalars['String']>;
  barcode?: InputMaybe<Scalars['String']>;
  weightPerUnit?: InputMaybe<Scalars['Float']>;
  selectedOption?: InputMaybe<Array<SelectedOptionInput> | SelectedOptionInput>;
  variation_id: Scalars['ObjectId'];
}>;


export type UpdateVariationMutation = { __typename?: 'Mutation', UpdateProductVariant: { __typename?: 'Variant', _id?: any | null } };


export const UpdateCategoryGeneralDocument = gql`
    mutation updateCategoryGeneral($name: String, $description: String, $image: String, $id: ObjectId!, $status: StatusType) {
  UpdateCategory(
    entry: {name: $name, description: $description, image: $image, status: $status}
    _id: $id
  ) {
    code
    info
    status
  }
}
    `;
export type UpdateCategoryGeneralMutationFn = Apollo.MutationFunction<UpdateCategoryGeneralMutation, UpdateCategoryGeneralMutationVariables>;

/**
 * __useUpdateCategoryGeneralMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryGeneralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryGeneralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryGeneralMutation, { data, loading, error }] = useUpdateCategoryGeneralMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      image: // value for 'image'
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateCategoryGeneralMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryGeneralMutation, UpdateCategoryGeneralMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryGeneralMutation, UpdateCategoryGeneralMutationVariables>(UpdateCategoryGeneralDocument, options);
      }
export type UpdateCategoryGeneralMutationHookResult = ReturnType<typeof useUpdateCategoryGeneralMutation>;
export type UpdateCategoryGeneralMutationResult = Apollo.MutationResult<UpdateCategoryGeneralMutation>;
export type UpdateCategoryGeneralMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryGeneralMutation, UpdateCategoryGeneralMutationVariables>;
export const AddOrderNoteDocument = gql`
    mutation addOrderNote($orderNotes: [OrderNoteInput!], $orderId: ObjectId!) {
  UpdateOrder(entry: {orderNotes: $orderNotes}, _id: $orderId) {
    _id
    code
    info
    status
  }
}
    `;
export type AddOrderNoteMutationFn = Apollo.MutationFunction<AddOrderNoteMutation, AddOrderNoteMutationVariables>;

/**
 * __useAddOrderNoteMutation__
 *
 * To run a mutation, you first call `useAddOrderNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderNoteMutation, { data, loading, error }] = useAddOrderNoteMutation({
 *   variables: {
 *      orderNotes: // value for 'orderNotes'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useAddOrderNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddOrderNoteMutation, AddOrderNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrderNoteMutation, AddOrderNoteMutationVariables>(AddOrderNoteDocument, options);
      }
export type AddOrderNoteMutationHookResult = ReturnType<typeof useAddOrderNoteMutation>;
export type AddOrderNoteMutationResult = Apollo.MutationResult<AddOrderNoteMutation>;
export type AddOrderNoteMutationOptions = Apollo.BaseMutationOptions<AddOrderNoteMutation, AddOrderNoteMutationVariables>;
export const AssignProductsToCategoryDocument = gql`
    mutation assignProductsToCategory($products: [ObjectId!]!, $categeory_id: ObjectId!) {
  AssignProductsCategory(products: $products, _id: $categeory_id) {
    code
    info
    status
  }
}
    `;
export type AssignProductsToCategoryMutationFn = Apollo.MutationFunction<AssignProductsToCategoryMutation, AssignProductsToCategoryMutationVariables>;

/**
 * __useAssignProductsToCategoryMutation__
 *
 * To run a mutation, you first call `useAssignProductsToCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignProductsToCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignProductsToCategoryMutation, { data, loading, error }] = useAssignProductsToCategoryMutation({
 *   variables: {
 *      products: // value for 'products'
 *      categeory_id: // value for 'categeory_id'
 *   },
 * });
 */
export function useAssignProductsToCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AssignProductsToCategoryMutation, AssignProductsToCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignProductsToCategoryMutation, AssignProductsToCategoryMutationVariables>(AssignProductsToCategoryDocument, options);
      }
export type AssignProductsToCategoryMutationHookResult = ReturnType<typeof useAssignProductsToCategoryMutation>;
export type AssignProductsToCategoryMutationResult = Apollo.MutationResult<AssignProductsToCategoryMutation>;
export type AssignProductsToCategoryMutationOptions = Apollo.BaseMutationOptions<AssignProductsToCategoryMutation, AssignProductsToCategoryMutationVariables>;
export const ConfirmUserEmailDocument = gql`
    mutation confirmUserEmail($token: String!) {
  ConfirmUserEmail(token: $token) {
    token {
      accessToken
      refreshToken
    }
  }
}
    `;
export type ConfirmUserEmailMutationFn = Apollo.MutationFunction<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>;

/**
 * __useConfirmUserEmailMutation__
 *
 * To run a mutation, you first call `useConfirmUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserEmailMutation, { data, loading, error }] = useConfirmUserEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>(ConfirmUserEmailDocument, options);
      }
export type ConfirmUserEmailMutationHookResult = ReturnType<typeof useConfirmUserEmailMutation>;
export type ConfirmUserEmailMutationResult = Apollo.MutationResult<ConfirmUserEmailMutation>;
export type ConfirmUserEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($name: String, $description: String, $image: String, $parent: ObjectId, $status: StatusType) {
  CreateCategory(
    entry: {name: $name, description: $description, image: $image, parent: $parent, status: $status}
  ) {
    code
    info
    status
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      image: // value for 'image'
 *      parent: // value for 'parent'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const CreatePayoutDocument = gql`
    mutation createPayout($issueDate: DateTime!, $totalAmount: Float!, $stores: [ObjectId!]) {
  CreatePayout(
    entry: {issueDate: $issueDate, totalAmount: $totalAmount, stores: $stores}
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type CreatePayoutMutationFn = Apollo.MutationFunction<CreatePayoutMutation, CreatePayoutMutationVariables>;

/**
 * __useCreatePayoutMutation__
 *
 * To run a mutation, you first call `useCreatePayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayoutMutation, { data, loading, error }] = useCreatePayoutMutation({
 *   variables: {
 *      issueDate: // value for 'issueDate'
 *      totalAmount: // value for 'totalAmount'
 *      stores: // value for 'stores'
 *   },
 * });
 */
export function useCreatePayoutMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayoutMutation, CreatePayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayoutMutation, CreatePayoutMutationVariables>(CreatePayoutDocument, options);
      }
export type CreatePayoutMutationHookResult = ReturnType<typeof useCreatePayoutMutation>;
export type CreatePayoutMutationResult = Apollo.MutationResult<CreatePayoutMutation>;
export type CreatePayoutMutationOptions = Apollo.BaseMutationOptions<CreatePayoutMutation, CreatePayoutMutationVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($name: String!, $description: String, $type: ProductType, $brand: String, $category: ObjectId, $tags: [String!], $isAvilable: Boolean, $images: [String!], $price: PriceInput, $store_id: ObjectId!) {
  CreateProduct(
    entry: {name: $name, store: $store_id, description: $description, type: $type, brand: $brand, category: $category, tags: $tags, isAvilable: $isAvilable, images: $images, price: $price}
  ) {
    status
    info
    code
    _id
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      type: // value for 'type'
 *      brand: // value for 'brand'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *      isAvilable: // value for 'isAvilable'
 *      images: // value for 'images'
 *      price: // value for 'price'
 *      store_id: // value for 'store_id'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const CreateProductVariationDocument = gql`
    mutation createProductVariation($images: [String!], $isActive: Boolean, $variantPrice: Float, $sku: String, $barcode: String, $weightPerUnit: Float, $selectedOption: [SelectedOptionInput!], $product_id: ObjectId!) {
  CreateVariant(
    entry: {images: $images, isActive: $isActive, variantPrice: $variantPrice, inventory: {sku: $sku, barCode: $barcode, weightPerUnit: $weightPerUnit}, selectedOption: $selectedOption}
    _id: $product_id
  ) {
    _id
  }
}
    `;
export type CreateProductVariationMutationFn = Apollo.MutationFunction<CreateProductVariationMutation, CreateProductVariationMutationVariables>;

/**
 * __useCreateProductVariationMutation__
 *
 * To run a mutation, you first call `useCreateProductVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductVariationMutation, { data, loading, error }] = useCreateProductVariationMutation({
 *   variables: {
 *      images: // value for 'images'
 *      isActive: // value for 'isActive'
 *      variantPrice: // value for 'variantPrice'
 *      sku: // value for 'sku'
 *      barcode: // value for 'barcode'
 *      weightPerUnit: // value for 'weightPerUnit'
 *      selectedOption: // value for 'selectedOption'
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useCreateProductVariationMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductVariationMutation, CreateProductVariationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductVariationMutation, CreateProductVariationMutationVariables>(CreateProductVariationDocument, options);
      }
export type CreateProductVariationMutationHookResult = ReturnType<typeof useCreateProductVariationMutation>;
export type CreateProductVariationMutationResult = Apollo.MutationResult<CreateProductVariationMutation>;
export type CreateProductVariationMutationOptions = Apollo.BaseMutationOptions<CreateProductVariationMutation, CreateProductVariationMutationVariables>;
export const CreateProductVariantDocument = gql`
    mutation createProductVariant($selectedOption: [SelectedOptionInput!], $weightPerUnit: Float, $barcode: String, $sku: String, $variantPrice: Float, $isActive: Boolean, $images: [String!], $product_id: ObjectId!) {
  CreateVariant(
    entry: {selectedOption: $selectedOption, inventory: {weightPerUnit: $weightPerUnit, barCode: $barcode, sku: $sku}, variantPrice: $variantPrice, isActive: $isActive, images: $images}
    _id: $product_id
  ) {
    _id
    images
    inventory {
      _id
      weightPerUnit
      sku
      barCode
    }
    isActive
    selectedOption {
      KEY
      VALUE
    }
    variantPrice
  }
}
    `;
export type CreateProductVariantMutationFn = Apollo.MutationFunction<CreateProductVariantMutation, CreateProductVariantMutationVariables>;

/**
 * __useCreateProductVariantMutation__
 *
 * To run a mutation, you first call `useCreateProductVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductVariantMutation, { data, loading, error }] = useCreateProductVariantMutation({
 *   variables: {
 *      selectedOption: // value for 'selectedOption'
 *      weightPerUnit: // value for 'weightPerUnit'
 *      barcode: // value for 'barcode'
 *      sku: // value for 'sku'
 *      variantPrice: // value for 'variantPrice'
 *      isActive: // value for 'isActive'
 *      images: // value for 'images'
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useCreateProductVariantMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductVariantMutation, CreateProductVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductVariantMutation, CreateProductVariantMutationVariables>(CreateProductVariantDocument, options);
      }
export type CreateProductVariantMutationHookResult = ReturnType<typeof useCreateProductVariantMutation>;
export type CreateProductVariantMutationResult = Apollo.MutationResult<CreateProductVariantMutation>;
export type CreateProductVariantMutationOptions = Apollo.BaseMutationOptions<CreateProductVariantMutation, CreateProductVariantMutationVariables>;
export const CreateStoreDocument = gql`
    mutation createStore($vendor: String, $name: String, $description: String, $status: String, $coverPicture: String, $logo: String, $country: String, $streetAddress: String, $apartment: String, $city: String, $phonePrefix: String, $phoneNumber: String, $zipCode: String) {
  CreateStore(
    entry: {vendor: $vendor, name: $name, description: $description, status: $status, storeMedia: {coverPicture: $coverPicture, logo: $logo}, storeAddress: {country: $country, streetAddress: $streetAddress, apartment: $apartment, city: $city, phoneNumber: {prefix: $phonePrefix, number: $phoneNumber}, zipCode: $zipCode}}
  ) {
    _id
    code
    status
    info
  }
}
    `;
export type CreateStoreMutationFn = Apollo.MutationFunction<CreateStoreMutation, CreateStoreMutationVariables>;

/**
 * __useCreateStoreMutation__
 *
 * To run a mutation, you first call `useCreateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreMutation, { data, loading, error }] = useCreateStoreMutation({
 *   variables: {
 *      vendor: // value for 'vendor'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      coverPicture: // value for 'coverPicture'
 *      logo: // value for 'logo'
 *      country: // value for 'country'
 *      streetAddress: // value for 'streetAddress'
 *      apartment: // value for 'apartment'
 *      city: // value for 'city'
 *      phonePrefix: // value for 'phonePrefix'
 *      phoneNumber: // value for 'phoneNumber'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useCreateStoreMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoreMutation, CreateStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoreMutation, CreateStoreMutationVariables>(CreateStoreDocument, options);
      }
export type CreateStoreMutationHookResult = ReturnType<typeof useCreateStoreMutation>;
export type CreateStoreMutationResult = Apollo.MutationResult<CreateStoreMutation>;
export type CreateStoreMutationOptions = Apollo.BaseMutationOptions<CreateStoreMutation, CreateStoreMutationVariables>;
export const CreateVendorStoreDocument = gql`
    mutation createVendorStore($name: String, $logo: String) {
  CreateStore(entry: {name: $name, storeMedia: {logo: $logo}}) {
    code
    info
    status
    _id
  }
}
    `;
export type CreateVendorStoreMutationFn = Apollo.MutationFunction<CreateVendorStoreMutation, CreateVendorStoreMutationVariables>;

/**
 * __useCreateVendorStoreMutation__
 *
 * To run a mutation, you first call `useCreateVendorStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorStoreMutation, { data, loading, error }] = useCreateVendorStoreMutation({
 *   variables: {
 *      name: // value for 'name'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useCreateVendorStoreMutation(baseOptions?: Apollo.MutationHookOptions<CreateVendorStoreMutation, CreateVendorStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVendorStoreMutation, CreateVendorStoreMutationVariables>(CreateVendorStoreDocument, options);
      }
export type CreateVendorStoreMutationHookResult = ReturnType<typeof useCreateVendorStoreMutation>;
export type CreateVendorStoreMutationResult = Apollo.MutationResult<CreateVendorStoreMutation>;
export type CreateVendorStoreMutationOptions = Apollo.BaseMutationOptions<CreateVendorStoreMutation, CreateVendorStoreMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($category_id: ObjectId!) {
  DeleteCategory(_id: $category_id) {
    code
    info
    status
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      category_id: // value for 'category_id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($product_id: [ObjectId!]!) {
  DeleteProduct(_id: $product_id) {
    code
    _id
    info
    status
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const DeleteProductVariantDocument = gql`
    mutation deleteProductVariant($product_id: ObjectId!, $variant_id: ObjectId!) {
  DeleteProductVariant(variant_id: $variant_id, _id: $product_id) {
    _id
    code
    status
    info
  }
}
    `;
export type DeleteProductVariantMutationFn = Apollo.MutationFunction<DeleteProductVariantMutation, DeleteProductVariantMutationVariables>;

/**
 * __useDeleteProductVariantMutation__
 *
 * To run a mutation, you first call `useDeleteProductVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductVariantMutation, { data, loading, error }] = useDeleteProductVariantMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *      variant_id: // value for 'variant_id'
 *   },
 * });
 */
export function useDeleteProductVariantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductVariantMutation, DeleteProductVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductVariantMutation, DeleteProductVariantMutationVariables>(DeleteProductVariantDocument, options);
      }
export type DeleteProductVariantMutationHookResult = ReturnType<typeof useDeleteProductVariantMutation>;
export type DeleteProductVariantMutationResult = Apollo.MutationResult<DeleteProductVariantMutation>;
export type DeleteProductVariantMutationOptions = Apollo.BaseMutationOptions<DeleteProductVariantMutation, DeleteProductVariantMutationVariables>;
export const DeleteStoreDocument = gql`
    mutation deleteStore($store_id: ObjectId!) {
  DeleteStore(_id: $store_id) {
    _id
    code
    status
    info
  }
}
    `;
export type DeleteStoreMutationFn = Apollo.MutationFunction<DeleteStoreMutation, DeleteStoreMutationVariables>;

/**
 * __useDeleteStoreMutation__
 *
 * To run a mutation, you first call `useDeleteStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoreMutation, { data, loading, error }] = useDeleteStoreMutation({
 *   variables: {
 *      store_id: // value for 'store_id'
 *   },
 * });
 */
export function useDeleteStoreMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoreMutation, DeleteStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoreMutation, DeleteStoreMutationVariables>(DeleteStoreDocument, options);
      }
export type DeleteStoreMutationHookResult = ReturnType<typeof useDeleteStoreMutation>;
export type DeleteStoreMutationResult = Apollo.MutationResult<DeleteStoreMutation>;
export type DeleteStoreMutationOptions = Apollo.BaseMutationOptions<DeleteStoreMutation, DeleteStoreMutationVariables>;
export const ExportProductsDocument = gql`
    query ExportProducts($storeID: ObjectId, $selectedProducts: [ObjectId!]) {
  ExportProducts(_id: $storeID, selectedProducts: $selectedProducts)
}
    `;

/**
 * __useExportProductsQuery__
 *
 * To run a query within a React component, call `useExportProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProductsQuery({
 *   variables: {
 *      storeID: // value for 'storeID'
 *      selectedProducts: // value for 'selectedProducts'
 *   },
 * });
 */
export function useExportProductsQuery(baseOptions?: Apollo.QueryHookOptions<ExportProductsQuery, ExportProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportProductsQuery, ExportProductsQueryVariables>(ExportProductsDocument, options);
      }
export function useExportProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportProductsQuery, ExportProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportProductsQuery, ExportProductsQueryVariables>(ExportProductsDocument, options);
        }
export type ExportProductsQueryHookResult = ReturnType<typeof useExportProductsQuery>;
export type ExportProductsLazyQueryHookResult = ReturnType<typeof useExportProductsLazyQuery>;
export type ExportProductsQueryResult = Apollo.QueryResult<ExportProductsQuery, ExportProductsQueryVariables>;
export const ForgetPasswordDocument = gql`
    mutation forgetPassword($email: String!) {
  ForgetPassword(email: $email) {
    _id
    code
    info
    status
  }
}
    `;
export type ForgetPasswordMutationFn = Apollo.MutationFunction<ForgetPasswordMutation, ForgetPasswordMutationVariables>;

/**
 * __useForgetPasswordMutation__
 *
 * To run a mutation, you first call `useForgetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgetPasswordMutation, { data, loading, error }] = useForgetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgetPasswordMutation, ForgetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgetPasswordMutation, ForgetPasswordMutationVariables>(ForgetPasswordDocument, options);
      }
export type ForgetPasswordMutationHookResult = ReturnType<typeof useForgetPasswordMutation>;
export type ForgetPasswordMutationResult = Apollo.MutationResult<ForgetPasswordMutation>;
export type ForgetPasswordMutationOptions = Apollo.BaseMutationOptions<ForgetPasswordMutation, ForgetPasswordMutationVariables>;
export const GetAllCountriesDocument = gql`
    query getAllCountries($search: String) {
  GetAllCountries(filters: {pagination: {disabled: true}, contains: $search}) {
    dialCode
    flag
    isoCode
    name
  }
}
    `;

/**
 * __useGetAllCountriesQuery__
 *
 * To run a query within a React component, call `useGetAllCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCountriesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAllCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCountriesQuery, GetAllCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCountriesQuery, GetAllCountriesQueryVariables>(GetAllCountriesDocument, options);
      }
export function useGetAllCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCountriesQuery, GetAllCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCountriesQuery, GetAllCountriesQueryVariables>(GetAllCountriesDocument, options);
        }
export type GetAllCountriesQueryHookResult = ReturnType<typeof useGetAllCountriesQuery>;
export type GetAllCountriesLazyQueryHookResult = ReturnType<typeof useGetAllCountriesLazyQuery>;
export type GetAllCountriesQueryResult = Apollo.QueryResult<GetAllCountriesQuery, GetAllCountriesQueryVariables>;
export const GetAllOrdersDocument = gql`
    query getAllOrders($contains: String, $pagination: Paginations, $user: ObjectId, $status: String) {
  GetAllOrders(
    sort: {createdAt: -1}
    filters: {contains: $contains, pagination: $pagination, user: $user, status: $status}
  ) {
    orders {
      _id
      user {
        lastName
        firstName
        email
        _id
        userAddress {
          phoneNumber {
            number
            prefix
          }
        }
      }
      createdAt
      paymentStatus
      paymentType
      orderNotes {
        note
      }
      refId
      status
      totalePrice
      updatedAt
      totaleItems
    }
    ordersCount
    queryCount
  }
}
    `;

/**
 * __useGetAllOrdersQuery__
 *
 * To run a query within a React component, call `useGetAllOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrdersQuery({
 *   variables: {
 *      contains: // value for 'contains'
 *      pagination: // value for 'pagination'
 *      user: // value for 'user'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetAllOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOrdersQuery, GetAllOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOrdersQuery, GetAllOrdersQueryVariables>(GetAllOrdersDocument, options);
      }
export function useGetAllOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrdersQuery, GetAllOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOrdersQuery, GetAllOrdersQueryVariables>(GetAllOrdersDocument, options);
        }
export type GetAllOrdersQueryHookResult = ReturnType<typeof useGetAllOrdersQuery>;
export type GetAllOrdersLazyQueryHookResult = ReturnType<typeof useGetAllOrdersLazyQuery>;
export type GetAllOrdersQueryResult = Apollo.QueryResult<GetAllOrdersQuery, GetAllOrdersQueryVariables>;
export const GetAllProductsDocument = gql`
    query getAllProducts($pagination: Pagination, $name: String, $brand: String, $type: ProductType, $category: ObjectId, $search: String, $status: ProductStatusType, $store: ObjectId) {
  GetAllProducts(
    filters: {pagination: $pagination, name: $name, brand: $brand, type: $type, category: $category, status: $status, contains: $search, store: $store, exclude: {status: DRAFT}}
    sort: {createdAt: -1}
  ) {
    products {
      _id
      status
      brand
      images
      name
      slug
      updatedAt
      category {
        _id
        name
      }
      store {
        _id
        name
      }
      createdAt
      options {
        name
      }
      price {
        price
      }
      inventory {
        _id
        barCode
        createdAt
        informOn
        qunatity
        sellOutOfStock
        sku
        trackQunatity
        updatedAt
        weightPerUnit
      }
      variations {
        variantPrice
        updatedAt
        selectedOption {
          KEY
          VALUE
        }
        isActive
        inventory {
          _id
          createdAt
          barCode
          informOn
          qunatity
          sellOutOfStock
          sku
          trackQunatity
          updatedAt
          weightPerUnit
        }
        images
        createdAt
        _id
      }
    }
    productsCount
    queryCount
  }
}
    `;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      name: // value for 'name'
 *      brand: // value for 'brand'
 *      type: // value for 'type'
 *      category: // value for 'category'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      store: // value for 'store'
 *   },
 * });
 */
export function useGetAllProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
      }
export function useGetAllProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
        }
export type GetAllProductsQueryHookResult = ReturnType<typeof useGetAllProductsQuery>;
export type GetAllProductsLazyQueryHookResult = ReturnType<typeof useGetAllProductsLazyQuery>;
export type GetAllProductsQueryResult = Apollo.QueryResult<GetAllProductsQuery, GetAllProductsQueryVariables>;
export const GetAllStoresDocument = gql`
    query getAllStores($vendor: String, $name: String, $status: String, $contains: String, $pagination: Pagination) {
  GetAllStores(
    filters: {vendor: $vendor, name: $name, status: $status, contains: $contains, pagination: $pagination}
    sort: {createdAt: -1}
  ) {
    storesCount
    stores {
      vendor {
        _id
        firstName
        lastName
      }
      updatedAt
      _id
      createdAt
      status
      name
      slug
      storeKyc {
        companyName
      }
      storeAddress {
        country
        phoneNumber {
          number
          prefix
        }
      }
      storeMedia {
        logo
      }
    }
    queryCount
  }
}
    `;

/**
 * __useGetAllStoresQuery__
 *
 * To run a query within a React component, call `useGetAllStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStoresQuery({
 *   variables: {
 *      vendor: // value for 'vendor'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      contains: // value for 'contains'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetAllStoresQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStoresQuery, GetAllStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStoresQuery, GetAllStoresQueryVariables>(GetAllStoresDocument, options);
      }
export function useGetAllStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStoresQuery, GetAllStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStoresQuery, GetAllStoresQueryVariables>(GetAllStoresDocument, options);
        }
export type GetAllStoresQueryHookResult = ReturnType<typeof useGetAllStoresQuery>;
export type GetAllStoresLazyQueryHookResult = ReturnType<typeof useGetAllStoresLazyQuery>;
export type GetAllStoresQueryResult = Apollo.QueryResult<GetAllStoresQuery, GetAllStoresQueryVariables>;
export const GetAllStoresPayoutDocument = gql`
    query getAllStoresPayout($contains: String) {
  GetAllStores(filters: {contains: $contains, pagination: {disabled: true}}) {
    queryCount
    storesCount
    stores {
      _id
      name
      vendor {
        _id
        firstName
        lastName
      }
      storeBalance
    }
  }
}
    `;

/**
 * __useGetAllStoresPayoutQuery__
 *
 * To run a query within a React component, call `useGetAllStoresPayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStoresPayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStoresPayoutQuery({
 *   variables: {
 *      contains: // value for 'contains'
 *   },
 * });
 */
export function useGetAllStoresPayoutQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStoresPayoutQuery, GetAllStoresPayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStoresPayoutQuery, GetAllStoresPayoutQueryVariables>(GetAllStoresPayoutDocument, options);
      }
export function useGetAllStoresPayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStoresPayoutQuery, GetAllStoresPayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStoresPayoutQuery, GetAllStoresPayoutQueryVariables>(GetAllStoresPayoutDocument, options);
        }
export type GetAllStoresPayoutQueryHookResult = ReturnType<typeof useGetAllStoresPayoutQuery>;
export type GetAllStoresPayoutLazyQueryHookResult = ReturnType<typeof useGetAllStoresPayoutLazyQuery>;
export type GetAllStoresPayoutQueryResult = Apollo.QueryResult<GetAllStoresPayoutQuery, GetAllStoresPayoutQueryVariables>;
export const GetAllUsersDocument = gql`
    query getAllUsers($pagination: Pagination, $email: String, $firstName: String, $lastName: String, $isApproved: Boolean, $isArchived: Boolean, $isBlocked: Boolean, $isConfirmed: Boolean, $search: String, $emailSort: Float, $lastNameSort: Float, $firstNameSort: Float) {
  GetAllUsers(
    filters: {pagination: $pagination, email: $email, firstName: $firstName, lastName: $lastName, isApproved: $isApproved, isArchived: $isArchived, isBlocked: $isBlocked, isConfirmed: $isConfirmed, role: USER, contains: $search}
    sort: {email: $emailSort, lastName: $lastNameSort, firstName: $firstNameSort}
  ) {
    users {
      _id
      email
      firstName
      avatar
      lastName
      nationality
      dateOfBirth
      isBlocked
      isArchived
      userAddress {
        zipCode
        suiteNumber
        state
        streetAddress
        phoneNumber {
          number
          prefix
        }
        latitude
        langintude
        country
        city
        apartment
      }
    }
    queryCount
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      isApproved: // value for 'isApproved'
 *      isArchived: // value for 'isArchived'
 *      isBlocked: // value for 'isBlocked'
 *      isConfirmed: // value for 'isConfirmed'
 *      search: // value for 'search'
 *      emailSort: // value for 'emailSort'
 *      lastNameSort: // value for 'lastNameSort'
 *      firstNameSort: // value for 'firstNameSort'
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetAllVendorsDocument = gql`
    query getAllVendors {
  __typename
  GetAllUsers(
    sort: {firstName: 1}
    filters: {role: VENDOR, pagination: {disabled: true}}
  ) {
    users {
      _id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetAllVendorsQuery__
 *
 * To run a query within a React component, call `useGetAllVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVendorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllVendorsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllVendorsQuery, GetAllVendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllVendorsQuery, GetAllVendorsQueryVariables>(GetAllVendorsDocument, options);
      }
export function useGetAllVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllVendorsQuery, GetAllVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllVendorsQuery, GetAllVendorsQueryVariables>(GetAllVendorsDocument, options);
        }
export type GetAllVendorsQueryHookResult = ReturnType<typeof useGetAllVendorsQuery>;
export type GetAllVendorsLazyQueryHookResult = ReturnType<typeof useGetAllVendorsLazyQuery>;
export type GetAllVendorsQueryResult = Apollo.QueryResult<GetAllVendorsQuery, GetAllVendorsQueryVariables>;
export const GetCategoryNodesDocument = gql`
    query GetCategoryNodes($id: ObjectId!) {
  GetCategoryNodes(_id: $id) {
    _id
    name
    hasNodes
  }
}
    `;

/**
 * __useGetCategoryNodesQuery__
 *
 * To run a query within a React component, call `useGetCategoryNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryNodesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryNodesQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryNodesQuery, GetCategoryNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryNodesQuery, GetCategoryNodesQueryVariables>(GetCategoryNodesDocument, options);
      }
export function useGetCategoryNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryNodesQuery, GetCategoryNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryNodesQuery, GetCategoryNodesQueryVariables>(GetCategoryNodesDocument, options);
        }
export type GetCategoryNodesQueryHookResult = ReturnType<typeof useGetCategoryNodesQuery>;
export type GetCategoryNodesLazyQueryHookResult = ReturnType<typeof useGetCategoryNodesLazyQuery>;
export type GetCategoryNodesQueryResult = Apollo.QueryResult<GetCategoryNodesQuery, GetCategoryNodesQueryVariables>;
export const GetCategoryGeneralByIdDocument = gql`
    query getCategoryGeneralById($id: ObjectId!) {
  GetCategoryById(_id: $id) {
    _id
    description
    image
    name
    status
  }
}
    `;

/**
 * __useGetCategoryGeneralByIdQuery__
 *
 * To run a query within a React component, call `useGetCategoryGeneralByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryGeneralByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryGeneralByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryGeneralByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryGeneralByIdQuery, GetCategoryGeneralByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryGeneralByIdQuery, GetCategoryGeneralByIdQueryVariables>(GetCategoryGeneralByIdDocument, options);
      }
export function useGetCategoryGeneralByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryGeneralByIdQuery, GetCategoryGeneralByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryGeneralByIdQuery, GetCategoryGeneralByIdQueryVariables>(GetCategoryGeneralByIdDocument, options);
        }
export type GetCategoryGeneralByIdQueryHookResult = ReturnType<typeof useGetCategoryGeneralByIdQuery>;
export type GetCategoryGeneralByIdLazyQueryHookResult = ReturnType<typeof useGetCategoryGeneralByIdLazyQuery>;
export type GetCategoryGeneralByIdQueryResult = Apollo.QueryResult<GetCategoryGeneralByIdQuery, GetCategoryGeneralByIdQueryVariables>;
export const GetCategoryNameByIdDocument = gql`
    query getCategoryNameById($id: ObjectId!) {
  GetCategoryById(_id: $id) {
    _id
    name
  }
}
    `;

/**
 * __useGetCategoryNameByIdQuery__
 *
 * To run a query within a React component, call `useGetCategoryNameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryNameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryNameByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryNameByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryNameByIdQuery, GetCategoryNameByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryNameByIdQuery, GetCategoryNameByIdQueryVariables>(GetCategoryNameByIdDocument, options);
      }
export function useGetCategoryNameByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryNameByIdQuery, GetCategoryNameByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryNameByIdQuery, GetCategoryNameByIdQueryVariables>(GetCategoryNameByIdDocument, options);
        }
export type GetCategoryNameByIdQueryHookResult = ReturnType<typeof useGetCategoryNameByIdQuery>;
export type GetCategoryNameByIdLazyQueryHookResult = ReturnType<typeof useGetCategoryNameByIdLazyQuery>;
export type GetCategoryNameByIdQueryResult = Apollo.QueryResult<GetCategoryNameByIdQuery, GetCategoryNameByIdQueryVariables>;
export const GetCategoryProductsByIdDocument = gql`
    query getCategoryProductsById($id: ObjectId!, $pagination: Pagination) {
  GetAllProducts(filters: {category: $id, pagination: $pagination}) {
    queryCount
    productsCount
    products {
      _id
      images
      name
      store {
        _id
        name
      }
      inventory {
        sku
        _id
      }
    }
  }
}
    `;

/**
 * __useGetCategoryProductsByIdQuery__
 *
 * To run a query within a React component, call `useGetCategoryProductsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryProductsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryProductsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCategoryProductsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryProductsByIdQuery, GetCategoryProductsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryProductsByIdQuery, GetCategoryProductsByIdQueryVariables>(GetCategoryProductsByIdDocument, options);
      }
export function useGetCategoryProductsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryProductsByIdQuery, GetCategoryProductsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryProductsByIdQuery, GetCategoryProductsByIdQueryVariables>(GetCategoryProductsByIdDocument, options);
        }
export type GetCategoryProductsByIdQueryHookResult = ReturnType<typeof useGetCategoryProductsByIdQuery>;
export type GetCategoryProductsByIdLazyQueryHookResult = ReturnType<typeof useGetCategoryProductsByIdLazyQuery>;
export type GetCategoryProductsByIdQueryResult = Apollo.QueryResult<GetCategoryProductsByIdQuery, GetCategoryProductsByIdQueryVariables>;
export const GetCategorySeoByIdDocument = gql`
    query getCategorySEOById($id: ObjectId!) {
  GetCategoryById(_id: $id) {
    _id
    seo {
      seotitle
      metatags {
        key
        value
      }
      metadescription
      keywords
      _id
    }
    slug
  }
}
    `;

/**
 * __useGetCategorySeoByIdQuery__
 *
 * To run a query within a React component, call `useGetCategorySeoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategorySeoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategorySeoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategorySeoByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCategorySeoByIdQuery, GetCategorySeoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategorySeoByIdQuery, GetCategorySeoByIdQueryVariables>(GetCategorySeoByIdDocument, options);
      }
export function useGetCategorySeoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategorySeoByIdQuery, GetCategorySeoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategorySeoByIdQuery, GetCategorySeoByIdQueryVariables>(GetCategorySeoByIdDocument, options);
        }
export type GetCategorySeoByIdQueryHookResult = ReturnType<typeof useGetCategorySeoByIdQuery>;
export type GetCategorySeoByIdLazyQueryHookResult = ReturnType<typeof useGetCategorySeoByIdLazyQuery>;
export type GetCategorySeoByIdQueryResult = Apollo.QueryResult<GetCategorySeoByIdQuery, GetCategorySeoByIdQueryVariables>;
export const GetCurrentSessionProductsDocument = gql`
    query getCurrentSessionProducts($pagination: Pagination, $name: String, $brand: String, $type: ProductType, $category: ObjectId, $search: String, $status: ProductStatusType) {
  GetCurrentSessionUserProducts(
    filters: {pagination: $pagination, name: $name, brand: $brand, type: $type, category: $category, status: $status, contains: $search}
    sort: {createdAt: -1}
  ) {
    queryCount
    productsCount
    products {
      _id
      status
      brand
      images
      name
      slug
      updatedAt
      category {
        _id
        name
      }
      store {
        _id
        name
      }
      createdAt
      options {
        name
      }
      price {
        price
      }
      inventory {
        _id
        barCode
        createdAt
        informOn
        qunatity
        sellOutOfStock
        sku
        trackQunatity
        updatedAt
        weightPerUnit
      }
      variations {
        variantPrice
        updatedAt
        selectedOption {
          KEY
          VALUE
        }
        isActive
        inventory {
          _id
          createdAt
          barCode
          informOn
          qunatity
          sellOutOfStock
          sku
          trackQunatity
          updatedAt
          weightPerUnit
        }
        images
        createdAt
        _id
      }
    }
  }
}
    `;

/**
 * __useGetCurrentSessionProductsQuery__
 *
 * To run a query within a React component, call `useGetCurrentSessionProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSessionProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSessionProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      name: // value for 'name'
 *      brand: // value for 'brand'
 *      type: // value for 'type'
 *      category: // value for 'category'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCurrentSessionProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentSessionProductsQuery, GetCurrentSessionProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentSessionProductsQuery, GetCurrentSessionProductsQueryVariables>(GetCurrentSessionProductsDocument, options);
      }
export function useGetCurrentSessionProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentSessionProductsQuery, GetCurrentSessionProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentSessionProductsQuery, GetCurrentSessionProductsQueryVariables>(GetCurrentSessionProductsDocument, options);
        }
export type GetCurrentSessionProductsQueryHookResult = ReturnType<typeof useGetCurrentSessionProductsQuery>;
export type GetCurrentSessionProductsLazyQueryHookResult = ReturnType<typeof useGetCurrentSessionProductsLazyQuery>;
export type GetCurrentSessionProductsQueryResult = Apollo.QueryResult<GetCurrentSessionProductsQuery, GetCurrentSessionProductsQueryVariables>;
export const GetFilterUsersDocument = gql`
    query getFilterUsers {
  GetAllUsers(filters: {pagination: {disabled: true}}) {
    users {
      _id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetFilterUsersQuery__
 *
 * To run a query within a React component, call `useGetFilterUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetFilterUsersQuery, GetFilterUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterUsersQuery, GetFilterUsersQueryVariables>(GetFilterUsersDocument, options);
      }
export function useGetFilterUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterUsersQuery, GetFilterUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterUsersQuery, GetFilterUsersQueryVariables>(GetFilterUsersDocument, options);
        }
export type GetFilterUsersQueryHookResult = ReturnType<typeof useGetFilterUsersQuery>;
export type GetFilterUsersLazyQueryHookResult = ReturnType<typeof useGetFilterUsersLazyQuery>;
export type GetFilterUsersQueryResult = Apollo.QueryResult<GetFilterUsersQuery, GetFilterUsersQueryVariables>;
export const GetHomePageSettingsDocument = gql`
    query GetHomePageSettings {
  GetHomePage {
    _id
    banners {
      _id
      image
      url
    }
    collections {
      _id
      description
      title
      color {
        _id
        hexColor
        hslaColor
        name
        rgbaColor
      }
      products {
        _id
        name
        category {
          _id
          name
        }
        slug
      }
    }
    name
    seo {
      _id
      keywords
      metadescription
      metatags {
        _id
        key
        value
      }
      seotitle
    }
    slides {
      _id
      description
      image
      showOverlay
      title
      action {
        to
      }
    }
    flashSales {
      _id
      color {
        _id
        hexColor
        hslaColor
        name
        rgbaColor
      }
      description
      endDate
      title
      products {
        _id
        name
        images
        category {
          _id
          name
        }
        price {
          price
          compareAtPrice
        }
        slug
      }
    }
  }
}
    `;

/**
 * __useGetHomePageSettingsQuery__
 *
 * To run a query within a React component, call `useGetHomePageSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomePageSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomePageSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomePageSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetHomePageSettingsQuery, GetHomePageSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomePageSettingsQuery, GetHomePageSettingsQueryVariables>(GetHomePageSettingsDocument, options);
      }
export function useGetHomePageSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomePageSettingsQuery, GetHomePageSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomePageSettingsQuery, GetHomePageSettingsQueryVariables>(GetHomePageSettingsDocument, options);
        }
export type GetHomePageSettingsQueryHookResult = ReturnType<typeof useGetHomePageSettingsQuery>;
export type GetHomePageSettingsLazyQueryHookResult = ReturnType<typeof useGetHomePageSettingsLazyQuery>;
export type GetHomePageSettingsQueryResult = Apollo.QueryResult<GetHomePageSettingsQuery, GetHomePageSettingsQueryVariables>;
export const GetInventoryHistoryDocument = gql`
    query getInventoryHistory($productID: ObjectId!, $inventoryID: ObjectId!) {
  GetProductInventoryHistory(_id: $inventoryID) {
    _id
    activity {
      activity
      activityId
    }
    createdAt
    refId
    sales
    updatedAt
    oldQuantity
    newQuantity
  }
  GetProductById(_id: $productID) {
    name
    _id
    variations {
      selectedOption {
        KEY
        VALUE
      }
      _id
    }
  }
}
    `;

/**
 * __useGetInventoryHistoryQuery__
 *
 * To run a query within a React component, call `useGetInventoryHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryHistoryQuery({
 *   variables: {
 *      productID: // value for 'productID'
 *      inventoryID: // value for 'inventoryID'
 *   },
 * });
 */
export function useGetInventoryHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryHistoryQuery, GetInventoryHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryHistoryQuery, GetInventoryHistoryQueryVariables>(GetInventoryHistoryDocument, options);
      }
export function useGetInventoryHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryHistoryQuery, GetInventoryHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryHistoryQuery, GetInventoryHistoryQueryVariables>(GetInventoryHistoryDocument, options);
        }
export type GetInventoryHistoryQueryHookResult = ReturnType<typeof useGetInventoryHistoryQuery>;
export type GetInventoryHistoryLazyQueryHookResult = ReturnType<typeof useGetInventoryHistoryLazyQuery>;
export type GetInventoryHistoryQueryResult = Apollo.QueryResult<GetInventoryHistoryQuery, GetInventoryHistoryQueryVariables>;
export const GetMainCategoriesDocument = gql`
    query getMainCategories {
  GetAllCategories(filters: {level: 1, pagination: {disabled: true}}) {
    categories {
      _id
      name
      hasNodes
    }
  }
}
    `;

/**
 * __useGetMainCategoriesQuery__
 *
 * To run a query within a React component, call `useGetMainCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMainCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMainCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMainCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetMainCategoriesQuery, GetMainCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMainCategoriesQuery, GetMainCategoriesQueryVariables>(GetMainCategoriesDocument, options);
      }
export function useGetMainCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMainCategoriesQuery, GetMainCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMainCategoriesQuery, GetMainCategoriesQueryVariables>(GetMainCategoriesDocument, options);
        }
export type GetMainCategoriesQueryHookResult = ReturnType<typeof useGetMainCategoriesQuery>;
export type GetMainCategoriesLazyQueryHookResult = ReturnType<typeof useGetMainCategoriesLazyQuery>;
export type GetMainCategoriesQueryResult = Apollo.QueryResult<GetMainCategoriesQuery, GetMainCategoriesQueryVariables>;
export const GetOrderByIdDocument = gql`
    query getOrderByID($orderID: ObjectId!, $storeID: ObjectId) {
  __typename
  GetOrderById(_id: $orderID, store: $storeID) {
    _id
    createdAt
    items {
      images
      itemName
      itemOption {
        KEY
        VALUE
      }
      itemPrice
      itemSku
      itemStatus
      itemStore {
        _id
        name
      }
      quntity
      refId
    }
    orderNotes {
      createdAt
      note
    }
    paymentStatus
    paymentType
    refId
    status
    totaleItems
    totalePrice
    updatedAt
    user {
      _id
      email
      firstName
      avatar
      lastName
      userAddress {
        zipCode
        city
        country
        streetAddress
        apartment
        phoneNumber {
          number
          prefix
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      storeID: // value for 'storeID'
 *   },
 * });
 */
export function useGetOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, options);
      }
export function useGetOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, options);
        }
export type GetOrderByIdQueryHookResult = ReturnType<typeof useGetOrderByIdQuery>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<typeof useGetOrderByIdLazyQuery>;
export type GetOrderByIdQueryResult = Apollo.QueryResult<GetOrderByIdQuery, GetOrderByIdQueryVariables>;
export const GetAllPayOutsDocument = gql`
    query GetAllPayOuts($pagination: Paginations) {
  GetAllPayOuts(sort: {createdAt: -1}, filters: {pagination: $pagination}) {
    payOutsCount
    payouts {
      _id
      issueDate
      createdAt
      totalAmount
      transactionId
      updatedAt
      stores {
        _id
        name
        slug
        vendor {
          _id
          firstName
          lastName
        }
      }
    }
    queryCount
  }
  GetAvailableBlance {
    totalBlance
  }
}
    `;

/**
 * __useGetAllPayOutsQuery__
 *
 * To run a query within a React component, call `useGetAllPayOutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPayOutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPayOutsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetAllPayOutsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPayOutsQuery, GetAllPayOutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPayOutsQuery, GetAllPayOutsQueryVariables>(GetAllPayOutsDocument, options);
      }
export function useGetAllPayOutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPayOutsQuery, GetAllPayOutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPayOutsQuery, GetAllPayOutsQueryVariables>(GetAllPayOutsDocument, options);
        }
export type GetAllPayOutsQueryHookResult = ReturnType<typeof useGetAllPayOutsQuery>;
export type GetAllPayOutsLazyQueryHookResult = ReturnType<typeof useGetAllPayOutsLazyQuery>;
export type GetAllPayOutsQueryResult = Apollo.QueryResult<GetAllPayOutsQuery, GetAllPayOutsQueryVariables>;
export const GetProductFilesByIdDocument = gql`
    query getProductFilesByID($product_id: ObjectId!) {
  GetProductById(_id: $product_id) {
    _id
    brand
    category {
      _id
      name
    }
    isAvilable
    tags
    name
    files {
      description
      extension
      fileName
      size
      source
    }
  }
}
    `;

/**
 * __useGetProductFilesByIdQuery__
 *
 * To run a query within a React component, call `useGetProductFilesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductFilesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductFilesByIdQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useGetProductFilesByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProductFilesByIdQuery, GetProductFilesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductFilesByIdQuery, GetProductFilesByIdQueryVariables>(GetProductFilesByIdDocument, options);
      }
export function useGetProductFilesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductFilesByIdQuery, GetProductFilesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductFilesByIdQuery, GetProductFilesByIdQueryVariables>(GetProductFilesByIdDocument, options);
        }
export type GetProductFilesByIdQueryHookResult = ReturnType<typeof useGetProductFilesByIdQuery>;
export type GetProductFilesByIdLazyQueryHookResult = ReturnType<typeof useGetProductFilesByIdLazyQuery>;
export type GetProductFilesByIdQueryResult = Apollo.QueryResult<GetProductFilesByIdQuery, GetProductFilesByIdQueryVariables>;
export const GetProductGeneralByIdDocument = gql`
    query getProductGeneralByID($product_id: ObjectId!) {
  __typename
  GetProductById(_id: $product_id) {
    _id
    brand
    category {
      _id
      name
    }
    description
    images
    name
    status
    tags
    type
    price {
      chargeAtTax
      compareAtPrice
      costPerItem
      price
    }
    isAvilable
  }
}
    `;

/**
 * __useGetProductGeneralByIdQuery__
 *
 * To run a query within a React component, call `useGetProductGeneralByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductGeneralByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductGeneralByIdQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useGetProductGeneralByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProductGeneralByIdQuery, GetProductGeneralByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductGeneralByIdQuery, GetProductGeneralByIdQueryVariables>(GetProductGeneralByIdDocument, options);
      }
export function useGetProductGeneralByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductGeneralByIdQuery, GetProductGeneralByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductGeneralByIdQuery, GetProductGeneralByIdQueryVariables>(GetProductGeneralByIdDocument, options);
        }
export type GetProductGeneralByIdQueryHookResult = ReturnType<typeof useGetProductGeneralByIdQuery>;
export type GetProductGeneralByIdLazyQueryHookResult = ReturnType<typeof useGetProductGeneralByIdLazyQuery>;
export type GetProductGeneralByIdQueryResult = Apollo.QueryResult<GetProductGeneralByIdQuery, GetProductGeneralByIdQueryVariables>;
export const GetProductInventoryByIdDocument = gql`
    query getProductInventoryByID($product_id: ObjectId!) {
  GetProductById(_id: $product_id) {
    _id
    brand
    category {
      _id
      name
    }
    inventory {
      _id
      barCode
      informOn
      qunatity
      sellOutOfStock
      sku
      trackQunatity
      weightPerUnit
      updatedAt
      createdAt
    }
    isAvilable
    tags
    variations {
      selectedOption {
        KEY
        VALUE
      }
      inventory {
        _id
        barCode
        createdAt
        informOn
        qunatity
        sellOutOfStock
        sku
        trackQunatity
        updatedAt
        weightPerUnit
      }
      _id
    }
    name
  }
}
    `;

/**
 * __useGetProductInventoryByIdQuery__
 *
 * To run a query within a React component, call `useGetProductInventoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductInventoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductInventoryByIdQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useGetProductInventoryByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProductInventoryByIdQuery, GetProductInventoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductInventoryByIdQuery, GetProductInventoryByIdQueryVariables>(GetProductInventoryByIdDocument, options);
      }
export function useGetProductInventoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductInventoryByIdQuery, GetProductInventoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductInventoryByIdQuery, GetProductInventoryByIdQueryVariables>(GetProductInventoryByIdDocument, options);
        }
export type GetProductInventoryByIdQueryHookResult = ReturnType<typeof useGetProductInventoryByIdQuery>;
export type GetProductInventoryByIdLazyQueryHookResult = ReturnType<typeof useGetProductInventoryByIdLazyQuery>;
export type GetProductInventoryByIdQueryResult = Apollo.QueryResult<GetProductInventoryByIdQuery, GetProductInventoryByIdQueryVariables>;
export const GetProductNameByIdDocument = gql`
    query getProductNameByID($product_id: ObjectId!) {
  __typename
  GetProductById(_id: $product_id) {
    _id
    name
  }
}
    `;

/**
 * __useGetProductNameByIdQuery__
 *
 * To run a query within a React component, call `useGetProductNameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductNameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductNameByIdQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useGetProductNameByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProductNameByIdQuery, GetProductNameByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductNameByIdQuery, GetProductNameByIdQueryVariables>(GetProductNameByIdDocument, options);
      }
export function useGetProductNameByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductNameByIdQuery, GetProductNameByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductNameByIdQuery, GetProductNameByIdQueryVariables>(GetProductNameByIdDocument, options);
        }
export type GetProductNameByIdQueryHookResult = ReturnType<typeof useGetProductNameByIdQuery>;
export type GetProductNameByIdLazyQueryHookResult = ReturnType<typeof useGetProductNameByIdLazyQuery>;
export type GetProductNameByIdQueryResult = Apollo.QueryResult<GetProductNameByIdQuery, GetProductNameByIdQueryVariables>;
export const GetProductOptionsByIdDocument = gql`
    query getProductOptionsByID($product_id: ObjectId!) {
  GetProductById(_id: $product_id) {
    _id
    options {
      inputType
      isActive
      name
      values
    }
    tags
    category {
      _id
      name
    }
    brand
    isAvilable
  }
}
    `;

/**
 * __useGetProductOptionsByIdQuery__
 *
 * To run a query within a React component, call `useGetProductOptionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductOptionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductOptionsByIdQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useGetProductOptionsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProductOptionsByIdQuery, GetProductOptionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductOptionsByIdQuery, GetProductOptionsByIdQueryVariables>(GetProductOptionsByIdDocument, options);
      }
export function useGetProductOptionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductOptionsByIdQuery, GetProductOptionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductOptionsByIdQuery, GetProductOptionsByIdQueryVariables>(GetProductOptionsByIdDocument, options);
        }
export type GetProductOptionsByIdQueryHookResult = ReturnType<typeof useGetProductOptionsByIdQuery>;
export type GetProductOptionsByIdLazyQueryHookResult = ReturnType<typeof useGetProductOptionsByIdLazyQuery>;
export type GetProductOptionsByIdQueryResult = Apollo.QueryResult<GetProductOptionsByIdQuery, GetProductOptionsByIdQueryVariables>;
export const GetProductRelatedProductsByIdDocument = gql`
    query getProductRelatedProductsByID($product_id: ObjectId!) {
  GetProductById(_id: $product_id) {
    _id
    tags
    category {
      _id
      name
    }
    brand
    isAvilable
    bundles {
      _id
      name
      price {
        price
      }
      images
      inventory {
        sku
      }
    }
  }
}
    `;

/**
 * __useGetProductRelatedProductsByIdQuery__
 *
 * To run a query within a React component, call `useGetProductRelatedProductsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductRelatedProductsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductRelatedProductsByIdQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useGetProductRelatedProductsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProductRelatedProductsByIdQuery, GetProductRelatedProductsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductRelatedProductsByIdQuery, GetProductRelatedProductsByIdQueryVariables>(GetProductRelatedProductsByIdDocument, options);
      }
export function useGetProductRelatedProductsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductRelatedProductsByIdQuery, GetProductRelatedProductsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductRelatedProductsByIdQuery, GetProductRelatedProductsByIdQueryVariables>(GetProductRelatedProductsByIdDocument, options);
        }
export type GetProductRelatedProductsByIdQueryHookResult = ReturnType<typeof useGetProductRelatedProductsByIdQuery>;
export type GetProductRelatedProductsByIdLazyQueryHookResult = ReturnType<typeof useGetProductRelatedProductsByIdLazyQuery>;
export type GetProductRelatedProductsByIdQueryResult = Apollo.QueryResult<GetProductRelatedProductsByIdQuery, GetProductRelatedProductsByIdQueryVariables>;
export const GetProductSeoByIdDocument = gql`
    query getProductSEOByID($product_id: ObjectId!) {
  GetProductById(_id: $product_id) {
    _id
    brand
    category {
      _id
      name
    }
    isAvilable
    tags
    name
    seo {
      seotitle
      metatags {
        _id
        key
        value
      }
      keywords
      _id
      metadescription
    }
    slug
  }
}
    `;

/**
 * __useGetProductSeoByIdQuery__
 *
 * To run a query within a React component, call `useGetProductSeoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSeoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSeoByIdQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useGetProductSeoByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProductSeoByIdQuery, GetProductSeoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductSeoByIdQuery, GetProductSeoByIdQueryVariables>(GetProductSeoByIdDocument, options);
      }
export function useGetProductSeoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductSeoByIdQuery, GetProductSeoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductSeoByIdQuery, GetProductSeoByIdQueryVariables>(GetProductSeoByIdDocument, options);
        }
export type GetProductSeoByIdQueryHookResult = ReturnType<typeof useGetProductSeoByIdQuery>;
export type GetProductSeoByIdLazyQueryHookResult = ReturnType<typeof useGetProductSeoByIdLazyQuery>;
export type GetProductSeoByIdQueryResult = Apollo.QueryResult<GetProductSeoByIdQuery, GetProductSeoByIdQueryVariables>;
export const GetProductVariationsByIdDocument = gql`
    query getProductVariationsByID($product_id: ObjectId!) {
  GetProductById(_id: $product_id) {
    variations {
      variantPrice
      selectedOption {
        KEY
        VALUE
      }
      isActive
      inventory {
        _id
        barCode
        sku
        weightPerUnit
      }
      images
      createdAt
      _id
    }
    _id
    tags
    brand
    isAvilable
    category {
      _id
      name
    }
  }
}
    `;

/**
 * __useGetProductVariationsByIdQuery__
 *
 * To run a query within a React component, call `useGetProductVariationsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductVariationsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductVariationsByIdQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useGetProductVariationsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProductVariationsByIdQuery, GetProductVariationsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductVariationsByIdQuery, GetProductVariationsByIdQueryVariables>(GetProductVariationsByIdDocument, options);
      }
export function useGetProductVariationsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductVariationsByIdQuery, GetProductVariationsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductVariationsByIdQuery, GetProductVariationsByIdQueryVariables>(GetProductVariationsByIdDocument, options);
        }
export type GetProductVariationsByIdQueryHookResult = ReturnType<typeof useGetProductVariationsByIdQuery>;
export type GetProductVariationsByIdLazyQueryHookResult = ReturnType<typeof useGetProductVariationsByIdLazyQuery>;
export type GetProductVariationsByIdQueryResult = Apollo.QueryResult<GetProductVariationsByIdQuery, GetProductVariationsByIdQueryVariables>;
export const GetProductsForRelatedProductsDocument = gql`
    query getProductsForRelatedProducts($pagination: Pagination) {
  GetAllProducts(filters: {pagination: $pagination}, sort: {createdAt: -1}) {
    queryCount
    productsCount
    products {
      _id
      name
      slug
      images
      price {
        price
      }
      inventory {
        sku
      }
      category {
        name
      }
      store {
        name
      }
    }
  }
}
    `;

/**
 * __useGetProductsForRelatedProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsForRelatedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForRelatedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForRelatedProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetProductsForRelatedProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsForRelatedProductsQuery, GetProductsForRelatedProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsForRelatedProductsQuery, GetProductsForRelatedProductsQueryVariables>(GetProductsForRelatedProductsDocument, options);
      }
export function useGetProductsForRelatedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsForRelatedProductsQuery, GetProductsForRelatedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsForRelatedProductsQuery, GetProductsForRelatedProductsQueryVariables>(GetProductsForRelatedProductsDocument, options);
        }
export type GetProductsForRelatedProductsQueryHookResult = ReturnType<typeof useGetProductsForRelatedProductsQuery>;
export type GetProductsForRelatedProductsLazyQueryHookResult = ReturnType<typeof useGetProductsForRelatedProductsLazyQuery>;
export type GetProductsForRelatedProductsQueryResult = Apollo.QueryResult<GetProductsForRelatedProductsQuery, GetProductsForRelatedProductsQueryVariables>;
export const GetProductsToAssignDocument = gql`
    query getProductsToAssign($pagination: Pagination, $selected_category: ObjectId) {
  GetAllProducts(
    filters: {pagination: $pagination, exclude: {category: $selected_category}}
    sort: {createdAt: -1}
  ) {
    queryCount
    productsCount
    products {
      _id
      name
      slug
      inventory {
        sku
      }
      category {
        name
      }
      store {
        name
      }
    }
  }
}
    `;

/**
 * __useGetProductsToAssignQuery__
 *
 * To run a query within a React component, call `useGetProductsToAssignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsToAssignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsToAssignQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      selected_category: // value for 'selected_category'
 *   },
 * });
 */
export function useGetProductsToAssignQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsToAssignQuery, GetProductsToAssignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsToAssignQuery, GetProductsToAssignQueryVariables>(GetProductsToAssignDocument, options);
      }
export function useGetProductsToAssignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsToAssignQuery, GetProductsToAssignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsToAssignQuery, GetProductsToAssignQueryVariables>(GetProductsToAssignDocument, options);
        }
export type GetProductsToAssignQueryHookResult = ReturnType<typeof useGetProductsToAssignQuery>;
export type GetProductsToAssignLazyQueryHookResult = ReturnType<typeof useGetProductsToAssignLazyQuery>;
export type GetProductsToAssignQueryResult = Apollo.QueryResult<GetProductsToAssignQuery, GetProductsToAssignQueryVariables>;
export const GetProfileInfoDocument = gql`
    query getProfileInfo {
  GetCurrentSessionUser {
    _id
    avatar
    dateOfBirth
    email
    firstName
    lastName
    nationality
    userAddress {
      zipCode
      streetAddress
      phoneNumber {
        number
        prefix
      }
      country
      city
      apartment
    }
  }
}
    `;

/**
 * __useGetProfileInfoQuery__
 *
 * To run a query within a React component, call `useGetProfileInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileInfoQuery, GetProfileInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileInfoQuery, GetProfileInfoQueryVariables>(GetProfileInfoDocument, options);
      }
export function useGetProfileInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileInfoQuery, GetProfileInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileInfoQuery, GetProfileInfoQueryVariables>(GetProfileInfoDocument, options);
        }
export type GetProfileInfoQueryHookResult = ReturnType<typeof useGetProfileInfoQuery>;
export type GetProfileInfoLazyQueryHookResult = ReturnType<typeof useGetProfileInfoLazyQuery>;
export type GetProfileInfoQueryResult = Apollo.QueryResult<GetProfileInfoQuery, GetProfileInfoQueryVariables>;
export const GetStoreByIdDocument = gql`
    query getStoreById($store_id: ObjectId!) {
  GetStoreById(_id: $store_id) {
    _id
    createdAt
    description
    name
    slug
    status
    storeAddress {
      apartment
      city
      country
      phoneNumber {
        number
        prefix
      }
      streetAddress
      zipCode
    }
    storeMedia {
      coverPicture
      logo
    }
    updatedAt
    vendor {
      _id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetStoreByIdQuery__
 *
 * To run a query within a React component, call `useGetStoreByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreByIdQuery({
 *   variables: {
 *      store_id: // value for 'store_id'
 *   },
 * });
 */
export function useGetStoreByIdQuery(baseOptions: Apollo.QueryHookOptions<GetStoreByIdQuery, GetStoreByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreByIdQuery, GetStoreByIdQueryVariables>(GetStoreByIdDocument, options);
      }
export function useGetStoreByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreByIdQuery, GetStoreByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreByIdQuery, GetStoreByIdQueryVariables>(GetStoreByIdDocument, options);
        }
export type GetStoreByIdQueryHookResult = ReturnType<typeof useGetStoreByIdQuery>;
export type GetStoreByIdLazyQueryHookResult = ReturnType<typeof useGetStoreByIdLazyQuery>;
export type GetStoreByIdQueryResult = Apollo.QueryResult<GetStoreByIdQuery, GetStoreByIdQueryVariables>;
export const GetStoreSettingsInformationDocument = gql`
    query getStoreSettingsInformation {
  GetCurrentSessionUserStores {
    stores {
      _id
      description
      name
      slug
      status
      storeAddress {
        zipCode
        suiteNumber
        streetAddress
        state
        phoneNumber {
          number
          prefix
        }
        country
        city
        apartment
      }
      storeBalance
      storeKyc {
        additionalSellingPlatforms
        alreadySelling
        businessIndustry
        companyName
        customersAgeRange
        dateOfEstablishment
        mainProducts
        typeOfCompany
        website
        yearlyRevenue
      }
      storeMedia {
        coverPicture
        logo
      }
    }
  }
}
    `;

/**
 * __useGetStoreSettingsInformationQuery__
 *
 * To run a query within a React component, call `useGetStoreSettingsInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreSettingsInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreSettingsInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoreSettingsInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetStoreSettingsInformationQuery, GetStoreSettingsInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreSettingsInformationQuery, GetStoreSettingsInformationQueryVariables>(GetStoreSettingsInformationDocument, options);
      }
export function useGetStoreSettingsInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreSettingsInformationQuery, GetStoreSettingsInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreSettingsInformationQuery, GetStoreSettingsInformationQueryVariables>(GetStoreSettingsInformationDocument, options);
        }
export type GetStoreSettingsInformationQueryHookResult = ReturnType<typeof useGetStoreSettingsInformationQuery>;
export type GetStoreSettingsInformationLazyQueryHookResult = ReturnType<typeof useGetStoreSettingsInformationLazyQuery>;
export type GetStoreSettingsInformationQueryResult = Apollo.QueryResult<GetStoreSettingsInformationQuery, GetStoreSettingsInformationQueryVariables>;
export const GetStoreStatusDocument = gql`
    query getStoreStatus {
  __typename
  GetCurrentSessionUserStores {
    stores {
      _id
      status
      slug
      storeBalance
    }
  }
}
    `;

/**
 * __useGetStoreStatusQuery__
 *
 * To run a query within a React component, call `useGetStoreStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoreStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetStoreStatusQuery, GetStoreStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreStatusQuery, GetStoreStatusQueryVariables>(GetStoreStatusDocument, options);
      }
export function useGetStoreStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreStatusQuery, GetStoreStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreStatusQuery, GetStoreStatusQueryVariables>(GetStoreStatusDocument, options);
        }
export type GetStoreStatusQueryHookResult = ReturnType<typeof useGetStoreStatusQuery>;
export type GetStoreStatusLazyQueryHookResult = ReturnType<typeof useGetStoreStatusLazyQuery>;
export type GetStoreStatusQueryResult = Apollo.QueryResult<GetStoreStatusQuery, GetStoreStatusQueryVariables>;
export const GetUserDashboardDocument = gql`
    query getUserDashboard($storeID: ObjectId!) {
  GetCurrentSessionOrders(store: $storeID, filters: {}) {
    ordersCount
    queryCount
    orders {
      totalePrice
    }
  }
}
    `;

/**
 * __useGetUserDashboardQuery__
 *
 * To run a query within a React component, call `useGetUserDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDashboardQuery({
 *   variables: {
 *      storeID: // value for 'storeID'
 *   },
 * });
 */
export function useGetUserDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetUserDashboardQuery, GetUserDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDashboardQuery, GetUserDashboardQueryVariables>(GetUserDashboardDocument, options);
      }
export function useGetUserDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDashboardQuery, GetUserDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDashboardQuery, GetUserDashboardQueryVariables>(GetUserDashboardDocument, options);
        }
export type GetUserDashboardQueryHookResult = ReturnType<typeof useGetUserDashboardQuery>;
export type GetUserDashboardLazyQueryHookResult = ReturnType<typeof useGetUserDashboardLazyQuery>;
export type GetUserDashboardQueryResult = Apollo.QueryResult<GetUserDashboardQuery, GetUserDashboardQueryVariables>;
export const GetUserDetailsDocument = gql`
    query getUserDetails($id: ObjectId!) {
  GetUserById(_id: $id) {
    _id
    avatar
    createdAt
    dateOfBirth
    email
    firstName
    isApproved
    isArchived
    isBlocked
    isConfirmed
    lastName
    nationality
    updatedAt
    role
    userAddress {
      zipCode
      suiteNumber
      streetAddress
      state
      phoneNumber {
        number
        prefix
      }
      latitude
      langintude
      country
      city
      apartment
    }
  }
}
    `;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const GetVendorOrdersDocument = gql`
    query getVendorOrders($contains: String, $pagination: Paginations, $user: ObjectId, $status: String, $storeID: ObjectId) {
  GetCurrentSessionOrders(
    store: $storeID
    sort: {createdAt: -1}
    filters: {contains: $contains, pagination: $pagination, user: $user, status: $status}
  ) {
    orders {
      _id
      user {
        lastName
        firstName
        email
        _id
        userAddress {
          phoneNumber {
            number
            prefix
          }
        }
      }
      createdAt
      paymentStatus
      paymentType
      orderNotes {
        note
      }
      refId
      status
      totalePrice
      updatedAt
      totaleItems
    }
    ordersCount
    queryCount
  }
}
    `;

/**
 * __useGetVendorOrdersQuery__
 *
 * To run a query within a React component, call `useGetVendorOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorOrdersQuery({
 *   variables: {
 *      contains: // value for 'contains'
 *      pagination: // value for 'pagination'
 *      user: // value for 'user'
 *      status: // value for 'status'
 *      storeID: // value for 'storeID'
 *   },
 * });
 */
export function useGetVendorOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorOrdersQuery, GetVendorOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorOrdersQuery, GetVendorOrdersQueryVariables>(GetVendorOrdersDocument, options);
      }
export function useGetVendorOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorOrdersQuery, GetVendorOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorOrdersQuery, GetVendorOrdersQueryVariables>(GetVendorOrdersDocument, options);
        }
export type GetVendorOrdersQueryHookResult = ReturnType<typeof useGetVendorOrdersQuery>;
export type GetVendorOrdersLazyQueryHookResult = ReturnType<typeof useGetVendorOrdersLazyQuery>;
export type GetVendorOrdersQueryResult = Apollo.QueryResult<GetVendorOrdersQuery, GetVendorOrdersQueryVariables>;
export const GetVendorStoresDocument = gql`
    query getVendorStores {
  GetCurrentSessionUserStores {
    stores {
      _id
      name
      storeMedia {
        logo
      }
    }
  }
}
    `;

/**
 * __useGetVendorStoresQuery__
 *
 * To run a query within a React component, call `useGetVendorStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVendorStoresQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorStoresQuery, GetVendorStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorStoresQuery, GetVendorStoresQueryVariables>(GetVendorStoresDocument, options);
      }
export function useGetVendorStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorStoresQuery, GetVendorStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorStoresQuery, GetVendorStoresQueryVariables>(GetVendorStoresDocument, options);
        }
export type GetVendorStoresQueryHookResult = ReturnType<typeof useGetVendorStoresQuery>;
export type GetVendorStoresLazyQueryHookResult = ReturnType<typeof useGetVendorStoresLazyQuery>;
export type GetVendorStoresQueryResult = Apollo.QueryResult<GetVendorStoresQuery, GetVendorStoresQueryVariables>;
export const ResendVerificationEmailDocument = gql`
    mutation resendVerificationEmail($email: String!) {
  ResendConfrimEmail(email: $email) {
    code
    info
    status
  }
}
    `;
export type ResendVerificationEmailMutationFn = Apollo.MutationFunction<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>(ResendVerificationEmailDocument, options);
      }
export type ResendVerificationEmailMutationHookResult = ReturnType<typeof useResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationResult = Apollo.MutationResult<ResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  RestUserCredentials(password: $password, token: $token) {
    _id
    code
    info
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SetAuthenticateDocument = gql`
    mutation setAuthenticate($email: String!, $password: String!) {
  AuthenticateUser(entry: {email: $email, password: $password}) {
    token {
      accessToken
      refreshToken
    }
    user {
      isApproved
      isArchived
      isBlocked
      isConfirmed
      lastName
      role
      firstName
      _id
      avatar
      email
    }
  }
}
    `;
export type SetAuthenticateMutationFn = Apollo.MutationFunction<SetAuthenticateMutation, SetAuthenticateMutationVariables>;

/**
 * __useSetAuthenticateMutation__
 *
 * To run a mutation, you first call `useSetAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAuthenticateMutation, { data, loading, error }] = useSetAuthenticateMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSetAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<SetAuthenticateMutation, SetAuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAuthenticateMutation, SetAuthenticateMutationVariables>(SetAuthenticateDocument, options);
      }
export type SetAuthenticateMutationHookResult = ReturnType<typeof useSetAuthenticateMutation>;
export type SetAuthenticateMutationResult = Apollo.MutationResult<SetAuthenticateMutation>;
export type SetAuthenticateMutationOptions = Apollo.BaseMutationOptions<SetAuthenticateMutation, SetAuthenticateMutationVariables>;
export const SetOrderItemStatusDocument = gql`
    mutation SetOrderItemStatus($status: ItemStatusType!, $refId: ObjectId!, $_id: ObjectId!) {
  SetOrderItemStatus(status: $status, refId: $refId, _id: $_id) {
    _id
    code
    info
    status
  }
}
    `;
export type SetOrderItemStatusMutationFn = Apollo.MutationFunction<SetOrderItemStatusMutation, SetOrderItemStatusMutationVariables>;

/**
 * __useSetOrderItemStatusMutation__
 *
 * To run a mutation, you first call `useSetOrderItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrderItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrderItemStatusMutation, { data, loading, error }] = useSetOrderItemStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      refId: // value for 'refId'
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useSetOrderItemStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetOrderItemStatusMutation, SetOrderItemStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrderItemStatusMutation, SetOrderItemStatusMutationVariables>(SetOrderItemStatusDocument, options);
      }
export type SetOrderItemStatusMutationHookResult = ReturnType<typeof useSetOrderItemStatusMutation>;
export type SetOrderItemStatusMutationResult = Apollo.MutationResult<SetOrderItemStatusMutation>;
export type SetOrderItemStatusMutationOptions = Apollo.BaseMutationOptions<SetOrderItemStatusMutation, SetOrderItemStatusMutationVariables>;
export const SetProductStatusDocument = gql`
    mutation setProductStatus($status: ProductStatusType!, $product_id: ObjectId!) {
  SetProductStatus(status: $status, _id: $product_id) {
    _id
    code
    info
    status
  }
}
    `;
export type SetProductStatusMutationFn = Apollo.MutationFunction<SetProductStatusMutation, SetProductStatusMutationVariables>;

/**
 * __useSetProductStatusMutation__
 *
 * To run a mutation, you first call `useSetProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProductStatusMutation, { data, loading, error }] = useSetProductStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useSetProductStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetProductStatusMutation, SetProductStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProductStatusMutation, SetProductStatusMutationVariables>(SetProductStatusDocument, options);
      }
export type SetProductStatusMutationHookResult = ReturnType<typeof useSetProductStatusMutation>;
export type SetProductStatusMutationResult = Apollo.MutationResult<SetProductStatusMutation>;
export type SetProductStatusMutationOptions = Apollo.BaseMutationOptions<SetProductStatusMutation, SetProductStatusMutationVariables>;
export const SetRegisterVendorDocument = gql`
    mutation setRegisterVendor($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
  RegisterVendor(
    entry: {email: $email, password: $password, firstName: $firstName, lastName: $lastName}
  ) {
    token {
      accessToken
      refreshToken
    }
    user {
      _id
    }
  }
}
    `;
export type SetRegisterVendorMutationFn = Apollo.MutationFunction<SetRegisterVendorMutation, SetRegisterVendorMutationVariables>;

/**
 * __useSetRegisterVendorMutation__
 *
 * To run a mutation, you first call `useSetRegisterVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRegisterVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRegisterVendorMutation, { data, loading, error }] = useSetRegisterVendorMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useSetRegisterVendorMutation(baseOptions?: Apollo.MutationHookOptions<SetRegisterVendorMutation, SetRegisterVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRegisterVendorMutation, SetRegisterVendorMutationVariables>(SetRegisterVendorDocument, options);
      }
export type SetRegisterVendorMutationHookResult = ReturnType<typeof useSetRegisterVendorMutation>;
export type SetRegisterVendorMutationResult = Apollo.MutationResult<SetRegisterVendorMutation>;
export type SetRegisterVendorMutationOptions = Apollo.BaseMutationOptions<SetRegisterVendorMutation, SetRegisterVendorMutationVariables>;
export const UnassginProductCategoryDocument = gql`
    mutation unassginProductCategory($product_id: ObjectId!) {
  UnassginProductCategory(_id: $product_id) {
    code
    info
    status
  }
}
    `;
export type UnassginProductCategoryMutationFn = Apollo.MutationFunction<UnassginProductCategoryMutation, UnassginProductCategoryMutationVariables>;

/**
 * __useUnassginProductCategoryMutation__
 *
 * To run a mutation, you first call `useUnassginProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassginProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassginProductCategoryMutation, { data, loading, error }] = useUnassginProductCategoryMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useUnassginProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UnassginProductCategoryMutation, UnassginProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassginProductCategoryMutation, UnassginProductCategoryMutationVariables>(UnassginProductCategoryDocument, options);
      }
export type UnassginProductCategoryMutationHookResult = ReturnType<typeof useUnassginProductCategoryMutation>;
export type UnassginProductCategoryMutationResult = Apollo.MutationResult<UnassginProductCategoryMutation>;
export type UnassginProductCategoryMutationOptions = Apollo.BaseMutationOptions<UnassginProductCategoryMutation, UnassginProductCategoryMutationVariables>;
export const UpdateCategorySeoDocument = gql`
    mutation updateCategorySEO($categeory_id: ObjectId!, $seoTitle: String, $metadescription: String, $metatags: [MetaTagsInput!]) {
  UpdateCategory(
    entry: {seo: {metadescription: $metadescription, seotitle: $seoTitle, metatags: $metatags}}
    _id: $categeory_id
  ) {
    code
    info
  }
}
    `;
export type UpdateCategorySeoMutationFn = Apollo.MutationFunction<UpdateCategorySeoMutation, UpdateCategorySeoMutationVariables>;

/**
 * __useUpdateCategorySeoMutation__
 *
 * To run a mutation, you first call `useUpdateCategorySeoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategorySeoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategorySeoMutation, { data, loading, error }] = useUpdateCategorySeoMutation({
 *   variables: {
 *      categeory_id: // value for 'categeory_id'
 *      seoTitle: // value for 'seoTitle'
 *      metadescription: // value for 'metadescription'
 *      metatags: // value for 'metatags'
 *   },
 * });
 */
export function useUpdateCategorySeoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategorySeoMutation, UpdateCategorySeoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategorySeoMutation, UpdateCategorySeoMutationVariables>(UpdateCategorySeoDocument, options);
      }
export type UpdateCategorySeoMutationHookResult = ReturnType<typeof useUpdateCategorySeoMutation>;
export type UpdateCategorySeoMutationResult = Apollo.MutationResult<UpdateCategorySeoMutation>;
export type UpdateCategorySeoMutationOptions = Apollo.BaseMutationOptions<UpdateCategorySeoMutation, UpdateCategorySeoMutationVariables>;
export const UpdateHomeSettingsDocument = gql`
    mutation updateHomeSettings($homePageID: ObjectId!, $collections: [CollectionInput!], $slides: [SlidesInput!], $banners: [BannersInput!], $seo: SeoInput, $flashSales: [FlashSaleInput!]) {
  UpdateHomePageById(
    entry: {collections: $collections, slides: $slides, banners: $banners, seo: $seo, flashSales: $flashSales}
    _id: $homePageID
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateHomeSettingsMutationFn = Apollo.MutationFunction<UpdateHomeSettingsMutation, UpdateHomeSettingsMutationVariables>;

/**
 * __useUpdateHomeSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateHomeSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHomeSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHomeSettingsMutation, { data, loading, error }] = useUpdateHomeSettingsMutation({
 *   variables: {
 *      homePageID: // value for 'homePageID'
 *      collections: // value for 'collections'
 *      slides: // value for 'slides'
 *      banners: // value for 'banners'
 *      seo: // value for 'seo'
 *      flashSales: // value for 'flashSales'
 *   },
 * });
 */
export function useUpdateHomeSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHomeSettingsMutation, UpdateHomeSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHomeSettingsMutation, UpdateHomeSettingsMutationVariables>(UpdateHomeSettingsDocument, options);
      }
export type UpdateHomeSettingsMutationHookResult = ReturnType<typeof useUpdateHomeSettingsMutation>;
export type UpdateHomeSettingsMutationResult = Apollo.MutationResult<UpdateHomeSettingsMutation>;
export type UpdateHomeSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateHomeSettingsMutation, UpdateHomeSettingsMutationVariables>;
export const UpdateInventoryQuantityDocument = gql`
    mutation updateInventoryQuantity($quantity: Float, $activity: InventoryActivity, $_id: ObjectId!) {
  UpdateProductInventoryQuantity(
    entry: {activity: $activity, inventory: {qunatity: $quantity}}
    _id: $_id
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateInventoryQuantityMutationFn = Apollo.MutationFunction<UpdateInventoryQuantityMutation, UpdateInventoryQuantityMutationVariables>;

/**
 * __useUpdateInventoryQuantityMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryQuantityMutation, { data, loading, error }] = useUpdateInventoryQuantityMutation({
 *   variables: {
 *      quantity: // value for 'quantity'
 *      activity: // value for 'activity'
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUpdateInventoryQuantityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryQuantityMutation, UpdateInventoryQuantityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryQuantityMutation, UpdateInventoryQuantityMutationVariables>(UpdateInventoryQuantityDocument, options);
      }
export type UpdateInventoryQuantityMutationHookResult = ReturnType<typeof useUpdateInventoryQuantityMutation>;
export type UpdateInventoryQuantityMutationResult = Apollo.MutationResult<UpdateInventoryQuantityMutation>;
export type UpdateInventoryQuantityMutationOptions = Apollo.BaseMutationOptions<UpdateInventoryQuantityMutation, UpdateInventoryQuantityMutationVariables>;
export const UpdateOrderStatusDocument = gql`
    mutation updateOrderStatus($status: String!, $orderID: ObjectId!) {
  SetOrderStatus(status: $status, _id: $orderID) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(UpdateOrderStatusDocument, options);
      }
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;
export const UpdateProductsFilesByIdDocument = gql`
    mutation updateProductsFilesByID($brand: String, $category: ObjectId, $tags: [String!], $isAvilable: Boolean, $product_id: ObjectId!, $files: [AddOnInput!]) {
  UpdateProduct(
    entry: {brand: $brand, category: $category, tags: $tags, isAvilable: $isAvilable, files: $files}
    _id: $product_id
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateProductsFilesByIdMutationFn = Apollo.MutationFunction<UpdateProductsFilesByIdMutation, UpdateProductsFilesByIdMutationVariables>;

/**
 * __useUpdateProductsFilesByIdMutation__
 *
 * To run a mutation, you first call `useUpdateProductsFilesByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductsFilesByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductsFilesByIdMutation, { data, loading, error }] = useUpdateProductsFilesByIdMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *      isAvilable: // value for 'isAvilable'
 *      product_id: // value for 'product_id'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUpdateProductsFilesByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductsFilesByIdMutation, UpdateProductsFilesByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductsFilesByIdMutation, UpdateProductsFilesByIdMutationVariables>(UpdateProductsFilesByIdDocument, options);
      }
export type UpdateProductsFilesByIdMutationHookResult = ReturnType<typeof useUpdateProductsFilesByIdMutation>;
export type UpdateProductsFilesByIdMutationResult = Apollo.MutationResult<UpdateProductsFilesByIdMutation>;
export type UpdateProductsFilesByIdMutationOptions = Apollo.BaseMutationOptions<UpdateProductsFilesByIdMutation, UpdateProductsFilesByIdMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($name: String!, $description: String, $type: ProductType, $brand: String, $category: ObjectId, $tags: [String!], $isAvilable: Boolean, $images: [String!], $price: PriceInput, $product_id: ObjectId!) {
  UpdateProduct(
    entry: {name: $name, description: $description, type: $type, brand: $brand, category: $category, tags: $tags, isAvilable: $isAvilable, images: $images, price: $price}
    _id: $product_id
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      type: // value for 'type'
 *      brand: // value for 'brand'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *      isAvilable: // value for 'isAvilable'
 *      images: // value for 'images'
 *      price: // value for 'price'
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateProductsInventoryByIdDocument = gql`
    mutation updateProductsInventoryByID($brand: String, $category: ObjectId, $tags: [String!], $isAvilable: Boolean, $product_id: ObjectId!) {
  UpdateProduct(
    entry: {brand: $brand, category: $category, tags: $tags, isAvilable: $isAvilable}
    _id: $product_id
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateProductsInventoryByIdMutationFn = Apollo.MutationFunction<UpdateProductsInventoryByIdMutation, UpdateProductsInventoryByIdMutationVariables>;

/**
 * __useUpdateProductsInventoryByIdMutation__
 *
 * To run a mutation, you first call `useUpdateProductsInventoryByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductsInventoryByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductsInventoryByIdMutation, { data, loading, error }] = useUpdateProductsInventoryByIdMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *      isAvilable: // value for 'isAvilable'
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useUpdateProductsInventoryByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductsInventoryByIdMutation, UpdateProductsInventoryByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductsInventoryByIdMutation, UpdateProductsInventoryByIdMutationVariables>(UpdateProductsInventoryByIdDocument, options);
      }
export type UpdateProductsInventoryByIdMutationHookResult = ReturnType<typeof useUpdateProductsInventoryByIdMutation>;
export type UpdateProductsInventoryByIdMutationResult = Apollo.MutationResult<UpdateProductsInventoryByIdMutation>;
export type UpdateProductsInventoryByIdMutationOptions = Apollo.BaseMutationOptions<UpdateProductsInventoryByIdMutation, UpdateProductsInventoryByIdMutationVariables>;
export const UpdateProductInventoryQuantityDocument = gql`
    mutation updateProductInventoryQuantity($quantity: Float, $weightPerUnit: Float, $informOn: Float, $trackQunatity: Boolean, $sellOutOfStock: Boolean, $sku: String, $barCode: String, $activity: InventoryActivity, $_id: ObjectId!) {
  UpdateProductInventoryQuantity(
    entry: {activity: $activity, inventory: {sku: $sku, barCode: $barCode, weightPerUnit: $weightPerUnit, informOn: $informOn, trackQunatity: $trackQunatity, sellOutOfStock: $sellOutOfStock, qunatity: $quantity}}
    _id: $_id
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateProductInventoryQuantityMutationFn = Apollo.MutationFunction<UpdateProductInventoryQuantityMutation, UpdateProductInventoryQuantityMutationVariables>;

/**
 * __useUpdateProductInventoryQuantityMutation__
 *
 * To run a mutation, you first call `useUpdateProductInventoryQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductInventoryQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductInventoryQuantityMutation, { data, loading, error }] = useUpdateProductInventoryQuantityMutation({
 *   variables: {
 *      quantity: // value for 'quantity'
 *      weightPerUnit: // value for 'weightPerUnit'
 *      informOn: // value for 'informOn'
 *      trackQunatity: // value for 'trackQunatity'
 *      sellOutOfStock: // value for 'sellOutOfStock'
 *      sku: // value for 'sku'
 *      barCode: // value for 'barCode'
 *      activity: // value for 'activity'
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUpdateProductInventoryQuantityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductInventoryQuantityMutation, UpdateProductInventoryQuantityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductInventoryQuantityMutation, UpdateProductInventoryQuantityMutationVariables>(UpdateProductInventoryQuantityDocument, options);
      }
export type UpdateProductInventoryQuantityMutationHookResult = ReturnType<typeof useUpdateProductInventoryQuantityMutation>;
export type UpdateProductInventoryQuantityMutationResult = Apollo.MutationResult<UpdateProductInventoryQuantityMutation>;
export type UpdateProductInventoryQuantityMutationOptions = Apollo.BaseMutationOptions<UpdateProductInventoryQuantityMutation, UpdateProductInventoryQuantityMutationVariables>;
export const UpdateProductOptionsDocument = gql`
    mutation updateProductOptions($options: [OptionInput!], $brand: String, $category: ObjectId, $tags: [String!], $isAvilable: Boolean, $product_id: ObjectId!) {
  UpdateProduct(
    entry: {options: $options, brand: $brand, category: $category, tags: $tags, isAvilable: $isAvilable}
    _id: $product_id
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateProductOptionsMutationFn = Apollo.MutationFunction<UpdateProductOptionsMutation, UpdateProductOptionsMutationVariables>;

/**
 * __useUpdateProductOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateProductOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductOptionsMutation, { data, loading, error }] = useUpdateProductOptionsMutation({
 *   variables: {
 *      options: // value for 'options'
 *      brand: // value for 'brand'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *      isAvilable: // value for 'isAvilable'
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useUpdateProductOptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductOptionsMutation, UpdateProductOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductOptionsMutation, UpdateProductOptionsMutationVariables>(UpdateProductOptionsDocument, options);
      }
export type UpdateProductOptionsMutationHookResult = ReturnType<typeof useUpdateProductOptionsMutation>;
export type UpdateProductOptionsMutationResult = Apollo.MutationResult<UpdateProductOptionsMutation>;
export type UpdateProductOptionsMutationOptions = Apollo.BaseMutationOptions<UpdateProductOptionsMutation, UpdateProductOptionsMutationVariables>;
export const UpdateProductRelatedProductsDocument = gql`
    mutation updateProductRelatedProducts($bundles: [ObjectId!], $brand: String, $category: ObjectId, $tags: [String!], $isAvilable: Boolean, $product_id: ObjectId!) {
  UpdateProduct(
    entry: {bundles: $bundles, category: $category, brand: $brand, tags: $tags, isAvilable: $isAvilable}
    _id: $product_id
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateProductRelatedProductsMutationFn = Apollo.MutationFunction<UpdateProductRelatedProductsMutation, UpdateProductRelatedProductsMutationVariables>;

/**
 * __useUpdateProductRelatedProductsMutation__
 *
 * To run a mutation, you first call `useUpdateProductRelatedProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductRelatedProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductRelatedProductsMutation, { data, loading, error }] = useUpdateProductRelatedProductsMutation({
 *   variables: {
 *      bundles: // value for 'bundles'
 *      brand: // value for 'brand'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *      isAvilable: // value for 'isAvilable'
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useUpdateProductRelatedProductsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductRelatedProductsMutation, UpdateProductRelatedProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductRelatedProductsMutation, UpdateProductRelatedProductsMutationVariables>(UpdateProductRelatedProductsDocument, options);
      }
export type UpdateProductRelatedProductsMutationHookResult = ReturnType<typeof useUpdateProductRelatedProductsMutation>;
export type UpdateProductRelatedProductsMutationResult = Apollo.MutationResult<UpdateProductRelatedProductsMutation>;
export type UpdateProductRelatedProductsMutationOptions = Apollo.BaseMutationOptions<UpdateProductRelatedProductsMutation, UpdateProductRelatedProductsMutationVariables>;
export const UpdateProductsSeoByIdDocument = gql`
    mutation updateProductsSEOByID($brand: String, $category: ObjectId, $tags: [String!], $isAvilable: Boolean, $product_id: ObjectId!, $seo: SeoInput) {
  UpdateProduct(
    entry: {brand: $brand, category: $category, tags: $tags, isAvilable: $isAvilable, seo: $seo}
    _id: $product_id
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateProductsSeoByIdMutationFn = Apollo.MutationFunction<UpdateProductsSeoByIdMutation, UpdateProductsSeoByIdMutationVariables>;

/**
 * __useUpdateProductsSeoByIdMutation__
 *
 * To run a mutation, you first call `useUpdateProductsSeoByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductsSeoByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductsSeoByIdMutation, { data, loading, error }] = useUpdateProductsSeoByIdMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *      isAvilable: // value for 'isAvilable'
 *      product_id: // value for 'product_id'
 *      seo: // value for 'seo'
 *   },
 * });
 */
export function useUpdateProductsSeoByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductsSeoByIdMutation, UpdateProductsSeoByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductsSeoByIdMutation, UpdateProductsSeoByIdMutationVariables>(UpdateProductsSeoByIdDocument, options);
      }
export type UpdateProductsSeoByIdMutationHookResult = ReturnType<typeof useUpdateProductsSeoByIdMutation>;
export type UpdateProductsSeoByIdMutationResult = Apollo.MutationResult<UpdateProductsSeoByIdMutation>;
export type UpdateProductsSeoByIdMutationOptions = Apollo.BaseMutationOptions<UpdateProductsSeoByIdMutation, UpdateProductsSeoByIdMutationVariables>;
export const UpdateProductsVariationsByIdDocument = gql`
    mutation updateProductsVariationsByID($brand: String, $category: ObjectId, $tags: [String!], $isAvilable: Boolean, $product_id: ObjectId!) {
  UpdateProduct(
    entry: {brand: $brand, category: $category, tags: $tags, isAvilable: $isAvilable}
    _id: $product_id
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateProductsVariationsByIdMutationFn = Apollo.MutationFunction<UpdateProductsVariationsByIdMutation, UpdateProductsVariationsByIdMutationVariables>;

/**
 * __useUpdateProductsVariationsByIdMutation__
 *
 * To run a mutation, you first call `useUpdateProductsVariationsByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductsVariationsByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductsVariationsByIdMutation, { data, loading, error }] = useUpdateProductsVariationsByIdMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *      isAvilable: // value for 'isAvilable'
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useUpdateProductsVariationsByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductsVariationsByIdMutation, UpdateProductsVariationsByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductsVariationsByIdMutation, UpdateProductsVariationsByIdMutationVariables>(UpdateProductsVariationsByIdDocument, options);
      }
export type UpdateProductsVariationsByIdMutationHookResult = ReturnType<typeof useUpdateProductsVariationsByIdMutation>;
export type UpdateProductsVariationsByIdMutationResult = Apollo.MutationResult<UpdateProductsVariationsByIdMutation>;
export type UpdateProductsVariationsByIdMutationOptions = Apollo.BaseMutationOptions<UpdateProductsVariationsByIdMutation, UpdateProductsVariationsByIdMutationVariables>;
export const UpdateProfileSettingsDocument = gql`
    mutation updateProfileSettings($email: String, $firstName: String, $lastName: String, $nationality: String, $phoneNumber: String, $phonePrefix: String, $zipCode: String, $city: String, $country: String, $streetAddress: String, $apartment: String, $dateOfBirth: DateTime, $avatar: String) {
  UpdateCurrentSessionUser(
    entry: {email: $email, firstName: $firstName, lastName: $lastName, nationality: $nationality, userAddress: {phoneNumber: {prefix: $phonePrefix, number: $phoneNumber}, zipCode: $zipCode, city: $city, country: $country, streetAddress: $streetAddress, apartment: $apartment}, dateOfBirth: $dateOfBirth, avatar: $avatar}
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateProfileSettingsMutationFn = Apollo.MutationFunction<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>;

/**
 * __useUpdateProfileSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProfileSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileSettingsMutation, { data, loading, error }] = useUpdateProfileSettingsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      nationality: // value for 'nationality'
 *      phoneNumber: // value for 'phoneNumber'
 *      phonePrefix: // value for 'phonePrefix'
 *      zipCode: // value for 'zipCode'
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      streetAddress: // value for 'streetAddress'
 *      apartment: // value for 'apartment'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateProfileSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>(UpdateProfileSettingsDocument, options);
      }
export type UpdateProfileSettingsMutationHookResult = ReturnType<typeof useUpdateProfileSettingsMutation>;
export type UpdateProfileSettingsMutationResult = Apollo.MutationResult<UpdateProfileSettingsMutation>;
export type UpdateProfileSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>;
export const UpdateSecuritySettingsDocument = gql`
    mutation updateSecuritySettings($newPassword: String!, $oldPassword: String!) {
  UpdateCurrentSessionUserPassword(
    newPassword: $newPassword
    oldPassword: $oldPassword
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateSecuritySettingsMutationFn = Apollo.MutationFunction<UpdateSecuritySettingsMutation, UpdateSecuritySettingsMutationVariables>;

/**
 * __useUpdateSecuritySettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSecuritySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSecuritySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSecuritySettingsMutation, { data, loading, error }] = useUpdateSecuritySettingsMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useUpdateSecuritySettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSecuritySettingsMutation, UpdateSecuritySettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSecuritySettingsMutation, UpdateSecuritySettingsMutationVariables>(UpdateSecuritySettingsDocument, options);
      }
export type UpdateSecuritySettingsMutationHookResult = ReturnType<typeof useUpdateSecuritySettingsMutation>;
export type UpdateSecuritySettingsMutationResult = Apollo.MutationResult<UpdateSecuritySettingsMutation>;
export type UpdateSecuritySettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSecuritySettingsMutation, UpdateSecuritySettingsMutationVariables>;
export const UpdateStoreDocument = gql`
    mutation updateStore($vendor: String, $name: String, $description: String, $status: String, $coverPicture: String, $logo: String, $country: String, $streetAddress: String, $apartment: String, $city: String, $phonePrefix: String, $phoneNumber: String, $zipCode: String, $store_id: ObjectId!) {
  UpdateStore(
    entry: {vendor: $vendor, name: $name, description: $description, status: $status, storeMedia: {coverPicture: $coverPicture, logo: $logo}, storeAddress: {country: $country, streetAddress: $streetAddress, apartment: $apartment, city: $city, phoneNumber: {prefix: $phonePrefix, number: $phoneNumber}, zipCode: $zipCode}}
    _id: $store_id
  ) {
    _id
    code
    status
    info
  }
}
    `;
export type UpdateStoreMutationFn = Apollo.MutationFunction<UpdateStoreMutation, UpdateStoreMutationVariables>;

/**
 * __useUpdateStoreMutation__
 *
 * To run a mutation, you first call `useUpdateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreMutation, { data, loading, error }] = useUpdateStoreMutation({
 *   variables: {
 *      vendor: // value for 'vendor'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      coverPicture: // value for 'coverPicture'
 *      logo: // value for 'logo'
 *      country: // value for 'country'
 *      streetAddress: // value for 'streetAddress'
 *      apartment: // value for 'apartment'
 *      city: // value for 'city'
 *      phonePrefix: // value for 'phonePrefix'
 *      phoneNumber: // value for 'phoneNumber'
 *      zipCode: // value for 'zipCode'
 *      store_id: // value for 'store_id'
 *   },
 * });
 */
export function useUpdateStoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreMutation, UpdateStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreMutation, UpdateStoreMutationVariables>(UpdateStoreDocument, options);
      }
export type UpdateStoreMutationHookResult = ReturnType<typeof useUpdateStoreMutation>;
export type UpdateStoreMutationResult = Apollo.MutationResult<UpdateStoreMutation>;
export type UpdateStoreMutationOptions = Apollo.BaseMutationOptions<UpdateStoreMutation, UpdateStoreMutationVariables>;
export const UpdateStoreCompanyDataDocument = gql`
    mutation updateStoreCompanyData($website: String, $typeOfCompany: String, $companyName: String, $dateOfEstablishment: DateTime, $country: String, $apartment: String, $streetAddress: String, $city: String, $zipCode: String, $phoneNumber: PhoneNumberInput, $store_id: ObjectId!) {
  UpdateStore(
    entry: {storeKyc: {website: $website, typeOfCompany: $typeOfCompany, companyName: $companyName, dateOfEstablishment: $dateOfEstablishment}, storeAddress: {country: $country, apartment: $apartment, streetAddress: $streetAddress, city: $city, zipCode: $zipCode, phoneNumber: $phoneNumber}}
    _id: $store_id
  ) {
    status
    info
    code
  }
}
    `;
export type UpdateStoreCompanyDataMutationFn = Apollo.MutationFunction<UpdateStoreCompanyDataMutation, UpdateStoreCompanyDataMutationVariables>;

/**
 * __useUpdateStoreCompanyDataMutation__
 *
 * To run a mutation, you first call `useUpdateStoreCompanyDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreCompanyDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreCompanyDataMutation, { data, loading, error }] = useUpdateStoreCompanyDataMutation({
 *   variables: {
 *      website: // value for 'website'
 *      typeOfCompany: // value for 'typeOfCompany'
 *      companyName: // value for 'companyName'
 *      dateOfEstablishment: // value for 'dateOfEstablishment'
 *      country: // value for 'country'
 *      apartment: // value for 'apartment'
 *      streetAddress: // value for 'streetAddress'
 *      city: // value for 'city'
 *      zipCode: // value for 'zipCode'
 *      phoneNumber: // value for 'phoneNumber'
 *      store_id: // value for 'store_id'
 *   },
 * });
 */
export function useUpdateStoreCompanyDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreCompanyDataMutation, UpdateStoreCompanyDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreCompanyDataMutation, UpdateStoreCompanyDataMutationVariables>(UpdateStoreCompanyDataDocument, options);
      }
export type UpdateStoreCompanyDataMutationHookResult = ReturnType<typeof useUpdateStoreCompanyDataMutation>;
export type UpdateStoreCompanyDataMutationResult = Apollo.MutationResult<UpdateStoreCompanyDataMutation>;
export type UpdateStoreCompanyDataMutationOptions = Apollo.BaseMutationOptions<UpdateStoreCompanyDataMutation, UpdateStoreCompanyDataMutationVariables>;
export const UpdateStoreKycDocument = gql`
    mutation updateStoreKYC($alreadySelling: Boolean, $yearlyRevenue: String, $mainProducts: String, $customersAgeRange: String, $businessIndustry: String, $additionalSellingPlatforms: [String!], $store_id: ObjectId!) {
  UpdateStore(
    entry: {storeKyc: {alreadySelling: $alreadySelling, yearlyRevenue: $yearlyRevenue, mainProducts: $mainProducts, customersAgeRange: $customersAgeRange, additionalSellingPlatforms: $additionalSellingPlatforms, businessIndustry: $businessIndustry}}
    _id: $store_id
  ) {
    status
    info
    code
  }
}
    `;
export type UpdateStoreKycMutationFn = Apollo.MutationFunction<UpdateStoreKycMutation, UpdateStoreKycMutationVariables>;

/**
 * __useUpdateStoreKycMutation__
 *
 * To run a mutation, you first call `useUpdateStoreKycMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreKycMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreKycMutation, { data, loading, error }] = useUpdateStoreKycMutation({
 *   variables: {
 *      alreadySelling: // value for 'alreadySelling'
 *      yearlyRevenue: // value for 'yearlyRevenue'
 *      mainProducts: // value for 'mainProducts'
 *      customersAgeRange: // value for 'customersAgeRange'
 *      businessIndustry: // value for 'businessIndustry'
 *      additionalSellingPlatforms: // value for 'additionalSellingPlatforms'
 *      store_id: // value for 'store_id'
 *   },
 * });
 */
export function useUpdateStoreKycMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreKycMutation, UpdateStoreKycMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreKycMutation, UpdateStoreKycMutationVariables>(UpdateStoreKycDocument, options);
      }
export type UpdateStoreKycMutationHookResult = ReturnType<typeof useUpdateStoreKycMutation>;
export type UpdateStoreKycMutationResult = Apollo.MutationResult<UpdateStoreKycMutation>;
export type UpdateStoreKycMutationOptions = Apollo.BaseMutationOptions<UpdateStoreKycMutation, UpdateStoreKycMutationVariables>;
export const UpdateStoreSettingsDocument = gql`
    mutation updateStoreSettings($storeId: ObjectId!, $name: String, $description: String, $coverPicture: String, $logo: String, $country: String, $streetAddress: String, $zipCode: String, $phoneNumber: String, $phonePrefix: String, $city: String, $companyName: String, $website: String, $apartment: String, $businessIndustry: String) {
  UpdateStore(
    entry: {name: $name, description: $description, storeMedia: {coverPicture: $coverPicture, logo: $logo}, storeAddress: {country: $country, streetAddress: $streetAddress, zipCode: $zipCode, phoneNumber: {prefix: $phonePrefix, number: $phoneNumber}, city: $city, apartment: $apartment}, storeKyc: {companyName: $companyName, website: $website, businessIndustry: $businessIndustry}}
    _id: $storeId
  ) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateStoreSettingsMutationFn = Apollo.MutationFunction<UpdateStoreSettingsMutation, UpdateStoreSettingsMutationVariables>;

/**
 * __useUpdateStoreSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateStoreSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreSettingsMutation, { data, loading, error }] = useUpdateStoreSettingsMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      coverPicture: // value for 'coverPicture'
 *      logo: // value for 'logo'
 *      country: // value for 'country'
 *      streetAddress: // value for 'streetAddress'
 *      zipCode: // value for 'zipCode'
 *      phoneNumber: // value for 'phoneNumber'
 *      phonePrefix: // value for 'phonePrefix'
 *      city: // value for 'city'
 *      companyName: // value for 'companyName'
 *      website: // value for 'website'
 *      apartment: // value for 'apartment'
 *      businessIndustry: // value for 'businessIndustry'
 *   },
 * });
 */
export function useUpdateStoreSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreSettingsMutation, UpdateStoreSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreSettingsMutation, UpdateStoreSettingsMutationVariables>(UpdateStoreSettingsDocument, options);
      }
export type UpdateStoreSettingsMutationHookResult = ReturnType<typeof useUpdateStoreSettingsMutation>;
export type UpdateStoreSettingsMutationResult = Apollo.MutationResult<UpdateStoreSettingsMutation>;
export type UpdateStoreSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateStoreSettingsMutation, UpdateStoreSettingsMutationVariables>;
export const UpdateStoreStatusDocument = gql`
    mutation updateStoreStatus($status: String, $store_id: ObjectId!) {
  UpdateStore(entry: {status: $status}, _id: $store_id) {
    _id
    code
    info
    status
  }
}
    `;
export type UpdateStoreStatusMutationFn = Apollo.MutationFunction<UpdateStoreStatusMutation, UpdateStoreStatusMutationVariables>;

/**
 * __useUpdateStoreStatusMutation__
 *
 * To run a mutation, you first call `useUpdateStoreStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreStatusMutation, { data, loading, error }] = useUpdateStoreStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      store_id: // value for 'store_id'
 *   },
 * });
 */
export function useUpdateStoreStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreStatusMutation, UpdateStoreStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreStatusMutation, UpdateStoreStatusMutationVariables>(UpdateStoreStatusDocument, options);
      }
export type UpdateStoreStatusMutationHookResult = ReturnType<typeof useUpdateStoreStatusMutation>;
export type UpdateStoreStatusMutationResult = Apollo.MutationResult<UpdateStoreStatusMutation>;
export type UpdateStoreStatusMutationOptions = Apollo.BaseMutationOptions<UpdateStoreStatusMutation, UpdateStoreStatusMutationVariables>;
export const UpdateUserDetailsDocument = gql`
    mutation updateUserDetails($email: String, $firstName: String, $lastName: String, $nationality: String, $dateOfBirth: DateTime, $avatar: String, $userID: ObjectId!) {
  UpdateUser(
    entry: {email: $email, firstName: $firstName, lastName: $lastName, nationality: $nationality, dateOfBirth: $dateOfBirth, avatar: $avatar}
    _id: $userID
  ) {
    code
    info
    status
  }
}
    `;
export type UpdateUserDetailsMutationFn = Apollo.MutationFunction<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>;

/**
 * __useUpdateUserDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateUserDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDetailsMutation, { data, loading, error }] = useUpdateUserDetailsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      nationality: // value for 'nationality'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      avatar: // value for 'avatar'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUpdateUserDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>(UpdateUserDetailsDocument, options);
      }
export type UpdateUserDetailsMutationHookResult = ReturnType<typeof useUpdateUserDetailsMutation>;
export type UpdateUserDetailsMutationResult = Apollo.MutationResult<UpdateUserDetailsMutation>;
export type UpdateUserDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>;
export const UpdateVariationDocument = gql`
    mutation updateVariation($images: [String!], $isActive: Boolean, $variantPrice: Float, $sku: String, $barcode: String, $weightPerUnit: Float, $selectedOption: [SelectedOptionInput!], $variation_id: ObjectId!) {
  UpdateProductVariant(
    entry: {images: $images, isActive: $isActive, variantPrice: $variantPrice, inventory: {sku: $sku, barCode: $barcode, weightPerUnit: $weightPerUnit}, selectedOption: $selectedOption}
    _id: $variation_id
  ) {
    _id
  }
}
    `;
export type UpdateVariationMutationFn = Apollo.MutationFunction<UpdateVariationMutation, UpdateVariationMutationVariables>;

/**
 * __useUpdateVariationMutation__
 *
 * To run a mutation, you first call `useUpdateVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVariationMutation, { data, loading, error }] = useUpdateVariationMutation({
 *   variables: {
 *      images: // value for 'images'
 *      isActive: // value for 'isActive'
 *      variantPrice: // value for 'variantPrice'
 *      sku: // value for 'sku'
 *      barcode: // value for 'barcode'
 *      weightPerUnit: // value for 'weightPerUnit'
 *      selectedOption: // value for 'selectedOption'
 *      variation_id: // value for 'variation_id'
 *   },
 * });
 */
export function useUpdateVariationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVariationMutation, UpdateVariationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVariationMutation, UpdateVariationMutationVariables>(UpdateVariationDocument, options);
      }
export type UpdateVariationMutationHookResult = ReturnType<typeof useUpdateVariationMutation>;
export type UpdateVariationMutationResult = Apollo.MutationResult<UpdateVariationMutation>;
export type UpdateVariationMutationOptions = Apollo.BaseMutationOptions<UpdateVariationMutation, UpdateVariationMutationVariables>;