import {
	Drawer,
	DrawerOverlay,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerFooter,
	Button,
	Accordion,
	Box,
	AccordionButton,
	AccordionIcon,
	Heading,
	AccordionPanel,
	Stack,
	AccordionItem,
	CheckboxGroup,
	Checkbox,
	FormLabel,
	DrawerCloseButton,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { FC } from "react";
import { BiFilter, BiFilterAlt, BiReset } from "react-icons/bi";
import { GetAllUsersQueryVariables } from "../../../api/generated/graphql";
import { Input } from "../../../components";

type UsersFilterDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	handleSubmitFilter: (values: {
		firstName: string;
		lastName: string;
		email: string;
		status: string[];
	}) => void;
	defaultValues: {
		firstName: string;
		lastName: string;
		email: string;
		status: string[];
	};
	handleResetFilter: () => void;
};

const UserFilterDrawer: FC<UsersFilterDrawerProps> = (props) => {
	const {
		isOpen,
		onClose,
		handleSubmitFilter,
		handleResetFilter,
		defaultValues,
	} = props;

	return (
		<Drawer isOpen={isOpen} onClose={onClose} placement='right'>
			<DrawerOverlay />
			<Formik
				validateOnMount={false}
				validateOnBlur={false}
				validateOnChange={false}
				initialValues={defaultValues}
				onSubmit={(values, { setSubmitting, setFieldError }) => {
					handleSubmitFilter(values);
					setSubmitting(false);
					onClose();
				}}>
				{({ values, handleChange, handleSubmit, isSubmitting }) => (
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader borderBottomWidth='1px'>
							Customers filter
						</DrawerHeader>
						<DrawerBody>
							<form onSubmit={handleSubmit} id='filter-form'>
								<Stack spacing='12px'>
									<Input
										label='First name'
										inputGroupProps={{ size: "sm" }}
										inputProps={{
											placeholder: "First name",
											name: "firstName",
											onChange: handleChange,
											value: values.firstName,
										}}
									/>
									<Input
										label='Last name'
										inputGroupProps={{ size: "sm" }}
										inputProps={{
											placeholder: "Last name",
											name: "lastName",
											onChange: handleChange,
											value: values.lastName,
										}}
									/>

									<Input
										label='Email'
										inputGroupProps={{ size: "sm" }}
										inputProps={{
											placeholder: "Email",
											name: "email",
											onChange: handleChange,
											value: values.email,
										}}
									/>

									<CheckboxGroup
										aria-labelledby='checkbox-group'
										colorScheme='blue'>
										<FormLabel>Customers status</FormLabel>
										<Stack spacing='8px'>
											<Checkbox
												name='status'
												value='isArchived'
												onChange={handleChange}
												checked={values.status.includes("isArchived")}>
												Archived customers
											</Checkbox>
											<Checkbox
												name='status'
												value='isBlocked'
												onChange={handleChange}
												checked={values.status.includes("isBlocked")}>
												Blocked customers
											</Checkbox>
											<Checkbox
												name='status'
												value='isConfirmed'
												onChange={handleChange}
												checked={values.status.includes("isConfirmed")}>
												Confirmed customers
											</Checkbox>
										</Stack>
									</CheckboxGroup>
								</Stack>
							</form>
						</DrawerBody>
						<DrawerFooter gap='2' borderTopWidth='1px'>
							<Button
								onClick={() => {
									handleResetFilter();
									onClose();
								}}
								isFullWidth
								leftIcon={<BiReset />}>
								Reset
							</Button>
							<Button
								isLoading={isSubmitting}
								type='submit'
								colorScheme='blue'
								isFullWidth
								form='filter-form'
								leftIcon={<BiFilterAlt />}>
								Filter
							</Button>
						</DrawerFooter>
					</DrawerContent>
				)}
			</Formik>
		</Drawer>
	);
};

export default UserFilterDrawer;
