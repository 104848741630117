import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const NavigationWrapper = styled(Box)`
	height: 100vh;
	width: fit-content;
	.logo_container {
		width: 160px;
		margin-left: 16px;
		margin-bottom: 30px;
		> img {
			width: 130px;
		}
	}
`;

export const SettingsNavigationWrapper = styled(Box)`
	position: sticky;
	top: 70px;
	height: fit-content;
	.logo_container {
		width: 100%;
	}
`;
