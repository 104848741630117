import { storeValueIsStoreObject } from "@apollo/client/cache/inmemory/helpers";
import {
	Box,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Skeleton,
	Stack,
	useToast,
} from "@chakra-ui/react";
import { Formik, FormikErrors } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { apolloClient } from "../../..";
import {
	useGetUserDetailsQuery,
	useUpdateUserDetailsMutation,
} from "../../../api/generated/graphql";
import {
	DateTimeInput,
	ImageUpload,
	Input,
	SelectInput,
} from "../../../components";
import { countryOptions } from "./tempCountries";

type EditCustomerPropsType = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	customerID?: string;
};

const EditCustomer = (props: EditCustomerPropsType) => {
	const { isOpen, onOpen, onClose, customerID } = props;

	const [updateUserMutation] = useUpdateUserDetailsMutation();

	const toast = useToast();

	const {
		loading: userDetailLoading,
		error: userDetailError,
		data: userDetailData,
	} = useGetUserDetailsQuery({
		variables: {
			id: customerID,
		},
	});

	useEffect(() => {
		setFiles(
			userDetailData?.GetUserById?.avatar
				? [
						{
							progress: false,
							id: userDetailData?.GetUserById?._id,
							src: userDetailData?.GetUserById?.avatar,
						},
				  ]
				: []
		);
	}, [userDetailData]);

	const [files, setFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const handleFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append(
			"public_id",
			customerID ? `${customerID}/${file.name}` : `no_ID/${file.name}`
		);
		setFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleDelete = (id: string) => {
		console.log(
			id,
			files.filter(({ id: imageId }) => id !== imageId)
		);
		setFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	const onHandleClose = () => {
		setFiles([]);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior='inside'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader borderBottomWidth='1px'>Edit customer</ModalHeader>
				<ModalCloseButton />

				{userDetailLoading ? (
					<ModalBody>
						<Stack py='9px' spacing='14px' opacity='0.4'>
							<Stack spacing='8px'>
								<Skeleton h='20px' w='68px' />
								<Skeleton h='140px' w='140px' />
							</Stack>
							<Stack direction='row' spacing='14px'>
								<Stack spacing='8px' w='full'>
									<Skeleton h='20px' w='68px' />
									<Skeleton h='40px' w='full' />
								</Stack>
								<Stack spacing='8px' w='full'>
									<Skeleton h='20px' w='68px' />
									<Skeleton h='40px' w='full' />
								</Stack>
							</Stack>
							<Stack spacing='8px' w='full'>
								<Skeleton h='20px' w='68px' />
								<Skeleton h='40px' w='full' />
							</Stack>
							<Stack spacing='8px' w='full'>
								<Skeleton h='20px' w='68px' />
								<Skeleton h='40px' w='full' />
							</Stack>
							<Stack spacing='8px' w='full'>
								<Skeleton h='20px' w='68px' />
								<Skeleton h='40px' w='full' />
							</Stack>
						</Stack>
					</ModalBody>
				) : (
					<Formik
						validateOnMount={false}
						validateOnBlur={false}
						validateOnChange={false}
						initialValues={{
							firstName: userDetailData?.GetUserById?.firstName || "",
							lastName: userDetailData?.GetUserById?.lastName || "",
							email: userDetailData?.GetUserById?.email || "",
							nationality: userDetailData?.GetUserById?.nationality || "",
							dateOfBirth: userDetailData?.GetUserById?.dateOfBirth
								? moment(userDetailData?.GetUserById?.dateOfBirth).toDate()
								: null,
						}}
						validate={(values) => {
							const errors: FormikErrors<typeof values> = {};
							if (!values.email) {
								errors.email = "Email is required";
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
							) {
								errors.email = "Invalid email address";
							}

							if (!values.nationality) {
								errors.nationality = "Nationality is required";
							}
							if (!values.firstName) {
								errors.firstName = "First name is required";
							}
							if (!values.lastName) {
								errors.lastName = "Last name is required";
							}
							if (!values.dateOfBirth) {
								errors.dateOfBirth = "Date of birth is required";
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting, setFieldError }) => {
							updateUserMutation({
								variables: {
									email: values.email,
									userID: customerID,
									avatar: files?.[0]?.src ? (files?.[0]?.src as string) : "",
									dateOfBirth: moment(values.dateOfBirth).format(),
									firstName: values.firstName,
									lastName: values.lastName,
									nationality: values.nationality,
								},
								onCompleted: (data) => {
									if (data.UpdateUser.code) {
										toast({
											title: "Customer data has been updated.",
											status: "success",

											position: "bottom-right",
											duration: 5000,
											isClosable: true,
										});
										apolloClient.refetchQueries({
											include: "active",
										});
										onHandleClose();
									}
								},
								onError: (error) => {
									setSubmitting(false);
									if (error.networkError) {
										toast({
											title: "Network error.",
											description:
												"Please check your internet connection, or refresh the page",
											status: "error",

											position: "bottom-right",
											duration: 5000,
											isClosable: true,
										});
									} else {
										toast({
											title: "An error occured while updating category.",
											description: error.message,
											status: "error",

											position: "bottom-right",
											duration: 5000,
											isClosable: true,
										});
									}
								},
							});
						}}>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setFieldValue,
						}) => (
							<>
								<ModalBody>
									<ImageUpload
										height='140px'
										width='140px'
										images={files}
										handleUpload={handleFile}
										handleDelete={handleDelete}
										label='Profile picture'
									/>
									<Box>
										<form onSubmit={handleSubmit} id='update-user-form'>
											<Stack spacing='12px' marginY='12px'>
												<Stack direction='row' spacing='14px'>
													<Input
														label='First name'
														inputProps={{
															placeholder: "First name",
															name: "firstName",
															onChange: handleChange,
															value: values.firstName,
														}}
														errorMessage={errors.firstName}
														isError={errors.firstName !== undefined}
													/>
													<Input
														label='Last name'
														inputProps={{
															placeholder: "Last name",
															name: "lastName",
															onChange: handleChange,
															value: values.lastName,
														}}
														errorMessage={errors.lastName}
														isError={errors.lastName !== undefined}
													/>
												</Stack>
												<Input
													label='Email'
													inputProps={{
														placeholder: "Email",
														name: "email",
														onChange: handleChange,
														value: values.email,
													}}
													errorMessage={errors.email}
													isError={errors.email !== undefined}
												/>
												{/* <Input
													label='Phone number'
													inputGroupProps={{ size: "sm" }}
													inputProps={{
														placeholder: "Phone number",
														type: "number",
														name: "phoneNumber",
														onChange: handleChange,
														value: values.phoneNumber,
													}}
													errorMessage={
														errors.phoneNumber || errors.phonePrefix
													}
													isError={
														errors.phoneNumber !== undefined ||
														errors.phonePrefix !== undefined
													}
													inputLeftAddon={{
														background: "transparent",
														p: 0,
														border: "none",
														children: (
															<Input
																inputProps={{
																	placeholder: "Prefix",
																	maxWidth: "68px",
																	pl: "12px",
																	type: "number",
																	name: "phonePrefix",
																	onChange: handleChange,
																	value: values.phonePrefix,
																}}
																inputGroupProps={{ size: "sm" }}
																isError={errors.phonePrefix !== undefined}
															/>
														),
													}}
												/> */}
												<SelectInput
													options={countryOptions}
													label='Nationality'
													selectProps={{
														name: "nationality",
														value: values.nationality,
														onChange: handleChange,
													}}
													errorMessage={errors.dateOfBirth}
													isError={errors.dateOfBirth !== undefined}
												/>
												<DateTimeInput
													name='dateOfBirth'
													label='Date of birth'
													onChange={(date) =>
														setFieldValue("dateOfBirth", date)
													}
													selected={values?.dateOfBirth}
													errorMessage={
														errors.dateOfBirth || errors.dateOfBirth
													}
													isError={errors.dateOfBirth !== undefined}
												/>
											</Stack>
										</form>
									</Box>
								</ModalBody>
								<ModalFooter borderTopWidth='1px'>
									<Button variant='outline' mr={3} onClick={onHandleClose}>
										Cancel
									</Button>
									<Button
										colorScheme='main'
										type='submit'
										form='update-user-form'
										isLoading={isSubmitting}>
										Save
									</Button>
								</ModalFooter>
							</>
						)}
					</Formik>
				)}
			</ModalContent>
		</Modal>
	);
};

export default EditCustomer;
