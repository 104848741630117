import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const AppBarWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	.appbar_content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 18px;
		.appbar_elements {
			display: flex;
			align-items: center;
			gap: 12px;
		}
		.profile_button {
			.profile_picture {
				border-radius: 50%;
				height: 24px;
				width: 24px;
				/* margin-right: 18px; */
				max-width: unset;
				object-fit: cover;
			}
		}
		.menu_profile_item {
			.profile_picture_menu {
				border-radius: 50%;
				height: 52px;
				width: 52px;
				object-fit: cover;
			}
		}
	}
`;
