import {
	Box,
	Button,
	Divider,
	Heading,
	IconButton,
	Link,
	Skeleton,
	Spinner,
	Stack,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import {
	BiArrowBack,
	BiChevronDown,
	BiChevronUp,
	BiPlus,
} from "react-icons/bi";
import { useLocation, Link as RouterLink } from "react-router-dom";
import {
	useGetCategoryNodesLazyQuery,
	useGetMainCategoriesQuery,
} from "../../api/generated/graphql";
import { PageWrapper } from "../../utils/globalStyle";
import { CategoriesWrapper } from "./Categories.styles";
import { CreateCategory, UpdateCategory } from "./Forms";

type CategoriesListDisplayProps = {
	loading?: boolean;
	level?: number;
	onCategorySelect?: (id: string) => void;
	onCreateCategory?: (id?: string) => void;
	fatherID?: string;
	selected?: string | null;
	list?:
		| {
				_id?: string | null;
				name?: string | null;
				hasNodes?: boolean | null;
		  }[]
		| null;
};

const CategoriesListDisplay = (props: CategoriesListDisplayProps) => {
	const {
		loading,
		list,
		level,
		onCategorySelect,
		selected,
		onCreateCategory,
		fatherID,
	} = props;

	const borderColor = useColorModeValue("gray.100", "gray.700");

	const [getNodes, { data: categoryNodesData, loading: catgoryNodesLoading }] =
		useGetCategoryNodesLazyQuery();

	const [moreCategoryID, setMoreCategoryID] = useState<string>("");

	const handleSelectCategory = (id: string) => {
		onCategorySelect?.(id);
		handleCategoryNodes(id);
	};

	const handleCategoryNodes = (id: string) => {
		if (id) {
			setMoreCategoryID(id);
			getNodes({ variables: { id } });
		}
		if (moreCategoryID === id) {
			setMoreCategoryID("");
		}
	};

	if (loading)
		return (
			<Stack direction='row' spacing='8px' align='center'>
				<Spinner color='blue.400' size='xs' thickness='2px' />{" "}
				<Text color='blue.400' fontWeight='medium' fontSize='sm'>
					Loading...
				</Text>
			</Stack>
		);

	return (
		<Stack width='full' minWidth='fit-content' spacing='0'>
			<Button
				justifyContent='start'
				size='sm'
				isFullWidth
				variant='ghost'
				fontSize='14px'
				colorScheme='blue'
				textColor='blue.200'
				fontWeight='normal'
				leftIcon={<BiPlus size={16} />}
				onClick={() => onCreateCategory?.(fatherID)}
				px={2}>
				{level !== 1 ? "Create new sub-category" : "Create new category"}
			</Button>
			<Stack spacing='4px' width='full'>
				{list?.map((category, index) => (
					<Box key={category?._id}>
						<Stack direction='row' align='center' spacing='0'>
							<Button
								justifyContent='start'
								size='sm'
								isFullWidth
								px={1}
								variant={selected === category?._id ? "solid" : "ghost"}
								fontSize='14px'
								leftIcon={
									moreCategoryID === category._id ? (
										<BiChevronUp
											size={18}
											opacity={category.hasNodes ? 1 : 0}
										/>
									) : (
										<BiChevronDown
											size={18}
											opacity={category.hasNodes ? 1 : 0}
										/>
									)
								}
								onClick={() => handleSelectCategory(category?._id || "")}>
								{category.name}
							</Button>
						</Stack>
						{moreCategoryID === category._id && (
							<Stack
								direction='row'
								pl={2}
								py={2}
								// spacing={"14px"}
								// w='full'
							>
								<Divider
									orientation='vertical'
									color={borderColor}
									height='inherit'
								/>
								<CategoriesListDisplay
									list={categoryNodesData?.GetCategoryNodes}
									loading={catgoryNodesLoading}
									onCategorySelect={onCategorySelect}
									selected={selected}
									onCreateCategory={onCreateCategory}
									fatherID={category._id}
								/>
							</Stack>
						)}
					</Box>
				))}
			</Stack>
		</Stack>
	);
};

const Categories = () => {
	const location = useLocation();

	const borderColor = useColorModeValue("gray.100", "gray.700");

	const { data: categoriesData, loading: categoriesLoading } =
		useGetMainCategoriesQuery({
			pollInterval: 300000,
		});

	const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

	const [createCategory, setCreateCategory] = useState<boolean>(false);

	const handleCreateCategory = (id?: string) => {
		if (id) {
			setSelectedCategory(id);
		} else {
			setSelectedCategory(null);
		}
		setCreateCategory(true);
	};

	const handleSelectCategory = (id: string) => {
		setSelectedCategory(id);
		setCreateCategory(false);
	};

	const { from } = (location?.state as { from?: string }) || "";

	const handleReset = () => {
		setCreateCategory(true);
		setSelectedCategory(null);
	};

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Heading fontSize='2xl'>Categories</Heading>
					</Box>
				</Box>
			</Box>
			<CategoriesWrapper direction='row' spacing='14px'>
				<Box
					className='categories_list'
					border='1px'
					borderColor={borderColor}
					rounded='base'>
					<Heading size='md' fontWeight='medium'>
						Categories list
					</Heading>
					<Text fontSize='sm' opacity={0.7} mb='12px'>
						Click on category to modify
					</Text>
					<CategoriesListDisplay
						list={categoriesData?.GetAllCategories?.categories}
						loading={categoriesLoading}
						level={1}
						onCategorySelect={handleSelectCategory}
						selected={selectedCategory}
						onCreateCategory={handleCreateCategory}
					/>
				</Box>
				<Box
					className='categories_action'
					border='1px'
					borderColor={borderColor}
					rounded='base'>
					{selectedCategory && !createCategory ? (
						<UpdateCategory
							selectedID={selectedCategory}
							onDeleteCategory={handleReset}
						/>
					) : (
						<CreateCategory
							parentID={selectedCategory}
							onCategoryCreated={handleReset}
						/>
					)}
				</Box>
			</CategoriesWrapper>
		</PageWrapper>
	);
};

export default Categories;
