import {
	Drawer,
	DrawerOverlay,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerFooter,
	Button,
	Accordion,
	Box,
	AccordionButton,
	AccordionIcon,
	Heading,
	AccordionPanel,
	Stack,
	AccordionItem,
	CheckboxGroup,
	Checkbox,
	FormLabel,
	DrawerCloseButton,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { FC } from "react";
import { BiFilter, BiFilterAlt, BiReset } from "react-icons/bi";
import {
	GetAllUsersQueryVariables,
	ProductType,
} from "../../../api/generated/graphql";
import { CategorySelectInput, Input, SelectInput } from "../../../components";

type ProductsFilterDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	handleSubmitFilter: (values: {
		name: string;
		brand: string;
		type: string;
		category: {
			label: string;
			id: string;
		};
	}) => void;
	defaultValues: {
		name: string;
		brand: string;
		type: string;
		category: {
			label: string;
			id: string;
		};
	};
	handleResetFilter: () => void;
};

const ProductsFilterDrawer: FC<ProductsFilterDrawerProps> = (props) => {
	const {
		isOpen,
		onClose,
		handleSubmitFilter,
		handleResetFilter,
		defaultValues,
	} = props;

	return (
		<Drawer isOpen={isOpen} onClose={onClose} placement='right'>
			<DrawerOverlay />
			<Formik
				validateOnMount={false}
				validateOnBlur={false}
				validateOnChange={false}
				initialValues={defaultValues}
				onSubmit={(values, { setSubmitting, setFieldError }) => {
					handleSubmitFilter(values);
					setSubmitting(false);
					onClose();
				}}>
				{({
					values,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader borderBottomWidth='1px'>Products filter</DrawerHeader>
						<DrawerBody>
							<form onSubmit={handleSubmit} id='product-filter-form'>
								<Stack spacing='12px'>
									<Input
										label='Exact name'
										inputGroupProps={{ size: "sm" }}
										inputProps={{
											placeholder: "Name",
											name: "name",
											onChange: handleChange,
											value: values.name,
										}}
									/>
									<Input
										label='Brand name'
										inputGroupProps={{ size: "sm" }}
										inputProps={{
											placeholder: "Brand",
											name: "brand",
											onChange: handleChange,
											value: values.brand,
										}}
									/>

									<SelectInput
										options={[
											{ label: "Physical", value: ProductType.Physical },
											{ label: "Digital", value: ProductType.Downloadable },
											{ label: "Physical & digital", value: ProductType.Mixed },
											{ label: "Service", value: ProductType.Service },
										]}
										label='Product type'
										selectProps={{
											name: "type",
											placeholder: "Choose a type...",
											value: values.type,
											onChange: handleChange,
										}}
									/>

									<CategorySelectInput
										selected={values.category}
										handleSelectCategory={(id, label) =>
											setFieldValue("category", { id, label })
										}
									/>
								</Stack>
							</form>
						</DrawerBody>
						<DrawerFooter gap='2' borderTopWidth='1px'>
							<Button
								onClick={() => {
									handleResetFilter();
									onClose();
								}}
								isFullWidth
								leftIcon={<BiReset />}>
								Reset
							</Button>
							<Button
								isLoading={isSubmitting}
								type='submit'
								colorScheme='blue'
								isFullWidth
								form='product-filter-form'
								leftIcon={<BiFilterAlt />}>
								Filter
							</Button>
						</DrawerFooter>
					</DrawerContent>
				)}
			</Formik>
		</Drawer>
	);
};

export default ProductsFilterDrawer;
