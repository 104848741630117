import { Box, Heading, Stack, useColorModeValue } from "@chakra-ui/react";
import SettingsWrapper from "../../components/SettingsWrapper/SettingsWrapper";

const StoreSeoSettings = () => {
	const sectionBgColor = useColorModeValue("white", "gray.900");

	return (
		<SettingsWrapper>
			<Heading size='lg' fontSize='2xl' fontWeight='medium'>
				Store SEO
			</Heading>
			<Stack marginTop='24px' spacing='20px'>
				<Box
					p='16px'
					bgColor={sectionBgColor}
					width='100%'
					shadow='md'
					rounded='base'>
					<Heading mt='4' size='md' fontSize='xl' fontWeight='medium'>
						Coming soon!
					</Heading>
				</Box>
			</Stack>
		</SettingsWrapper>
	);
};
export default StoreSeoSettings;
