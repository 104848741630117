import {
	FormControl,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	MenuDivider,
	Button,
	Box,
	MenuItem,
	MenuGroup,
	Spinner,
	Text,
	Stack,
	useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode, useMemo, useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import Input, { InputPropsType } from "../Input/Input";

type DropDownInputProps = {
	input?: InputPropsType;
	label?: string;
	placeholder?: string;
	selected?: string;
	isLoading?: boolean;
	errorMessage?: string;
	list: {
		render: ReactNode;
		value: string;
	}[];
	onSelect: (value: string) => void;
	name?: string;
};

const DropDownInput = (props: DropDownInputProps) => {
	const {
		selected,
		label,
		placeholder,
		errorMessage,
		isLoading,
		list,
		onSelect,
		name,
	} = props;

	const [openDropDown, setOpenDropDown] = useState<boolean>(false);

	const [search, setSearch] = useState<string>("");

	const hoverBg = useColorModeValue("gray.100", "gray.600");

	// useMemo(() => first, [second])

	const searchedList = useMemo(
		() =>
			list.filter((item) =>
				item.value.toLowerCase().includes(search.toLowerCase())
			),
		[search, list]
	);

	const handleSelect = (value: string) => {
		onSelect(value);
		setOpenDropDown(false);
	};

	return (
		<FormControl position='relative'>
			<Input
				label={label}
				inputProps={{
					placeholder: placeholder,
					name: name,
					value: selected,
					isReadOnly: true,
					onChange: undefined,
					onClickCapture: () => setOpenDropDown(true),
				}}
				inputRightElementProps={{
					children: <BiChevronDown size={18} />,
				}}
				errorMessage={errorMessage}
				isError={errorMessage !== undefined}
			/>
			<Box w='full'>
				<Menu
					isLazy
					matchWidth
					isOpen={openDropDown}
					onClose={() => setOpenDropDown(!openDropDown)}
					closeOnSelect={false}>
					<MenuButton w='100%' h='0' position='absolute'></MenuButton>
					<MenuList zIndex={99}>
						<Box padding='8px 12px'>
							<Input
								inputProps={{
									placeholder: "Search",
									onChange: (e) => setSearch(e.target.value),
								}}
							/>
						</Box>
						{isLoading ? (
							<Stack
								direction='row'
								alignItems='center'
								color={"blue.400"}
								padding='8px 12px'>
								<Spinner size='sm' />
								<Text>Loading...</Text>
							</Stack>
						) : (
							<Box maxHeight='165px' overflowY='scroll'>
								{searchedList.map((option, index) => (
									<Box
										key={index}
										bgColor={selected === option.value ? hoverBg : "inherit"}
										className='dropdown_item'
										padding='6px 16px'
										onClick={() => handleSelect(option.value)}
										_hover={{ backgroundColor: hoverBg, cursor: "pointer" }}>
										{option.render || option.value}
									</Box>
								))}
							</Box>
						)}
					</MenuList>
				</Menu>
			</Box>
		</FormControl>
	);
};

export default DropDownInput;
