import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Checkbox,
	Divider,
	Heading,
	IconButton,
	Link,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Spinner,
	Stack,
	Tab,
	TabList,
	Tabs,
	Text,
	Tooltip,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useRef, useState } from "react";
import {
	BiArchive,
	BiArrowBack,
	BiBlock,
	BiBoltCircle,
	BiBriefcase,
	BiCheckCircle,
	BiCircle,
	BiDotsHorizontalRounded,
	BiEdit,
	BiFace,
	BiFilterAlt,
	BiLinkExternal,
	BiRadioCircleMarked,
	BiReset,
	BiSearch,
	BiShow,
	BiShowAlt,
	BiTrash,
} from "react-icons/bi";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { apolloClient } from "../..";
import {
	GetAllProductsQueryVariables,
	GetCurrentSessionProductsQueryVariables,
	ProductStatusType,
	ProductType,
	useDeleteProductMutation,
	useExportProductsLazyQuery,
	useExportProductsQuery,
	useGetAllProductsQuery,
	useGetAllUsersQuery,
	useGetCurrentSessionProductsQuery,
	useSetProductStatusMutation,
} from "../../api/generated/graphql";
import { picturePlaceholder } from "../../assets";
import {
	ConfirmDeleteDialog,
	Input,
	Table,
	TableHeadType,
} from "../../components";
import { getDataFromCache } from "../../utils/cache";
import { PageWrapper } from "../../utils/globalStyle";
import ProductsFilterDrawer from "./Filters/ProductsFilterDrawer";

const Products = () => {
	const location = useLocation();

	const toast = useToast();

	const navigate = useNavigate();

	const storeID = getDataFromCache("store_id", true);

	const [pagination, setPagination] = useState({
		limit: 10,
		skip: 0,
	});

	const [setProductStatus, setProductStatusParam] =
		useSetProductStatusMutation();

	const handleChangeProductStatus = (id: string, status: ProductStatusType) => {
		setProductStatus({
			variables: {
				product_id: id,
				status: status,
			},
			onCompleted: (data) => {
				if (data.SetProductStatus._id) {
					toast({
						title: "Product status has been updated with success!",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
				}
				apolloClient.refetchQueries({
					include: "active",
				});
			},
			onError: (error) => {
				toast({
					title: "An error occured while updating product status.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	const userRole: string = getDataFromCache("user_role", true);

	const [tabIndex, setTabIndex] = useState<number>(0);

	const [status, setStatus] = useState<ProductStatusType>();

	const handleChangeTabIndex = (index: number) => {
		// Archive = 'ARCHIVE',
		// Declined = 'DECLINED',
		// Draft = 'DRAFT',
		// Pending = 'PENDING',
		// Published = 'PUBLISHED'
		switch (index) {
			case 1: {
				setStatus(ProductStatusType.Pending);
				break;
			}
			case 2: {
				setStatus(ProductStatusType.Published);
				break;
			}
			case 3: {
				setStatus(
					userRole === "VENDOR"
						? ProductStatusType.Draft
						: ProductStatusType.Declined
				);
				break;
			}
			default:
				setStatus(undefined);
		}

		setTabIndex(index);
	};

	const [selectedCustomer, setSelectedCustomer] = useState<
		string | undefined
	>();

	const [filters, setFilters] = useState<
		Pick<GetCurrentSessionProductsQueryVariables, "name" | "brand" | "type"> & {
			category: {
				label: string;
				id: string;
			};
		}
	>();
	// const [adminFilters, setAdminFilters] =
	// 	useState<
	// 		Pick<
	// 			GetAllProductsQueryVariables,
	// 			"name" | "search" | "brand" | "type" | "category" | "store"
	// 		>
	// 	>();

	const [search, setSearch] =
		useState<Pick<GetCurrentSessionProductsQueryVariables, "search">>();

	const [defaultFilterValues, setDefaultFilterValues] = useState({
		name: "",
		brand: "",
		type: "",
		category: {
			label: "",
			id: "",
		},
	});

	const { from } = (location?.state as { from?: string }) || "";

	const renderProductStatus = (status: string | null | undefined) => {
		switch (status) {
			case "ARCHIVE":
				return <Text opacity={0.6}>Archived</Text>;
			case "DECLINED":
				return <Text color='red.300'>Declined</Text>;
			case "DRAFT":
				return <Text>Draft</Text>;
			case "PENDING":
				return <Text color='yellow.400'>Requested</Text>;
			case "PUBLISHED":
				return (
					<Stack
						direction='row'
						alignItems='center'
						spacing='2px'
						color='green.400'>
						<BiRadioCircleMarked />
						<Text> Online</Text>
					</Stack>
				);
		}
	};

	const handlePageChange = (dir: "next" | "prev") => {
		if (dir === "next") {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip + temp.limit;
				return temp;
			});
		} else {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip - temp.limit;
				return temp;
			});
		}
	};

	const handleLimitChange = (value: number) => {
		setPagination((prevState) => {
			let temp = { ...prevState };
			temp.limit = +value;
			return temp;
		});
	};

	const {
		loading: productsVendorLoading,
		error: productsVendorError,
		data: productsVendorData,
	} = useGetCurrentSessionProductsQuery({
		variables: {
			pagination: pagination,
			status: status,
			search: search?.search || undefined,
			brand: filters?.brand || undefined,
			category: filters?.category?.id || undefined,
			name: filters?.name || undefined,
			type: filters?.type || undefined,
		},
		skip: userRole !== "VENDOR",
	});

	const {
		loading: productsAdminLoading,
		error: productsAdminError,
		data: productsAdminData,
	} = useGetAllProductsQuery({
		variables: {
			pagination: pagination,
			status: status,
			search: search?.search || undefined,
			brand: filters?.brand || undefined,
			category: filters?.category?.id || undefined,
			name: filters?.name || undefined,
			type: filters?.type || undefined,
		},
		skip: userRole !== "ADMIN",
	});

	const [deleteProductMutation, deleteProductParam] =
		useDeleteProductMutation();

	const handleDeleteProduct = (id: string) => {
		deleteProductMutation({
			variables: {
				product_id: id,
			},
			onCompleted: (data) => {
				if (data?.DeleteProduct?.code) {
					toast({
						title: "Product has been deleted with success.",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					apolloClient.refetchQueries({
						include: "active",
					});
				}
			},
			onError: (error) => {
				toast({
					title: "An error occurred while deleting product.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

	const handleSelectProduct = (id: string) => {
		setSelectedProducts((prevState) => [...prevState, id]);
	};

	const handleRemoveSelectedProduct = (id: string) => {
		setSelectedProducts((prevState) => {
			let temp = [...prevState];
			const index = temp.indexOf(id, 0);
			if (index > -1) {
				temp.splice(index, 1);
			}
			return temp;
		});
	};

	const productsVendorTableData =
		productsVendorData?.GetCurrentSessionUserProducts?.products?.map(
			(product, index) => ({
				id: product._id,
				check: (
					<Checkbox
						value={product._id}
						onChange={(e) => {
							if (e.target.checked) {
								handleSelectProduct(e.target.value);
							} else {
								handleRemoveSelectedProduct(e.target.value);
							}
						}}
					/>
				),
				image: (
					<Box className='table_image'>
						<img
							src={product.images?.[0] || picturePlaceholder}
							alt='product_profile_picture'
						/>
					</Box>
				),
				name: product?.name,
				category: product?.category?.name,
				brand: product?.brand,
				status: setProductStatusParam.loading ? (
					<Stack direction='row' alignItems='center' color={"blue.400"}>
						<Spinner size='sm' />
						<Text>Loading...</Text>
					</Stack>
				) : (
					renderProductStatus(product.status)
				),
				optionNumber: product?.options.length,
				variationNumber: product?.variations.length,
				creationDate: moment(product.createdAt).format("lll"),
				basePrice: product?.price?.price + " TND",
				action: (
					<Stack
						direction='row'
						display='inline-flex'
						width='fit-content'
						alignItems='center'
						justifyContent='flex-end'>
						<IconButton
							onClick={() =>
								navigate(`/${storeID}/products/${product._id}`, {
									state: {
										from: `/${storeID}/products`,
										pageName: "Products",
									},
								})
							}
							aria-label='edit_button'
							size='sm'
							variant='ghost'>
							<BiEdit size={18} />
						</IconButton>
						<Menu>
							<MenuButton
								as={IconButton}
								aria-label='menu_more'
								size='sm'
								variant='ghost'
								className='menu_iconButton'>
								<BiDotsHorizontalRounded size={18} />
							</MenuButton>
							<MenuList px='2' shadow='lg'>
								{product.status === "DRAFT" ? (
									<MenuItem
										rounded='base'
										icon={<BiCheckCircle size={16} />}
										onClick={() =>
											handleChangeProductStatus(
												product._id,
												ProductStatusType.Pending
											)
										}>
										Request publishing
									</MenuItem>
								) : (
									<MenuItem
										rounded='base'
										icon={<BiReset size={16} />}
										onClick={() =>
											handleChangeProductStatus(
												product._id,
												ProductStatusType.Draft
											)
										}>
										Revert to draft
									</MenuItem>
								)}

								<MenuDivider />
								{/* <MenuItem
									rounded='base'
									icon={<BiTrash size={16} />}
									color='status.error'
									>
									Delete product
								</MenuItem> */}
								<ConfirmDeleteDialog
									dialogButtonProps={{
										type: "MenuItem",
										children: "Delete product",
										props: {
											icon: <BiTrash size={18} />,
											color: "status.error",
											rounded: "base",
										},
									}}
									onDelete={() => handleDeleteProduct(product?._id)}
									isLoading={deleteProductParam.loading}
								/>
							</MenuList>
						</Menu>
					</Stack>
				),
			})
		) || [];

	type ProductsVendorTableData = typeof productsVendorTableData[number];

	const productsVendorTableHead: TableHeadType<ProductsVendorTableData>[] = [
		{ accessor: "check", label: "" },
		{ accessor: "image", label: "" },
		{ accessor: "name", label: "Name" },
		{ accessor: "category", label: "Category" },
		{ accessor: "brand", label: "Brand" },
		{ accessor: "status", label: "Status" },
		{ accessor: "optionNumber", label: "Options N°" },
		{ accessor: "variationNumber", label: "Variations N°" },
		{ accessor: "creationDate", label: "Created at" },
		{
			accessor: "basePrice",
			label: "Base price",
			headCellProps: { isNumeric: true },
		},
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	const productsAdminTableData =
		productsAdminData?.GetAllProducts?.products?.map((product, index) => ({
			id: product._id,
			check: (
				<Checkbox
					value={product._id}
					onChange={(e) => {
						if (e.target.checked) {
							handleSelectProduct(e.target.value);
						} else {
							handleRemoveSelectedProduct(e.target.value);
						}
					}}
					isChecked={selectedProducts?.includes(product._id)}
				/>
			),
			image: (
				<Box className='table_image'>
					<img
						src={product.images?.[0] || picturePlaceholder}
						alt='product_profile_picture'
					/>
				</Box>
			),
			name: product?.name,
			category: product?.category?.name,
			store: (
				<Link
					as={RouterLink}
					to={`/admin/stores/${product?.store?._id}`}
					color='blue.300'>
					{product?.store?.name}
				</Link>
			),
			status: setProductStatusParam.loading ? (
				<Stack direction='row' alignItems='center' color={"blue.400"}>
					<Spinner size='sm' />
					<Text>Loading...</Text>
				</Stack>
			) : (
				renderProductStatus(product.status)
			),
			creationDate: moment(product.createdAt).format("lll"),
			basePrice: product?.price?.price + " TND",
			action: (
				<Stack
					direction='row'
					display='inline-flex'
					width='fit-content'
					alignItems='center'
					justifyContent='flex-end'>
					{(product.status === "PUBLISHED" || product.status === "PENDING") && (
						<>
							{product.status === "PUBLISHED" ? (
								<Link
									color='blue.300'
									target='_blank'
									href={`${process.env.REACT_APP_MARKETPLACE_URL}product/${product?.slug}`}>
									<Stack direction='row' alignItems='center' spacing='4px'>
										<Text whiteSpace='nowrap'>See in store</Text>{" "}
										<BiLinkExternal size={16} />
									</Stack>
								</Link>
							) : (
								<Button
									colorScheme='green'
									size='sm'
									leftIcon={<BiCheckCircle size={18} />}
									onClick={() =>
										handleChangeProductStatus(
											product._id,
											ProductStatusType.Published
										)
									}>
									Publish
								</Button>
							)}
							<Tooltip label='Decline product' aria-label='A tooltip'>
								<IconButton
									aria-label='decline_button'
									size='sm'
									variant='outline'
									colorScheme='red'
									onClick={() =>
										handleChangeProductStatus(
											product._id,
											ProductStatusType.Declined
										)
									}>
									<BiBlock size={14} />
								</IconButton>
							</Tooltip>
						</>
					)}
					<IconButton
						onClick={() =>
							navigate(`/admin/products/${product._id}`, {
								state: {
									from: location.pathname,
									pageName: "Products",
								},
							})
						}
						aria-label='show_button'
						size='sm'
						variant='ghost'>
						<BiShowAlt size={18} />
					</IconButton>
					<Menu>
						<MenuButton
							as={IconButton}
							aria-label='menu_more'
							size='sm'
							variant='ghost'
							className='menu_iconButton'>
							<BiDotsHorizontalRounded size={18} />
						</MenuButton>
						<MenuList px='2' shadow='lg'>
							<MenuItem
								rounded='base'
								icon={<BiTrash size={16} />}
								color='status.error'>
								Delete product
							</MenuItem>
						</MenuList>
					</Menu>
				</Stack>
			),
		})) || [];

	type ProductsAdminTableData = typeof productsAdminTableData[number];

	const productsAdminTableHead: TableHeadType<ProductsAdminTableData>[] = [
		{ accessor: "check", label: "" },
		{ accessor: "image", label: "" },
		{ accessor: "name", label: "Name" },
		{ accessor: "category", label: "Category" },
		{ accessor: "store", label: "Store" },
		{ accessor: "status", label: "Status" },
		{ accessor: "creationDate", label: "Created at" },
		{
			accessor: "basePrice",
			label: "Base price",
			headCellProps: { isNumeric: true },
		},
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	const handleFilter = (values: {
		name: string;
		type: string;
		category: {
			label: string;
			id: string;
		};
		brand: string;
	}) => {
		setDefaultFilterValues(values);
		setFilters({
			brand: values.brand || undefined,
			category: values.category || undefined,
			name: values.name || undefined,
			type: (values.type as ProductType) || undefined,
		});
	};

	const getFiltersCount = () => {
		let count = 0;
		if (filters) {
			Object.values(filters).forEach((value, index) => {
				let testValue: string | undefined = undefined;
				if (typeof value !== "string") {
					testValue = value?.id !== "" ? value?.id : undefined;
				} else {
					testValue = value;
				}
				if (testValue) {
					count = count + 1;
				}
			});
		}
		return count;
	};

	const handleResetFilter = () => {
		setFilters(undefined);
		setDefaultFilterValues({
			brand: "",
			category: {
				label: "",
				id: "",
			},
			name: "",
			type: "",
		});
	};

	const handleSearchSubmit: React.KeyboardEventHandler<HTMLInputElement> = (
		event
	) => {
		if (event.code === "Enter") {
			let target = event.target as typeof event.target & {
				value: string;
			};
			setSearch({
				search: target.value,
			});
		}
	};

	const {
		isOpen: openFilter,
		onOpen: onOpenFilter,
		onClose: onCloseFilter,
	} = useDisclosure();

	const {
		isOpen: isOpenExportDialog,
		onOpen: onOpenExportDialog,
		onClose: onCloseExportDialog,
	} = useDisclosure();

	const cancelExportRef = useRef(null);

	const [
		exportProducts,
		{ data: exportedProductsData, loading: exportedProductsLoading },
	] = useExportProductsLazyQuery({
		onCompleted: (data) => {
			const link = document.createElement("a");
			link.target = "_blank";
			link.download = `Products_${moment().format("LLL")}.xlsx`;
			link.href = URL.createObjectURL(
				new Blob([new Uint8Array(data?.ExportProducts)], { type: "text/plain" })
			);
			link.click();
		},
	});

	const handleExportProducts = () => {
		exportProducts({
			variables: {
				storeID: storeID || undefined,
				selectedProducts: selectedProducts.length > 0 ? selectedProducts : [],
			},
		});
	};

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Heading fontSize='2xl'>Products list</Heading>
					</Box>
				</Box>

				<Stack direction='row'>
					<Button variant='outline' onClick={onOpenExportDialog}>
						{selectedProducts.length > 0 ? `Export selected` : "Export"}
					</Button>
					{userRole === "VENDOR" && (
						<Button
							colorScheme='main'
							onClick={() =>
								navigate(`/${storeID}/products/create`, {
									state: {
										from: location.pathname,
										pageName: "Products",
									},
								})
							}>
							Create product
						</Button>
					)}
				</Stack>
			</Box>
			{selectedProducts.length > 0 && (
				<Text fontSize='sm' opacity={0.6}>
					{selectedProducts.length} products selected
				</Text>
			)}
			{userRole === "VENDOR" ? (
				<Tabs
					colorScheme='blue'
					mb='14px'
					index={tabIndex}
					onChange={handleChangeTabIndex}>
					<TabList>
						<Tab>All</Tab>
						<Tab>Pending</Tab>
						<Tab>Published</Tab>
						<Tab>Draft</Tab>
					</TabList>
				</Tabs>
			) : (
				<Tabs
					colorScheme='blue'
					mb='14px'
					index={tabIndex}
					onChange={handleChangeTabIndex}>
					<TabList>
						<Tab>All</Tab>
						<Tab>Review requests</Tab>
						<Tab>Published</Tab>
						<Tab>Declined</Tab>
					</TabList>
				</Tabs>
			)}

			<Box className='page_actions'>
				<Input
					inputProps={{
						placeholder: "Search for product",
						onKeyUp: handleSearchSubmit,
					}}
					inputLeftElementProps={{ children: <BiSearch /> }}
				/>
				<Divider orientation='vertical' />
				<Button
					variant='outline'
					leftIcon={
						getFiltersCount() === 0 ? (
							<BiFilterAlt />
						) : (
							<Text
								bgColor='main.400'
								color='white'
								px='2'
								py='1'
								rounded='full'
								fontSize='sm'>
								{getFiltersCount()}
							</Text>
						)
					}
					onClick={onOpenFilter}>
					Filter
				</Button>
			</Box>
			{userRole === "VENDOR" ? (
				<Table
					head={productsVendorTableHead}
					data={productsVendorTableData}
					isLoading={productsVendorLoading}
					emptyState={
						<Box className='empty_table_container'>
							<BiBriefcase size={42} />
							<Text fontSize='md' fontWeight='medium'>
								{search?.search
									? `No results for "${search.search}"`
									: getFiltersCount() !== 0 || tabIndex > 0
									? "There is no products with these filters"
									: "You have created no products yet."}
							</Text>
						</Box>
					}
					pagination={{
						count:
							productsVendorData?.GetCurrentSessionUserProducts.queryCount || 0,
						limit: pagination.limit,
						skip: pagination.skip,
						onPageChange: handlePageChange,
						onChangeLimit: handleLimitChange,
					}}
				/>
			) : (
				<Table
					head={productsAdminTableHead}
					data={productsAdminTableData}
					isLoading={productsAdminLoading}
					emptyState={
						<Box className='empty_table_container'>
							<BiBriefcase size={42} />
							<Text fontSize='md' fontWeight='medium'>
								{search
									? `No results for "${search.search}"`
									: getFiltersCount() !== 0 || tabIndex > 0
									? "There is no products with these filters"
									: "There is no products yet."}
							</Text>
						</Box>
					}
					pagination={{
						count: productsAdminData?.GetAllProducts.queryCount || 0,
						limit: pagination.limit,
						skip: pagination.skip,
						onPageChange: handlePageChange,
						onChangeLimit: handleLimitChange,
					}}
				/>
			)}
			<ProductsFilterDrawer
				isOpen={openFilter}
				onClose={onCloseFilter}
				handleSubmitFilter={handleFilter}
				defaultValues={defaultFilterValues}
				handleResetFilter={handleResetFilter}
			/>
			<AlertDialog
				isOpen={isOpenExportDialog}
				leastDestructiveRef={cancelExportRef}
				onClose={onCloseExportDialog}
				isCentered
				size='lg'>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogCloseButton />
						<AlertDialogHeader
							borderBottomWidth='1px'
							fontSize='lg'
							fontWeight='bold'>
							Export products ?
						</AlertDialogHeader>

						<AlertDialogBody my='14px'>
							Are you sure you want to export{" "}
							{selectedProducts.length > 0
								? `${selectedProducts.length} ${
										selectedProducts.length > 1 ? "Products" : "Product"
								  }`
								: "All products"}
						</AlertDialogBody>

						<AlertDialogFooter borderTopWidth='1px'>
							<Button ref={cancelExportRef} onClick={onCloseExportDialog}>
								Cancel
							</Button>
							<Button
								colorScheme='main'
								isLoading={exportedProductsLoading}
								onClick={handleExportProducts}
								ml={3}>
								Export
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</PageWrapper>
	);
};

export default Products;
