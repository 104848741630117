import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogCloseButton,
	useDisclosure,
	Button,
	ButtonProps,
	IconButton,
	IconButtonProps,
	MenuItemProps,
	MenuItem,
} from "@chakra-ui/react";
import { useRef, useState, ReactNode } from "react";
import { Input } from "../..";

type ConfirmDeleteDialogProps = {
	dialogButtonProps: {
		type: "IconButton" | "Button" | "MenuItem";
		children?: ReactNode;
		props?: ButtonProps | IconButtonProps | MenuItemProps;
	};
	onDelete?: () => void;
	isLoading?: boolean;
};

const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps) => {
	const { dialogButtonProps, onDelete, isLoading } = props;

	const {
		isOpen: isOpenDeleteDialog,
		onOpen: onOpenDeleteDialog,
		onClose: onCloseDeleteDialog,
	} = useDisclosure();

	const cancelDeleteRef = useRef(null);

	const [confirmDelete, setConfirmDelete] = useState<string>("");

	const [confirmDeleteError, setConfirmDeleteError] = useState<string>();

	const handleDelete = () => {
		if (confirmDelete.toUpperCase() === "REMOVE") {
			onDelete?.();
			setConfirmDelete("");
			onCloseDeleteDialog();
		} else {
			setConfirmDeleteError("Format is wrong");
		}
	};
	return (
		<>
			{dialogButtonProps.type === "Button" ? (
				<Button onClick={onOpenDeleteDialog} {...dialogButtonProps.props}>
					{dialogButtonProps.children}
				</Button>
			) : dialogButtonProps.type === "IconButton" ? (
				<IconButton
					onClick={onOpenDeleteDialog}
					aria-label='generic_button'
					{...dialogButtonProps.props}>
					{dialogButtonProps.children}
				</IconButton>
			) : (
				<MenuItem onClick={onOpenDeleteDialog} {...dialogButtonProps.props}>
					{dialogButtonProps.children}
				</MenuItem>
			)}
			<AlertDialog
				isOpen={isOpenDeleteDialog}
				leastDestructiveRef={cancelDeleteRef}
				onClose={onCloseDeleteDialog}
				isCentered
				size='lg'>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogCloseButton />
						<AlertDialogHeader
							borderBottomWidth='1px'
							fontSize='lg'
							fontWeight='bold'>
							Are you sure you want to delete this category?
						</AlertDialogHeader>

						<AlertDialogBody my='14px'>
							<Input
								label='Please write "REMOVE" to confirm deleting this category'
								inputProps={{
									value: confirmDelete,
									onChange: (e) => setConfirmDelete(e.target.value),
								}}
								errorMessage={confirmDeleteError}
								isError={confirmDeleteError !== undefined}
							/>
						</AlertDialogBody>

						<AlertDialogFooter borderTopWidth='1px'>
							<Button ref={cancelDeleteRef} onClick={onCloseDeleteDialog}>
								Cancel
							</Button>
							<Button
								disabled={confirmDelete.toUpperCase() !== "REMOVE" || isLoading}
								colorScheme='red'
								isLoading={isLoading}
								onClick={handleDelete}
								ml={3}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export default ConfirmDeleteDialog;
