import {
	Box,
	useColorMode,
	Divider,
	useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { logoImage, logoImageDARKUI } from "../../../../assets";
import { NavItem } from "../../../commons";
import { SettingsNavigationWrapper } from "../Navigation.styles";
import { NavigationPropsType } from "../Navigation.types";

const SettingsNavigation: React.FC<
	NavigationPropsType & {
		width?: string;
		topSection?: ReactNode;
	}
> = (props) => {
	const { items, width, topSection } = props;

	const location = useLocation();
	const { colorMode } = useColorMode();

	const bgColor = useColorModeValue("white", "gray.900");
	const borderColor = useColorModeValue("gray.200", "gray.700");

	return (
		<SettingsNavigationWrapper
			bg={bgColor}
			borderRadius='base'
			shadow='md'
			w={width ? width : "220px"}>
			{topSection && (
				<Box className='top_section_container'>
					{topSection}
					<Box>
						<Divider borderColor={borderColor} />
					</Box>
				</Box>
			)}
			<Box paddingBottom='8px'>
				{items?.map((nav, index) =>
					nav === "SEPERATOR" ? (
						<Box key={index} marginY='2' paddingX='16px'>
							<Divider borderColor={borderColor} />
						</Box>
					) : (
						<Box paddingTop='8px' key={index}>
							<NavItem
								label={nav.label}
								Icon={nav.Icon}
								selected={location.pathname.includes(nav.href)}
								href={nav.href}
							/>
						</Box>
					)
				)}
			</Box>
		</SettingsNavigationWrapper>
	);
};

export default SettingsNavigation;
