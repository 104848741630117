import {
	Box,
	Heading,
	Skeleton,
	Button,
	Tabs,
	TabList,
	Tab,
	TabPanel,
	TabPanels,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverHeader,
	PopoverBody,
	PopoverCloseButton,
	Popover,
	Stack,
	Flex,
	Spacer,
	FormLabel,
	useColorModeValue,
	useColorMode,
	useToast,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { useState } from "react";
import ReactQuill from "react-quill";
import {
	StatusType,
	useCreateCategoryMutation,
	useGetCategoryNameByIdQuery,
} from "../../../api/generated/graphql";
import { ImageUpload, Input, SelectInput } from "../../../components";
import { CategoryFormWrapper } from "../Categories.styles";

import "react-quill/dist/quill.snow.css";
import { apolloClient } from "../../..";

const modules = {
	toolbar: [
		[{ header: "1" }, { header: "2" }, { font: [] }],
		[{ size: [] }],
		["bold", "italic", "underline", "strike", "blockquote"],
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" },
		],
		["link", "image"],
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	},
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */

type CreateCategoryProps = {
	parentID?: string | null;
	onCategoryCreated?: () => void;
};

const CreateCategory = (props: CreateCategoryProps) => {
	const { parentID, onCategoryCreated } = props;

	const [files, setFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const [createCategoryMutation] = useCreateCategoryMutation();

	const handleFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append("public_id", `categories/${file.name}`);
		setFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleDelete = (id: string) => {
		console.log(
			id,
			files.filter(({ id: imageId }) => id !== imageId)
		);
		setFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	const [tabIndex, setTabIndex] = useState(0);

	const { data: categoryNameData, loading: categoryNameLoading } =
		useGetCategoryNameByIdQuery({
			variables: {
				id: parentID,
			},
			skip: !parentID,
		});

	const toast = useToast();

	return (
		<Formik
			validateOnMount={false}
			validateOnBlur={false}
			validateOnChange={false}
			initialValues={{
				name: "",
				description: "",
				status: "DRAFT",
			}}
			onSubmit={(values, { setSubmitting, setValues }) => {
				createCategoryMutation({
					variables: {
						parent: parentID ? parentID : undefined,
						description: values.description,
						name: values.name,
						status: values.status as StatusType,
						image: files?.[0]?.src ? (files?.[0]?.src as string) : "",
					},
					onCompleted: (data) => {
						if (data.CreateCategory?.code) {
							setSubmitting(false);
							toast({
								title: "Category has been created with success!",
								status: "success",

								position: "bottom-right",
								duration: 5000,
								isClosable: true,
							});
							apolloClient.refetchQueries({
								include: "active",
							});
							setValues({
								description: "",
								name: "",
								status: "",
							});
							onCategoryCreated?.();
						}
					},
					onError: (error) => {
						toast({
							title: "An error occured while updating category.",
							description: error.message,
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						setSubmitting(false);
					},
				});
			}}>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				setFieldValue,
				initialValues,
			}) => (
				<CategoryFormWrapper>
					<Box className='category_form_header'>
						{parentID ? (
							<Skeleton isLoaded={!categoryNameLoading}>
								<Heading size='md'>
									New subcategory under "
									{categoryNameData?.GetCategoryById?.name}"
								</Heading>
							</Skeleton>
						) : (
							<Heading size='md'>Creating new category</Heading>
						)}
						<Button
							colorScheme='main'
							size='sm'
							type='submit'
							isLoading={isSubmitting}
							form='create-category-form'
							disabled={initialValues === values || isSubmitting}>
							Save new category
						</Button>
					</Box>
					<Tabs index={tabIndex} colorScheme='blue' isLazy>
						<TabList>
							<Tab>General</Tab>
							<Popover>
								<PopoverTrigger>
									<Tab px={4}>Products</Tab>
								</PopoverTrigger>

								<PopoverContent>
									<PopoverArrow />
									<PopoverCloseButton />
									<PopoverHeader>Hold on!</PopoverHeader>
									<PopoverBody>
										Save categories general settings to access their products?
									</PopoverBody>
								</PopoverContent>
							</Popover>
							<Popover colorScheme='yellow'>
								<PopoverTrigger>
									<Tab px={4}>SEO</Tab>
								</PopoverTrigger>

								<PopoverContent>
									<PopoverArrow />

									<PopoverCloseButton />
									<PopoverHeader>Hold on!</PopoverHeader>
									<PopoverBody>
										Save categories general settings to access their seo
										settings?
									</PopoverBody>
								</PopoverContent>
							</Popover>
						</TabList>

						<TabPanels>
							<TabPanel p='12px 0px'>
								<form onSubmit={handleSubmit} id='create-category-form'>
									<Stack spacing='14px'>
										<ImageUpload
											height='230px'
											width='470px'
											images={files}
											handleUpload={handleFile}
											handleDelete={handleDelete}
											label='Category image'
										/>
										<Stack direction='row'>
											<Input
												label='Category name'
												inputProps={{
													placeholder: "Category name",
													name: "name",
													onChange: handleChange,
													value: values.name,
												}}
												errorMessage={errors.name}
												isError={errors.name !== undefined}
											/>
											<Spacer />
											<SelectInput
												formControlProps={{
													w: "420px",
												}}
												options={[
													{ label: "Draft", value: "DRAFT" },
													{ label: "Published", value: "PUBLISHED" },
												]}
												label='Status'
												selectProps={{
													name: "status",
													value: values.status,
													onChange: handleChange,
												}}
												errorMessage={errors.status}
												isError={errors.status !== undefined}
											/>
										</Stack>
										<Box>
											<FormLabel marginBottom='1'>Description</FormLabel>
											<ReactQuill
												value={values.description}
												onChange={(content) =>
													setFieldValue("description", content)
												}
												modules={modules}
												placeholder='Describe this category'
											/>
										</Box>
									</Stack>
								</form>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</CategoryFormWrapper>
			)}
		</Formik>
	);
};

export default CreateCategory;
