import {
	Box,
	Heading,
	useColorModeValue,
	Text,
	Stack,
	Skeleton,
	Link,
	Divider,
} from "@chakra-ui/react";
import { BiCheckCircle, BiImages, BiWorld } from "react-icons/bi";
import { useGetUserDashboardQuery } from "../../api/generated/graphql";
import { dashboardCover } from "../../assets";
import { getDataFromCache } from "../../utils/cache";
import { PageWrapper } from "../../utils/globalStyle";
import { Dashboardwrapper } from "./Dashboard.styles";

const Dashboard = () => {
	const borderColor = useColorModeValue("gray.200", "gray.700");

	const storeID = getDataFromCache("store_id", true);

	const { data: dashboardData, loading: dashboardLoading } =
		useGetUserDashboardQuery({
			variables: {
				storeID,
			},
		});

	const rendertotalRevenue = (
		orders: { totalePrice?: number | null | undefined }[] | null | undefined
	) => {
		let tempRevenue = 0;
		orders?.map((order) => {
			tempRevenue = tempRevenue + (order?.totalePrice || 0);
		});
		return tempRevenue;
	};

	return (
		<Dashboardwrapper>
			<Box className='cover_section'>
				<img src={dashboardCover} alt='dashboardCover' />
				<Box className='content'>
					<Heading>Greetings!</Heading>
					<Text mb='4'>Welcome back on the sellerHQ of Elmall</Text>
					<Link target='_black' href={process.env.REACT_APP_MARKETPLACE_URL}>
						{process.env.REACT_APP_MARKETPLACE_URL}
					</Link>
				</Box>
			</Box>
			<Heading fontSize='xl' fontWeight='semibold'>
				Reports
			</Heading>
			<Stack direction='row' mt='2' spacing='16px'>
				<Box
					border='1px'
					borderColor={borderColor}
					rounded='md'
					p='16px 16px'
					width='200px'>
					<Text fontWeight='medium'>Total revenue</Text>
					<Heading size='lg'>
						{rendertotalRevenue(dashboardData?.GetCurrentSessionOrders?.orders)}{" "}
						TND
					</Heading>
				</Box>
				<Box
					border='1px'
					borderColor={borderColor}
					rounded='md'
					p='16px 16px'
					width='200px'>
					<Text fontWeight='medium'>Total Orders received</Text>
					<Skeleton
						isLoaded={!dashboardLoading}
						opacity={dashboardLoading ? 0.4 : 1}
						w='full'>
						<Heading size='lg'>
							{dashboardData?.GetCurrentSessionOrders?.orders?.length || 0}
						</Heading>
					</Skeleton>
				</Box>
			</Stack>
			<Box display='flex' mt='4'>
				<Box flex='1' mr='4'>
					<Heading fontSize='xl' fontWeight='semibold' mb='2'>
						Complete your setup
					</Heading>
					<Stack
						border='1px'
						borderColor={borderColor}
						rounded='md'
						p='16px 16px'>
						<Box opacity={0.4} display='flex'>
							<BiCheckCircle size={20} fill={"#48BB78"} />
							<Box ml='1'>
								<Heading fontSize='md' fontWeight='semibold'>
									Create your account
								</Heading>
								<Text>
									Thank you for creating your accounting and waiting for admin
									to approve your store in the marketplace
								</Text>
							</Box>
						</Box>
						<Divider />
						<Box display='flex'>
							<BiCheckCircle opacity={0} size={20} fill={"#48BB78"} />
							<Box ml='1'>
								<Heading fontSize='md' fontWeight='semibold'>
									Add your products or services
								</Heading>
								<Text>
									Get started by adding names, prices, and images. and then
									submit your products to get reviewed by the administration.
								</Text>
							</Box>
						</Box>
						<Divider />
						<Box display='flex'>
							<BiCheckCircle opacity={0} size={20} fill={"#48BB78"} />
							<Box ml='1'>
								<Heading fontSize='md' fontWeight='semibold'>
									Manage your products inventory and availability
								</Heading>
								<Text>
									To improve the workflow in the marketplace logistics. Declare
									your inventory accurately.
								</Text>
							</Box>
						</Box>
					</Stack>
				</Box>
				<Box flex='1'>
					<Heading fontSize='xl' fontWeight='semibold' mb='2'>
						Tips to improve sales
					</Heading>
					<Stack
						border='1px'
						borderColor={borderColor}
						rounded='md'
						p='16px 16px'>
						<Box display='flex'>
							<BiImages size={24} />
							<Box ml='1'>
								<Heading fontSize='md' fontWeight='semibold'>
									Provide clear and attractive images.
								</Heading>
								<Text>
									Imagery is the most important part in sales. Studies show that
									48% of customers lean to products with great images more.
								</Text>
							</Box>
						</Box>
						<Divider />
						<Box display='flex'>
							<BiWorld size={24} />
							<Box ml='1'>
								<Heading fontSize='md' fontWeight='semibold'>
									Don’t forget seo!
								</Heading>
								<Text>
									SEO helps build long-term equity for your brand. A good
									ranking and a favorable placement help elevate your store’s
									profile.
								</Text>
							</Box>
						</Box>
					</Stack>
				</Box>
			</Box>
		</Dashboardwrapper>
	);
};

export default Dashboard;
