import {
	Box,
	Stack,
	useColorModeValue,
	Text,
	IconButton,
	Heading,
	Button,
	Skeleton,
	useColorMode,
} from "@chakra-ui/react";
import { FC } from "react";
import {
	BiArrowBack,
	BiBookBookmark,
	BiBriefcaseAlt,
	BiBrush,
	BiBrushAlt,
	BiCog,
	BiDesktop,
	BiGlobe,
	BiLinkExternal,
	BiPaint,
	BiShieldQuarter,
	BiSpreadsheet,
	BiStoreAlt,
	BiUserCircle,
} from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetVendorStoresQuery } from "../../api/generated/graphql";
import { getDataFromCache } from "../../utils/cache";
import { CacheUserDataType } from "../../utils/globalTypes";
import { NavigationPropsType, SettingsNavigation } from "../modules";
import { SettingsContainer } from "./SettingsWrapper.styles";
import { logoImage, logoImageDARKUI } from "../../assets";

const storeID = getDataFromCache("store_id", true);

const getNavigation = (
	role: "ADMIN" | "VENDOR"
): NavigationPropsType["items"] => {
	switch (role) {
		case "ADMIN":
			return [
				{
					Icon: <BiUserCircle size='18' />,
					href: `/admin/settings/profile`,
					label: "Profile",
				},
				{
					Icon: <BiShieldQuarter size='18' />,
					href: `/admin/settings/security`,
					label: "Account security",
				},
				"SEPERATOR",
				{
					Icon: <BiDesktop size='18' />,
					href: "/admin/settings/home",
					label: "Home page config",
				},
				{
					Icon: <BiBrush size='18' />,
					href: "/admin/settings/design",
					label: "Design",
				},
				{
					Icon: <BiBookBookmark size='18' />,
					href: "/admin/settings/policies",
					label: "Market Policies",
				},
			];
		case "VENDOR":
			return [
				{
					Icon: <BiStoreAlt size='18' />,
					href: `/${storeID}/settings/store`,
					label: "Store Information",
				},
				{
					Icon: <BiGlobe size='18' />,
					href: `/${storeID}/settings/seo`,
					label: "SEO",
				},
				{
					Icon: <BiUserCircle size='18' />,
					href: `/${storeID}/settings/profile`,
					label: "Profile",
				},
				{
					Icon: <BiShieldQuarter size='18' />,
					href: `/${storeID}/settings/security`,
					label: "Account security",
				},
			];
	}
};

const SettingsWrapper: FC = (props) => {
	const { children } = props;

	const { colorMode } = useColorMode();

	const location = useLocation();

	const role = getDataFromCache("user_role", true);

	const userData: CacheUserDataType = getDataFromCache("user_data");

	const bannerBgColor = useColorModeValue("white", "gray.900");

	const containerBgColor = useColorModeValue("gray.100", "gray.800");

	const navList = getNavigation(role);

	const { from } = (location?.state as { from?: string }) || "";

	const navigate = useNavigate();

	const { data: storeNameData, loading: storeNameLoading } =
		useGetVendorStoresQuery();

	return (
		<SettingsContainer>
			<Box bgColor={containerBgColor} className='app_container'>
				<Box
					background={bannerBgColor}
					shadow='md'
					position={"sticky"}
					top='0'
					overflow='hidden'
					zIndex={99}>
					<Stack direction='row' p='8px 16px' alignItems='center'>
						<IconButton
							size='sm'
							variant='ghost'
							aria-label='back_settings_button'
							onClick={() =>
								navigate("/", {
									state: {
										from: location.pathname,
									},
								})
							}>
							<BiArrowBack size={20} />
						</IconButton>
						<Heading
							size='md'
							fontWeight='semibold'
							display='flex'
							alignItems='center'
							gap='5px'>
							<BiCog size={22} /> Settings
						</Heading>
					</Stack>
				</Box>
				<Stack direction='row' p='42px 220px' spacing='24px'>
					<SettingsNavigation
						items={navList}
						width='260px'
						topSection={
							role === "VENDOR" ? (
								<Box p='16px'>
									<Skeleton
										isLoaded={!storeNameLoading}
										opacity={storeNameLoading ? 0.6 : 1}
										minH='46px'>
										<Stack direction='row'>
											{storeNameData?.GetCurrentSessionUserStores?.stores?.[0]
												?.storeMedia?.logo && (
												<img
													className='store_logo_settings'
													src={
														storeNameData?.GetCurrentSessionUserStores
															?.stores?.[0]?.storeMedia?.logo
													}
													alt='store_logo'
												/>
											)}
											<Box>
												<Text fontSize='lg'>
													{
														storeNameData?.GetCurrentSessionUserStores
															.stores?.[0].name
													}
												</Text>
												<Text opacity={0.6}>
													{`${userData?.firstName} ${userData?.lastName}`}
												</Text>
											</Box>
										</Stack>
									</Skeleton>
								</Box>
							) : (
								<Box p='16px'>
									<Box w='160px'>
										{colorMode === "dark" ? (
											<img src={logoImageDARKUI} alt='logo' />
										) : (
											<img src={logoImage} alt='logo' />
										)}
									</Box>
								</Box>
							)
						}
					/>
					<Box w='full' flex={1}>
						{children}
					</Box>
				</Stack>
			</Box>
		</SettingsContainer>
	);
};

export default SettingsWrapper;
