import {
	Box,
	Button,
	Divider,
	FormLabel,
	Heading,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Skeleton,
	Stack,
	Switch,
	Text,
	useColorModeValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { apolloClient } from "../../..";
import {
	UpdateProductsVariationsByIdMutationVariables,
	useCreateProductVariantMutation,
	useDeleteProductVariantMutation,
	useGetProductOptionsByIdQuery,
	useGetProductVariationsByIdQuery,
	useUpdateProductsVariationsByIdMutation,
	useUpdateVariationMutation,
} from "../../../api/generated/graphql";
import { picturePlaceholder } from "../../../assets";
import {
	CategorySelectInput,
	TagsSelectInput,
	Input,
	Table,
	TableHeadType,
	SelectInput,
	ImageUpload,
	NumberInput,
	ConfirmDeleteDialog,
} from "../../../components";
import { getDataFromCache } from "../../../utils/cache";

type ProductVariationsFormProps = {
	actionButtons: (node: ReactNode) => void;
	productID?: string;
};

type VariantType = {
	__typename?: "Variant";
	variantPrice?: number | null;
	isActive?: boolean | null;
	images?: Array<string> | null;
	createdAt?: any | null;
	_id?: any | null;
	selectedOption?: Array<{
		__typename?: "SelectedOption";
		KEY?: string | null;
		VALUE?: string | null;
	}> | null;
	inventory?: {
		__typename?: "Inventory";
		_id: any;
		barCode?: string | null;
		sku?: string | null;
		weightPerUnit?: number | null;
	} | null;
};

const ProductVariationsForm = (props: ProductVariationsFormProps) => {
	const { productID, actionButtons } = props;

	const borderColor = useColorModeValue("gray.100", "gray.700");

	const toast = useToast();

	const [updateVariantsMutation] = useUpdateProductsVariationsByIdMutation();

	const { data: variantsProductData, loading: variantsProductLoading } =
		useGetProductVariationsByIdQuery({
			variables: {
				product_id: productID,
			},
		});

	const variantProductInitialValues: Omit<
		UpdateProductsVariationsByIdMutationVariables,
		"tags" | "product_id" | "category"
	> & {
		category: {
			id: string;
			label: string;
		};
		tags: string[];
	} = {
		brand: "",
		isAvilable: true,
		category: {
			id: "",
			label: "",
		},
		tags: [],
	};

	const variantsProductFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: variantProductInitialValues,
		// validate: (values) => {
		// 	const errors: FormikErrors<typeof values> = {};
		// 	if (!values.name) {
		// 		errors.name = "Product name is required!";
		// 	}
		// 	return errors;
		// },
		onSubmit: (values, { setSubmitting }) => {
			updateVariantsMutation({
				variables: {
					product_id: productID,
					brand: values.brand || undefined,
					category: values.category.id || undefined,
					isAvilable: values.isAvilable,
					tags: values.tags || undefined,
				},
				onCompleted: (data) => {
					if (data.UpdateProduct?._id) {
						setSubmitting(false);
						toast({
							title: "Product variations has been updated with success.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while updating product.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	const [selectedVariant, setSelectedVariant] = useState<VariantType>();

	const [deleteProductVariationMutation, deleteProductVariationParam] =
		useDeleteProductVariantMutation();

	const handleDeleteVariation = (id: string) => {
		deleteProductVariationMutation({
			variables: {
				product_id: productID,
				variant_id: id,
			},
			onCompleted: (data) => {
				if (data?.DeleteProductVariant?.code) {
					toast({
						title: "Variation deleted with success.",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					apolloClient.refetchQueries({
						include: "active",
					});
				}
			},
			onError: (error) => {
				toast({
					title: "An error occurred while deleting variation.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	const optionsTableData =
		variantsProductData?.GetProductById?.variations?.map((variant, index) => ({
			image: (
				<Box className='table_image'>
					<img
						src={variant?.images?.[0] || picturePlaceholder}
						alt='product_profile_picture'
					/>
				</Box>
			),
			variantName: `Variant ${index}`,
			selectedOptions: (
				<Text>
					{variant?.selectedOption?.map(
						(option, index) =>
							`${option.KEY}: ${option.VALUE}${
								variant?.selectedOption?.length !== index + 1 ? " - " : ""
							}`
					)}
				</Text>
			),
			sku: variant?.inventory?.sku,
			weight: variant?.inventory?.weightPerUnit,
			price: variant?.variantPrice,
			// status: (
			// 	<Text color={option.isActive ? "green.400" : undefined}>
			// 		{option.isActive ? "Active" : "Deactivated"}
			// 	</Text>
			// ),
			action: (
				<Stack
					direction='row'
					display='inline-flex'
					width='fit-content'
					alignItems='center'
					justifyContent='flex-end'>
					<IconButton
						onClick={() => {
							setSelectedVariant(variant);
							onOpenOptionModal();
						}}
						aria-label='edit_button'
						size='sm'
						variant='ghost'>
						<BiEdit size={18} />
					</IconButton>
					<ConfirmDeleteDialog
						dialogButtonProps={{
							type: "IconButton",
							children: <BiTrash size={18} />,
							props: {
								variant: "ghost",
								size: "sm",
								isLoading: deleteProductVariationParam.loading,
							},
						}}
						onDelete={() => handleDeleteVariation(variant?._id)}
						isLoading={deleteProductVariationParam.loading}
					/>
					{/* <IconButton
						aria-label='delete-option-button'
						colorScheme='error'
						variant='ghost'>
						<BiTrash size={18} />
					</IconButton> */}
				</Stack>
			),
		})) || [];

	type OptionsTableData = typeof optionsTableData[number];

	const optionsTableHead: TableHeadType<OptionsTableData>[] = [
		{ accessor: "image", label: "" },
		{ accessor: "variantName", label: "Variant" },
		{ accessor: "selectedOptions", label: "Options" },
		{ accessor: "sku", label: "SKU" },
		{ accessor: "weight", label: "Weight" },
		{ accessor: "price", label: "Price" },
		// { accessor: "status", label: "Status" },
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	useEffect(() => {
		variantsProductFormik.setValues({
			brand: variantsProductData?.GetProductById?.brand || "",
			isAvilable: variantsProductData?.GetProductById?.isAvilable,
			category: {
				id: variantsProductData?.GetProductById?.category?._id || "",
				label: variantsProductData?.GetProductById?.category?.name || "",
			},
			tags: variantsProductData?.GetProductById.tags || [],
		});
	}, [variantsProductData]);

	const userRole: string = getDataFromCache("user_role", true);

	const navigate = useNavigate();

	const storeID = getDataFromCache("store_id", true);

	useEffect(() => {
		actionButtons(
			<>
				<Button
					variant='outline'
					onClick={() =>
						navigate(
							userRole === "ADMIN" ? `/admin/products` : `/${storeID}/products`,
							{
								state: {
									pageName: "Products",
								},
							}
						)
					}>
					Discard
				</Button>
				<Button
					colorScheme='main'
					type='submit'
					form='update-product-variations'
					isLoading={variantsProductFormik.isSubmitting}>
					Save
				</Button>
			</>
		);
	}, [variantsProductFormik.isSubmitting]);

	const {
		isOpen: isOpenOptionModal,
		onOpen: onOpenOptionModal,
		onClose: onCloseOptionModal,
	} = useDisclosure();

	return (
		<>
			<form
				id='update-product-variations'
				onSubmit={variantsProductFormik.handleSubmit}>
				<Stack direction='row' spacing='14px' alignItems='flex-start'>
					<Stack flex={0.75} spacing='12px'>
						<Skeleton
							isLoaded={!variantsProductLoading}
							opacity={variantsProductLoading ? 0.4 : 1}>
							<Stack
								padding='18px 18px'
								border='1px'
								borderColor={borderColor}
								rounded='base'>
								<Stack
									width='full'
									direction='row'
									justifyContent='space-between'>
									<Box>
										<Heading size='md' mb='2'>
											Variations
										</Heading>
										<Text
											opacity={0.6}
											lineHeight='1.2'
											fontSize='sm'
											width='750px'>
											Each product can have an unlimited number of options.
											Based on them, you can create an unlimited number of
											variations for this particular product. The combinations
											of options, as well as the options themselves, can vary
											from product to product.
										</Text>
									</Box>
									<Button
										onClick={onOpenOptionModal}
										colorScheme='main'
										size='sm'>
										Add new variation
									</Button>
								</Stack>
								<Table
									head={optionsTableHead}
									data={optionsTableData}
									isLoading={variantsProductLoading}
									emptyState={
										<Box className='empty_table_container'>
											<Text fontSize='md' fontWeight='medium'>
												Product has no variations
											</Text>
										</Box>
									}
								/>
							</Stack>
						</Skeleton>
					</Stack>
					<Stack flex={0.25} spacing='12px'>
						<Skeleton
							isLoaded={!variantsProductLoading}
							opacity={variantsProductLoading ? 0.4 : 1}>
							<Stack
								padding='18px 24px'
								border='1px'
								borderColor={borderColor}
								rounded='base'>
								<Input
									label='Brand'
									inputProps={{
										placeholder: "Brand",
										name: "brand",
										onChange: variantsProductFormik.handleChange,
										value: variantsProductFormik.values.brand || "",
									}}
									errorMessage={variantsProductFormik.errors.brand}
									isError={variantsProductFormik.errors.brand !== undefined}
								/>
								<CategorySelectInput
									selected={variantsProductFormik.values.category}
									handleSelectCategory={(id, label) =>
										variantsProductFormik.setFieldValue("category", {
											id,
											label,
										})
									}
								/>
								<Box paddingTop='12px' paddingBottom='6px'>
									<Divider color={borderColor} />
								</Box>
								<TagsSelectInput
									tags={variantsProductFormik.values.tags}
									onCreateTag={(value) =>
										variantsProductFormik.setFieldValue("tags", [
											value,
											...variantsProductFormik.values.tags,
										])
									}
									onDeleteTag={(index) => {
										let tempValues = [...variantsProductFormik.values.tags];
										tempValues.splice(index, 1);
										variantsProductFormik.setFieldValue("tags", tempValues);
									}}
								/>
							</Stack>
						</Skeleton>
						<Skeleton
							isLoaded={!variantsProductLoading}
							opacity={variantsProductLoading ? 0.4 : 1}>
							<Stack
								padding='18px 24px'
								border='1px'
								borderColor={borderColor}
								rounded='base'>
								<FormLabel>Product availability</FormLabel>
								<Stack direction='row' alignItems='center'>
									<Switch
										size='md'
										colorScheme='green'
										name='isAvilable'
										onChange={variantsProductFormik.handleChange}
										isChecked={
											variantsProductFormik.values.isAvilable !== null &&
											variantsProductFormik.values.isAvilable
										}
									/>
									<Text
										color={
											variantsProductFormik.values.isAvilable
												? "green.400"
												: undefined
										}>
										{variantsProductFormik.values.isAvilable
											? "Available"
											: "Not available"}
									</Text>
								</Stack>
							</Stack>
						</Skeleton>
					</Stack>
				</Stack>
			</form>
			<Modal
				isOpen={isOpenOptionModal}
				onClose={onCloseOptionModal}
				isCentered
				size='lg'
				scrollBehavior='inside'>
				<ModalOverlay />
				<CreateVariationModal
					onClose={() => {
						setSelectedVariant(undefined);
						onCloseOptionModal();
					}}
					productID={productID || ""}
					selectedVariant={selectedVariant}
				/>
			</Modal>
		</>
	);
};

export default ProductVariationsForm;

type CreateVariationModalProps = {
	onClose: () => void;
	productID: string;
	selectedVariant?: VariantType;
};

const CreateVariationModal = (props: CreateVariationModalProps) => {
	const storeID = getDataFromCache("store_id", true);

	const [files, setFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const handleFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append(
			"public_id",
			`${storeID}/products/${props.productID}/${file.name}`
		);
		setFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleDelete = (id: string) => {
		console.log(
			id,
			files.filter(({ id: imageId }) => id !== imageId)
		);
		setFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	const toast = useToast();

	const [handleUpdateVariationMutation] = useUpdateVariationMutation();

	const [handleCreateVariationMutation] = useCreateProductVariantMutation();

	const { data: optionsProductData, loading: optionsProductLoading } =
		useGetProductOptionsByIdQuery({
			variables: {
				product_id: props.productID,
			},
		});

	const [optionsList, setOptionsList] = useState<
		{
			name?: string;
			values?: string[];
			selected?: string;
		}[]
	>([]);

	const handleCloseModal = () => {
		setFiles([]);
		setOptionsList([]);
		props.onClose();
	};

	const variationInitialValues = {
		variationPrice: props.selectedVariant?.variantPrice?.toString() || "0",
		isActive: props.selectedVariant?.isActive ? true : false,
		weightPerUnit:
			props.selectedVariant?.inventory?.weightPerUnit?.toString() || "0",
		barcode: props.selectedVariant?.inventory?.barCode,
		sku: props.selectedVariant?.inventory?.sku,
	};

	const variationsFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: variationInitialValues,

		onSubmit: (values, { setSubmitting }) => {
			if (props.selectedVariant) {
				handleUpdateVariationMutation({
					variables: {
						variation_id: props.selectedVariant._id,
						barcode: values.barcode,
						images:
							files.map((files) => (files.src ? (files.src as string) : "")) ||
							undefined,
						isActive: values.isActive,
						selectedOption: optionsList.map((option, index) => ({
							KEY: option.name,
							VALUE: option.selected,
						})),
						sku: values.sku,
						variantPrice: parseFloat(values.variationPrice),
						weightPerUnit: parseFloat(values.weightPerUnit),
					},
					onCompleted: (data) => {
						if (data.UpdateProductVariant?._id) {
							setSubmitting(false);
							toast({
								title: "Variation has been updated with success.",
								status: "success",

								position: "bottom-right",
								duration: 5000,
								isClosable: true,
							});
							apolloClient.refetchQueries({
								include: "active",
							});
							handleCloseModal();
						}
					},
					onError: (error) => {
						setSubmitting(false);
						toast({
							title: "An error occurred while updating variation.",
							description: error.message,
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
					},
				});
			} else {
				handleCreateVariationMutation({
					variables: {
						product_id: props.productID,
						barcode: values.barcode,
						images:
							files.map((files) => (files.src ? (files.src as string) : "")) ||
							undefined,
						isActive: values.isActive,
						selectedOption: optionsList.map((option, index) => ({
							KEY: option.name,
							VALUE: option.selected,
						})),
						sku: values.sku,
						variantPrice: parseFloat(values.variationPrice),
						weightPerUnit: parseFloat(values.weightPerUnit),
					},
					onCompleted: (data) => {
						if (data.CreateVariant?._id) {
							setSubmitting(false);
							toast({
								title: "Variation has been created with success.",
								status: "success",

								position: "bottom-right",
								duration: 5000,
								isClosable: true,
							});
							apolloClient.refetchQueries({
								include: "active",
							});
							handleCloseModal();
						}
					},
					onError: (error) => {
						setSubmitting(false);
						toast({
							title: "An error occurred while creating variation.",
							description: error.message,
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
					},
				});
			}
		},
	});

	useEffect(() => {
		setOptionsList((prevState) => {
			let temp = [...prevState];

			optionsProductData?.GetProductById.options.forEach((option, index) => {
				let exist = false;
				props.selectedVariant?.selectedOption?.forEach(
					(selected, optionIndex) => {
						if (option.name === selected?.KEY) {
							exist = true;
							if (option?.values && option?.values.length > 0) {
								temp.push({
									name: option?.name || "",
									values: ["All", ...option?.values],
									selected: selected.VALUE || "All",
								});
							}
						}
					}
				);

				if (!exist) {
					if (option?.values && option?.values.length > 0) {
						temp.push({
							name: option?.name || "",
							values: ["All", ...option?.values],
							selected: "All",
						});
					}
				}
			});
			return temp;
		});
		setFiles(
			props.selectedVariant?.images?.map((item, index) => ({
				progress: false,
				id: index.toString(),
				src: item,
			})) || []
		);
	}, [optionsProductData]);

	const handleChangeOption = (value: string, optionName: string) => {
		setOptionsList((prevState) => {
			let listTemp = [...prevState];
			prevState.forEach((option, listIndex) => {
				if (option.name === optionName) {
					listTemp[listIndex].selected = value;
				}
			});
			return listTemp;
		});
	};

	return (
		<ModalContent>
			<ModalHeader borderBottomWidth='1px'>
				{props.selectedVariant ? "Update variation" : "Create variation"}
			</ModalHeader>
			<ModalCloseButton />
			<ModalBody>
				<form
					id='create-update-variant'
					onSubmit={variationsFormik.handleSubmit}>
					<Stack spacing='12px'>
						<ImageUpload
							height='120px'
							width={files.length === 0 ? "100%" : "120px"}
							multiple
							images={files}
							handleUpload={handleFile}
							handleDelete={handleDelete}
							label='Images'
							gridColCount={3}
						/>
						<Stack direction='row' spacing='12px'>
							<Input
								label='SKU ( Stock Keeping Unit )'
								inputProps={{
									name: "sku",
									placeholder: "SKU",
									value: variationsFormik.values.sku || "",
									onChange: variationsFormik.handleChange,
								}}
							/>
							<Input
								label='Barcode'
								inputProps={{
									name: "barcode",
									placeholder: "Barcode",
									value: variationsFormik.values.barcode || "",
									onChange: variationsFormik.handleChange,
								}}
							/>
						</Stack>
						<Stack direction='row' spacing='12px'>
							<NumberInput
								label='Price'
								numberInputProps={{
									allowMouseWheel: true,
									placeholder: "Price",
									name: "variationPrice",
									onChange: (vString, vNumber) =>
										variationsFormik.setFieldValue("variationPrice", vString),
									value: variationsFormik.values.variationPrice || 0,
									width: "100%",
									precision: 3,
								}}
								inputRightAddon={{ children: "TND" }}
							/>
							<NumberInput
								label='Weight per unit'
								numberInputProps={{
									allowMouseWheel: true,
									placeholder: "Weight",
									name: "weightPerUnit",
									onChange: (vString, vNumber) =>
										variationsFormik.setFieldValue("weightPerUnit", vString),
									value: variationsFormik.values.weightPerUnit || 0,
									width: "100%",
									precision: 3,
								}}
								inputRightAddon={{ children: "kg" }}
							/>
						</Stack>
						<Skeleton
							isLoaded={!optionsProductLoading}
							opacity={optionsProductLoading ? 0.4 : 1}>
							<Stack my={3}>
								<Heading fontWeight={"semibold"} size='sm'>
									Select options
								</Heading>
								{optionsList.map((option, index) => (
									<SelectInput
										label={option?.name || `Option ${index + 1}`}
										options={
											option?.values?.map((value) => ({
												label: value,
												value: value,
											})) || []
										}
										selectProps={{
											onChange: (e) =>
												handleChangeOption(e.target.value, option.name || ""),
											name: "selectOption",
											value: option?.selected,
										}}
									/>
								))}
							</Stack>
						</Skeleton>
					</Stack>
				</form>
			</ModalBody>

			<ModalFooter borderTopWidth='1px'>
				<Button variant='ghost' mr={3} onClick={handleCloseModal}>
					Discard
				</Button>
				<Button
					type='submit'
					form='create-update-variant'
					colorScheme='main'
					isLoading={variationsFormik.isSubmitting}>
					{props.selectedVariant ? "Update variation" : "Create variation"}
				</Button>
			</ModalFooter>
		</ModalContent>
	);
};
