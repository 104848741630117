import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const Dashboardwrapper = styled(Box)`
	.cover_section {
		position: relative;
		color: white;
		margin-bottom: 12px;
		.content {
			padding: 16px 16px;
		}
		img {
			position: absolute;
			border-radius: 5px;
			z-index: -10;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
`;
