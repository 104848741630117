import {
	Box,
	Button,
	IconButton,
	Link,
	Text,
	useToast,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Formik, FormikErrors } from "formik";
import { useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { useSetAuthenticateMutation } from "../../../../api/generated/graphql";
import { Input } from "../../../../components";
import { setInCache } from "../../../../utils/cache";

const AuthFormWrapper = styled("form")`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 26px;
`;

const LoginForm = (props: {
	handleEmailNotConfirmed: (email: string) => void;
}) => {
	const { handleEmailNotConfirmed } = props;

	const [mutateAuth] = useSetAuthenticateMutation();

	const [showPassword, setShowPassword] = useState<boolean>(false);

	const toast = useToast();

	const navigate = useNavigate();

	return (
		<Formik
			validateOnMount={false}
			validateOnBlur={false}
			validateOnChange={false}
			initialValues={{ email: "", password: "" }}
			validate={(values) => {
				const errors: FormikErrors<typeof values> = {};
				if (!values.email) {
					errors.email = "Email is required";
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				) {
					errors.email = "Invalid email address";
				}
				if (!values.password) {
					errors.password = "Password is required";
				}
				console.log(errors);
				return errors;
			}}
			onSubmit={(values, { setSubmitting, setFieldError }) => {
				mutateAuth({
					variables: {
						email: values.email,
						password: values.password,
					},
					onCompleted: (data) => {
						if (
							data?.AuthenticateUser?.token?.accessToken &&
							data?.AuthenticateUser?.token?.refreshToken
						) {
							setSubmitting(false);
							setInCache("user_tokens", {
								refresh: data.AuthenticateUser.token?.refreshToken,
								access: data.AuthenticateUser.token?.accessToken,
							});
							setInCache("user_role", data.AuthenticateUser.user.role, true);
							setInCache("user_data", {
								firstName: data.AuthenticateUser.user.firstName,
								lastName: data.AuthenticateUser.user.lastName,
								avatar: data.AuthenticateUser.user.avatar,
								_id: data.AuthenticateUser.user._id,
							});
							window.location.reload();
						}
						if (
							data?.AuthenticateUser?.token === null &&
							!data?.AuthenticateUser?.user?.isConfirmed
						) {
							setSubmitting(false);
							handleEmailNotConfirmed(
								data?.AuthenticateUser?.user?.email || ""
							);
						}
					},
					onError: (error) => {
						setSubmitting(false);
						if (error.networkError) {
							toast({
								title: "Network error.",
								description:
									"Please check your internet connection, or refresh the page",
								status: "error",

								position: "bottom-right",
								duration: 5000,
								isClosable: true,
							});
						} else {
							switch (error.message) {
								case "USER_NOT_FOUND": {
									setFieldError("email", "User not found");
									break;
								}
								case "BAD_CREDENTIALS": {
									setFieldError("password", "Wrong password");
									break;
								}
								case "USER_BLOCKED": {
									setFieldError("email", "User is blocked from access");
									break;
								}
								case "INTERNAL_SERVER_ERROR": {
									toast({
										title: "An error has occured.",
										description: "Internal server error",
										status: "error",

										position: "bottom-right",
										duration: 5000,
										isClosable: true,
									});
									break;
								}
								default: {
									toast({
										title: "An error has occured.",
										status: "error",
										duration: 5000,

										position: "bottom-right",
										isClosable: true,
									});
								}
							}
						}
					},
				});
			}}>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<AuthFormWrapper onSubmit={handleSubmit}>
					<Input
						inputProps={{
							type: "text",
							name: "email",
							onChange: handleChange,
							onBlur: handleBlur,
							value: values.email,
							placeholder: "Email",
						}}
						errorMessage={errors.email}
						isError={errors.email !== undefined}
						label='Email'
					/>
					<Input
						inputProps={{
							type: showPassword ? "text" : "password",
							name: "password",
							onChange: handleChange,
							onBlur: handleBlur,
							value: values.password,
							placeholder: "Password",
						}}
						inputRightElementProps={{
							children: (
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									aria-label='Password toggle'
									variant='ghost'>
									{showPassword ? <BiShow size={18} /> : <BiHide size={18} />}
								</IconButton>
							),
						}}
						errorMessage={errors.password}
						isError={errors.password !== undefined}
						label='Password'
					/>
					<Box textAlign='right'>
						<Link color='blue.400' as={RouterLink} to='/forgot-password'>
							Forgot password?
						</Link>
					</Box>
					<Button
						marginTop='4'
						colorScheme='main'
						type='submit'
						isLoading={isSubmitting}
						size='lg'>
						Login
					</Button>
					<Text>
						New to elmall?{" "}
						<Link
							color='blue.400'
							as={RouterLink}
							to='/authentication/register'>
							Create an account!
						</Link>
					</Text>
				</AuthFormWrapper>
			)}
		</Formik>
	);
};

export default LoginForm;
