import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const FullPageLoadWrapper = styled(Box)`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 9999;
`;
