import { useToast } from "@chakra-ui/react";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AuthRoutes } from ".";
import { useGetVendorStoresQuery } from "../api/generated/graphql";
import { FullPageLoad } from "../components";
import {
	CreateProduct,
	CreateStore,
	Customers,
	Dashboard,
	Products,
	Inventory,
	InventoryHistory,
	UpdateProduct,
	VendorStoreSettings,
	ProfileSettings,
	AccountSecuritySettings,
	StoreSeoSettings,
	Orders,
	OrderDetails,
} from "../pages";
import { getDataFromCache, setInCache } from "../utils/cache";
import { CacheUserDataType } from "../utils/globalTypes";

const Layout = lazy(() => import("../components/modules/Layout/Layout"));

const VendorApp = ({ id }: { id: string }) => {
	return (
		<Suspense fallback={<FullPageLoad message='Accessing your dashboard' />}>
			<Layout role='VENDOR'>
				<Routes>
					<Route path='/'>
						<Route index element={<Navigate to={`/${id}`} replace />} />
						<Route path={id}>
							<Route
								index
								element={
									<Navigate
										to={`/${id}/products`}
										state={{
											pageName: "Products",
										}}
										replace
									/>
								}
							/>
							<Route path='dashboard' element={<Dashboard />} />
							<Route path='products'>
								<Route index element={<Products />} />
								<Route path='create' element={<CreateProduct />} />
								<Route path=':productID' element={<UpdateProduct />} />
							</Route>
							<Route path='inventory'>
								<Route index element={<Inventory />} />
								<Route path=':productID'>
									<Route index element={<InventoryHistory />} />
									<Route
										path='variant/:variantID'
										element={<InventoryHistory />}
									/>
								</Route>
							</Route>
							<Route path='customers' element={<Customers />} />
							<Route path='orders'>
								<Route index element={<Orders />} />

								<Route path=':orderID' element={<OrderDetails />} />
							</Route>
							<Route path='settings'>
								<Route
									index
									element={<Navigate to={`/${id}/settings/store`} replace />}
								/>
								<Route path='store' element={<VendorStoreSettings />} />
								<Route path='profile' element={<ProfileSettings />} />
								<Route path='security' element={<AccountSecuritySettings />} />
								<Route path='seo' element={<StoreSeoSettings />} />
							</Route>
						</Route>
						<Route path='*' element={<Navigate to='/' replace />} />
					</Route>
				</Routes>
			</Layout>
		</Suspense>
	);
};

const VendorRoutes = () => {
	const storeID: string = getDataFromCache("store_id", true);

	const toast = useToast();

	const {
		loading: userStoreLoading,
		error: userStoreError,
		data: userStoreData,
	} = useGetVendorStoresQuery({
		onCompleted: (data) => {
			console.log(data);
			if (data?.GetCurrentSessionUserStores?.stores?.[0]?._id) {
				setInCache(
					"store_id",
					data?.GetCurrentSessionUserStores?.stores?.[0]?._id,
					true
				);
			}
		},
		skip: storeID ? true : false,
	});

	if (userStoreLoading) {
		return <FullPageLoad message='Accessing your dashboard' />;
	}
	if (storeID) {
		return <VendorApp id={storeID} />;
	}
	if (!userStoreData?.GetCurrentSessionUserStores?.stores?.[0]?._id) {
		return (
			<Routes>
				<Route path='/'>
					<Route index element={<Navigate to={`/create-store`} replace />} />
					<Route path='create-store' element={<CreateStore />} />
					<Route path='*' element={<Navigate to='/create-store' replace />} />
				</Route>
			</Routes>
		);
	} else {
		window.location.reload();
		return (
			<VendorApp
				id={userStoreData?.GetCurrentSessionUserStores?.stores?.[0]?._id}
			/>
		);
	}
};

export default VendorRoutes;
