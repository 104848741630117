import {
	Box,
	Button,
	Heading,
	Stack,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { apolloClient } from "../..";
import { useUpdateSecuritySettingsMutation } from "../../api/generated/graphql";
import { Input } from "../../components";
import SettingsWrapper from "../../components/SettingsWrapper/SettingsWrapper";

const AccountSecuritySettings = () => {
	const sectionBgColor = useColorModeValue("white", "gray.900");

	const [securityMutation] = useUpdateSecuritySettingsMutation();

	const toast = useToast();

	const securityInitalValues = {
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	};

	const securityFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: securityInitalValues,
		onSubmit: (values, { setSubmitting }) => {
			securityMutation({
				variables: {
					newPassword: values.newPassword,
					oldPassword: values.oldPassword,
				},
				onCompleted: (data) => {
					if (data.UpdateCurrentSessionUserPassword?.code) {
						setSubmitting(false);
						toast({
							title: "Password has been updated with success.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while updating your password.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	return (
		<SettingsWrapper>
			<form onSubmit={securityFormik.handleSubmit}>
				<Heading size='lg' fontSize='2xl' fontWeight='medium'>
					Account security
				</Heading>
				<Stack marginTop='24px' spacing='20px'>
					<Box
						p='16px'
						bgColor={sectionBgColor}
						width='100%'
						shadow='md'
						rounded='base'>
						<Heading size='md' fontSize='xl' fontWeight='medium'>
							Update your password
						</Heading>
						<Stack mt='4' width='480px'>
							<Input
								label='Old password'
								inputProps={{
									type: "password",
									placeholder: "Your old password",
									name: "oldPassword",
									value: securityFormik.values.oldPassword,
									onChange: securityFormik.handleChange,
								}}
							/>
							<Input
								label='New password'
								inputProps={{
									type: "password",
									placeholder: "Your new password",
									name: "newPassword",
									value: securityFormik.values.newPassword,
									onChange: securityFormik.handleChange,
								}}
							/>
							<Input
								label='Confirm New password'
								inputProps={{
									type: "password",
									placeholder: "Confirm new password",
									name: "confirmPassword",
									value: securityFormik.values.confirmPassword,
									onChange: securityFormik.handleChange,
								}}
							/>
						</Stack>
					</Box>
					<Box textAlign='right'>
						<Button
							colorScheme='main'
							type='submit'
							isLoading={securityFormik?.isSubmitting}>
							Save changes
						</Button>
					</Box>
				</Stack>
			</form>
		</SettingsWrapper>
	);
};
export default AccountSecuritySettings;
