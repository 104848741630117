import {
	Box,
	Button,
	Divider,
	Heading,
	IconButton,
	Link,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import {
	BiArchive,
	BiArrowBack,
	BiBlock,
	BiDotsHorizontal,
	BiDotsHorizontalRounded,
	BiEdit,
	BiExport,
	BiFace,
	BiFilterAlt,
	BiRefresh,
	BiSearch,
	BiTable,
	BiTrash,
} from "react-icons/bi";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
	GetAllUsersQueryVariables,
	useGetAllUsersQuery,
} from "../../api/generated/graphql";
import { profilePicturePlaceholder } from "../../assets";
import { Input, Table, TableHeadType } from "../../components";
import { PageWrapper } from "../../utils/globalStyle";
import UsersFilterDrawer from "./Filters/Filters";
import EditCustomer from "./Modal/EditCustomer";

const Customers = () => {
	const location = useLocation();

	const [pagination, setPagination] = useState({
		limit: 10,
		skip: 0,
	});

	const [selectedCustomer, setSelectedCustomer] = useState<
		string | undefined
	>();

	const [filters, setFilters] =
		useState<
			Pick<
				GetAllUsersQueryVariables,
				| "email"
				| "firstName"
				| "lastName"
				| "isArchived"
				| "isBlocked"
				| "isConfirmed"
			>
		>();

	const [search, setSearch] =
		useState<Pick<GetAllUsersQueryVariables, "search">>();

	const [defaultFilterValues, setDefaultFilterValues] = useState({
		firstName: "",
		lastName: "",
		email: "",
		status: [""],
	});

	const {
		isOpen: openFilter,
		onOpen: onOpenFilter,
		onClose: onCloseFilter,
	} = useDisclosure();

	const { from } = (location?.state as { from?: string }) || "";

	const handlePageChange = (dir: "next" | "prev") => {
		if (dir === "next") {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip + temp.limit;
				return temp;
			});
		} else {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip - temp.limit;
				return temp;
			});
		}
	};

	const handleLimitChange = (value: number) => {
		setPagination((prevState) => {
			let temp = { ...prevState };
			temp.limit = +value;
			return temp;
		});
	};

	const {
		loading: userLoading,
		error: userError,
		data: userData,
	} = useGetAllUsersQuery({
		variables: {
			pagination: pagination,
			...filters,
			...search,
		},
		pollInterval: 300000,
	});

	const customersTableData =
		userData?.GetAllUsers?.users?.map((user, index) => ({
			id: user._id,
			avatar: (
				<Box className='table_image'>
					<img
						src={user.avatar || profilePicturePlaceholder}
						alt='user_profile_picture'
					/>
				</Box>
			),
			firstName: user?.firstName,
			lastName: user?.lastName,
			email: user?.email,
			phoneNumber:
				user?.userAddress?.[0]?.phoneNumber?.prefix &&
				user?.userAddress?.[0]?.phoneNumber?.number
					? user?.userAddress?.[0]?.phoneNumber?.prefix +
					  " " +
					  user?.userAddress?.[0]?.phoneNumber?.number
					: "--",
			nationality: user?.nationality,
			dataOfBirth: moment(user.dateOfBirth).format("ll"),
			action: (
				<Menu>
					<MenuButton
						className='profile_button'
						as={Button}
						aria-label='menu_more'
						size='sm'
						variant='ghost'>
						<BiDotsHorizontalRounded />
					</MenuButton>
					<MenuList px='2' shadow='lg'>
						<MenuItem
							rounded='base'
							icon={<BiEdit size={16} />}
							onClick={() => setSelectedCustomer(user._id)}>
							Edit customer
						</MenuItem>
						<MenuDivider />
						<MenuItem rounded='base' icon={<BiBlock size={16} />}>
							Block customer
						</MenuItem>
						<MenuItem rounded='base' icon={<BiArchive size={16} />}>
							Archive customer
						</MenuItem>
						<MenuDivider />
						<MenuItem
							rounded='base'
							icon={<BiTrash size={16} />}
							color='status.error'>
							Delete customer
						</MenuItem>
					</MenuList>
				</Menu>
			),
		})) || [];

	type UserTableData = typeof customersTableData[number];

	const customersTableHead: TableHeadType<UserTableData>[] = [
		{ accessor: "avatar", label: "" },
		{ accessor: "firstName", label: "First name" },
		{ accessor: "lastName", label: "Last name" },
		{ accessor: "email", label: "Email" },
		{ accessor: "phoneNumber", label: "Phone Number" },
		{ accessor: "nationality", label: "Nationality" },
		{ accessor: "dataOfBirth", label: "Date of birth" },
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	const handleFilter = (values: {
		firstName: string;
		lastName: string;
		email: string;
		status: string[];
	}) => {
		setDefaultFilterValues(values);
		setFilters({
			email: values.email || undefined,
			firstName: values.firstName || undefined,
			lastName: values.lastName || undefined,
			isBlocked: values.status.includes("isBlocked") || undefined,
			isArchived: values.status.includes("isArchived") || undefined,
			isConfirmed: values.status.includes("isConfirmed") || undefined,
		});
	};

	const getFiltersCount = () => {
		let count = 0;
		if (filters) {
			Object.values(filters).forEach((value, index) => {
				if (value) {
					count = count + 1;
				}
			});
		}
		return count;
	};

	const handleResetFilter = () => {
		setFilters(undefined);
		setDefaultFilterValues({
			firstName: "",
			lastName: "",
			email: "",
			status: [""],
		});
	};

	const handleSearchSubmit: React.KeyboardEventHandler<HTMLInputElement> = (
		event
	) => {
		if (event.code === "Enter") {
			let target = event.target as typeof event.target & {
				value: string;
			};
			setSearch({
				search: target.value,
			});
		}
	};

	const {
		isOpen: EditModalOpen,
		onOpen: EditModalOnOpen,
		onClose: EditModalOnClose,
	} = useDisclosure();

	const handleCloseEditModal = () => {
		setSelectedCustomer(undefined);
		EditModalOnClose();
	};

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Heading fontSize='2xl'>Customers</Heading>
					</Box>
				</Box>
				{/* <Button variant='outline'>Export</Button> */}
			</Box>
			<Box className='page_actions'>
				<Input
					inputProps={{
						placeholder: "Search for customer",
						onKeyUp: handleSearchSubmit,
					}}
					inputLeftElementProps={{ children: <BiSearch /> }}
				/>
				<Divider orientation='vertical' />
				<Button
					variant='outline'
					leftIcon={
						getFiltersCount() === 0 ? (
							<BiFilterAlt />
						) : (
							<Text
								bgColor='main.400'
								color='white'
								px='2'
								py='1'
								rounded='full'
								fontSize='sm'>
								{getFiltersCount()}
							</Text>
						)
					}
					onClick={onOpenFilter}>
					Filter
				</Button>
			</Box>
			<Table
				head={customersTableHead}
				data={customersTableData}
				isLoading={userLoading}
				emptyState={
					<Box className='empty_table_container'>
						<BiFace size={42} />
						<Text fontSize='md' fontWeight='medium'>
							{search?.search
								? `No results for "${search.search}"`
								: getFiltersCount() !== 0
								? "There is no stores with these filters"
								: "There is no customers yet."}
						</Text>
					</Box>
				}
				pagination={{
					count: userData?.GetAllUsers.queryCount || 0,
					limit: pagination.limit,
					skip: pagination.skip,
					onPageChange: handlePageChange,
					onChangeLimit: handleLimitChange,
				}}
			/>
			<UsersFilterDrawer
				isOpen={openFilter}
				onClose={onCloseFilter}
				handleSubmitFilter={handleFilter}
				defaultValues={defaultFilterValues}
				handleResetFilter={handleResetFilter}
			/>
			<EditCustomer
				isOpen={selectedCustomer !== undefined && selectedCustomer !== ""}
				onOpen={EditModalOnOpen}
				onClose={handleCloseEditModal}
				customerID={selectedCustomer}
			/>
		</PageWrapper>
	);
};

export default Customers;
