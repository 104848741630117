import {
	Box,
	Link,
	Text,
	Stack,
	Button,
	Heading,
	Divider,
	useToast,
	useDisclosure,
	useColorModeValue,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Select,
	IconButton,
	Spinner,
	Skeleton,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import {
	BiArrowBack,
	BiChevronDown,
	BiChevronLeft,
	BiChevronRight,
	BiFilterAlt,
	BiMoney,
	BiSearch,
	BiShow,
} from "react-icons/bi";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { apolloClient } from "../..";
import {
	GetAllOrdersQuery,
	GetAllOrdersQueryVariables,
	Order,
	useGetAllOrdersQuery,
	useGetVendorOrdersQuery,
	useUpdateOrderStatusMutation,
} from "../../api/generated/graphql";
import { Input } from "../../components";
import { PaginationWrapper } from "../../components/modules/Table/Table.styles";
import { getDataFromCache } from "../../utils/cache";
import { PageWrapper } from "../../utils/globalStyle";
import OrderListFilter from "./Filters/OrdersListFilter";

const Orders = () => {
	const location = useLocation();

	const { from } = (location?.state as { from?: string }) || "";

	const toast = useToast();

	const navigate = useNavigate();

	const userRole: string = getDataFromCache("user_role", true);

	const [pagination, setPagination] = useState({
		limit: 10,
		skip: 0,
	});

	const [filters, setFilters] =
		useState<Pick<GetAllOrdersQueryVariables, "user" | "status">>();

	const [search, setSearch] =
		useState<Pick<GetAllOrdersQueryVariables, "contains">>();

	const [defaultFilterValues, setDefaultFilterValues] = useState({
		user: "",
		status: "",
	});

	const handlePageChange = (dir: "next" | "prev") => {
		if (dir === "next") {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip + temp.limit;
				return temp;
			});
		} else {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip - temp.limit;
				return temp;
			});
		}
	};

	const handleLimitChange = (value: number) => {
		setPagination((prevState) => {
			let temp = { ...prevState };
			temp.limit = +value;
			return temp;
		});
	};

	const handleFilter = (values: { user: string; status: string }) => {
		setDefaultFilterValues(values);
		setFilters({
			user: values.user?.split("_")[1] || undefined,
			status: values.status || undefined,
		});
	};

	const getFiltersCount = () => {
		let count = 0;
		if (filters) {
			Object.values(filters).forEach((value, index) => {
				let testValue: string | undefined = undefined;
				if (typeof value !== "string") {
					testValue = value?.id !== "" ? value?.id : undefined;
				} else {
					testValue = value;
				}
				if (testValue) {
					count = count + 1;
				}
			});
		}
		return count;
	};

	const handleResetFilter = () => {
		setFilters(undefined);
		setDefaultFilterValues({
			user: "",
			status: "",
		});
	};

	const handleSearchSubmit: React.KeyboardEventHandler<HTMLInputElement> = (
		event
	) => {
		if (event.code === "Enter") {
			let target = event.target as typeof event.target & {
				value: string;
			};
			setSearch({
				contains: target.value,
			});
		}
	};

	const {
		isOpen: openFilter,
		onOpen: onOpenFilter,
		onClose: onCloseFilter,
	} = useDisclosure();

	const {
		loading: ordersLoading,
		error: ordersError,
		data: ordersData,
	} = useGetAllOrdersQuery({
		variables: {
			contains: search?.contains || undefined,
			pagination: pagination,
			status: filters?.status || undefined,
			user: filters?.user || undefined,
		},
		skip: userRole !== "ADMIN",
	});

	const storeID = getDataFromCache("store_id", true);

	const {
		loading: vendorOrdersLoading,
		error: vendorOrdersError,
		data: vendorOrdersData,
	} = useGetVendorOrdersQuery({
		variables: {
			contains: search?.contains || undefined,
			pagination: pagination,
			status: filters?.status || undefined,
			user: filters?.user || undefined,
			storeID,
		},
		skip: userRole !== "VENDOR",
	});

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Heading fontSize='2xl'>Orders list</Heading>
					</Box>
				</Box>
			</Box>
			<Box className='page_actions'>
				<Input
					inputProps={{
						placeholder: "Search for Order ID",
						onKeyUp: handleSearchSubmit,
					}}
					inputLeftElementProps={{ children: <BiSearch /> }}
				/>
				<Divider orientation='vertical' />
				<Button
					variant='outline'
					leftIcon={
						getFiltersCount() === 0 ? (
							<BiFilterAlt />
						) : (
							<Text
								bgColor='main.400'
								color='white'
								px='2'
								py='1'
								rounded='full'
								fontSize='sm'>
								{getFiltersCount()}
							</Text>
						)
					}
					onClick={onOpenFilter}>
					Filter
				</Button>
			</Box>
			{userRole === "ADMIN" ? (
				<>
					{ordersLoading ? (
						<OrdersLoadingDisplay />
					) : (
						<>
							{ordersData?.GetAllOrders.orders?.map((order, index) => (
								<OrderItem role='ADMIN' order={order} key={index} />
							))}
							{ordersData?.GetAllOrders.orders && (
								<PaginationWrapper>
									<Box className='rows_perpage'>
										<Text>Show rows per page</Text>
										<Select
											size='sm'
											onChange={(e) =>
												handleLimitChange(parseInt(e.target.value))
											}
											value={pagination.limit}>
											<option value={5}>5</option>
											<option value={10}>10</option>
											<option value={25}>25</option>
											<option value={50}>50</option>
											<option value={100}>100</option>
										</Select>
									</Box>
									<Box className='pagination_action'>
										<Text>{`${pagination.skip + 1} - ${
											pagination.skip + pagination.limit >
											(ordersData?.GetAllOrders.queryCount || 0)
												? ordersData?.GetAllOrders.queryCount || 0
												: pagination.skip + pagination.limit
										} of ${ordersData?.GetAllOrders.queryCount || 0}`}</Text>
										<IconButton
											onClick={() => handlePageChange("prev")}
											disabled={pagination.skip < 1}
											aria-label='previous_button'
											size='sm'
											variant='ghost'>
											<BiChevronLeft size={24} />
										</IconButton>
										<IconButton
											onClick={() => handlePageChange("next")}
											aria-label='next_button'
											disabled={
												(ordersData?.GetAllOrders.queryCount || 0) <
												pagination.skip + pagination.limit
											}
											size='sm'
											variant='ghost'>
											<BiChevronRight size={24} />
										</IconButton>
									</Box>
								</PaginationWrapper>
							)}
						</>
					)}
				</>
			) : (
				<>
					{vendorOrdersLoading ? (
						<OrdersLoadingDisplay />
					) : (
						<>
							{vendorOrdersData?.GetCurrentSessionOrders?.orders?.map(
								(order, index) => (
									<OrderItem role='VENDOR' order={order} key={index} />
								)
							)}
							{vendorOrdersData?.GetCurrentSessionOrders?.orders && (
								<PaginationWrapper>
									<Box className='rows_perpage'>
										<Text>Show rows per page</Text>
										<Select
											size='sm'
											onChange={(e) =>
												handleLimitChange(parseInt(e.target.value))
											}
											value={pagination.limit}>
											<option value={5}>5</option>
											<option value={10}>10</option>
											<option value={25}>25</option>
											<option value={50}>50</option>
											<option value={100}>100</option>
										</Select>
									</Box>
									<Box className='pagination_action'>
										<Text>{`${pagination.skip + 1} - ${
											pagination.skip + pagination.limit >=
											(vendorOrdersData?.GetCurrentSessionOrders?.queryCount ||
												0)
												? vendorOrdersData?.GetCurrentSessionOrders
														?.queryCount || 0
												: pagination.skip + pagination.limit
										} of ${
											vendorOrdersData?.GetCurrentSessionOrders?.queryCount || 0
										}`}</Text>
										<IconButton
											onClick={() => handlePageChange("prev")}
											disabled={pagination.skip < 1}
											aria-label='previous_button'
											size='sm'
											variant='ghost'>
											<BiChevronLeft size={24} />
										</IconButton>
										<IconButton
											onClick={() => handlePageChange("next")}
											aria-label='next_button'
											disabled={
												(vendorOrdersData?.GetCurrentSessionOrders
													?.queryCount || 0) <=
												pagination.skip + pagination.limit
											}
											size='sm'
											variant='ghost'>
											<BiChevronRight size={24} />
										</IconButton>
									</Box>
								</PaginationWrapper>
							)}
						</>
					)}
				</>
			)}

			<OrderListFilter
				isOpen={openFilter}
				onClose={onCloseFilter}
				handleSubmitFilter={handleFilter}
				defaultValues={defaultFilterValues}
				handleResetFilter={handleResetFilter}
			/>
		</PageWrapper>
	);
};

export default Orders;

type OrderItemProps = {
	role: "ADMIN" | "VENDOR";
	order: {
		__typename?: "Order";
		_id: any;
		createdAt?: any | null;
		paymentStatus?: string | null;
		paymentType?: string | null;
		refId: string;
		status?: string | null;
		totalePrice?: number | null;
		updatedAt?: any | null;
		totaleItems?: number | null;
		user?: {
			__typename?: "User";
			lastName?: string | null;
			firstName?: string | null;
			email?: string | null;
			_id?: any | null;
			userAddress?: Array<{
				__typename?: "Address";
				phoneNumber?: {
					__typename?: "PhoneNumber";
					number?: string | null;
					prefix?: string | null;
				} | null;
			}> | null;
		} | null;
		orderNotes?: Array<{
			__typename?: "OrderNote";
			note?: string | null;
		}> | null;
	};
};

const OrderItem = (props: OrderItemProps) => {
	const { order, role } = props;

	const wrapperBorder = useColorModeValue("gray.200", "gray.600");
	const bgHoverColor = useColorModeValue("gray.50", "gray.700");

	const storeID = getDataFromCache("store_id", true);

	const location = useLocation();

	const navigate = useNavigate();

	const renderPaymentStatus = (status: string | null | undefined) => {
		switch (status) {
			case "PENDING":
				return {
					text: "Awaiting payment",
					color: "yellow",
				};
			case "SUCCESS":
				return {
					text: "Paid",
					color: "green",
				};
			case "FAILED":
				return {
					text: "Payment failed",
					color: "red",
				};
			case "REFUNDED":
				return {
					text: "Refunded",
					color: "blue",
				};
			case "COMPLETED":
				return {
					text: "Payment completed",
					color: "green",
				};
			default:
				return {
					text: status,
					color: "black",
				};
		}
	};

	const renderOrderStatus = (status: string | null | undefined) => {
		switch (status) {
			case "PENDING":
				return {
					text: "Awaiting processing",
					color: "blue",
				};
			case "PROCESSING":
				return {
					text: "Processing",
					color: "blue",
				};
			case "CONFIRMED":
				return {
					text: "Confirmed",
					color: "green",
				};
			case "READY":
				return {
					text: "Ready for delivery",
					color: "green",
				};
			case "TRANSPORTING":
				return {
					text: "In Transportation",
					color: "blue",
				};
			case "COMPLETED":
				return {
					text: "Order completed",
					color: "green",
				};
			case "CANCELED":
				return {
					text: "Order canceled",
					color: "red",
				};
			case "REFUNDED":
				return {
					text: "Order refunded",
					color: "red",
				};
			case "FAILED":
				return {
					text: "Order failed",
					color: "red",
				};
			default:
				return {
					text: status,
					color: "black",
				};
		}
	};

	const renderPaymentType = (type: string | null | undefined) => {
		switch (type) {
			case "ONLINE":
				return (
					<>
						<BiMoney fill='#3A85F5' size={18} />
						<Text ml='1' fontWeight='medium'>
							Online payment
						</Text>
					</>
				);
			default:
				return (
					<>
						<BiMoney color='#3A85F5' />
						<Text ml='1' fontWeight='medium'>
							Cash on delivery
						</Text>
					</>
				);
		}
	};

	const toast = useToast();

	const [changeOrderStatusMutation, changeOrderStatusParams] =
		useUpdateOrderStatusMutation();

	const handleChangeOrderStatus = (orderID: string, status: string) => {
		changeOrderStatusMutation({
			variables: {
				orderID,
				status,
			},
			onCompleted: (data) => {
				if (data.SetOrderStatus._id) {
					toast({
						title: "Order status has been updated with success!",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
				}
				apolloClient.refetchQueries({
					include: "active",
				});
			},
			onError: (error) => {
				toast({
					title: "An error occurred while updating order status.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	return (
		<Box
			mb='2'
			display='flex'
			alignItems='center'
			justifyContent='space-between'
			p='14px 18px'
			border='1px'
			borderColor={wrapperBorder}
			rounded='md'
			boxShadow='none'
			transition='0.1s ease-in all'
			_hover={{
				backgroundColor: bgHoverColor,
				cursor: "pointer",
				boxShadow: "0 0 0 1px #0b5eda",
				borderColor: "#0b5eda",
			}}>
			<Stack direction='row' spacing='18px'>
				<Box
					display='flex'
					flexDirection='column'
					justifyContent='space-between'>
					<Heading size='md' fontWeight='bold' fontSize='lg'>
						#{order?.refId.replaceAll("#", "")}
					</Heading>
					<Text opacity={0.6}>{moment(order?.createdAt).format("LLL")}</Text>
				</Box>
				<Stack direction='row' spacing='16px'>
					<Stack spacing='6px'>
						<Button
							variant='link'
							height='fit-content'
							colorScheme={renderPaymentStatus(order?.paymentStatus).color}
							pointerEvents='none'>
							<Heading size='md' fontWeight='semibold' fontSize='lg'>
								{renderPaymentStatus(order?.paymentStatus).text}
							</Heading>
						</Button>
						<Box display='flex' alignItems='center'>
							{renderPaymentType(order.paymentType)}
						</Box>
					</Stack>
					{role === "ADMIN" ? (
						<Menu>
							<MenuButton
								as={Button}
								variant='link'
								rightIcon={
									changeOrderStatusParams.loading ? (
										<Spinner size='xs' thickness='2px' />
									) : (
										<BiChevronDown size={18} />
									)
								}
								height='fit-content'
								colorScheme={renderOrderStatus(order?.status).color}>
								<Heading size='md' fontWeight='semibold' fontSize='lg'>
									{renderOrderStatus(order?.status).text}
								</Heading>
							</MenuButton>
							<MenuList>
								<MenuItem
									onClick={() =>
										handleChangeOrderStatus(order?._id, "PENDING")
									}>
									Awaiting processing
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleChangeOrderStatus(order?._id, "PROCESSING")
									}>
									Processing
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleChangeOrderStatus(order?._id, "CONFIRMED")
									}>
									Confirmed
								</MenuItem>
								<MenuItem
									onClick={() => handleChangeOrderStatus(order?._id, "READY")}>
									Ready for delivery
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleChangeOrderStatus(order?._id, "TRANSPORTING")
									}>
									In Transportation
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleChangeOrderStatus(order?._id, "COMPLETED")
									}>
									Delivery canceled
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleChangeOrderStatus(order?._id, "CANCELED")
									}>
									Order canceled
								</MenuItem>
								<MenuItem
									onClick={() =>
										handleChangeOrderStatus(order?._id, "REFUNDED")
									}>
									Order refunded
								</MenuItem>
								<MenuItem
									onClick={() => handleChangeOrderStatus(order?._id, "FAILED")}>
									Order failed
								</MenuItem>
							</MenuList>
						</Menu>
					) : (
						<Button
							variant='link'
							height='fit-content'
							colorScheme={renderOrderStatus(order?.status).color}
							pointerEvents='none'>
							<Heading size='md' fontWeight='semibold' fontSize='lg'>
								{renderOrderStatus(order?.status).text}
							</Heading>
						</Button>
					)}
				</Stack>
				<Stack spacing='-2px'>
					<Text fontSize='sm'>
						{order?.user?.firstName + " " + order?.user?.lastName}
					</Text>
					<Link
						fontSize='sm'
						color='blue.400'
						href={`mailto:${order?.user?.email}`}>
						{order?.user?.email}
					</Link>
					<Link
						fontSize='sm'
						color='blue.400'
						href={`tel:${order?.user?.userAddress?.[0]?.phoneNumber?.prefix} ${order?.user?.userAddress?.[0]?.phoneNumber?.number}`}>
						{order?.user?.userAddress?.[0]?.phoneNumber?.prefix}
						{order?.user?.userAddress?.[0]?.phoneNumber?.number}
					</Link>
				</Stack>
			</Stack>
			<Stack direction='row' spacing='10px' alignItems='center'>
				<Heading size='md' fontWeight='semibold' fontSize='xl'>
					{order?.totalePrice} TND
				</Heading>
				{/* <Button variant='outline'>Print</Button> */}
				<Button
					variant='outline'
					onClick={() =>
						navigate(
							role === "ADMIN"
								? `/admin/orders/${order._id}`
								: `/${storeID}/orders/${order._id}`,
							{
								state: {
									from: location.pathname,
									pageName: "Orders",
								},
							}
						)
					}
					leftIcon={<BiShow size={18} />}>
					View order
				</Button>
			</Stack>
		</Box>
	);
};

const OrdersLoadingDisplay = () => {
	return (
		<Stack spacing='8px'>
			<Skeleton height='75px' opacity={0.4} />
			<Skeleton height='75px' opacity={0.4} />
			<Skeleton height='75px' opacity={0.4} />
			<Skeleton height='75px' opacity={0.4} />
			<Skeleton height='75px' opacity={0.4} />
			<Skeleton height='75px' opacity={0.4} />
			<Skeleton height='75px' opacity={0.4} />
			<Skeleton height='75px' opacity={0.4} />
			<Skeleton height='75px' opacity={0.4} />
			<Skeleton height='75px' opacity={0.4} />
		</Stack>
	);
};
