import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const AuthenticationWrapper = styled(Box)`
	height: 100vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 85px 120px;
	.logo_container {
		width: 180px;
		> img {
			width: 160px;
		}
	}
	.imageCoverContainer {
		width: 100%;
		overflow: hidden;
		border-radius: 10px;
		> img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
`;
