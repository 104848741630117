import { Spinner, useColorModeValue, Text } from "@chakra-ui/react";
import { FullPageLoadWrapper } from "./FullPageLoad.styles";

const FullPageLoad = (props: { message?: string }) => {
	const bgColor = useColorModeValue("gray.50", "gray.800");

	return (
		<FullPageLoadWrapper bg={bgColor}>
			<Spinner color='main.400' size='xl' thickness='6px' />
			{props.message && (
				<Text mt='4' fontWeight='medium'>
					{props.message}
				</Text>
			)}
		</FullPageLoadWrapper>
	);
};

export default FullPageLoad;
