import {
	Box,
	Button,
	IconButton,
	Link,
	Stack,
	Text,
	useToast,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Formik, FormikErrors } from "formik";
import { useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { useSetRegisterVendorMutation } from "../../../../api/generated/graphql";
import { Input } from "../../../../components";
import { setInCache } from "../../../../utils/cache";

const AuthFormWrapper = styled("form")`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 26px;
`;

const RegisterForm = () => {
	const [mutateRegister] = useSetRegisterVendorMutation();

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] =
		useState<boolean>(false);
	const toast = useToast();

	const navigate = useNavigate();

	return (
		<Formik
			validateOnMount={false}
			validateOnBlur={false}
			validateOnChange={false}
			initialValues={{
				email: "",
				password: "",
				firstName: "",
				lastName: "",
				confirmPassword: "",
			}}
			validate={(values) => {
				const errors: FormikErrors<typeof values> = {};
				if (!values.email) {
					errors.email = "Email is required";
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				) {
					errors.email = "Invalid email address";
				}
				if (!values.password) {
					errors.password = "Password is required";
				} else if (values.password !== values.confirmPassword) {
					errors.confirmPassword = "Password confirmation is not matching";
				}
				if (!values.firstName) {
					errors.firstName = "First name is required";
				}
				if (!values.lastName) {
					errors.lastName = "Last name is required";
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting, setFieldError }) => {
				mutateRegister({
					variables: {
						email: values.email,
						password: values.password,
						firstName: values.firstName,
						lastName: values.lastName,
					},
					onCompleted: (data) => {
						if (data?.RegisterVendor?.user?._id) {
							navigate("/authentication/login", {
								state: {
									activity: "REGISTRED",
								},
							});
						}
					},
					onError: (error) => {
						setSubmitting(false);
						if (error.networkError) {
							toast({
								title: "Network error.",
								description:
									"Please check your internet connection, or refresh the page",
								status: "error",

								position: "bottom-right",
								duration: 5000,
								isClosable: true,
							});
						} else {
							switch (error.message) {
								case "EMAIL_ALREADY_EXIST": {
									setFieldError("email", "Email already in use");
									break;
								}
								case "INTERNAL_SERVER_ERROR": {
									toast({
										title: "An error has occured.",
										description: "Internal server error",
										status: "error",

										position: "bottom-right",
										duration: 5000,
										isClosable: true,
									});
									break;
								}
								default: {
									toast({
										title: "An error has occured.",
										status: "error",
										duration: 5000,
										position: "bottom-right",
										isClosable: true,
									});
								}
							}
						}
					},
				});
			}}>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<AuthFormWrapper onSubmit={handleSubmit}>
					<Input
						inputProps={{
							type: "text",
							name: "email",
							onChange: handleChange,
							onBlur: handleBlur,
							value: values.email,
							placeholder: "Email",
						}}
						errorMessage={errors.email}
						isError={errors.email !== undefined}
						label='Email'
					/>
					<Stack direction='row' spacing='20px'>
						<Input
							inputProps={{
								type: "text",
								name: "firstName",
								onChange: handleChange,
								onBlur: handleBlur,
								value: values.firstName,
								placeholder: "First name",
							}}
							errorMessage={errors.firstName}
							isError={errors.firstName !== undefined}
							label='First name'
						/>
						<Input
							inputProps={{
								type: "text",
								name: "lastName",
								onChange: handleChange,
								onBlur: handleBlur,
								value: values.lastName,
								placeholder: "Last name",
							}}
							errorMessage={errors.lastName}
							isError={errors.lastName !== undefined}
							label='Last name'
						/>
					</Stack>
					<Input
						inputProps={{
							type: showPassword ? "text" : "password",
							name: "password",
							onChange: handleChange,
							onBlur: handleBlur,
							value: values.password,
							placeholder: "Password",
						}}
						inputRightElementProps={{
							children: (
								<IconButton
									tabIndex={-1}
									onClick={() => setShowPassword(!showPassword)}
									aria-label='Password toggle'
									variant='ghost'>
									{showPassword ? <BiShow size={18} /> : <BiHide size={18} />}
								</IconButton>
							),
						}}
						errorMessage={errors.password}
						isError={errors.password !== undefined}
						label='Password'
					/>
					<Input
						inputProps={{
							type: showConfirmPassword ? "text" : "password",
							name: "confirmPassword",
							onChange: handleChange,
							onBlur: handleBlur,
							value: values.confirmPassword,
							placeholder: "Confirm your password",
						}}
						inputRightElementProps={{
							children: (
								<IconButton
									tabIndex={-1}
									onClick={() => setShowConfirmPassword(!showConfirmPassword)}
									aria-label='Password toggle'
									variant='ghost'>
									{showConfirmPassword ? (
										<BiShow size={18} />
									) : (
										<BiHide size={18} />
									)}
								</IconButton>
							),
						}}
						errorMessage={errors.confirmPassword}
						isError={errors.confirmPassword !== undefined}
						label='Confirm your password'
					/>
					<Button
						marginTop='4'
						colorScheme='main'
						type='submit'
						isLoading={isSubmitting}
						size='lg'>
						Create your account
					</Button>
					<Text>
						Already have an account?{" "}
						<Link color='blue.400' as={RouterLink} to='/authentication/login'>
							Log in
						</Link>
					</Text>
				</AuthFormWrapper>
			)}
		</Formik>
	);
};

export default RegisterForm;
