import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const ImageUploadWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 12px;
`;

export const ImageContainer = styled(Box)`
	overflow: hidden;
	:hover {
		.image_config {
			opacity: 1;

			pointer-events: all;
		}
		.image_preview {
			filter: blur(5px);
		}
	}
	.image_preview {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.image_loading {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #ffffff60;
		pointer-events: none;
	}
	.image_config {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #00000060;
		pointer-events: none;
	}
`;
