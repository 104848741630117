import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FullPageLoad } from "../components";

const Authentication = lazy(
	() => import("../pages/Authentication/Authentication")
);

const ConfirmEmail = lazy(() => import("../pages/ConfirmEmail/ConfirmEmail"));

const ForgetPassword = lazy(
	() => import("../pages/ForgetPassword/ForgetPassword")
);

const ResetPassword = lazy(
	() => import("../pages/ResetPassword/ResetPassword")
);

const AuthRoutes = () => {
	return (
		<Routes>
			<Route path='/'>
				<Route index element={<Navigate to='/authentication/login' />} />
				<Route
					path='authentication/login'
					element={
						<Suspense fallback={<FullPageLoad />}>
							<Authentication get='LOGIN' />
						</Suspense>
					}
				/>
				<Route
					path='authentication/register'
					element={
						<Suspense fallback={<FullPageLoad />}>
							<Authentication get='REGISTER' />
						</Suspense>
					}
				/>
				<Route
					path='confirm-email'
					element={
						<Suspense fallback={<FullPageLoad />}>
							<ConfirmEmail />
						</Suspense>
					}
				/>
				<Route
					path='forgot-password'
					element={
						<Suspense fallback={<FullPageLoad />}>
							<ForgetPassword />
						</Suspense>
					}
				/>
				<Route
					path='reset-password'
					element={
						<Suspense fallback={<FullPageLoad />}>
							<ResetPassword />
						</Suspense>
					}
				/>
				<Route path='*' element={<Navigate to='/authentication/login' />} />
			</Route>
		</Routes>
	);
};

export default AuthRoutes;
