import { Flex, Stack, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { useState } from "react";
import { Input } from "../../commons";

type TagsSelectInputProps = {
	tags: string[];
	onCreateTag: (value: string) => void;
	onDeleteTag: (index: number) => void;
};

const TagsSelectInput = (props: TagsSelectInputProps) => {
	const { tags, onCreateTag, onDeleteTag } = props;

	const [currentTag, setCurrentTag] = useState<string>("");

	const handleCreateTag: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
		e.preventDefault();
		if ((e.code === "Space" && e.key === " ") || e.key === "Enter") {
			let target = e.target as typeof e.target & {
				value: string;
			};
			if (target.value.replace(/\s/g, "")) {
				setCurrentTag("");
				onCreateTag(target.value.replace(/\s/g, ""));
			}
		}
	};

	return (
		<Stack>
			<Input
				label='Tags'
				inputProps={{
					placeholder: "Enter a tag",
					name: "tag",
					onKeyUp: handleCreateTag,
					onKeyDown: (e) => {
						e.key === "Enter" && e.preventDefault();
					},
					value: currentTag.replace(/\s/g, ""),
					onChange: (e) => setCurrentTag(e.target.value),
				}}
				helperText='Press "Space" or "Enter" to create a tag.'
				// errorMessage={errors.brand}
				// isError={errors.brand !== undefined}
			/>
			<Flex gap='4px' flexWrap='wrap'>
				{tags.map((tag, index) => (
					<Tag variant='solid' flexWrap='wrap'>
						<TagLabel>{tag}</TagLabel>
						<TagCloseButton onClick={() => onDeleteTag(index)} />
					</Tag>
				))}
			</Flex>
		</Stack>
	);
};

export default TagsSelectInput;
