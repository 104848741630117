import {
	useColorModeValue,
	Stack,
	Box,
	useColorMode,
	Heading,
	Text,
	Button,
	Slide,
	SlideFade,
	FormControl,
	FormLabel,
	Textarea,
	FormErrorMessage,
	useToast,
} from "@chakra-ui/react";
import { Formik, FormikErrors } from "formik";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import {
	useCreateVendorStoreMutation,
	useGetAllCountriesQuery,
	useUpdateStoreCompanyDataMutation,
	useUpdateStoreKycMutation,
} from "../../api/generated/graphql";
import { logoImage, logoImageDARKUI } from "../../assets";
import {
	DateTimeInput,
	DropDownInput,
	ImageUpload,
	Input,
	SelectInput,
} from "../../components";
import { getDataFromCache, setInCache } from "../../utils/cache";
import { CacheUserDataType } from "../../utils/globalTypes";
import { CreateStoreWrapper } from "./CreateStore.styles";

const CreateStore = () => {
	const bgColor = useColorModeValue("gray.100", "gray.900");

	const formBg = useColorModeValue("gray.50", "gray.800");

	const stepperBg = useColorModeValue("gray.200", "gray.700");

	const { colorMode } = useColorMode();

	const [stepIndex, setStepIndex] = useState<number>(0);

	const handleNextStep = () => {
		if (stepIndex + 1 < 3) {
			setStepIndex(stepIndex + 1);
		}
	};

	const userData: CacheUserDataType = getDataFromCache("user_data");

	const handlePrevStep = () => {
		if (stepIndex + 1 > 1) {
			setStepIndex(stepIndex - 1);
		}
	};

	const [files, setFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const handleFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append("public_id", `store/${userData._id}`);
		setFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleDelete = (id: string) => {
		console.log(
			id,
			files.filter(({ id: imageId }) => id !== imageId)
		);
		setFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	const toast = useToast();

	const [storeId, setStoreId] = useState<string>();

	const [createStoreMutation, createStoreParam] =
		useCreateVendorStoreMutation();

	const [updateStoreKYCMutation, updateStoreKYCParam] =
		useUpdateStoreKycMutation();

	const [updateStoreCompanyMutation, updateStoreCompanyParam] =
		useUpdateStoreCompanyDataMutation();

	const { data: countriesData, loading: countriesLoading } =
		useGetAllCountriesQuery();

	const [countriesList, setCountriesList] = useState<
		{
			render: ReactNode;
			value: string;
		}[]
	>([]);

	const [prefixList, setPrefixList] = useState<
		{
			render: ReactNode;
			value: string;
		}[]
	>([]);

	useEffect(() => {
		setCountriesList(
			countriesData?.GetAllCountries.map((country) => ({
				value: country.name || "",
				render: (
					<Stack direction='row' spacing='10px' alignItems='center'>
						<img
							className='select_country_image'
							src={country.flag || ""}
							alt={country.name || "countryflag"}
						/>{" "}
						<Text>{country.name}</Text>
					</Stack>
				),
			})) || []
		);
		setPrefixList(
			countriesData?.GetAllCountries.map((country) => ({
				value: country.dialCode || "",
				render: (
					<Stack direction='row' spacing='10px' alignItems='center'>
						<img
							className='select_country_image'
							src={country.flag || ""}
							alt={country.dialCode || "countryflag"}
						/>{" "}
						<Text>{country.dialCode}</Text>
					</Stack>
				),
			})) || []
		);
	}, [countriesData]);

	return (
		<CreateStoreWrapper h='100vh' w='100vw' bgColor={bgColor}>
			<Stack alignItems='center'>
				<Stack spacing='0' alignItems='center' textAlign='center'>
					<Box className='logo_container'>
						{colorMode === "dark" ? (
							<img src={logoImageDARKUI} alt='logo' />
						) : (
							<img src={logoImage} alt='logo' />
						)}
					</Box>

					<Heading size='md'>Complete your registration</Heading>
					<Text fontSize='sm' opacity={0.6}>
						Let’s finalize the details to prepare your store for a <br /> quick
						launch.
					</Text>
				</Stack>
				<Stack width='xl'>
					<Box className='stepper_container'>
						<Text fontSize='sm' fontWeight='medium' color='main.300'>
							Step {stepIndex + 1} of 3
						</Text>
						<Box className='stepper' bgColor={stepperBg}>
							<Box
								width={`${((stepIndex + 1) / 3) * 100}%`}
								className='stepper_value'
								bgColor='main.300'
								outline='1px solid'
								outlineColor='main.300'
							/>
						</Box>
					</Box>
					<Box className='forms_container'>
						<SlideFade
							className='slide_content'
							offsetX='80px'
							offsetY='0'
							in={stepIndex + 1 === 1}
							unmountOnExit>
							<Box
								className='store_form'
								w='full'
								shadow='md'
								bgColor={formBg}
								rounded='base'
								p='18px'
								mb='24px'>
								<Formik
									validateOnMount={false}
									validateOnBlur={false}
									validateOnChange={false}
									initialValues={{
										name: "",
									}}
									validate={(values) => {
										const errors: FormikErrors<typeof values> = {};
										if (!values.name) {
											errors.name = "Store name is required";
										}
										return errors;
									}}
									onSubmit={(values, { setSubmitting, setFieldError }) => {
										createStoreMutation({
											variables: {
												logo: files?.[0]?.src
													? (files?.[0]?.src as string)
													: "",
												name: values.name,
											},
											onCompleted: (data) => {
												if (data.CreateStore._id) {
													setSubmitting(false);
													setInCache("store_id", data.CreateStore._id, true);
													setStoreId(data.CreateStore._id);
													handleNextStep();
												}
											},
											onError: (error) => {
												setSubmitting(false);
												toast({
													title: "An error occured while creating store.",
													description: error.message,
													status: "error",

													position: "bottom-right",
													duration: 5000,
													isClosable: true,
												});
											},
										});
									}}>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
									}) => (
										<form onSubmit={handleSubmit}>
											<Stack spacing='18px'>
												<Box>
													<Heading size='md' mb='12px'>
														Setup your store
													</Heading>
													<Heading size='sm'>What's your store name?</Heading>
													<Text
														lineHeight='normal'
														fontSize='sm'
														opacity={0.8}
														mb='4px'>
														Enter the name of your marketplace as you want it to
														appear to your customers. You can change the name of
														your store at any time later.
													</Text>
													<Input
														label='Store name'
														inputProps={{
															placeholder: "Store name",
															name: "name",
															value: values.name,
															onChange: handleChange,
														}}
														errorMessage={errors.name}
														isError={errors.name !== undefined}
													/>
												</Box>
												<Box>
													<Heading size='sm'>Set your store logo</Heading>
													<Text
														lineHeight='normal'
														fontSize='sm'
														opacity={0.8}
														mb='4px'>
														Set your logo that will be shown in your store page.
														Recomanded size 512x128 pixels.
													</Text>
													<ImageUpload
														height='140px'
														width='100%'
														images={files}
														handleUpload={handleFile}
														handleDelete={handleDelete}
														label='Store logo'
													/>
												</Box>
												<Button
													alignSelf='flex-end'
													type='submit'
													isLoading={isSubmitting}
													width='140px'
													colorScheme={"main"}>
													Continue
												</Button>
											</Stack>
										</form>
									)}
								</Formik>
							</Box>
						</SlideFade>
						<SlideFade
							className='slide_content'
							offsetX='80px'
							offsetY='0'
							in={stepIndex + 1 === 2}
							unmountOnExit>
							<Box
								className='store_form'
								w='full'
								shadow='md'
								bgColor={formBg}
								rounded='base'
								p='18px'
								mb='24px'>
								<Formik
									validateOnMount={false}
									validateOnBlur={false}
									validateOnChange={false}
									initialValues={{
										mainProducts: "",
										customersAgeRange: "",
										businessIndustry: "",
										yearlyRevenue: "",
										alreadySelling: "",
									}}
									onSubmit={(values, { setSubmitting, setFieldError }) => {
										updateStoreKYCMutation({
											variables: {
												store_id: storeId,
												alreadySelling:
													values.alreadySelling === "TRUE" ? true : false,
												businessIndustry: values.businessIndustry,
												yearlyRevenue: values.yearlyRevenue,
												customersAgeRange: values.customersAgeRange,
												mainProducts: values.mainProducts,
											},
											onCompleted: (data) => {
												if (data.UpdateStore.code) {
													setSubmitting(false);
													handleNextStep();
												}
											},
											onError: (error) => {
												setSubmitting(false);
												toast({
													title: "An error occured while creating store.",
													description: error.message,
													status: "error",

													position: "bottom-right",
													duration: 5000,
													isClosable: true,
												});
											},
										});
									}}>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
									}) => (
										<form onSubmit={handleSubmit}>
											<Stack spacing='18px'>
												<Box>
													<Heading size='md' mb='12px'>
														Setup your store
													</Heading>
													<Heading size='sm'>
														Tell us a little about your business
													</Heading>
													<Text
														lineHeight='normal'
														fontSize='sm'
														opacity={0.8}
														mb='4px'>
														We’ll help you get started based on your responses.
													</Text>
												</Box>
												<Stack>
													<SelectInput
														label='Are you already selling?'
														options={[
															{
																label: "I'm not already selling",
																value: "FALSE",
															},
															{
																label: "I sell but not online",
																value: "TRUE",
															},
															{
																label: "I already sell but with another system",
																value: "TRUE",
															},
														]}
														selectProps={{
															placeholder: "Please choose...",
															name: "alreadySelling",
															value: values.alreadySelling,
															onChange: handleChange,
														}}
													/>
													<SelectInput
														label='What is your current yearly revenue?'
														options={[
															{
																label: "0$ I'm getting started",
															},
															{
																label: "Up to 500$",
															},
															{
																label: "From 500$ to 1,000$",
															},
															{
																label: "From 1,000$ to 5,000$",
															},
															{
																label: "From 5,000$ to 10,0000$",
															},
															{
																label: "From 10,0000$ to 30,0000$",
															},
															{
																label: "+30,0000$",
															},
														]}
														selectProps={{
															placeholder: "Please choose...",
															value: values.yearlyRevenue,
															name: "yearlyRevenue",
															onChange: handleChange,
														}}
													/>
													<SelectInput
														label='Which is your industry of operation?'
														options={[
															{
																label: "Clothing",
															},
															{
																label: "Electronics",
															},
															{
																label: "Furniture",
															},
															{
																label: "Handcrafts",
															},
															{
																label: "Jewelry",
															},
															{
																label: "Painting",
															},
															{
																label: "Photography",
															},
															{
																label: "Restaurants",
															},
															{
																label: "Groceries",
															},
															{
																label: "Other food & drink",
															},
															{
																label: "Sports",
															},
															{
																label: "Toys",
															},
															{
																label: "Services",
															},
															{
																label: "Virtual services",
															},
															{
																label: "Other",
															},
														]}
														selectProps={{
															placeholder: "Please choose...",
															value: values.businessIndustry,
															name: "businessIndustry",
															onChange: handleChange,
														}}
													/>
													<SelectInput
														label='What is your customers age range?'
														options={[
															{
																label: "Youger than 12 years old",
															},
															{
																label: "Between 12 and 24 years old",
															},
															{
																label: "Between 24 and 40 years old",
															},
															{
																label: "Older than 40 years old",
															},
															{
																label: "I'm not sure",
															},
														]}
														selectProps={{
															placeholder: "Please choose...",
															value: values.customersAgeRange,
															name: "customersAgeRange",
															onChange: handleChange,
														}}
													/>
													<FormControl>
														<FormLabel marginBottom='1'>
															Meta description
														</FormLabel>
														<Textarea
															placeholder='Describe your main products'
															name='mainProducts'
															onChange={handleChange}
															value={values.mainProducts}
														/>
													</FormControl>
												</Stack>
												<Stack direction='row' alignSelf='flex-end'>
													<Button
														onClick={handleNextStep}
														width='80px'
														variant='ghost'>
														Skip
													</Button>
													<Button
														type='submit'
														width='140px'
														colorScheme={"main"}
														isLoading={isSubmitting}>
														Continue
													</Button>
												</Stack>
											</Stack>
										</form>
									)}
								</Formik>
							</Box>
						</SlideFade>
						<SlideFade
							className='slide_content'
							offsetX='80px'
							offsetY='0'
							in={stepIndex + 1 === 3}
							unmountOnExit>
							<Box
								className='store_form'
								w='full'
								shadow='md'
								bgColor={formBg}
								rounded='base'
								p='18px'
								mb='24px'>
								<Formik
									validateOnMount={false}
									validateOnBlur={false}
									validateOnChange={false}
									initialValues={{
										companyName: "",
										typeOfCompany: "",
										website: "",
										dateOfEstablishment: null,
										country: "",
										apartment: "",
										streetAddress: "",
										city: "",
										zipCode: "",
										phoneNumber: "",
										phonePrefix: "",
									}}
									validate={(values) => {
										const errors: FormikErrors<typeof values> = {};
										if (!values.companyName) {
											errors.companyName = "Company name is required";
										}
										if (!values.typeOfCompany) {
											errors.typeOfCompany = "Company type is required";
										}
										if (!values.dateOfEstablishment) {
											errors.dateOfEstablishment =
												"Date of establishment is required";
										}
										if (!values.apartment) {
											errors.apartment =
												"Apartment, suite, etc. type is required";
										}
										if (!values.streetAddress) {
											errors.streetAddress = "Address is required";
										}
										if (!values.country) {
											errors.country = "Country is required";
										}
										if (!values.city) {
											errors.city = "City is required";
										}
										if (!values.zipCode) {
											errors.zipCode = "Zip/Postal code is required";
										}
										if (!values.phoneNumber) {
											errors.phoneNumber = "Phone number is required";
										}
										if (!values.phonePrefix) {
											errors.phonePrefix = "Prefix is required";
										}
										return errors;
									}}
									onSubmit={(values, { setSubmitting, setFieldError }) => {
										updateStoreCompanyMutation({
											variables: {
												store_id: storeId,
												apartment: values.apartment,
												city: values.city,
												companyName: values.companyName,
												country: values.country,
												dateOfEstablishment: moment(
													values.dateOfEstablishment
												).format(),
												phoneNumber: {
													number: values.phoneNumber.toString(),
													prefix: values.phonePrefix,
												},
												streetAddress: values.streetAddress,
												typeOfCompany: values.typeOfCompany,
												website: values.website,
												zipCode: values.zipCode,
											},
											onCompleted: (data) => {
												if (data.UpdateStore.code) {
													setSubmitting(false);
													window.location.reload();
												}
											},
											onError: (error) => {
												setSubmitting(false);
												toast({
													title: "An error occured while creating store.",
													description: error.message,
													status: "error",

													position: "bottom-right",
													duration: 5000,
													isClosable: true,
												});
											},
										});
									}}>
									{({
										values,
										errors,
										setFieldValue,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
									}) => (
										<form onSubmit={handleSubmit}>
											<Stack spacing='18px'>
												<Box>
													<Heading size='md' mb='12px'>
														Enter your business information
													</Heading>
												</Box>
												<Stack>
													<Heading size='sm'>Company info</Heading>
													<Stack direction='row' spacing='14px'>
														<Input
															label='Company name'
															inputProps={{
																placeholder: "Company name",
																name: "companyName",
																value: values.companyName,
																onChange: handleChange,
															}}
															errorMessage={errors.companyName}
															isError={errors.companyName !== undefined}
														/>
														<Input
															label='Company type'
															inputProps={{
																placeholder: "Company type",
																name: "typeOfCompany",
																value: values.typeOfCompany,
																onChange: handleChange,
															}}
															errorMessage={errors.typeOfCompany}
															isError={errors.typeOfCompany !== undefined}
														/>
													</Stack>
													<Stack direction='row' spacing='14px'>
														<Input
															label='Website'
															inputProps={{
																placeholder: "Website (Optional)",
																name: "website",
																value: values.website,
																onChange: handleChange,
															}}
															errorMessage={errors.website}
															isError={errors.website !== undefined}
														/>
														<DateTimeInput
															name='dateOfEstablishment'
															label='Date of establishment'
															onChange={(date) =>
																setFieldValue("dateOfEstablishment", date)
															}
															selected={values?.dateOfEstablishment}
															errorMessage={
																errors.dateOfEstablishment ||
																errors.dateOfEstablishment
															}
															isError={errors.dateOfEstablishment !== undefined}
														/>
													</Stack>
												</Stack>
												<Stack>
													<Heading size='sm'>Company address</Heading>
													<Stack>
														<Input
															label='Address'
															inputProps={{
																placeholder: "Address",
																name: "streetAddress",
																value: values.streetAddress,
																onChange: handleChange,
															}}
															errorMessage={errors.streetAddress}
															isError={errors.streetAddress !== undefined}
														/>
														<Input
															label='Apartment, suite, etc.'
															inputProps={{
																placeholder: "Apartment, suite, etc.",
																name: "apartment",
																value: values.apartment,
																onChange: handleChange,
															}}
															errorMessage={errors.apartment}
															isError={errors.apartment !== undefined}
														/>
														<DropDownInput
															label='Country'
															placeholder='Country'
															name='country'
															selected={values.country}
															errorMessage={errors.country}
															list={countriesList}
															isLoading={countriesLoading}
															onSelect={(value) =>
																setFieldValue("country", value)
															}
														/>
														<Stack direction='row' spacing='14px'>
															<Input
																label='City'
																inputProps={{
																	placeholder: "City",
																	name: "city",
																	value: values.city,
																	onChange: handleChange,
																}}
																errorMessage={errors.city}
																isError={errors.city !== undefined}
															/>
															<Input
																label='Zip/Postal code'
																inputProps={{
																	placeholder: "Zip/Postal code",
																	name: "zipCode",
																	value: values.zipCode,
																	onChange: handleChange,
																}}
																errorMessage={errors.zipCode}
																isError={errors.zipCode !== undefined}
															/>
														</Stack>
														<Input
															label='Phone number'
															inputProps={{
																placeholder: "Phone number",
																type: "number",
																name: "phoneNumber",
																onChange: handleChange,
																value: values.phoneNumber,
															}}
															errorMessage={
																errors.phoneNumber || errors.phonePrefix
															}
															isError={
																errors.phoneNumber !== undefined ||
																errors.phonePrefix !== undefined
															}
															inputLeftAddon={{
																background: "transparent",
																p: 0,
																border: "none",
																children: (
																	<Box w='100px'>
																		<DropDownInput
																			name='phonePrefix'
																			placeholder='Prefix'
																			selected={values.phonePrefix}
																			list={prefixList}
																			isLoading={countriesLoading}
																			onSelect={(value) =>
																				setFieldValue("phonePrefix", value)
																			}
																		/>
																	</Box>
																),
															}}
														/>
													</Stack>
												</Stack>
												<Button
													alignSelf='flex-end'
													type='submit'
													isLoading={isSubmitting}
													width='140px'
													colorScheme={"main"}>
													Access your store
												</Button>
											</Stack>
										</form>
									)}
								</Formik>
							</Box>
						</SlideFade>
					</Box>
				</Stack>
			</Stack>
		</CreateStoreWrapper>
	);
};

export default CreateStore;
