import { Box, Link, Text, Heading } from "@chakra-ui/react";
import {
	Link as RouterLink,
	useNavigate,
	useLocation,
	useParams,
} from "react-router-dom";

import { BiArrowBack, BiBriefcase } from "react-icons/bi";
import { PageWrapper } from "../../../utils/globalStyle";
import { useGetInventoryHistoryQuery } from "../../../api/generated/graphql";
import { Table, TableHeadType } from "../../../components";

const InventoryHistory = () => {
	const location = useLocation();

	const { productID, variantID } = useParams();

	const navigate = useNavigate();

	const { from } = (location?.state as { from?: string }) || "";

	const { data: historyData, loading: historyLoading } =
		useGetInventoryHistoryQuery({
			variables: {
				inventoryID: variantID ? variantID : productID,
				productID: productID,
			},
		});

	const renderActivity = (activity: string) => {
		switch (activity) {
			case "STOCK_ENTRY":
				return "Stock entry";
			case "CORRECTION":
				return "Correction";
			case "COUNT":
				return "Count";
			case "RECEIVED":
				return "Received";
			case "RETURN_RESTOCK":
				return "Return restock";
			case "DAMAGED":
				return "Damaged";
			case "THEFT_LOSS":
				return "Theft or loss";
			case "PROMOTION_DONATION":
				return "Promotion or donation";
			case "ORDER":
				return "Order";
			default:
				return activity;
		}
	};

	const historyTableData =
		historyData?.GetProductInventoryHistory?.map((history, index) => ({
			date: history?.createdAt,
			activity:
				history?.activity?.activity === "ORDER" ? (
					<Text>
						{renderActivity(history?.activity?.activity || "")}
						<Link as={RouterLink} to={`/orders`}>
							({history?.activity?.activityId})
						</Link>
					</Text>
				) : (
					<Text>{renderActivity(history?.activity?.activity || "")}</Text>
				),
			sales: history?.sales || "-",
			available: (
				<Text>
					<Text display='inline-block' opacity={0.6} mr='2'>
						(
						{historyData?.GetProductInventoryHistory?.[index + 1]
							? (history?.newQuantity || 0) -
							  (historyData?.GetProductInventoryHistory?.[index + 1]
									?.newQuantity || 0)
							: history?.newQuantity}
						)
					</Text>
					{history?.newQuantity}
				</Text>
			),
		})) || [];

	type HistoryTableData = typeof historyTableData[number];

	const historyTableHead: TableHeadType<HistoryTableData>[] = [
		{ accessor: "date", label: "Action date" },
		{ accessor: "activity", label: "Activity" },
		{ accessor: "sales", label: "Sales", headCellProps: { isNumeric: true } },
		{
			accessor: "available",
			label: "available",
			headCellProps: { isNumeric: true },
		},
	];

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Heading fontSize='2xl'>Inventory history</Heading>
					</Box>
				</Box>
			</Box>
			<Heading fontSize='xl' fontWeight='semibold' mb='4'>
				{historyData?.GetProductById?.name}
				{variantID &&
					historyData?.GetProductById?.variations &&
					historyData?.GetProductById?.variations?.length > 0 &&
					historyData?.GetProductById?.variations.map((variant) => {
						if (variant?._id === variantID)
							return (
								<Text opacity={0.6} fontWeight='medium'>
									{variant?.selectedOption?.map(
										(option, index) =>
											`${option.KEY}: ${option.VALUE}${
												variant?.selectedOption?.length !== index + 1
													? " - "
													: ""
											}`
									)}
								</Text>
							);
						else return null;
					})}
			</Heading>
			<Table
				head={historyTableHead}
				data={historyTableData}
				isLoading={historyLoading}
				emptyState={
					<Box className='empty_table_container'>
						<BiBriefcase size={42} />
						<Text fontSize='md' fontWeight='medium'>
							There is no history to this product.
						</Text>
					</Box>
				}
			/>
		</PageWrapper>
	);
};

export default InventoryHistory;
