import {
	Box,
	Button,
	Heading,
	Text,
	Link,
	Stack,
	Tabs,
	TabList,
	Tab,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	TabPanels,
	TabPanel,
	Flex,
	useColorModeValue,
	useToast,
	Skeleton,
} from "@chakra-ui/react";
import {
	Link as RouterLink,
	Navigate,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { PageWrapper } from "../../../utils/globalStyle";
import { ReactNode, useEffect, useState } from "react";
import ProductGeneralForm from "../Forms/ProductGeneralForm";
import { FormikErrors, useFormik } from "formik";
import {
	CreateProductMutationVariables,
	ProductType,
	useCreateProductMutation,
	useGetProductNameByIdQuery,
} from "../../../api/generated/graphql";
import { apolloClient } from "../../..";
import { getDataFromCache } from "../../../utils/cache";
import ProductOptionsForm from "../Forms/ProductOptionsForm";
import ProductVariationsForm from "../Forms/ProductVariationsForm";
import ProductInventoryForm from "../Forms/ProductInventoryForm";
import ProductFilesForm from "../Forms/ProductFilesForm";
import ProductSEOForm from "../Forms/ProductSEOForm";
import ProductRelatedForm from "../Forms/ProductRelatedForm";

const UpdateProduct = () => {
	const location = useLocation();

	const { from } = (location?.state as { from?: string }) || "";

	const { productID } = useParams();

	const storeID = getDataFromCache("store_id", true);

	const userRole: string = getDataFromCache("user_role", true);

	const [tabIndex, setTabIndex] = useState<number>(0);

	const [actionButtons, setActionButtons] = useState<ReactNode>();

	const getActionButtons = (node?: ReactNode) => {
		setActionButtons(node);
	};

	const toast = useToast();

	const navigate = useNavigate();

	const { data: productNameData, loading: productNameLoading } =
		useGetProductNameByIdQuery({
			variables: {
				product_id: productID,
			},
			onCompleted: (data) => {
				console.log(data);
			},
			onError: (error) => {
				toast({
					title: "An error occurred while fetching product.",
					description: error.message,
					status: "error",
					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
				navigate(`${storeID}/products`);
			},
		});

	const handleChangeIndex = (index: number) => {
		setTabIndex(index);
		setActionButtons(undefined);
	};

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Skeleton
							isLoaded={!productNameLoading}
							opacity={productNameLoading ? 0.4 : 1}>
							<Heading fontSize='2xl'>
								{productNameData?.GetProductById?.name}
							</Heading>
						</Skeleton>
					</Box>
				</Box>
				<Stack direction='row'>{actionButtons}</Stack>
			</Box>
			<Tabs
				index={tabIndex}
				colorScheme='blue'
				onChange={handleChangeIndex}
				isLazy>
				<TabList>
					<Tab>General</Tab>
					<Tab>Options</Tab>

					<Tab>Variations</Tab>

					<Tab>Inventory</Tab>

					<Tab>Files</Tab>

					<Tab>SEO</Tab>

					<Tab>Related Products</Tab>
				</TabList>
				<TabPanels>
					<TabPanel p='12px 0px'>
						<ProductGeneralForm
							actionButtons={getActionButtons}
							accessType='UPDATE'
							productID={productID}
						/>
					</TabPanel>
					<TabPanel p='12px 0px'>
						<ProductOptionsForm
							actionButtons={getActionButtons}
							productID={productID}
						/>
					</TabPanel>
					<TabPanel p='12px 0px'>
						<ProductVariationsForm
							actionButtons={getActionButtons}
							productID={productID}
						/>
					</TabPanel>
					<TabPanel p='12px 0px'>
						<ProductInventoryForm
							actionButtons={getActionButtons}
							productID={productID}
						/>
					</TabPanel>
					<TabPanel p='12px 0px'>
						<ProductFilesForm
							actionButtons={getActionButtons}
							productID={productID}
						/>
					</TabPanel>
					<TabPanel p='12px 0px'>
						<ProductSEOForm
							actionButtons={getActionButtons}
							productID={productID}
						/>
					</TabPanel>
					<TabPanel p='12px 0px'>
						<ProductRelatedForm
							actionButtons={getActionButtons}
							productID={productID}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</PageWrapper>
	);
};

export default UpdateProduct;
