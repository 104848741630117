import {
	Box,
	Button,
	Divider,
	FormLabel,
	Heading,
	Skeleton,
	Stack,
	Switch,
	Tag,
	Text,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apolloClient } from "../../..";
import {
	CreateProductMutationVariables,
	ProductType,
	useCreateProductMutation,
	useGetProductGeneralByIdQuery,
	useUpdateProductMutation,
} from "../../../api/generated/graphql";
import {
	CategorySelectInput,
	ImageUpload,
	Input,
	NumberInput,
	SelectInput,
	TagsSelectInput,
} from "../../../components";
import { getDataFromCache } from "../../../utils/cache";

const modules = {
	toolbar: [
		[{ header: "1" }, { header: "2" }, { font: [] }],
		[{ size: [] }],
		["bold", "italic", "underline", "strike", "blockquote"],
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" },
		],
		["link", "image"],
	],
	clipboard: {
		matchVisual: false,
	},
};

type ProductGeneralFormProps = {
	actionButtons: (node: ReactNode) => void;
	accessType?: "CREATE" | "UPDATE";
	productID?: string;
};

const ProductGeneralForm = (props: ProductGeneralFormProps) => {
	const { actionButtons, accessType = "CREATE", productID } = props;

	const borderColor = useColorModeValue("gray.100", "gray.700");

	const toast = useToast();

	const navigate = useNavigate();

	const storeID = getDataFromCache("store_id", true);

	const [files, setFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const handleFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append(
			"public_id",
			accessType === "CREATE"
				? `${storeID}/products/${file.name}`
				: `${storeID}/products/${productID}/${file.name}`
		);
		setFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleDelete = (id: string) => {
		console.log(
			id,
			files.filter(({ id: imageId }) => id !== imageId)
		);
		setFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	const { data: generalProductData, loading: generalProductLoading } =
		useGetProductGeneralByIdQuery({
			variables: {
				product_id: productID,
			},
			skip: !productID,
		});

	const [createProductMutation] = useCreateProductMutation();

	const [updateProductMutation] = useUpdateProductMutation();

	const generalProductInitialValues: Omit<
		CreateProductMutationVariables,
		"images" | "tags" | "price" | "store_id" | "category"
	> & {
		chargeAtTax: boolean;
		compareAtPrice: string;
		costPerItem: string;
		price: string;
		category: {
			id: string;
			label: string;
		};
		tags: string[];
	} = {
		name: "",
		description: "",
		type: ProductType.Physical,
		brand: "",
		category: {
			id: "",
			label: "",
		},
		isAvilable: true,
		chargeAtTax: false,
		compareAtPrice: "0.000",
		costPerItem: "0.000",
		price: "0.000",
		tags: [],
	};

	useEffect(() => {
		generalProductFormik.setValues({
			name: generalProductData?.GetProductById.name || "",
			description: generalProductData?.GetProductById.description || "",
			type:
				(generalProductData?.GetProductById?.type as ProductType) ||
				ProductType.Physical,
			brand: generalProductData?.GetProductById?.brand || "",
			category: {
				id: generalProductData?.GetProductById?.category?._id || "",
				label: generalProductData?.GetProductById?.category?.name || "",
			},
			isAvilable: generalProductData?.GetProductById.isAvilable ? true : false,
			chargeAtTax:
				generalProductData?.GetProductById.price?.chargeAtTax || false,
			compareAtPrice:
				generalProductData?.GetProductById.price?.compareAtPrice?.toString() ||
				"0.000",
			costPerItem:
				generalProductData?.GetProductById.price?.costPerItem?.toString() ||
				"0.000",
			price:
				generalProductData?.GetProductById.price?.price?.toString() || "0.000",
			tags: generalProductData?.GetProductById.tags || [],
		});
		setFiles(
			generalProductData?.GetProductById?.images?.map((item, index) => ({
				progress: false,
				id: index.toString(),
				src: item,
			})) || []
		);
	}, [generalProductData]);

	const generalProductFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: generalProductInitialValues,
		validate: (values) => {
			const errors: FormikErrors<typeof values> = {};
			if (!values.name) {
				errors.name = "Product name is required!";
			}
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			if (accessType === "CREATE") {
				createProductMutation({
					variables: {
						store_id: storeID,
						name: values.name,
						description: values.description || undefined,
						type: values.type || undefined,
						brand: values.brand || undefined,
						category: values.category.id || undefined,
						isAvilable: values.isAvilable,
						price: {
							chargeAtTax: values.chargeAtTax || undefined,
							compareAtPrice: parseFloat(values.compareAtPrice) || undefined,
							costPerItem: parseFloat(values.costPerItem) || undefined,
							price: parseFloat(values.price) || undefined,
						},
						images:
							files.map((files) => (files.src ? (files.src as string) : "")) ||
							undefined,
						tags: values.tags,
					},
					onCompleted: (data) => {
						if (data.CreateProduct?._id) {
							setSubmitting(false);
							toast({
								title: "Product has been created.",
								status: "success",

								position: "bottom-right",
								duration: 5000,
								isClosable: true,
							});
							apolloClient.refetchQueries({
								include: "active",
							});
							navigate(`/${storeID}/products/${data.CreateProduct?._id}`, {
								state: {
									from: `/${storeID}/products`,
									pageName: "Products",
								},
							});
						}
					},
					onError: (error) => {
						toast({
							title: "An error occured while creating product.",
							description: error.message,
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						setSubmitting(false);
					},
				});
			} else {
				updateProductMutation({
					variables: {
						product_id: productID,
						name: values.name,
						description: values.description || undefined,
						type: values.type || undefined,
						brand: values.brand || undefined,
						category: values.category.id || undefined,
						isAvilable: values.isAvilable,
						price: {
							chargeAtTax: values.chargeAtTax || undefined,
							compareAtPrice: parseFloat(values.compareAtPrice) || undefined,
							costPerItem: parseFloat(values.costPerItem) || undefined,
							price: parseFloat(values.price) || undefined,
						},
						images:
							files.map((files) => (files.src ? (files.src as string) : "")) ||
							undefined,
						tags: values.tags,
					},
					onCompleted: (data) => {
						if (data.UpdateProduct?._id) {
							setSubmitting(false);
							toast({
								title: "Product has been updated with success.",
								status: "success",

								position: "bottom-right",
								duration: 5000,
								isClosable: true,
							});
							apolloClient.refetchQueries({
								include: "active",
							});
						}
					},
					onError: (error) => {
						setSubmitting(false);
						toast({
							title: "An error occurred while updating product.",
							description: error.message,
							status: "error",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
					},
				});
			}
		},
	});

	const userRole: string = getDataFromCache("user_role", true);

	useEffect(() => {
		actionButtons(
			<>
				<Button
					variant='outline'
					onClick={() =>
						navigate(
							userRole === "ADMIN" ? `/admin/products` : `/${storeID}/products`,
							{
								state: {
									pageName: "Products",
								},
							}
						)
					}>
					Discard
				</Button>
				<Button
					colorScheme='main'
					type='submit'
					form='create-product-form'
					isLoading={generalProductFormik.isSubmitting}>
					Save
				</Button>
			</>
		);
	}, [generalProductFormik.isSubmitting]);

	return (
		<form id='create-product-form' onSubmit={generalProductFormik.handleSubmit}>
			<Stack direction='row' spacing='14px' alignItems='flex-start'>
				<Stack flex={0.75} spacing='12px'>
					<Skeleton
						isLoaded={!generalProductLoading}
						opacity={generalProductLoading ? 0.4 : 1}>
						<Stack
							padding='18px 18px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<Stack direction='row' spacing='18px'>
								<Input
									label='Product title'
									inputProps={{
										placeholder: "Title",
										name: "name",
										onChange: generalProductFormik.handleChange,
										value: generalProductFormik.values.name,
									}}
									errorMessage={generalProductFormik.errors.name}
									isError={generalProductFormik.errors.name !== undefined}
								/>
								<SelectInput
									formControlProps={{
										w: "420px",
									}}
									options={[
										{ label: "Physical", value: ProductType.Physical },
										{ label: "Digital", value: ProductType.Downloadable },
										{ label: "Physical & digital", value: ProductType.Mixed },
										{ label: "Service", value: ProductType.Service },
									]}
									label='Product type'
									selectProps={{
										name: "type",
										value: generalProductFormik.values.type || "",
										onChange: generalProductFormik.handleChange,
									}}
									errorMessage={generalProductFormik.errors.type}
									isError={generalProductFormik.errors.type !== undefined}
								/>
							</Stack>
							<Box>
								<FormLabel marginBottom='1'>Description</FormLabel>
								<ReactQuill
									value={generalProductFormik.values.description || ""}
									onChange={(content) =>
										generalProductFormik.setFieldValue("description", content)
									}
									modules={modules}
									placeholder='Describe this category'
								/>
							</Box>
						</Stack>
					</Skeleton>
					<Skeleton
						isLoaded={!generalProductLoading}
						opacity={generalProductLoading ? 0.4 : 1}>
						<Box
							padding='18px 18px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<ImageUpload
								height='180px'
								width={files.length === 0 ? "100%" : "180px"}
								images={files}
								handleUpload={handleFile}
								handleDelete={handleDelete}
								label='Product media'
								multiple
								gridColCount={5}
							/>
						</Box>
					</Skeleton>
					<Skeleton
						isLoaded={!generalProductLoading}
						opacity={generalProductLoading ? 0.4 : 1}>
						<Box
							padding='18px 18px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<Heading size='sm' fontWeight='semibold'>
								Pricing
							</Heading>

							<Stack direction='row' marginTop='12px' spacing='18px'>
								<NumberInput
									label='Price'
									numberInputProps={{
										allowMouseWheel: true,
										placeholder: "Price",
										name: "price",
										onChange: (vString, vNumber) =>
											generalProductFormik.setFieldValue("price", vString),
										value: generalProductFormik.values.price || 0,
										width: "100%",
										precision: 3,
									}}
									errorMessage={generalProductFormik.errors.price}
									isError={generalProductFormik.errors.price !== undefined}
									inputRightAddon={{ children: "TND" }}
								/>
								compareAtPrice: 0, costPerItem: 0,
								<NumberInput
									label='Compare at price'
									numberInputProps={{
										allowMouseWheel: true,
										placeholder: "Compare at price",
										name: "compareAtPrice",
										onChange: (vString, vNumber) =>
											generalProductFormik.setFieldValue(
												"compareAtPrice",
												vString
											),
										value: generalProductFormik.values.compareAtPrice || 0,
										width: "100%",
										precision: 3,
									}}
									errorMessage={generalProductFormik.errors.compareAtPrice}
									isError={
										generalProductFormik.errors.compareAtPrice !== undefined
									}
									inputRightAddon={{ children: "TND" }}
								/>
								<NumberInput
									label='Cost per item'
									numberInputProps={{
										allowMouseWheel: true,
										placeholder: "Cost per item",
										name: "costPerItem",
										onChange: (vString, vNumber) =>
											generalProductFormik.setFieldValue(
												"costPerItem",
												vString
											),
										value: generalProductFormik.values.costPerItem || 0,
										width: "100%",
										precision: 3,
									}}
									helperText="Customers won't see this"
									errorMessage={generalProductFormik.errors.costPerItem}
									isError={
										generalProductFormik.errors.costPerItem !== undefined
									}
									inputRightAddon={{ children: "TND" }}
								/>
							</Stack>
						</Box>
					</Skeleton>
				</Stack>
				<Stack flex={0.25} spacing='12px'>
					<Skeleton
						isLoaded={!generalProductLoading}
						opacity={generalProductLoading ? 0.4 : 1}>
						<Stack
							padding='18px 24px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<Input
								label='Brand'
								inputProps={{
									placeholder: "Brand",
									name: "brand",
									onChange: generalProductFormik.handleChange,
									value: generalProductFormik.values.brand || "",
								}}
								errorMessage={generalProductFormik.errors.brand}
								isError={generalProductFormik.errors.brand !== undefined}
							/>
							<CategorySelectInput
								selected={generalProductFormik.values.category}
								handleSelectCategory={(id, label) =>
									generalProductFormik.setFieldValue("category", { id, label })
								}
							/>
							<Box paddingTop='12px' paddingBottom='6px'>
								<Divider color={borderColor} />
							</Box>
							<TagsSelectInput
								tags={generalProductFormik.values.tags}
								onCreateTag={(value) =>
									generalProductFormik.setFieldValue("tags", [
										value,
										...generalProductFormik.values.tags,
									])
								}
								onDeleteTag={(index) => {
									let tempValues = [...generalProductFormik.values.tags];
									tempValues.splice(index, 1);
									generalProductFormik.setFieldValue("tags", tempValues);
								}}
							/>
						</Stack>
					</Skeleton>
					<Skeleton
						isLoaded={!generalProductLoading}
						opacity={generalProductLoading ? 0.4 : 1}>
						<Stack
							padding='18px 24px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<FormLabel>Product availability</FormLabel>
							<Stack direction='row' alignItems='center'>
								<Switch
									size='md'
									colorScheme='green'
									name='isAvilable'
									onChange={generalProductFormik.handleChange}
									isChecked={
										generalProductFormik.values.isAvilable !== null &&
										generalProductFormik.values.isAvilable
									}
								/>
								<Text
									color={
										generalProductFormik.values.isAvilable
											? "green.400"
											: undefined
									}>
									{generalProductFormik.values.isAvilable
										? "Available"
										: "Not available"}
								</Text>
							</Stack>
						</Stack>
					</Skeleton>
				</Stack>
			</Stack>
		</form>
	);
};

export default ProductGeneralForm;
