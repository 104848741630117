import {
	Box,
	FormControl,
	FormLabel,
	Heading,
	Stack,
	Textarea,
	useColorModeValue,
	Button,
	Text,
	Skeleton,
	useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { BiLinkExternal, BiRadioCircleMarked, BiReset } from "react-icons/bi";
import { useLocation, useParams } from "react-router-dom";
import { apolloClient } from "../..";
import {
	useGetAllCountriesQuery,
	useGetStoreSettingsInformationQuery,
	useUpdateStoreCompanyDataMutation,
	useUpdateStoreSettingsMutation,
} from "../../api/generated/graphql";
import {
	DropDownInput,
	ImageUpload,
	Input,
	SelectInput,
} from "../../components";
import SettingsWrapper from "../../components/SettingsWrapper/SettingsWrapper";
import { getDataFromCache } from "../../utils/cache";

const VendorStoreSettings = () => {
	const sectionBgColor = useColorModeValue("white", "gray.900");

	const { data: storeData, loading: storeDataLoading } =
		useGetStoreSettingsInformationQuery();

	const store = storeData?.GetCurrentSessionUserStores?.stores?.[0];

	const { data: countriesData, loading: countriesLoading } =
		useGetAllCountriesQuery();

	const [countriesList, setCountriesList] = useState<
		{
			render: ReactNode;
			value: string;
		}[]
	>([]);

	const [prefixList, setPrefixList] = useState<
		{
			render: ReactNode;
			value: string;
		}[]
	>([]);

	useEffect(() => {
		setCountriesList(
			countriesData?.GetAllCountries.map((country) => ({
				value: country.name || "",
				render: (
					<Stack direction='row' spacing='10px' alignItems='center'>
						<img
							className='select_country_image'
							src={country.flag || ""}
							alt={country.name || "countryflag"}
						/>{" "}
						<Text>{country.name}</Text>
					</Stack>
				),
			})) || []
		);
		setPrefixList(
			countriesData?.GetAllCountries.map((country) => ({
				value: country.dialCode || "",
				render: (
					<Stack direction='row' spacing='10px' alignItems='center'>
						<img
							className='select_country_image'
							src={country.flag || ""}
							alt={country.dialCode || "countryflag"}
						/>{" "}
						<Text>{country.dialCode}</Text>
					</Stack>
				),
			})) || []
		);
	}, [countriesData]);

	const storeID = getDataFromCache("store_id", true);

	const toast = useToast();

	const [coverFiles, setCoverFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const handleCoverFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append(
			"public_id",
			storeID
				? `${storeID}/cover/${file.name}`
				: `stores/misc/cover/${file.name}`
		);
		setCoverFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setCoverFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleCoverDelete = (id: string) => {
		setCoverFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	const [logoFiles, setLogoFiles] = useState<
		{
			progress: boolean;
			id: string;
			src: File | string | undefined;
		}[]
	>([]);

	const handleLogoFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append(
			"public_id",
			storeID ? `${storeID}/logo/${file.name}` : `stores/misc/logo/${file.name}`
		);
		setLogoFiles((prevFiles) => [
			...prevFiles,
			{
				src: file,
				id: file.name,
				progress: true,
				name: file.name,
				type: file.type,
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setLogoFiles((prevFiles) => [
			...prevFiles.filter(({ id }) => id !== file.name),
			{
				src: data.secure_url,
				id: data.asset_id,
				progress: false,
			},
		]);
	};

	const handleLogoDelete = (id: string) => {
		setLogoFiles((prevFiles) =>
			prevFiles.filter(({ id: imageId }) => id !== imageId)
		);
	};

	const renderStoreStatus = (status: string | null | undefined) => {
		switch (status) {
			case "DRAFT":
				return <Text color='yellow.400'>Review request</Text>;
			case "DECLINED":
				return <Text color='red.300'>Declined</Text>;
			case "PUBLISHED":
				return (
					<Stack
						direction='row'
						alignItems='center'
						spacing='2px'
						color='green.400'>
						<BiRadioCircleMarked />
						<Text> Online</Text>
					</Stack>
				);
		}
	};

	useEffect(() => {
		storeInfoFormik.setValues({
			name: storeData?.GetCurrentSessionUserStores?.stores?.[0]?.name || "",
			description:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.description || "",
			streetAddress:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.streetAddress || "",
			country:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.country || "",
			apartment:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.apartment || "",
			city:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.city || "",
			phonePrefix:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.phoneNumber?.prefix || "",
			phoneNumber:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.phoneNumber?.number || "",
			zipCode:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.zipCode || "",
			businessIndustry:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeKyc
					?.businessIndustry || "",
			companyName:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeKyc
					?.companyName || "",
			typeOfCompany:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeKyc
					?.typeOfCompany || "",
			website:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeKyc
					?.website || "",
		});
		setLogoFiles(
			storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeMedia?.logo
				? [
						{
							progress: false,
							id: storeData?.GetCurrentSessionUserStores?.stores?.[0]?._id,
							src: storeData?.GetCurrentSessionUserStores?.stores?.[0]
								?.storeMedia?.logo,
						},
				  ]
				: []
		);
		setCoverFiles(
			storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeMedia
				?.coverPicture
				? [
						{
							progress: false,
							id: storeData?.GetCurrentSessionUserStores?.stores?.[0]?._id,
							src: storeData?.GetCurrentSessionUserStores?.stores?.[0]
								?.storeMedia?.coverPicture,
						},
				  ]
				: []
		);
	}, [storeData]);

	const [updateStoreSettingsMutation] = useUpdateStoreSettingsMutation();

	const storeInfoFormInitialValues = {
		name: "",
		description: "",
		country: "",
		streetAddress: "",
		apartment: "",
		city: "",
		phonePrefix: "",
		phoneNumber: "",
		zipCode: "",
		companyName: "",
		typeOfCompany: "",
		website: "",
		businessIndustry: "",
	};

	const storeInfoFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: storeInfoFormInitialValues,
		onSubmit: (values, { setSubmitting }) => {
			updateStoreSettingsMutation({
				variables: {
					storeId: storeID,
					apartment: values.apartment || undefined,
					city: values.city || undefined,
					companyName: values.companyName || undefined,
					country: values.country || undefined,
					businessIndustry: values.businessIndustry || undefined,
					coverPicture: coverFiles?.[0]?.src
						? (coverFiles?.[0]?.src as string)
						: "",
					description: values.description || undefined,
					logo: logoFiles?.[0]?.src ? (logoFiles?.[0]?.src as string) : "",
					name: values.name || undefined,
					phoneNumber: values.phoneNumber || undefined,
					phonePrefix: values.phonePrefix || undefined,
					streetAddress: values.streetAddress || undefined,
					website: values.website || undefined,
					zipCode: values.zipCode || undefined,
				},
				onCompleted: (data) => {
					if (data.UpdateStore?._id) {
						setSubmitting(false);
						toast({
							title: "Settings has been updated with success.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while updating store settings.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	const handleResetForm = () => {
		storeInfoFormik.setValues({
			name: storeData?.GetCurrentSessionUserStores?.stores?.[0]?.name || "",
			description:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.description || "",
			streetAddress:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.streetAddress || "",
			country:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.country || "",
			apartment:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.apartment || "",
			city:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.city || "",
			phonePrefix:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.phoneNumber?.prefix || "",
			phoneNumber:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.phoneNumber?.number || "",
			zipCode:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeAddress
					?.zipCode || "",
			businessIndustry:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeKyc
					?.businessIndustry || "",
			companyName:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeKyc
					?.companyName || "",
			typeOfCompany:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeKyc
					?.typeOfCompany || "",
			website:
				storeData?.GetCurrentSessionUserStores?.stores?.[0]?.storeKyc
					?.website || "",
		});
	};

	return (
		<SettingsWrapper>
			<form onSubmit={storeInfoFormik.handleSubmit}>
				<Heading size='lg' fontSize='2xl' fontWeight='medium'>
					Store information
				</Heading>
				<Stack marginTop='24px' spacing='20px'>
					<Box
						p='16px'
						bgColor={sectionBgColor}
						width='100%'
						shadow='md'
						rounded='base'>
						<Heading size='md' fontSize='xl' fontWeight='medium'>
							General information
						</Heading>
						<Stack mt='4' direction='row' justifyContent='space-between'>
							<Button
								variant='link'
								fontWeight='normal'
								rightIcon={<BiLinkExternal />}>
								{`${process.env.REACT_APP_MARKETPLACE_URL}${store?.slug}`}
							</Button>
							<Stack direction='row' spacing='6px'>
								<Text opacity={0.8}>Status: </Text>
								<Text fontWeight='medium'>
									{renderStoreStatus(store?.status)}
								</Text>
							</Stack>
						</Stack>
						<Stack mt='2'>
							<Input
								label='Store name'
								inputProps={{
									placeholder: "Store name",
									name: "name",
									value: storeInfoFormik.values.name,
									onChange: storeInfoFormik.handleChange,
								}}
							/>
							<FormControl>
								<FormLabel marginBottom='1'>Description</FormLabel>
								<Textarea
									placeholder='Description'
									name='description'
									onChange={storeInfoFormik.handleChange}
									value={storeInfoFormik.values.description}
								/>
							</FormControl>
						</Stack>
					</Box>
					<Box
						p='16px'
						bgColor={sectionBgColor}
						width='100%'
						shadow='md'
						rounded='base'>
						<Heading size='md' fontSize='xl' fontWeight='medium'>
							Store media
						</Heading>

						<Stack mt='4' direction='row' spacing='16px'>
							<Box>
								<ImageUpload
									height='220px'
									width='220px'
									images={logoFiles}
									handleUpload={handleLogoFile}
									handleDelete={handleLogoDelete}
									label='Store logo'
								/>
							</Box>
							<Box flex={1}>
								<ImageUpload
									height='220px'
									width='100%'
									images={coverFiles}
									handleUpload={handleCoverFile}
									handleDelete={handleCoverDelete}
									label='Store Cover'
								/>
							</Box>
						</Stack>
					</Box>
					<Box
						p='16px'
						bgColor={sectionBgColor}
						width='100%'
						shadow='md'
						rounded='base'>
						<Heading size='md' fontSize='xl' fontWeight='medium'>
							Address settings
						</Heading>
						<Stack mt='4'>
							<Skeleton isLoaded={!storeDataLoading}>
								<Stack direction='row' spacing='18px'>
									<Input
										label='Address'
										inputProps={{
											placeholder: "Address",
											name: "streetAddress",
											onChange: storeInfoFormik.handleChange,
											value: storeInfoFormik.values.streetAddress || "",
										}}
										errorMessage={storeInfoFormik.errors.streetAddress}
										isError={storeInfoFormik.errors.streetAddress !== undefined}
									/>
									<Input
										label='Apartment, suite, etc.'
										inputProps={{
											placeholder: "Apartment, suite, etc.",
											name: "apartment",
											onChange: storeInfoFormik.handleChange,
											value: storeInfoFormik.values.apartment,
										}}
										errorMessage={storeInfoFormik.errors.apartment}
										isError={storeInfoFormik.errors.apartment !== undefined}
									/>
								</Stack>
							</Skeleton>

							<Skeleton isLoaded={!storeDataLoading}>
								<Stack direction='row' spacing='16px'>
									<DropDownInput
										label='Country'
										placeholder='Country'
										name='country'
										selected={storeInfoFormik.values.country}
										errorMessage={storeInfoFormik.errors.country}
										list={countriesList}
										isLoading={countriesLoading}
										onSelect={(value) =>
											storeInfoFormik.setFieldValue("country", value)
										}
									/>
									<Input
										label='City'
										inputProps={{
											placeholder: "City",
											name: "city",
											onChange: storeInfoFormik.handleChange,
											value: storeInfoFormik.values.city,
										}}
										errorMessage={storeInfoFormik.errors.city}
										isError={storeInfoFormik.errors.city !== undefined}
									/>
								</Stack>
							</Skeleton>
							<Skeleton isLoaded={!storeDataLoading}>
								<Stack direction='row' spacing='16px'>
									<Input
										label='Phone number'
										inputProps={{
											placeholder: "Phone number",
											type: "number",
											name: "phoneNumber",
											onChange: storeInfoFormik.handleChange,
											value: storeInfoFormik.values.phoneNumber,
										}}
										errorMessage={
											storeInfoFormik.errors.phoneNumber ||
											storeInfoFormik.errors.phonePrefix
										}
										isError={
											storeInfoFormik.errors.phoneNumber !== undefined ||
											storeInfoFormik.errors.phonePrefix !== undefined
										}
										inputLeftAddon={{
											background: "transparent",
											p: 0,
											border: "none",
											children: (
												<Box w='100px'>
													<DropDownInput
														name='phonePrefix'
														placeholder='Prefix'
														selected={storeInfoFormik.values.phonePrefix}
														list={prefixList}
														isLoading={countriesLoading}
														onSelect={(value) =>
															storeInfoFormik.setFieldValue(
																"phonePrefix",
																value
															)
														}
													/>
												</Box>
											),
										}}
									/>
									<Input
										label='Zip/Postal code'
										inputProps={{
											placeholder: "Zip/Postal code",
											name: "zipCode",
											value: storeInfoFormik.values.zipCode,
											onChange: storeInfoFormik.handleChange,
										}}
										errorMessage={storeInfoFormik.errors.zipCode}
										isError={storeInfoFormik.errors.zipCode !== undefined}
									/>
								</Stack>
							</Skeleton>
						</Stack>
					</Box>
					<Box
						p='16px'
						bgColor={sectionBgColor}
						width='100%'
						shadow='md'
						rounded='base'>
						<Heading size='md' fontSize='xl' fontWeight='medium'>
							Other info
						</Heading>

						<Stack mt='4'>
							<Stack direction='row' spacing='14px'>
								<Input
									label='Company name'
									inputProps={{
										placeholder: "Company name",
										name: "companyName",
										value: storeInfoFormik.values.companyName,
										onChange: storeInfoFormik.handleChange,
									}}
									errorMessage={storeInfoFormik.errors.companyName}
									isError={storeInfoFormik.errors.companyName !== undefined}
								/>
								<Input
									label='Company type'
									inputProps={{
										placeholder: "Company type",
										name: "typeOfCompany",
										value: storeInfoFormik.values.typeOfCompany,
										onChange: storeInfoFormik.handleChange,
									}}
									errorMessage={storeInfoFormik.errors.typeOfCompany}
									isError={storeInfoFormik.errors.typeOfCompany !== undefined}
								/>
							</Stack>
							<Stack direction='row' spacing='14px'>
								<Input
									label='Website'
									inputProps={{
										placeholder: "Website (Optional)",
										name: "website",
										value: store?.storeKyc?.website || "",
										onChange: storeInfoFormik.handleChange,
									}}
									errorMessage={storeInfoFormik.errors.website}
									isError={storeInfoFormik.errors.website !== undefined}
								/>
								<SelectInput
									label='Business industry'
									options={[
										{
											label: "Clothing",
										},
										{
											label: "Electronics",
										},
										{
											label: "Furniture",
										},
										{
											label: "Handcrafts",
										},
										{
											label: "Jewelry",
										},
										{
											label: "Painting",
										},
										{
											label: "Photography",
										},
										{
											label: "Restaurants",
										},
										{
											label: "Groceries",
										},
										{
											label: "Other food & drink",
										},
										{
											label: "Sports",
										},
										{
											label: "Toys",
										},
										{
											label: "Services",
										},
										{
											label: "Virtual services",
										},
										{
											label: "Other",
										},
									]}
									selectProps={{
										placeholder: "Please choose...",
										value: storeInfoFormik.values.businessIndustry || "",
										name: "businessIndustry",
										onChange: storeInfoFormik.handleChange,
									}}
								/>
							</Stack>
						</Stack>
					</Box>
					<Box textAlign='right'>
						<Button
							onClick={handleResetForm}
							leftIcon={<BiReset size={18} />}
							mr={4}>
							Reset
						</Button>
						<Button
							colorScheme='main'
							type='submit'
							isLoading={storeInfoFormik?.isSubmitting}>
							Save changes
						</Button>
					</Box>
				</Stack>
			</form>
		</SettingsWrapper>
	);
};

export default VendorStoreSettings;
