import {
	FormLabel as ChakraFormLabel,
	FormControl as ChakraFormControl,
	FormControlProps,
	FormLabelProps,
	SelectProps,
	Select,
	Box,
	FormErrorMessage,
	FormHelperText,
} from "@chakra-ui/react";
import Input from "../Input/Input";

type SelectInputProps = {
	formControlProps?: Omit<FormControlProps, "isInvalid">;
	label?: string;
	isError?: boolean;
	formLabelProps?: FormLabelProps;
	selectProps?: SelectProps;
	options: { value?: string; label: string }[];
	errorMessage?: string;
	helperText?: string;
};

const SelectInput = (props: SelectInputProps) => {
	const {
		formControlProps,
		label,
		isError,
		formLabelProps,
		selectProps,
		options,
		errorMessage,
		helperText,
	} = props;

	return (
		<ChakraFormControl {...formControlProps} isInvalid={isError}>
			{label && (
				<ChakraFormLabel {...formLabelProps} marginBottom='1'>
					{label}
				</ChakraFormLabel>
			)}
			<Select {...selectProps}>
				{options.map((option, index) => (
					<option
						key={index}
						value={option.value ? option.value : option.label}>
						{option.label}
					</option>
				))}
			</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			{errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
		</ChakraFormControl>
	);
};

export default SelectInput;
