import {
	Box,
	Button,
	Heading,
	Text,
	Link,
	Stack,
	Tabs,
	TabList,
	Tab,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	TabPanels,
	TabPanel,
	Flex,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";
import {
	Link as RouterLink,
	Navigate,
	useLocation,
	useNavigate,
} from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { PageWrapper } from "../../../utils/globalStyle";
import { ReactNode, useState } from "react";
import ProductGeneralForm from "../Forms/ProductGeneralForm";
import { FormikErrors, useFormik } from "formik";
import {
	CreateProductMutationVariables,
	ProductType,
	useCreateProductMutation,
} from "../../../api/generated/graphql";
import { apolloClient } from "../../..";
import { getDataFromCache } from "../../../utils/cache";

const CreateProduct = () => {
	const location = useLocation();

	const { from } = (location?.state as { from?: string }) || "";

	const [tabIndex, setTabIndex] = useState<number>(0);

	const [actionButtons, setActionButtons] = useState<ReactNode>();

	const getActionButtons = (node?: ReactNode) => {
		setActionButtons(node);
	};

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Heading fontSize='2xl'>Create Product</Heading>
					</Box>
				</Box>
				<Stack direction='row'>{actionButtons}</Stack>
			</Box>
			<Tabs index={tabIndex} colorScheme='blue' isLazy>
				<TabList>
					<Tab>General</Tab>
					<Popover>
						<PopoverTrigger>
							<Tab px={4}>Options</Tab>
						</PopoverTrigger>

						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Hold on!</PopoverHeader>
							<PopoverBody>
								Save product general settings to access their options.
							</PopoverBody>
						</PopoverContent>
					</Popover>
					<Popover>
						<PopoverTrigger>
							<Tab px={4}>Variations</Tab>
						</PopoverTrigger>

						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Hold on!</PopoverHeader>
							<PopoverBody>
								Save product general settings to access their variations.
							</PopoverBody>
						</PopoverContent>
					</Popover>
					<Popover>
						<PopoverTrigger>
							<Tab px={4}>Inventory</Tab>
						</PopoverTrigger>

						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Hold on!</PopoverHeader>
							<PopoverBody>
								Save product general settings to access their inventory.
							</PopoverBody>
						</PopoverContent>
					</Popover>
					<Popover>
						<PopoverTrigger>
							<Tab px={4}>Files</Tab>
						</PopoverTrigger>

						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Hold on!</PopoverHeader>
							<PopoverBody>
								Save product general settings to access their files.
							</PopoverBody>
						</PopoverContent>
					</Popover>
					<Popover>
						<PopoverTrigger>
							<Tab px={4}>SEO</Tab>
						</PopoverTrigger>

						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Hold on!</PopoverHeader>
							<PopoverBody>
								Save product general settings to access their SEO.
							</PopoverBody>
						</PopoverContent>
					</Popover>
					<Popover>
						<PopoverTrigger>
							<Tab px={4}>Related Products</Tab>
						</PopoverTrigger>

						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Hold on!</PopoverHeader>
							<PopoverBody>
								Save product general settings to access their Related Products.
							</PopoverBody>
						</PopoverContent>
					</Popover>
					{/* <Popover>
						<PopoverTrigger>
							<Tab px={4}>Related products</Tab>
						</PopoverTrigger>

						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Hold on!</PopoverHeader>
							<PopoverBody>
								Save product general settings to access their related products.
							</PopoverBody>
						</PopoverContent>
					</Popover> */}
				</TabList>
				<TabPanels>
					<TabPanel p='12px 0px'>
						<ProductGeneralForm actionButtons={getActionButtons} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</PageWrapper>
	);
};

export default CreateProduct;
