import { Box, Heading, LinkOverlay, useColorModeValue } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { NavItemProps } from "./NavItem.types";
import { NavItemWrapper } from "./NavItems.styles";

const NavItem: React.FC<NavItemProps> = (props) => {
	const { href, Icon, label, selected } = props;

	const location = useLocation();

	const selectedHeadingColor = useColorModeValue("main.300", "main.200");
	const headingColor = useColorModeValue("gray.600", "gray.300");
	const hoverBgColor = useColorModeValue("gray.100", "gray.800");

	return (
		<NavItemWrapper
			position='relative'
			display='flex'
			alignItems='center'
			_hover={{ backgroundColor: hoverBgColor, cursor: "pointer" }}>
			<Heading
				className='navContent'
				size='sm'
				fontWeight='semibold'
				display='flex'
				alignItems='center'
				color={selected ? selectedHeadingColor : headingColor}>
				{Icon}
				<LinkOverlay
					as={RouterLink}
					to={href}
					state={{ pageName: label, from: location.pathname }}>
					{label}
				</LinkOverlay>
			</Heading>
			{selected && (
				<Box className='selectedNavigation' bg={selectedHeadingColor} />
			)}
		</NavItemWrapper>
	);
};

export default NavItem;
