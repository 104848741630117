import { Box } from "@chakra-ui/react";
import { FC } from "react";
import {
	BiArchiveIn,
	BiBriefcaseAlt,
	BiCog,
	BiCube,
	BiFace,
	BiGridAlt,
	BiLayer,
	BiPhotoAlbum,
	BiStore,
	BiTransferAlt,
} from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { AppBar } from "..";
import { getDataFromCache } from "../../../utils/cache";
import FullPageLoad from "../FullPageLoad/FullPageLoad";
import Navigation from "../Navigation/Navigation";
import { NavigationPropsType } from "../Navigation/Navigation.types";
import { LayoutWrapper } from "./Layout.styles";

const storeID = getDataFromCache("store_id", true);

const getNavigation = (
	role: "ADMIN" | "VENDOR"
): NavigationPropsType["items"] => {
	switch (role) {
		case "ADMIN":
			return [
				{
					Icon: <BiBriefcaseAlt size='18' />,
					href: "/admin/products",
					label: "Products",
				},
				{
					Icon: <BiArchiveIn size='18' />,
					href: "/admin/orders",
					label: "Orders",
				},
				{
					Icon: <BiLayer size='18' />,
					href: "/admin/categories",
					label: "Categories",
				},
				{
					Icon: <BiCube size='18' />,
					href: "/admin/inventory",
					label: "Inventory",
				},
				{
					Icon: <BiStore size='18' />,
					href: "/admin/stores",
					label: "Stores",
				},
				{
					Icon: <BiFace size='18' />,
					href: "/admin/customers",
					label: "Customers",
				},
				"SEPERATOR",
				// {
				// 	Icon: <BiPhotoAlbum size='18' />,
				// 	href: "/admin/media-library",
				// 	label: "Media library",
				// },
				{
					Icon: <BiTransferAlt size='18' />,
					href: "/admin/payout",
					label: "Pay-out",
				},
				{
					Icon: <BiCog size='18' />,
					href: "/admin/settings",
					label: "Settings",
				},
			];
		case "VENDOR":
			return [
				{
					Icon: <BiGridAlt size='18' />,
					href: `/${storeID}/dashboard`,
					label: "Dashboard",
				},
				"SEPERATOR",
				{
					Icon: <BiBriefcaseAlt size='18' />,
					href: `/${storeID}/products`,
					label: "Products",
				},
				{
					Icon: <BiArchiveIn size='18' />,
					href: `/${storeID}/orders`,
					label: "Orders",
				},
				{
					Icon: <BiCube size='18' />,
					href: `/${storeID}/inventory`,
					label: "Inventory",
				},
				"SEPERATOR",

				// {
				// 	Icon: <BiPhotoAlbum size='18' />,
				// 	href: `/${storeID}/media-library`,
				// 	label: "Media library",
				// },
				{
					Icon: <BiCog size='18' />,
					href: `/${storeID}/settings`,
					label: "Settings",
				},
			];
	}
};

const Layout: FC<{ role: "ADMIN" | "VENDOR" }> = (props) => {
	const { role, children } = props;

	const location = useLocation();

	const navList = getNavigation(role);

	if (location.pathname.includes("settings")) return <Box>{children}</Box>;

	return (
		<LayoutWrapper>
			<Navigation items={navList} />
			<Box className='app_container'>
				<AppBar />
				<Box className='app_content'>{children}</Box>
			</Box>
		</LayoutWrapper>
	);
};

export default Layout;
