import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
	colors: {
		main: {
			50: "#FEE6E9",
			100: "#FCBAC2",
			200: "#FB8E9C",
			300: "#F96275",
			400: "#F7364E",
			500: "#F50A27",
			600: "#C4081F",
			700: "#930617",
			800: "#62040F",
			900: "#310208",
		},
		blue: {
			50: "#e0f1ff",
			100: "#b2d2ff",
			200: "#83b4fc",
			300: "#5396f7",
			400: "#2578f4",
			500: "#0b5eda",
			600: "#0349ab",
			700: "#00347b",
			800: "#001f4d",
			900: "#000a1f",
		},
		status: {
			error: "#F9515E",
			success: "#17E068",
		},
		black: "#191919",
		white: "#ffffff",
		error: {
			50: "#FFE5E5",
			100: "#FFB8B8",
			200: "#FF8A8A",
			300: "#FF5C5C",
			400: "#FF2E2E",
			500: "#FF0000",
			600: "#CC0000",
			700: "#990000",
			800: "#660000",
			900: "#330000",
		},
		// gray: {
		// 	50: "#F1F2F3",
		// 	100: "#D9DBDE",
		// 	200: "#C0C4C9",
		// 	300: "#A7ADB4",
		// 	400: "#8E969E",
		// 	500: "#767F89",
		// 	600: "#5E666E",
		// 	700: "#474D52",
		// 	800: "#2F3337",
		// 	900: "#181A1B",
		// },
	},
	fonts: {
		heading: "Outfit, sans-serif",
		body: "Outfit, sans-serif",
	},
	config: {
		initialColorMode: "light",
		useSystemColorMode: false,
	},
});
