import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FullPageLoad } from "../components";
import {
	Customers,
	Categories,
	Products,
	Stores,
	Inventory,
	InventoryHistory,
	AdminCreateStore,
	ProfileSettings,
	AccountSecuritySettings,
	HomePageSettings,
	DesignSettings,
	PoliciesSettings,
	Orders,
	OrderDetails,
	Payout,
	UpdateProduct,
} from "../pages";

const Layout = lazy(() => import("../components/modules/Layout/Layout"));

const AdminRoutes = () => {
	return (
		<Suspense fallback={<FullPageLoad message='Accessing your dashboard' />}>
			<Layout role='ADMIN'>
				<Routes>
					<Route path='/'>
						<Route index element={<Navigate to='/admin' replace />} />
						<Route path='admin'>
							<Route
								index
								element={<Navigate to='/admin/products' replace />}
							/>

							<Route path='products'>
								<Route index element={<Products />} />
								<Route path=':productID' element={<UpdateProduct />} />
							</Route>
							<Route path='stores'>
								<Route index element={<Stores />} />
								<Route path='create' element={<AdminCreateStore />} />
								<Route path=':storeID' element={<AdminCreateStore />} />
							</Route>
							<Route path='inventory'>
								<Route index element={<Inventory />} />
								<Route path=':productID'>
									<Route index element={<InventoryHistory />} />
									<Route
										path='variant/:variantID'
										element={<InventoryHistory />}
									/>
								</Route>
							</Route>
							<Route path='customers' element={<Customers />} />
							<Route path='categories' element={<Categories />} />
							<Route path='orders'>
								<Route index element={<Orders />} />

								<Route path=':orderID' element={<OrderDetails />} />
							</Route>
							<Route path='payout' element={<Payout />} />
							<Route path='settings'>
								<Route
									index
									element={<Navigate to={`/admin/settings/profile`} replace />}
								/>

								<Route path='profile' element={<ProfileSettings />} />
								<Route path='security' element={<AccountSecuritySettings />} />
								<Route path='home' element={<HomePageSettings />} />
								<Route path='design' element={<DesignSettings />} />
								<Route path='policies' element={<PoliciesSettings />} />
							</Route>
						</Route>
						<Route path='*' element={<Navigate to='/admin' replace />} />
					</Route>
				</Routes>
			</Layout>
		</Suspense>
	);
};

export default AdminRoutes;
