import {
	Box,
	Text,
	Divider,
	FormLabel,
	Heading,
	Skeleton,
	Stack,
	useColorModeValue,
	Button,
	Switch,
	useToast,
	IconButton,
	Spinner,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { apolloClient } from "../../..";
import {
	UpdateProductsFilesByIdMutationVariables,
	useGetProductFilesByIdQuery,
	useUpdateProductsFilesByIdMutation,
} from "../../../api/generated/graphql";
import {
	CategorySelectInput,
	Input,
	Table,
	TableHeadType,
	TagsSelectInput,
} from "../../../components";
import { getDataFromCache } from "../../../utils/cache";

type ProductFilesFormProps = {
	productID?: string;
	actionButtons: (node: ReactNode) => void;
};

const bytesToSize = (bytes: number) => {
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	if (bytes === 0) return "n/a";
	const i = parseInt(
		Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
		10
	);
	if (i === 0) return `${bytes} ${sizes[i]}`;
	return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

const getExtension = (basename: string) => {
	const pos = basename.lastIndexOf(".");

	if (basename === "" || pos < 1) {
		return {
			fileName: "Unknown",
			fileExt: "Unknown",
		};
	}

	const fileName = basename.substring(0, pos);

	const fileExt = basename.substring(pos + 1, basename.length);

	return {
		fileName,
		fileExt,
	};
};

const ProductFilesForm = (props: ProductFilesFormProps) => {
	const { productID, actionButtons } = props;

	const borderColor = useColorModeValue("gray.100", "gray.700");

	const toast = useToast();

	const storeID = getDataFromCache("store_id", true);

	const [files, setFiles] = useState<
		{
			fileName: string;
			description: string;
			source: string;
			extension: string;
			size: string;
		}[]
	>([]);

	const { data: filesProductData, loading: filesProductLoading } =
		useGetProductFilesByIdQuery({
			variables: {
				product_id: productID,
			},
			skip: !productID,
		});

	useEffect(() => {
		setFiles(
			filesProductData?.GetProductById.files.map((file) => ({
				description: file.description || "",
				extension: file.extension || "",
				fileName: file.fileName || "",
				size: file.size || "",
				source: file.source || "",
			})) || []
		);
		filesProductFormik.setValues({
			brand: filesProductData?.GetProductById?.brand || "",
			isAvilable: filesProductData?.GetProductById?.isAvilable,
			category: {
				id: filesProductData?.GetProductById?.category?._id || "",
				label: filesProductData?.GetProductById?.category?.name || "",
			},
			tags: filesProductData?.GetProductById.tags || [],
		});
	}, [filesProductData]);

	const filesProductInitialValues: Omit<
		UpdateProductsFilesByIdMutationVariables,
		"tags" | "product_id" | "category" | "files"
	> & {
		category: {
			id: string;
			label: string;
		};
		tags: string[];
	} = {
		brand: "",
		isAvilable: true,
		category: {
			id: "",
			label: "",
		},
		tags: [],
	};

	const [updateFilesMutation] = useUpdateProductsFilesByIdMutation();

	const filesProductFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: filesProductInitialValues,
		// validate: (values) => {
		// 	const errors: FormikErrors<typeof values> = {};
		// 	if (!values.name) {
		// 		errors.name = "Product name is required!";
		// 	}
		// 	return errors;
		// },
		onSubmit: (values, { setSubmitting }) => {
			updateFilesMutation({
				variables: {
					product_id: productID,
					brand: values.brand || undefined,
					category: values.category.id || undefined,
					isAvilable: values.isAvilable,
					tags: values.tags || undefined,
					files: files,
				},
				onCompleted: (data) => {
					if (data.UpdateProduct?._id) {
						setSubmitting(false);
						toast({
							title: "Product inventory has been updated with success.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while updating product.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	const userRole: string = getDataFromCache("user_role", true);

	const navigate = useNavigate();

	useEffect(() => {
		actionButtons(
			<>
				<Button
					variant='outline'
					onClick={() =>
						navigate(
							userRole === "ADMIN" ? `/admin/products` : `/${storeID}/products`,
							{
								state: {
									pageName: "Products",
								},
							}
						)
					}>
					Discard
				</Button>
				<Button
					colorScheme='main'
					type='submit'
					form='update-product-files'
					isLoading={filesProductFormik.isSubmitting}>
					Save
				</Button>
			</>
		);
	}, [filesProductFormik.isSubmitting]);

	const filesTableData =
		files.map((file, index) => ({
			fileName: file?.fileName,
			// description: file?.description,
			extension: file?.extension,
			size: file.size,
			action:
				file.source !== "UPLOADING" ? (
					<IconButton
						aria-label='delete-option-button'
						colorScheme='error'
						variant='ghost'
						onClick={() => handleDeleteFile(index)}>
						<BiTrash size={18} />
					</IconButton>
				) : (
					<Stack
						direction='row'
						justifyContent='flex-end'
						alignItems='center'
						color='blue.400'>
						<Spinner size='sm' />
						<Text>Uploading...</Text>
					</Stack>
				),
		})) || [];

	type FilesTableData = typeof filesTableData[number];

	const filesTableHead: TableHeadType<FilesTableData>[] = [
		{ accessor: "fileName", label: "File name" },
		{ accessor: "extension", label: "Extension" },
		{ accessor: "size", label: "Size" },
		// { accessor: "status", label: "Status" },
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	const handleUploadFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "pn8kkadk");
		formData.append(
			"public_id",
			`${storeID}/products/${productID}/files/${file.name}`
		);
		setFiles((prevFiles) => [
			...prevFiles,
			{
				description: "",
				extension: getExtension(file.name).fileExt,
				fileName: getExtension(file.name).fileName,
				size: bytesToSize(file.size).toString(),
				source: "UPLOADING",
			},
		]);
		const data = await (
			await fetch("https://api.cloudinary.com/v1_1/market-dashboard/upload", {
				method: "POST",
				body: formData,
			})
		).json();

		setFiles((prevFiles) => [
			...prevFiles.filter(
				({ fileName }) => fileName !== getExtension(file.name).fileName
			),
			{
				description: "",
				extension: getExtension(file.name).fileExt,
				fileName: getExtension(file.name).fileName,
				size: bytesToSize(file.size).toString(),
				source: data.secure_url,
			},
		]);
	};

	const handleDeleteFile = (index: number) => {
		setFiles((prevState) => {
			const temp = [...prevState];
			temp.splice(index, 1);
			return temp;
		});
	};

	return (
		<Stack direction='row' spacing='14px' alignItems='flex-start'>
			<Stack flex={0.75} spacing='12px'>
				<Stack
					padding='18px 18px'
					border='1px'
					borderColor={borderColor}
					rounded='base'>
					<Stack width='full' direction='row' justifyContent='space-between'>
						<Box>
							<Heading size='md' mb='2'>
								Files
							</Heading>
							<Text opacity={0.6} lineHeight='1.2' fontSize='sm' width='650px'>
								Upload files for your customers to download when once the order
								receives status Paid.
							</Text>
						</Box>
						<UploadFileButton handleUpload={handleUploadFile} multiple />
					</Stack>
				</Stack>
				<Table
					head={filesTableHead}
					data={filesTableData}
					isLoading={filesProductLoading}
					emptyState={
						<Box className='empty_table_container'>
							<Text fontSize='md' fontWeight='medium'>
								Product has no files
							</Text>
						</Box>
					}
				/>
			</Stack>
			<Stack flex={0.25} spacing='12px'>
				<form
					id='update-product-files'
					onSubmit={filesProductFormik.handleSubmit}>
					<Skeleton
						isLoaded={!filesProductLoading}
						opacity={filesProductLoading ? 0.4 : 1}>
						<Stack
							padding='18px 24px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<Input
								label='Brand'
								inputProps={{
									placeholder: "Brand",
									name: "brand",
									onChange: filesProductFormik.handleChange,
									value: filesProductFormik.values.brand || "",
								}}
								errorMessage={filesProductFormik.errors.brand}
								isError={filesProductFormik.errors.brand !== undefined}
							/>
							<CategorySelectInput
								selected={filesProductFormik.values.category}
								handleSelectCategory={(id, label) =>
									filesProductFormik.setFieldValue("category", {
										id,
										label,
									})
								}
							/>
							<Box paddingTop='12px' paddingBottom='6px'>
								<Divider color={borderColor} />
							</Box>
							<TagsSelectInput
								tags={filesProductFormik.values.tags}
								onCreateTag={(value) =>
									filesProductFormik.setFieldValue("tags", [
										value,
										...filesProductFormik.values.tags,
									])
								}
								onDeleteTag={(index) => {
									let tempValues = [...filesProductFormik.values.tags];
									tempValues.splice(index, 1);
									filesProductFormik.setFieldValue("tags", tempValues);
								}}
							/>
						</Stack>
					</Skeleton>
					<Skeleton
						isLoaded={!filesProductLoading}
						opacity={filesProductLoading ? 0.4 : 1}>
						<Stack
							padding='18px 24px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<FormLabel>Product availability</FormLabel>
							<Stack direction='row' alignItems='center'>
								<Switch
									size='md'
									colorScheme='green'
									name='isAvilable'
									onChange={filesProductFormik.handleChange}
									isChecked={
										filesProductFormik.values.isAvilable !== null &&
										filesProductFormik.values.isAvilable
									}
								/>
								<Text
									color={
										filesProductFormik.values.isAvilable
											? "green.400"
											: undefined
									}>
									{filesProductFormik.values.isAvilable
										? "Available"
										: "Not available"}
								</Text>
							</Stack>
						</Stack>
					</Skeleton>
				</form>
			</Stack>
		</Stack>
	);
};

export default ProductFilesForm;

type UploadFileButtonProps = {
	multiple?: boolean;
	accept?: string | string[];
	maxFiles?: number;
	handleUpload: (file: File) => void;
};

const UploadFileButton = (props: UploadFileButtonProps) => {
	const { multiple, accept, maxFiles, handleUpload } = props;

	const onDrop = useCallback((acceptedFiles: Array<File>) => {
		acceptedFiles.forEach((file: File) => {
			handleUpload(file);
		});
	}, []);

	const { getRootProps, getInputProps, fileRejections } = useDropzone({
		onDrop,
		multiple,
		accept,
		maxFiles,
	});

	return (
		<Box {...getRootProps()}>
			<input {...getInputProps()} />
			<Button
				// onClick={onOpenOptionModal}
				colorScheme='main'
				size='sm'>
				Upload new file
			</Button>
		</Box>
	);
};
