import {
	Box,
	Link,
	Heading,
	Text,
	Stack,
	useColorModeValue,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Spinner,
	Button,
	useToast,
	Divider,
	FormControl,
	FormLabel,
	Textarea,
	Skeleton,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { BiArrowBack, BiChevronDown, BiMap, BiMoney } from "react-icons/bi";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { apolloClient } from "../../..";
import {
	ItemStatusType,
	useAddOrderNoteMutation,
	useGetOrderByIdQuery,
	useSetOrderItemStatusMutation,
	useUpdateOrderStatusMutation,
} from "../../../api/generated/graphql";
import { picturePlaceholder, profilePicturePlaceholder } from "../../../assets";
import { getDataFromCache } from "../../../utils/cache";
import { PageWrapper } from "../../../utils/globalStyle";

const OrderDetails = () => {
	const { orderID } = useParams();

	const location = useLocation();

	const { from } = (location?.state as { from?: string }) || "";

	const userRole = getDataFromCache("user_role", true);

	const storeID = getDataFromCache("store_id", true);

	const wrapperBorder = useColorModeValue("gray.200", "gray.600");

	const { data: orderDetailData, loading: orderDetailLoading } =
		useGetOrderByIdQuery({
			variables: {
				orderID,
				storeID: storeID || undefined,
			},
		});

	const renderItemStatus = (status: string | null | undefined) => {
		switch (status) {
			case "READY":
				return {
					text: "Ready",
					color: "green",
				};
			case "NOTREADY":
				return {
					text: "Not ready",
					color: "red",
				};
			default:
				return {
					text: status,
					color: "black",
				};
		}
	};

	const renderPaymentStatus = (status: string | null | undefined) => {
		switch (status) {
			case "PENDING":
				return {
					text: "Awaiting payment",
					color: "yellow",
				};
			case "SUCCESS":
				return {
					text: "Paid",
					color: "green",
				};
			case "FAILED":
				return {
					text: "Payment failed",
					color: "red",
				};
			case "REFUNDED":
				return {
					text: "Refunded",
					color: "blue",
				};
			case "COMPLETED":
				return {
					text: "Payment completed",
					color: "green",
				};
			default:
				return {
					text: status,
					color: "black",
				};
		}
	};

	const renderOrderStatus = (status: string | null | undefined) => {
		switch (status) {
			case "PENDING":
				return {
					text: "Awaiting processing",
					color: "blue",
				};
			case "PROCESSING":
				return {
					text: "Processing",
					color: "blue",
				};
			case "CONFIRMED":
				return {
					text: "Confirmed",
					color: "green",
				};
			case "READY":
				return {
					text: "Ready for delivery",
					color: "green",
				};
			case "TRANSPORTING":
				return {
					text: "In Transportation",
					color: "blue",
				};
			case "COMPLETED":
				return {
					text: "Order completed",
					color: "green",
				};
			case "CANCELED":
				return {
					text: "Order canceled",
					color: "red",
				};
			case "REFUNDED":
				return {
					text: "Order refunded",
					color: "red",
				};
			case "FAILED":
				return {
					text: "Order failed",
					color: "red",
				};
			default:
				return {
					text: status,
					color: "black",
				};
		}
	};

	const toast = useToast();

	const [changeOrderStatusMutation, changeOrderStatusParams] =
		useUpdateOrderStatusMutation();

	const handleChangeOrderStatus = (orderID: string, status: string) => {
		changeOrderStatusMutation({
			variables: {
				orderID,
				status,
			},
			onCompleted: (data) => {
				if (data.SetOrderStatus._id) {
					toast({
						title: "Order status has been updated with success!",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
				}
				apolloClient.refetchQueries({
					include: "active",
				});
			},
			onError: (error) => {
				toast({
					title: "An error occurred while updating order status.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	const [changeItemStatusMutation] = useSetOrderItemStatusMutation();

	const handleChangeItemStatus = (
		orderId: string,
		itemId: string,
		status: "READY" | "NOTREADY"
	) => {
		changeItemStatusMutation({
			variables: {
				_id: orderID,
				refId: itemId,
				status:
					status === "READY" ? ItemStatusType.Ready : ItemStatusType.Notready,
			},
			onCompleted: (data) => {
				if (data.SetOrderItemStatus._id) {
					toast({
						title: "Item status has been updated with success!",
						status: "success",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
				}
				apolloClient.refetchQueries({
					include: "active",
				});
			},
			onError: (error) => {
				toast({
					title: "An error occurred while updating Item status.",
					description: error.message,
					status: "error",

					position: "bottom-right",
					duration: 5000,
					isClosable: true,
				});
			},
		});
	};

	const [activateNote, setActivateNote] = useState<boolean>(false);

	const [orderNotesMutation] = useAddOrderNoteMutation();

	const orderNotesFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: { note: "" },
		onSubmit: (values, { setSubmitting }) => {
			const notes = orderDetailData?.GetOrderById?.orderNotes?.map((note) => ({
				note: note.note,
			}));

			notes?.push({ note: values.note });

			orderNotesMutation({
				variables: {
					orderId: orderDetailData?.GetOrderById._id,
					orderNotes: notes,
				},
				onCompleted: (data) => {
					if (data.UpdateOrder?.code) {
						setSubmitting(false);
						toast({
							title: "Order note has been added!",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
						setActivateNote(false);
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while while creating note.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	return (
		<PageWrapper>
			<Box className='page_header'>
				<Box className='page_header_title'>
					<Box className='page_header_backlink'>
						{from && from !== "" && (
							<Link
								as={RouterLink}
								to={from}
								display='flex'
								alignItems='center'
								color='blue.400'>
								<BiArrowBack size={14} /> <Text fontSize='sm'>Back</Text>
							</Link>
						)}
					</Box>
					<Box className='page_header_title_content'>
						<Skeleton
							isLoaded={!orderDetailLoading}
							opacity={orderDetailLoading ? 0.4 : 1}
							minW='250px'>
							<Heading fontSize='2xl'>
								#{orderDetailData?.GetOrderById?.refId.replaceAll("#", "")}
							</Heading>
						</Skeleton>
					</Box>
				</Box>
			</Box>
			<Stack spacing='18px' direction='row'>
				<Stack width='1100px' spacing='18px'>
					<Box
						p='18px 24px'
						border='1px'
						borderColor={wrapperBorder}
						rounded='md'>
						<Box display='flex' justifyContent='space-between'>
							<Skeleton
								isLoaded={!orderDetailLoading}
								opacity={orderDetailLoading ? 0.4 : 1}
								minW='250px'>
								<Heading size='md' fontWeight='medium' fontSize='lg'>
									{moment(orderDetailData?.GetOrderById?.createdAt).format(
										"LLL"
									)}
								</Heading>
							</Skeleton>
							<Skeleton
								isLoaded={!orderDetailLoading}
								opacity={orderDetailLoading ? 0.4 : 1}
								minW='60px'>
								<Heading size='md' fontWeight='semibold' fontSize='xl'>
									{orderDetailData?.GetOrderById?.totalePrice} TND
								</Heading>
							</Skeleton>
						</Box>
						<Stack direction='row' spacing='16px' mt='4'>
							<Skeleton
								isLoaded={!orderDetailLoading}
								opacity={orderDetailLoading ? 0.4 : 1}
								minW='148px'
								minH='48px'>
								<Box>
									<Text>Payment status</Text>
									<Button
										variant='link'
										pointerEvents='none'
										height='fit-content'
										colorScheme={
											renderPaymentStatus(
												orderDetailData?.GetOrderById?.paymentStatus
											).color
										}>
										<Heading size='md' fontWeight='semibold' fontSize='lg'>
											{
												renderPaymentStatus(
													orderDetailData?.GetOrderById?.paymentStatus
												).text
											}
										</Heading>
									</Button>
								</Box>
							</Skeleton>
							<Skeleton
								isLoaded={!orderDetailLoading}
								opacity={orderDetailLoading ? 0.4 : 1}
								minW='148px'
								minH='48px'>
								<Box>
									<Text>Shipment status</Text>
									{userRole === "ADMIN" ? (
										<Menu>
											<MenuButton
												as={Button}
												variant='link'
												rightIcon={
													changeOrderStatusParams.loading ? (
														<Spinner size='xs' thickness='2px' />
													) : (
														<BiChevronDown size={18} />
													)
												}
												height='fit-content'
												colorScheme={
													renderOrderStatus(
														orderDetailData?.GetOrderById?.status
													).color
												}>
												<Heading size='md' fontWeight='semibold' fontSize='lg'>
													{
														renderOrderStatus(
															orderDetailData?.GetOrderById?.status
														).text
													}
												</Heading>
											</MenuButton>
											<MenuList>
												<MenuItem
													onClick={() =>
														handleChangeOrderStatus(
															orderDetailData?.GetOrderById?._id,
															"PENDING"
														)
													}>
													Awaiting processing
												</MenuItem>
												<MenuItem
													onClick={() =>
														handleChangeOrderStatus(
															orderDetailData?.GetOrderById?._id,
															"PROCESSING"
														)
													}>
													Processing
												</MenuItem>
												<MenuItem
													onClick={() =>
														handleChangeOrderStatus(
															orderDetailData?.GetOrderById?._id,
															"CONFIRMED"
														)
													}>
													Confirmed
												</MenuItem>
												<MenuItem
													onClick={() =>
														handleChangeOrderStatus(
															orderDetailData?.GetOrderById?._id,
															"READY"
														)
													}>
													Ready for delivery
												</MenuItem>
												<MenuItem
													onClick={() =>
														handleChangeOrderStatus(
															orderDetailData?.GetOrderById?._id,
															"TRANSPORTING"
														)
													}>
													In Transportation
												</MenuItem>
												<MenuItem
													onClick={() =>
														handleChangeOrderStatus(
															orderDetailData?.GetOrderById?._id,
															"COMPLETED"
														)
													}>
													Delivery canceled
												</MenuItem>
												<MenuItem
													onClick={() =>
														handleChangeOrderStatus(
															orderDetailData?.GetOrderById?._id,
															"CANCELED"
														)
													}>
													Order canceled
												</MenuItem>
												<MenuItem
													onClick={() =>
														handleChangeOrderStatus(
															orderDetailData?.GetOrderById?._id,
															"REFUNDED"
														)
													}>
													Order refunded
												</MenuItem>
												<MenuItem
													onClick={() =>
														handleChangeOrderStatus(
															orderDetailData?.GetOrderById?._id,
															"FAILED"
														)
													}>
													Order failed
												</MenuItem>
											</MenuList>
										</Menu>
									) : (
										<Button
											variant='link'
											pointerEvents='none'
											height='fit-content'
											colorScheme={
												renderOrderStatus(orderDetailData?.GetOrderById?.status)
													.color
											}>
											<Heading size='md' fontWeight='semibold' fontSize='lg'>
												{
													renderOrderStatus(
														orderDetailData?.GetOrderById?.status
													).text
												}
											</Heading>
										</Button>
									)}
								</Box>
							</Skeleton>
						</Stack>
						<Box mt='4'>
							{orderDetailLoading && (
								<Stack>
									<Skeleton opacity={0.4} height='100px' />
									<Skeleton opacity={0.4} height='100px' />
								</Stack>
							)}

							{orderDetailData?.GetOrderById?.items?.map((item, index) => (
								<Box
									key={index}
									p='12px 16px'
									border='1px'
									borderColor={wrapperBorder}
									rounded='md'
									display='flex'
									justifyContent='space-between'
									mb='3'>
									<Box display='flex'>
										<Box
											h='75px'
											w='75px'
											rounded='md'
											objectFit='cover'
											overflow='hidden'
											mr='2'>
											<img
												src={item.images?.[0] || picturePlaceholder}
												alt='product_image'
											/>
										</Box>
										<Stack spacing='0px'>
											<Text fontSize='sm'>
												Vendor{" "}
												<Link
													as={RouterLink}
													to={`/admin/stores/${item?.itemStore?._id}`}
													state={{
														from: location.pathname,
														pageName: "Stores",
													}}
													color='blue.400'>
													{item?.itemStore?.name}
												</Link>
											</Text>
											<Text fontWeight='semibold'>
												{item?.quntity}x {item?.itemName}{" "}
												<Link
													fontSize='xs'
													fontWeight={"medium"}
													as={RouterLink}
													to={
														userRole === "ADMIN"
															? `/admin/products/${item?.refId}`
															: `/${storeID}/products/${item?.refId}`
													}
													state={{
														from: location.pathname,
														pageName: "Products",
													}}
													color='blue.400'>
													View product
												</Link>
											</Text>
											{item?.itemOption && (
												<Text
													display='inline-block'
													fontSize='xs'
													opacity={0.8}>
													(
													{item?.itemOption?.map(
														(option, index) =>
															`${option.KEY}: ${option.VALUE}${
																item?.itemOption?.length !== index + 1
																	? " / "
																	: ""
															}`
													)}
													)
												</Text>
											)}
											{item?.itemSku && (
												<Text
													display='inline-block'
													fontSize='xs'
													opacity={0.8}>
													SKU: {item?.itemSku}
												</Text>
											)}
										</Stack>
									</Box>
									<Box textAlign='right'>
										<Heading
											size='md'
											fontWeight='semibold'
											fontSize='lg'
											mb='4'>
											{item?.itemPrice} TND
										</Heading>
										<Box>
											<Text fontSize='sm' opacity={0.6}>
												Availability status
											</Text>
											{userRole === "ADMIN" ? (
												<Button
													variant='link'
													colorScheme={renderItemStatus(item.itemStatus).color}
													pointerEvents='none'>
													{renderItemStatus(item.itemStatus).text}
												</Button>
											) : (
												<Menu>
													<MenuButton
														as={Button}
														variant='link'
														rightIcon={
															changeOrderStatusParams.loading ? (
																<Spinner size='xs' thickness='2px' />
															) : (
																<BiChevronDown size={18} />
															)
														}
														height='fit-content'
														colorScheme={
															renderItemStatus(item.itemStatus).color
														}>
														{renderItemStatus(item.itemStatus).text}
													</MenuButton>
													<MenuList>
														<MenuItem
															onClick={() =>
																handleChangeItemStatus(
																	orderDetailData?.GetOrderById?._id,
																	item.refId,
																	"READY"
																)
															}>
															Ready
														</MenuItem>
														<MenuItem
															onClick={() =>
																handleChangeItemStatus(
																	orderDetailData?.GetOrderById?._id,
																	item.refId,
																	"NOTREADY"
																)
															}>
															Not ready
														</MenuItem>
													</MenuList>
												</Menu>
											)}
										</Box>
									</Box>
								</Box>
							))}
						</Box>
						<Box mt='4'>
							<Divider mt='4' />
							<Box display='flex' justifyContent='space-between'>
								<Skeleton
									isLoaded={!orderDetailLoading}
									opacity={orderDetailLoading ? 0.4 : 1}>
									<Text size='sm'>
										Total items: {orderDetailData?.GetOrderById?.totaleItems}
									</Text>
								</Skeleton>
								<Skeleton
									isLoaded={!orderDetailLoading}
									opacity={orderDetailLoading ? 0.4 : 1}>
									<Stack direction='row'>
										<Text fontWeight='medium' size='sm' mr='6'>
											Total to pay
										</Text>
										<Text fontWeight='medium' size='sm'>
											{orderDetailData?.GetOrderById?.totalePrice} TND
										</Text>
									</Stack>
								</Skeleton>
							</Box>
						</Box>
					</Box>
					<Box
						p='18px 24px'
						border='1px'
						width='900px'
						borderColor={wrapperBorder}
						rounded='md'>
						<Heading size='md' fontWeight='medium' fontSize='lg'>
							Order notes
						</Heading>
						<Skeleton
							isLoaded={!orderDetailLoading}
							opacity={orderDetailLoading ? 0.4 : 1}>
							<Stack mt='4' spacing='18px'>
								{orderDetailData?.GetOrderById.orderNotes?.map(
									(note, index) => (
										<Box key={index}>
											<Text fontSize='sm' opacity={0.6}>
												{moment(note.createdAt).format("LLL")}
											</Text>
											<Text>{note.note}</Text>
										</Box>
									)
								)}
								{activateNote ? (
									<form onSubmit={orderNotesFormik.handleSubmit}>
										<FormControl mt='4'>
											<FormLabel marginBottom='1'>Write your note</FormLabel>
											<Textarea
												placeholder='Note'
												name='note'
												onChange={orderNotesFormik.handleChange}
												value={orderNotesFormik.values.note}
											/>
										</FormControl>
										<Button
											colorScheme='main'
											mt='2'
											type='submit'
											isLoading={orderNotesFormik.isSubmitting}>
											Add your note
										</Button>
									</form>
								) : (
									<Box mt='3'>
										<Button onClick={() => setActivateNote(true)}>
											Add a note
										</Button>
									</Box>
								)}
							</Stack>
						</Skeleton>
					</Box>
				</Stack>
				<Box
					p='18px 24px'
					border='1px'
					borderColor={wrapperBorder}
					rounded='md'
					w='full'
					height='fit-content'>
					<Skeleton
						isLoaded={!orderDetailLoading}
						opacity={orderDetailLoading ? 0.4 : 1}>
						<Stack>
							<Box display='flex' alignItems='center'>
								<img
									src={
										orderDetailData?.GetOrderById.user?.avatar ||
										profilePicturePlaceholder
									}
									className='order_user_picture'
									alt='user_profile_picture'
								/>
								<Box ml='3'>
									<Text fontWeight='medium'>
										{orderDetailData?.GetOrderById.user?.firstName +
											" " +
											orderDetailData?.GetOrderById.user?.lastName}
									</Text>
									<Link
										fontSize='sm'
										color='blue.400'
										href={`mailto:${orderDetailData?.GetOrderById?.user?.email}`}>
										{orderDetailData?.GetOrderById?.user?.email}
									</Link>
									<Text>
										Phone:{" "}
										<Link
											color='blue.400'
											href={`tel:${orderDetailData?.GetOrderById?.user?.userAddress?.[0]?.phoneNumber?.prefix} ${orderDetailData?.GetOrderById?.user?.userAddress?.[0]?.phoneNumber?.number}`}>
											{
												orderDetailData?.GetOrderById?.user?.userAddress?.[0]
													?.phoneNumber?.prefix
											}
											{
												orderDetailData?.GetOrderById?.user?.userAddress?.[0]
													?.phoneNumber?.number
											}
										</Link>
									</Text>
								</Box>
							</Box>
							<Divider />
							<Box>
								<Heading
									size='sm'
									fontWeight='semibold'
									display='flex'
									gap='4px'>
									<BiMoney fill='#3A85F5' size={18} /> Payment
								</Heading>
								<Stack direction='row' pt='2'>
									<Text>Choice: </Text>
									<Text fontWeight='medium'>
										{orderDetailData?.GetOrderById?.paymentType === "ONLINE"
											? "Online payment"
											: "Cash on delivery"}
									</Text>
								</Stack>
							</Box>
							<Divider />
							<Box>
								<Heading
									size='sm'
									fontWeight='semibold'
									display='flex'
									gap='4px'>
									<BiMap fill='#3A85F5' size={18} /> Address
								</Heading>
								<Text mt='2'>
									{orderDetailData?.GetOrderById?.user?.userAddress?.[0]
										?.apartment +
										" " +
										orderDetailData?.GetOrderById?.user?.userAddress?.[0]
											?.streetAddress}
								</Text>
								<Text>
									{orderDetailData?.GetOrderById?.user?.userAddress?.[0]?.city +
										" " +
										orderDetailData?.GetOrderById?.user?.userAddress?.[0]
											?.zipCode}
								</Text>
								<Text>
									{
										orderDetailData?.GetOrderById?.user?.userAddress?.[0]
											?.country
									}
								</Text>
								<Text>
									Phone:{" "}
									<Link
										color='blue.400'
										href={`tel:${orderDetailData?.GetOrderById?.user?.userAddress?.[0]?.phoneNumber?.prefix} ${orderDetailData?.GetOrderById?.user?.userAddress?.[0]?.phoneNumber?.number}`}>
										{
											orderDetailData?.GetOrderById?.user?.userAddress?.[0]
												?.phoneNumber?.prefix
										}
										{
											orderDetailData?.GetOrderById?.user?.userAddress?.[0]
												?.phoneNumber?.number
										}
									</Link>
								</Text>
							</Box>
						</Stack>
					</Skeleton>
				</Box>
			</Stack>
		</PageWrapper>
	);
};

export default OrderDetails;
