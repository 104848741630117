import {
	Box,
	Text,
	Heading,
	Stack,
	useColorModeValue,
	useToast,
	Skeleton,
	Divider,
	Button,
	FormLabel,
	Switch,
	FormControl,
	Textarea,
	IconButton,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import {
	UpdateProductsSeoByIdMutationVariables,
	useGetProductSeoByIdQuery,
	useUpdateProductsSeoByIdMutation,
} from "../../../api/generated/graphql";
import {
	CategorySelectInput,
	TagsSelectInput,
	Input,
} from "../../../components";
import { apolloClient } from "../../..";
import { BiPlus, BiTrash } from "react-icons/bi";
import { getDataFromCache } from "../../../utils/cache";
import { useNavigate } from "react-router-dom";

type ProductFilesFormProps = {
	productID?: string;
	actionButtons: (node: ReactNode) => void;
};

const ProductSEOForm = (props: ProductFilesFormProps) => {
	const { productID, actionButtons } = props;

	const borderColor = useColorModeValue("gray.100", "gray.700");

	const toast = useToast();

	const { data: seoProductData, loading: seoProductLoading } =
		useGetProductSeoByIdQuery({
			variables: {
				product_id: productID,
			},
		});

	const [metaTags, setMetaTags] = useState<
		{ key?: string | null; value?: string | null }[]
	>([]);

	const handleChangeMetaTags = (
		value: string,
		index: number,
		type: "key" | "value"
	) => {
		setMetaTags((prevState) => {
			let temp = [...prevState];
			if (type === "key") {
				temp[index].key = value;
			} else {
				temp[index].value = value;
			}
			return temp;
		});
	};

	const handleDeleteMetaTag = (index: number) => {
		setMetaTags((prevState) => {
			let temp = [...prevState];
			temp.splice(index, 1);
			return temp;
		});
	};

	const handleAddMetatag = () => {
		setMetaTags((prevState) => [...prevState, { key: "", value: "" }]);
	};

	const [updateSeoMutation] = useUpdateProductsSeoByIdMutation();

	const seoProductInitialValues: Omit<
		UpdateProductsSeoByIdMutationVariables,
		"tags" | "product_id" | "category" | "seo"
	> & {
		category: {
			id: string;
			label: string;
		};
		tags: string[];
		metaDescription: string;
		seoTitle: string;
	} = {
		brand: "",
		isAvilable: true,
		metaDescription: "",
		seoTitle: "",
		category: {
			id: "",
			label: "",
		},
		tags: [],
	};

	const seoProductFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: seoProductInitialValues,
		// validate: (values) => {
		// 	const errors: FormikErrors<typeof values> = {};
		// 	if (!values.name) {
		// 		errors.name = "Product name is required!";
		// 	}
		// 	return errors;
		// },
		onSubmit: (values, { setSubmitting }) => {
			updateSeoMutation({
				variables: {
					product_id: productID,
					brand: values.brand || undefined,
					category: values.category.id || undefined,
					seo: {
						metadescription: values.metaDescription,
						metatags: metaTags,
						seotitle: values.seoTitle,
					},
					isAvilable: values.isAvilable,
					tags: values.tags || undefined,
				},
				onCompleted: (data) => {
					if (data.UpdateProduct?._id) {
						setSubmitting(false);
						toast({
							title: "Product seo has been updated with success.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while updating product.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	useEffect(() => {
		setMetaTags(
			seoProductData?.GetProductById?.seo?.metatags?.map((tag) => ({
				key: tag.key || "",
				value: tag.value || "",
			})) || []
		);
		seoProductFormik.setValues({
			brand: seoProductData?.GetProductById?.brand || "",
			isAvilable: seoProductData?.GetProductById?.isAvilable,
			metaDescription:
				seoProductData?.GetProductById?.seo?.metadescription || "",
			seoTitle: seoProductData?.GetProductById?.seo?.seotitle || "",
			category: {
				id: seoProductData?.GetProductById?.category?._id || "",
				label: seoProductData?.GetProductById?.category?.name || "",
			},
			tags: seoProductData?.GetProductById.tags || [],
		});
	}, [seoProductData]);

	const userRole: string = getDataFromCache("user_role", true);

	const navigate = useNavigate();

	const storeID = getDataFromCache("store_id", true);

	useEffect(() => {
		actionButtons(
			<>
				<Button
					variant='outline'
					onClick={() =>
						navigate(
							userRole === "ADMIN" ? `/admin/products` : `/${storeID}/products`,
							{
								state: {
									pageName: "Products",
								},
							}
						)
					}>
					Discard
				</Button>
				<Button
					colorScheme='main'
					type='submit'
					form='update-product-seo'
					isLoading={seoProductFormik.isSubmitting}>
					Save
				</Button>
			</>
		);
	}, [seoProductFormik.isSubmitting]);

	return (
		<form id='update-product-seo' onSubmit={seoProductFormik.handleSubmit}>
			<Stack direction='row' spacing='14px' alignItems='flex-start'>
				<Stack flex={0.75} spacing='12px'>
					<Stack
						padding='18px 18px'
						border='1px'
						borderColor={borderColor}
						rounded='base'>
						<Box>
							<Heading size='md' mb='2'>
								SEO
							</Heading>
							<Text opacity={0.6} lineHeight='1.2' fontSize='sm' width='650px'>
								Add a title and description to see how this product might appear
								in a search engine listing.
							</Text>
						</Box>
						<Stack>
							<Input
								label='Page title'
								inputProps={{
									placeholder: "Page title",
									name: "seoTitle",
									onChange: seoProductFormik.handleChange,
									value: seoProductFormik.values.seoTitle,
								}}
							/>
							<Input
								label='Page slug (read only)'
								inputProps={{
									placeholder: "Page slug",
									value: seoProductData?.GetProductById?.slug || "",
									isReadOnly: true,
								}}
								inputLeftAddon={{
									children: process.env.REACT_APP_MARKETPLACE_URL + "products/",
								}}
							/>
							<FormControl>
								<FormLabel marginBottom='1'>Meta description</FormLabel>
								<Textarea
									placeholder='Meta Description'
									name='metaDescription'
									onChange={seoProductFormik.handleChange}
									value={seoProductFormik.values.metaDescription}
								/>
							</FormControl>
							<Box>
								<FormLabel>Meta tags</FormLabel>
								{metaTags.map((tag, index) => (
									<Box>
										<Text>Tag {index + 1}</Text>
										<Stack
											mb='12px'
											direction='row'
											alignItems='flex-end'
											spacing='14px'>
											<Input
												label='Key'
												inputProps={{
													placeholder: "Key",
													onChange: (e) =>
														handleChangeMetaTags(e.target.value, index, "key"),
													value: tag.key || "",
												}}
											/>
											<Input
												label='Value'
												inputProps={{
													placeholder: "Value",
													onChange: (e) =>
														handleChangeMetaTags(
															e.target.value,
															index,
															"value"
														),
													value: tag.value || "",
												}}
											/>
											<IconButton
												variant='ghost'
												colorScheme='red'
												aria-label='delete_meta-tags'
												onClick={() => handleDeleteMetaTag(index)}>
												<BiTrash size='18' />
											</IconButton>
										</Stack>
									</Box>
								))}
								<Button
									onClick={handleAddMetatag}
									variant='outline'
									leftIcon={<BiPlus />}
									isFullWidth>
									Add a meta tag
								</Button>
							</Box>
						</Stack>
					</Stack>
				</Stack>
				<Stack flex={0.25} spacing='12px'>
					<Skeleton
						isLoaded={!seoProductLoading}
						opacity={seoProductLoading ? 0.4 : 1}>
						<Stack
							padding='18px 24px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<Input
								label='Brand'
								inputProps={{
									placeholder: "Brand",
									name: "brand",
									onChange: seoProductFormik.handleChange,
									value: seoProductFormik.values.brand || "",
								}}
								errorMessage={seoProductFormik.errors.brand}
								isError={seoProductFormik.errors.brand !== undefined}
							/>
							<CategorySelectInput
								selected={seoProductFormik.values.category}
								handleSelectCategory={(id, label) =>
									seoProductFormik.setFieldValue("category", {
										id,
										label,
									})
								}
							/>
							<Box paddingTop='12px' paddingBottom='6px'>
								<Divider color={borderColor} />
							</Box>
							<TagsSelectInput
								tags={seoProductFormik.values.tags}
								onCreateTag={(value) =>
									seoProductFormik.setFieldValue("tags", [
										value,
										...seoProductFormik.values.tags,
									])
								}
								onDeleteTag={(index) => {
									let tempValues = [...seoProductFormik.values.tags];
									tempValues.splice(index, 1);
									seoProductFormik.setFieldValue("tags", tempValues);
								}}
							/>
						</Stack>
					</Skeleton>
					<Skeleton
						isLoaded={!seoProductLoading}
						opacity={seoProductLoading ? 0.4 : 1}>
						<Stack
							padding='18px 24px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<FormLabel>Product availability</FormLabel>
							<Stack direction='row' alignItems='center'>
								<Switch
									size='md'
									colorScheme='green'
									name='isAvilable'
									onChange={seoProductFormik.handleChange}
									isChecked={
										seoProductFormik.values.isAvilable !== null &&
										seoProductFormik.values.isAvilable
									}
								/>
								<Text
									color={
										seoProductFormik.values.isAvilable ? "green.400" : undefined
									}>
									{seoProductFormik.values.isAvilable
										? "Available"
										: "Not available"}
								</Text>
							</Stack>
						</Stack>
					</Skeleton>
				</Stack>
			</Stack>
		</form>
	);
};
export default ProductSEOForm;
