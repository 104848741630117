import React from "react";
import ReactDOM from "react-dom";
import {
	ChakraProvider,
	ColorModeScript,
	theme as chakraTheme,
} from "@chakra-ui/react";

import App from "./App";
import { Fonts, theme } from "./global";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { getDataFromCache } from "./utils/cache";
import { UserTokens } from "./utils/globalTypes";
import "./index.css";

const tokens: UserTokens = getDataFromCache("user_tokens");

export const apolloClient = new ApolloClient({
	uri: process.env.REACT_APP_GQL_URL,
	cache: new InMemoryCache(),
	headers: {
		Authorization: tokens?.access || "",
	},
});

console.log(chakraTheme);

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={apolloClient}>
			<ChakraProvider theme={theme}>
				<ColorModeScript initialColorMode={theme.config.initialColorMode} />
				<Fonts />
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</ChakraProvider>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
