import {
	Box,
	Heading,
	Stack,
	useColorModeValue,
	useToast,
	Text,
	Button,
	IconButton,
	Skeleton,
	Divider,
	FormLabel,
	Switch,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Checkbox,
	useDisclosure,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { BiBriefcaseAlt, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { apolloClient } from "../../..";
import {
	UpdateProductRelatedProductsMutationFn,
	UpdateProductRelatedProductsMutationVariables,
	useGetProductRelatedProductsByIdQuery,
	useGetProductsForRelatedProductsQuery,
	useUpdateProductRelatedProductsMutation,
} from "../../../api/generated/graphql";
import { picturePlaceholder } from "../../../assets";
import {
	CategorySelectInput,
	Input,
	Table,
	TableHeadType,
	TagsSelectInput,
} from "../../../components";
import { getDataFromCache } from "../../../utils/cache";

type ProductRelatedFormProps = {
	productID?: string;
	actionButtons: (node: ReactNode) => void;
};

const ProductRelatedForm = (props: ProductRelatedFormProps) => {
	const { productID, actionButtons } = props;

	const borderColor = useColorModeValue("gray.100", "gray.700");

	const toast = useToast();

	const navigate = useNavigate();

	const userRole: string = getDataFromCache("user_role", true);

	const storeID = getDataFromCache("store_id", true);

	const [updateRelatedProductsMutation] =
		useUpdateProductRelatedProductsMutation();

	const { data: relatedProductsData, loading: relatedProductsLoading } =
		useGetProductRelatedProductsByIdQuery({
			variables: {
				product_id: productID,
			},
		});

	const relatedProductInitialValues: Omit<
		UpdateProductRelatedProductsMutationVariables,
		"tags" | "product_id" | "category" | "bundles"
	> & {
		category: {
			id: string;
			label: string;
		};
		tags: string[];
		bundles: {
			_id: string;
			name: string;
			sku: string;
			price: string;
			image: string;
		}[];
	} = {
		brand: "",
		isAvilable: true,
		category: {
			id: "",
			label: "",
		},
		tags: [],
		bundles: [],
	};

	const relatedProductFormik = useFormik({
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: relatedProductInitialValues,
		onSubmit: (values, { setSubmitting }) => {
			updateRelatedProductsMutation({
				variables: {
					product_id: productID,
					brand: values.brand || undefined,
					bundles: values.bundles.map((product) => product?._id) || [],
					category: values.category?.id || undefined,
					isAvilable: values.isAvilable,
					tags: values.tags || undefined,
				},
				onCompleted: (data) => {
					if (data?.UpdateProduct?._id) {
						setSubmitting(false);
						toast({
							title: "Related Products has been updated with success.",
							status: "success",

							position: "bottom-right",
							duration: 5000,
							isClosable: true,
						});
						apolloClient.refetchQueries({
							include: "active",
						});
					}
				},
				onError: (error) => {
					toast({
						title: "An error occurred while updating product.",
						description: error.message,
						status: "error",

						position: "bottom-right",
						duration: 5000,
						isClosable: true,
					});
					setSubmitting(false);
				},
			});
		},
	});

	const handleDeleteBundle = (index: number) => {
		let temp = [...relatedProductFormik.values.bundles];
		temp.splice(index, 1);

		return temp;
	};

	const productsTableData =
		relatedProductFormik?.values?.bundles?.map((product, index) => ({
			image: (
				<Box className='table_image'>
					<img
						src={product?.image || picturePlaceholder}
						alt='product_profile_picture'
					/>
				</Box>
			),
			productName: product?.name,
			sku: product?.sku,
			price: product?.price + " TND",
			// status: (
			// 	<Text color={option.isActive ? "green.400" : undefined}>
			// 		{option.isActive ? "Active" : "Deactivated"}
			// 	</Text>
			// ),
			action: (
				<IconButton
					aria-label='delete-option-button'
					colorScheme='error'
					variant='ghost'
					onClick={() =>
						relatedProductFormik.setFieldValue(
							"bundles",
							handleDeleteBundle(index)
						)
					}>
					<BiTrash size={18} />
				</IconButton>
			),
		})) || [];

	type ProductsTableData = typeof productsTableData[number];

	const productsTableHead: TableHeadType<ProductsTableData>[] = [
		{ accessor: "image", label: "" },
		{ accessor: "productName", label: "Product name" },
		{ accessor: "sku", label: "SKU" },
		{ accessor: "price", label: "Price" },
		{ accessor: "action", label: "", headCellProps: { isNumeric: true } },
	];

	useEffect(() => {
		relatedProductFormik.setValues({
			brand: relatedProductsData?.GetProductById?.brand || "",
			isAvilable: relatedProductsData?.GetProductById?.isAvilable,
			bundles:
				relatedProductsData?.GetProductById?.bundles?.map((product, index) => ({
					_id: product?._id || "",
					image: product?.images?.[0] || "",
					name: product?.name || "",
					price: product?.price?.price ? product?.price?.price.toString() : "",
					sku: product?.inventory?.sku || "",
				})) || [],
			category: {
				id: relatedProductsData?.GetProductById?.category?._id || "",
				label: relatedProductsData?.GetProductById?.category?.name || "",
			},
			tags: relatedProductsData?.GetProductById.tags || [],
		});
	}, [relatedProductsData]);

	const {
		isOpen: assignIsOpen,
		onClose: assignOnClose,
		onOpen: assignOnOpen,
	} = useDisclosure();

	const handleAddProducts = (
		products: {
			_id: string;
			name: string;
			sku: string;
			price: string;
			image: string;
		}[]
	) => {
		relatedProductFormik.setFieldValue("bundles", products);
		assignOnClose();
	};

	useEffect(() => {
		actionButtons(
			<>
				<Button
					variant='outline'
					onClick={() =>
						navigate(
							userRole === "ADMIN" ? `/admin/products` : `/${storeID}/products`,
							{
								state: {
									pageName: "Products",
								},
							}
						)
					}>
					Discard
				</Button>
				<Button
					colorScheme='main'
					type='submit'
					form='update-product-related-products'
					isLoading={relatedProductFormik.isSubmitting}>
					Save
				</Button>
			</>
		);
	}, [relatedProductFormik.isSubmitting]);

	return (
		<form
			id='update-product-related-products'
			onSubmit={relatedProductFormik.handleSubmit}>
			<Stack direction='row' spacing='14px' alignItems='flex-start'>
				<Stack flex={0.75} spacing='12px'>
					<Stack
						padding='18px 18px'
						border='1px'
						borderColor={borderColor}
						rounded='base'>
						<Stack width='full' direction='row' justifyContent='space-between'>
							<Box>
								<Heading size='md' mb='2'>
									Related Products
								</Heading>
								<Text
									opacity={0.6}
									lineHeight='1.2'
									fontSize='sm'
									width='650px'>
									Show the “You may also like” section on the product page to
									promote other products and increase sales. You can pick
									related products manually or we will handle it automaticly.
								</Text>
							</Box>

							<Button onClick={assignOnOpen} colorScheme='main' size='sm'>
								Assign products
							</Button>
						</Stack>
					</Stack>
					<Table
						head={productsTableHead}
						data={productsTableData}
						isLoading={relatedProductsLoading}
						emptyState={
							<Box className='empty_table_container'>
								<Text fontSize='md' fontWeight='medium'>
									Product has no related products
								</Text>
							</Box>
						}
					/>
				</Stack>
				<Stack flex={0.25} spacing='12px'>
					<Skeleton
						isLoaded={!relatedProductsLoading}
						opacity={relatedProductsLoading ? 0.4 : 1}>
						<Stack
							padding='18px 24px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<Input
								label='Brand'
								inputProps={{
									placeholder: "Brand",
									name: "brand",
									onChange: relatedProductFormik.handleChange,
									value: relatedProductFormik.values.brand || "",
								}}
								errorMessage={relatedProductFormik.errors.brand}
								isError={relatedProductFormik.errors.brand !== undefined}
							/>
							<CategorySelectInput
								selected={relatedProductFormik.values.category}
								handleSelectCategory={(id, label) =>
									relatedProductFormik.setFieldValue("category", {
										id,
										label,
									})
								}
							/>
							<Box paddingTop='12px' paddingBottom='6px'>
								<Divider color={borderColor} />
							</Box>
							<TagsSelectInput
								tags={relatedProductFormik.values.tags}
								onCreateTag={(value) =>
									relatedProductFormik.setFieldValue("tags", [
										value,
										...relatedProductFormik.values.tags,
									])
								}
								onDeleteTag={(index) => {
									let tempValues = [...relatedProductFormik.values.tags];
									tempValues.splice(index, 1);
									relatedProductFormik.setFieldValue("tags", tempValues);
								}}
							/>
						</Stack>
					</Skeleton>
					<Skeleton
						isLoaded={!relatedProductsLoading}
						opacity={relatedProductsLoading ? 0.4 : 1}>
						<Stack
							padding='18px 24px'
							border='1px'
							borderColor={borderColor}
							rounded='base'>
							<FormLabel>Product availability</FormLabel>
							<Stack direction='row' alignItems='center'>
								<Switch
									size='md'
									colorScheme='green'
									name='isAvilable'
									onChange={relatedProductFormik.handleChange}
									isChecked={
										relatedProductFormik.values.isAvilable !== null &&
										relatedProductFormik.values.isAvilable
									}
								/>
								<Text
									color={
										relatedProductFormik.values.isAvilable
											? "green.400"
											: undefined
									}>
									{relatedProductFormik.values.isAvilable
										? "Available"
										: "Not available"}
								</Text>
							</Stack>
						</Stack>
					</Skeleton>
				</Stack>
			</Stack>
			{assignIsOpen && (
				<AssignProductsModal
					handleAssignProduct={handleAddProducts}
					isOpen={assignIsOpen}
					onClose={assignOnClose}
					selected={relatedProductFormik?.values?.bundles}
				/>
			)}
		</form>
	);
};

export default ProductRelatedForm;

type AssignProductsModalProps = {
	isOpen: boolean;
	onClose: () => void;
	handleAssignProduct: (
		product: {
			_id: string;
			name: string;
			sku: string;
			price: string;
			image: string;
		}[]
	) => void;
	selected: {
		_id: string;
		name: string;
		sku: string;
		price: string;
		image: string;
	}[];
};

const AssignProductsModal = (props: AssignProductsModalProps) => {
	const {
		isOpen,
		onClose,
		handleAssignProduct,
		selected: prevSelectedProducts,
	} = props;

	const [selectedProducts, setSelectedProducts] = useState<
		{
			_id: string;
			name: string;
			sku: string;
			price: string;
			image: string;
		}[]
	>(prevSelectedProducts);

	const handleSelectProduct = (product: {
		_id: string;
		name: string;
		sku: string;
		price: string;
		image: string;
	}) => {
		setSelectedProducts((prevState) => [...prevState, product]);
	};

	const [pagination, setPagination] = useState({
		limit: 10,
		skip: 0,
	});

	const handlePageChange = (dir: "next" | "prev") => {
		if (dir === "next") {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip + temp.limit;
				return temp;
			});
		} else {
			setPagination((prevState) => {
				let temp = { ...prevState };
				temp.skip = temp.skip - temp.limit;
				return temp;
			});
		}
	};

	const handleLimitChange = (value: number) => {
		setPagination((prevState) => {
			let temp = { ...prevState };
			temp.limit = +value;
			return temp;
		});
	};

	const { data: productToAssignListData, loading: productToAssignListLoading } =
		useGetProductsForRelatedProductsQuery({
			variables: {
				pagination: pagination,
			},
		});

	const handleRemoveSelectedProduct = (id: string) => {
		setSelectedProducts((prevState) => {
			let temp = [...prevState];
			const index = temp
				.map((p) => {
					return p._id;
				})
				.indexOf(id, 0);
			if (index > -1) {
				temp.splice(index, 1);
			}
			return temp;
		});
	};

	const productsToAssignTableData =
		productToAssignListData?.GetAllProducts?.products?.map(
			(product, index) => ({
				id: product._id,
				check: (
					<Checkbox
						value={product._id}
						onChange={(e) => {
							if (e.target.checked) {
								handleSelectProduct({
									_id: product?._id,
									image: product?.images?.[0] || "",
									name: product?.name || "",
									price: product?.price?.price
										? product?.price?.price.toString()
										: "",
									sku: product?.inventory?.sku || "",
								});
							} else {
								handleRemoveSelectedProduct(e.target.value);
							}
						}}
						isChecked={
							selectedProducts.find(
								(selected) => selected?._id === product?._id
							)
								? true
								: false
						}
					/>
				),
				name: product?.name,
				sku: product?.inventory?.sku,
				category: product?.category?.name,
				store: product?.store?.name,
			})
		) || [];

	type ProductsToAssignTableType = typeof productsToAssignTableData[number];

	const productsToAssignTableHead: TableHeadType<ProductsToAssignTableType>[] =
		[
			{ accessor: "check", label: "" },
			{ accessor: "name", label: "Name" },
			{ accessor: "sku", label: "SKU" },
			{ accessor: "category", label: "Category" },
			{ accessor: "store", label: "store" },
		];

	const handleSubmit = () => {
		handleAssignProduct(selectedProducts);
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			scrollBehavior='inside'
			size='2xl'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader borderBottomWidth='1px'>Assign Products</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack spacing='14px' py='14px'>
						<Heading size='md'>
							Select products wish you want to assign to category
						</Heading>
						<Box>
							{selectedProducts.length !== 0 && (
								<Text fontSize='sm' opacity={0.6}>
									{selectedProducts.length} Selected
								</Text>
							)}
							<Table
								head={productsToAssignTableHead}
								data={productsToAssignTableData}
								isLoading={productToAssignListLoading}
								emptyState={
									<Box className='empty_table_container'>
										<BiBriefcaseAlt size={42} />
										<Text fontSize='md' fontWeight='medium'>
											There is no products!
										</Text>
									</Box>
								}
								pagination={{
									count:
										productToAssignListData?.GetAllProducts.queryCount || 0,
									limit: pagination.limit,
									skip: pagination.skip,
									onPageChange: handlePageChange,
									onChangeLimit: handleLimitChange,
								}}
							/>
						</Box>
					</Stack>
				</ModalBody>

				<ModalFooter borderTopWidth='1px'>
					<Button variant='Outline' onClick={onClose}>
						Cancel
					</Button>
					<Button colorScheme='main' onClick={handleSubmit}>
						Assign products
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
